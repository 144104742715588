/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/library/auth';
import About from '@/views/About.vue'
import Portal from '@/views/Portal.vue'
// import Remote from '@/views/Remote.vue'
import Root from '@/views/Root.vue'
import Panel from '@/views/Panel.vue'
import Panel_RKP_CHR from '@/views/Panel_RKP_CHR.vue'
import Panel_RKP_RGB from '@/views/Panel_RKP_RGB.vue'
import Settings from '@/views/Settings.vue'
import SettingsRKP from '@/views/SettingsRKP.vue'
import Inspection from '@/views/Inspection.vue'
import InspectionRKP from '@/views/InspectionRKP.vue'
import Calibration from '@/views/Calibration.vue'
import Terminal from '@/views/Terminal.vue'
import Logs from '@/views/Logs.vue'
import LogFile from '@/views/LogFile.vue'
//import History from '@/views/History.vue'
import HistoryDevice from '@/views/HistoryDevice.vue'
import DatasetEditor from '@/views/DatasetEditor.vue'
import HistoryEditor from '@/views/HistoryEditor.vue'
// import Projects from '@/views/Projects.vue'
//import Project from '@/views/Project.vue'
import ProjectEditor from '@/views/ProjectEditor.vue'
import LogoutSuccess from '@/components/LogoutSuccess.vue';
import UserNotRegistered from '@/components/UserNotRegistered.vue';
import UserInfoApi from '@/library/user-info-api';
import store from '@/store/index.js';
import ErrorComponent from '@/components/Error';


var appMode = process.env.VUE_APP_MODE;
console.log(`VUE_APP_MODE: ${appMode}`);

function requireAuth(to, from, next) { 

  var currUrl = window.location.href; 
  console.log('requireAuth CURL: ' + currUrl);
    
  console.log(`requireAuth. isUserSignedIn: ${auth.auth.isUserSignedIn()}, URL: ${to.fullPath}, PathToRedirect: ${localStorage.getItem("PathToRedirect")}`);
  //Use local storage in browser to persist the requested path when no log in
  if (typeof(Storage) !== "undefined") {
    console.log(`Local storage is supported`)
    if (!auth.auth.isUserSignedIn()) {
      localStorage.setItem("PathToRedirect", to.fullPath);
    }    
  } else {
    console.error(`Local storage is not supported`)
  }
  
  if (!auth.auth.isUserSignedIn()) { // User is not signed in
      store.commit('login/setLoggedIn', false);
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
  } else { // User is signed in
    UserInfoApi.getUserInfo().then(response => {
      console.log(`UserInfoApi.getUserInfo Response: ${JSON.stringify(response)}`)
      if (response.email_verified == "false" || response.email_verified == false) {
        console.log(`User is not verified. Redirect to /userNotRegistered`);
        next({
          path: '/userNotRegistered',
          query: { redirect: to.fullPath }
        });
      } else {
        store.commit('login/setLoggedIn', true);
        store.commit('login/setCognitoInfo', response);
        let pathToRedirect = localStorage.getItem("PathToRedirect")
        if (pathToRedirect !== 'null') { // If PathToRedirect was stored 
          localStorage.setItem("PathToRedirect", null);
          next({path: pathToRedirect});
        } else next()
      }
    })
    .catch(error => {
      console.log(`Error getting user info: ${error}`);
      next({
        path: '/login',
        query: { message: 'Error getting user info' }
      });
    });
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/portal', // Redirect to /portal
    //component: Home,
    //beforeEnter: requireAuth
    beforeEnter(to, from, next){
      var currUrl = window.location.href;      
      console.log('HOME CURL: ' + currUrl);
    }
  },
  {
    path: '/login',
    beforeEnter(to, from, next){
      //auth.auth.getSession();
      console.log('beforeEnter /login')
      auth.login();
    }
  },
  {
    path: '/login/oauth2/code/cognito',
    beforeEnter(to, from, next){
      console.log('/login/oauth2/code/cognito')
      var currUrl = window.location.href;      
      console.log('CURL: ' + currUrl);
      auth.auth.parseCognitoWebResponse(currUrl);
      //next();
    }
  },
  {
    path: '/logout',
    component: LogoutSuccess,
    beforeEnter(to, from, next){
      auth.logout();
      next();
    }
  },
  {
    path: '/userNotRegistered',
    component: UserNotRegistered,
  },
  {
    path: '/error',
    component: ErrorComponent
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About
  },
  {
    path: '/root',
    name: 'Root',
    component: Root,
    beforeEnter: requireAuth
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    beforeEnter: requireAuth
  },
  // {
  //   path: '/remote',
  //   name: 'Remote',
  //   component: Remote,
  //   beforeEnter: requireAuth
  // },
  {
    path: '/panel/:Customer/:DevId/:DevIp',
    name: 'Panel',
    component: Panel,
    beforeEnter(to, from, next){
      var currUrl = window.location.href;      
      console.log('Panel CURL: ' + currUrl);
      if (appMode != 'LOCAL') { // Require Auth only in REMOTE mode
        requireAuth(to, from, next)
      } else { // LOCAL mode
        next();
      }
    }
    //beforeEnter: requireAuth
  },
  {
    path: '/panel_rkp_chr/:DevId/:DevIp',
    name: 'PanelRKPCHR',
    component: Panel_RKP_CHR,
    beforeEnter: requireAuth
  },
  {
    path: '/panel_rkp_rgb/:DevId/:DevIp',
    name: 'PanelRKPRGB',
    component: Panel_RKP_RGB,
    beforeEnter: requireAuth
  },
  {
    path: '/settings/:DevName/:DevId',
    name: 'Settings',
    component: Settings,
    beforeEnter: requireAuth
  },
  {
    path: '/settings_rkp/:DevName/:DevId',
    name: 'SettingsRKP',
    component: SettingsRKP,
    beforeEnter: requireAuth
  },
  {
    path: '/inspection/:DevId',
    name: 'Inspection',
    component: Inspection,
    beforeEnter: requireAuth
  },
  {
    path: '/inspection_rkp/:DevId',
    name: 'InspectionRKP',
    component: InspectionRKP,
    beforeEnter: requireAuth
  },
  {
    path: '/calibration/:DevId',
    name: 'Calibration',
    component: Calibration,
    beforeEnter: requireAuth
  },
  {
    path: '/terminal/:DevId',
    name: 'Terminal',
    component: Terminal,
    beforeEnter: requireAuth
  },
  {
    path: '/logs/:DevId',
    name: 'Logs',
    component: Logs,
    beforeEnter: requireAuth
  },
  {
    path: '/logfile/:FileName/:DevId',
    name: 'LogFile',
    component: LogFile,
    beforeEnter: requireAuth
  },
  {
    path: '/history/:Customer/:ProjectId/:DevName/:DevIds/:startDateTime',
    name: 'HistoryDevice',
    component: HistoryDevice,
    beforeEnter: requireAuth
  },
  // Project History editor
  {
    path: '/historyeditor/:customer/:projectId/:startDateTime',
    name: 'HistoryEditor',
    component: HistoryEditor,
    beforeEnter: requireAuth
  },
  {
    path: '/dataset/:Customer/:DatasetId',
    name: 'DatasetEditor',
    component: DatasetEditor,
    beforeEnter: requireAuth
  },
  // {
  //   path: '/projects',
  //   name: 'Projects',
  //   component: Projects,
  //   beforeEnter: requireAuth
  // },
  // {
  //   path: '/project/:ProjectId',
  //   name: 'Project',
  //   component: Project,
  //   beforeEnter: requireAuth
  // },
  {
    path: '/projecteditor/:ProjectId',
    name: 'ProjectEditor',
    component: ProjectEditor,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

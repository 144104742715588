// Created by SPe on 17/07/2022
// component for New Project Form
<template>
  <div class="root">
    <h2>Create a new Project</h2>
    <!-- Project Name -->
    <h4>Project name:</h4>
    <!-- <label for="projectName">Project name:</label><br> -->
    <input class="py-1 rounded-md border-2 border-gray-800 bg-green-100" type="text" id="projectName" placeholder="Project Name" v-model="projectName" :class="{error: !validateProjectName(projectName)}"/>
    <div v-if="!validateProjectName(projectName)" class="bg-yellow-200 p-2"><small style="color:blue">Project name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed. Can not be the same as an already existing one.</small></div>
    <hr class="my-2">
    <!-- Camera configuration -->
    <!-- <h4>Camera config:</h4>
    <select v-model="camConfigSelected" @change="(e)=>{onNewCanConfigSelected(e.target.value)}" class="bg-gray-100 py-1 border-2 border-gray-800">
        <option v-for="(conf, id) in supportedCamCaptures" :key="conf" :value="id">{{conf.Name}}</option>
    </select>
    <hr class="my-2"> -->
    <!-- Image ROI config -->
    <!-- <h4>Image Region of Interest:</h4>
    <table class="my-2">
        <thead>
            <th class="text-center">Width</th>
            <th class="text-center">Height</th></thead>
        <tr>
            <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiWidth" type="number" min="32" :step="roiStep" :max="maxRoiWidth" v-model="roiWidth" @change="onRoiChange($event)"></td>
            <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiHeight" type="number" min="32" :step="roiStep" :max="maxRoiHeight" v-model="roiHeight" @change="onRoiChange($event)"></td>
        </tr>
    </table>
    <div style="background-color:antiquewhite"><small>Region of Interest is a cropped subset of complete image where the deffect is identified. Position within whole image is defined by Tilt and Pan device settings. For better performance, Width and Heigth should be multiple of 32 pixels</small></div>
    <hr class="my-2"> -->
    <!-- Image NIS config -->
    <!-- <h4>Image Engine Size:</h4>
    <table class="my-2">
        <thead>
            <th class="text-center">Width</th>
            <th class="text-center">Height</th></thead>
        <tr>
            <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiWidth" type="number" min="32" :step="roiStep" :max="maxRoiWidth" v-model="roiWidth" @change="onRoiChange($event)"></td>
            <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiHeight" type="number" min="32" :step="roiStep" :max="maxRoiHeight" v-model="roiHeight" @change="onRoiChange($event)"></td>
        </tr>
    </table>
    <div style="background-color:antiquewhite"><small>For better performance, Width and Heigth should be multiple of 32 pixels</small></div> -->
    <hr class="my-2">
    <!-- List of defects -->
    <h4>Defects:</h4>
    <div style="background-color:antiquewhite"><small>You must specify a list of possible defects to be detected in product through visual inspection using images captured by the cameras(s). Select detection if you want to localize defects. Note that this will require to provide location annotation. You can group defects if will produce same action from the system.</small></div>
    <div v-for="(defect, index) in defectList" :key="index">
        <!-- <label :for="'defect' + index" >{{'Defect - ' + index}}:</label><br> -->
        <input class="py-1 rounded-md border-2 border-gray-800 bg-green-100" type="text" :id="'defect-' + index" placeholder="Defect Name" v-model="defectList[index]" :disabled="defect==='Ok'" :class="{error: !validateDefectName(defect)}"/>
        <button v-if="defect!=='Ok'" @click="removeDefect(index)" class="removeButton bg-button-red rounded-lg py-2 px-1">X</button>
        <!-- <button v-if="defect!=='Ok'" @click="onIncludeDetection(index)" title="Include detection" 
            :class="[ includeDetection[index] ? 'mx-2 bg-green-800 rounded-lg': 'mx-2 bg-gray-300 rounded-lg']">
            <i class="bi bi-aspect-ratio"></i>
        </button> -->
        <div v-if="!validateDefectName(defect)" class="bg-yellow-200 p-2"><small style="color:blue">Defect names can only contains letters and numbers with maximun length of 24 characters. Spaces are not allowed. 'Ok' is reserved for undefctive labeling</small></div>
    </div>     
    <!-- Add Defect Button -->
    <div style="text-align: center cursor-pointer"><button @click="addDefect" class="addButton bg-button-green rounded-lg">+ Add Defect</button></div>
    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <br>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';
// import appConfig from '@/config.js';

let MAX_USER_DEFECTS = 15 // Max number of defects defined by user. 'Ok' is not counted

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            projectName: '', // Project Name
            defectList: ['Ok'], // List of defects. Start with default 'Ok'
            includeDetection: [false], // List of boolean to indicate if defect has detection
            
            // camConfigSelected: null,
            // roiWidth: 0,
            // roiHeight: 0,
            // roiStep: 32, // Step in RoI pixels

            handleKeyDown: null,
        }
    },
    props: {
        currProjectNames: {type: Array, default: []},
    },
    methods: {
        submitForm() {
            console.log('Submit Form');
            // Check validation
            // Valid Name and at least one defect
            if (this.validateProjectName(this.projectName) && this.defectList.length > 1) {
                // Remove empty defects from list
                for (let defect of this.defectList) {
                    if (defect === '') {
                        this.defectList.splice(this.defectList.indexOf(defect), 1);
                        this.includeDetection.splice(this.defectList.indexOf(defect), 1);
                    }
                }
                // Remove duplicated
                let duplicateds = [];
                this.defectList = this.defectList.filter(function(item, pos, self) {
                    if (self.indexOf(item) == pos) return true;
                    else{
                        duplicateds.push(item)
                        return false;
                    }                    
                })
                if (duplicateds.length > 0) alert(`Defects can not be duplicated. ${duplicateds} has been removed`)
                // Send event 
                let data = {
                        ProjectName: this.projectName,
                        Defects: this.defectList,
                        // Ensure only defects in list with detection are included in DefectsWithDetection
                        DefectsWithDetection: this.defectList.filter((defect, index) => this.includeDetection[index]),
                        // CamConfig: this.supportedCamCaptures[this.camConfigSelected],
                        // RoiConfig: {Width: this.roiWidth, Height: this.roiHeight},
                    }
                this.$emit("newProjectSubmitted", data);
                console.log(`newProjectSubmitted. Form successfully submitted with data: ${JSON.stringify(data)}`);
        
            } else {
                console.log('Form failed validation'); 
                this.errorMsg='Form Data not valid. Review it and re-submit. At least one defect should be added'           
            }
        },
        cancel() {
            console.log('Cancel');
            this.$emit("newProjectSubmitted", null);
        },        
        addDefect() {
            console.log(`Add defect`)
            // Only Add if below MAX_USER_DEFECTS
            if (this.defectList.length > MAX_USER_DEFECTS) {
                console.log(`Can not add more defects. Max limit (${MAX_USER_DEFECTS}) has been reached`);
                alert(`Can not add more defects. Max limit (${MAX_USER_DEFECTS}) has been reached`);
                return;
            }
            // Only add if noone is empty
            for (let defect of this.defectList) {
                if (defect === '') {
                    console.log(`Can not add defect. At least one defect in empty`);
                    alert('Can not add new Defect. There is still empty ones');
                    return;
                }
            }
            // If all defects are not empty --> add a new one
            this.defectList.push(''); // Add an empty Defect
            this.includeDetection.push(false); // Add an empty Defect
        },
        removeDefect(index) {
            console.log(`Removing Defect of index: ${index}`);
            // Only remove defect if there is more than one
            if (this.defectList.length > 1) this.defectList.splice(index, 1);
            if (this.includeDetection.length > 1) this.includeDetection.splice(index, 1);
            else alert('Can not remove last Defect. At least ther should be one');
        },
        onIncludeDetection(index) {
            console.log(`onIncludeDetection. Index: ${index}`);
           
            if (this.defectList[index] !== 'Ok') {
                // Toggle includeDetection
                this.includeDetection[index] = !this.includeDetection[index];
            }
            else {
                alert(`'Ok' defect can not have detection`);
                this.includeDetection[index] = false;
            }
        },
        validateProjectName(projectName) {
            if (projectName) {
                // Regulatr expressión to check that string has length >= 1. Only letters and numbers and start with a letter
                let re = new RegExp("^[a-zA-Z][a-zA-Z0-9-_]*$");
                if (re.test(projectName) && projectName.length < 24 && !this.currProjectNames.includes(projectName)) return true
                else return false;
            } else return false
        },
        validateDefectName(inputValue) {
            if (inputValue) {
                // Regulatr expressión to check that string has length >= 1. Only letters and numbers and start with a letter
                let re = new RegExp("^[a-zA-Z][a-zA-Z0-9-_]*$");
                if (re.test(inputValue) && inputValue.length < 24) return true
                else return false;
            } else return false
        },
        clearForm() {
            this.errorMsg = null;
            this.projectName = '';            
            this.defectList = ['Ok'];
            this.includeDetection = [false];
        },
        // onRoiChange(event) {
        //     // Limit the value to [1, maxRoiXXX]
        //     if (event.target.id === 'RoiWidth') {
        //         event.target.value = Math.min(Math.max(event.target.value, 1), this.maxRoiWidth); // Limit to min/max
        //         event.target.value = Math.ceil(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
        //     }
        //     else if (event.target.id === 'RoiHeight') {
        //         event.target.value = Math.min(Math.max(event.target.value, 1), this.maxRoiHeight); // Limit to min/max
        //         event.target.value = Math.ceil(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
        //     }
        // },
        // onNewCanConfigSelected() {
        //     let camConfig = this.supportedCamCaptures[this.camConfigSelected];
        //     console.log(`onNewCanConfigSelected. Cam Config: ${JSON.stringify(camConfig)}`); 
        //     // Ensure ROI parameters are consistent with camera parameters
        //     if (this.roiWidth > camConfig.Width) this.roiWidth = camConfig.Width; // Limit ROI width to camera width 
        //     if (this.roiHeight > camConfig.Height) this.roiHeight = camConfig.Height; // Limit ROI width to camera width 
        // }
    },
    computed: {
        // supportedCamCaptures() { return appConfig.supportedCamCaptures },
        // maxRoiWidth() { if (this.camConfigSelected) return  this.supportedCamCaptures[this.camConfigSelected].Width},
        // maxRoiHeight() { if (this.camConfigSelected) return  this.supportedCamCaptures[this.camConfigSelected].Height},
    },
    
    // Lifecycle hooks
    mounted() {
        console.log(`New Project Form Mounting. Current Project Names: ${this.currProjectNames}`); 
        // // Defalut Camera configuration is the first available
        // this.camConfigSelected = Object.keys(this.supportedCamCaptures)[0];
        // // Default Image ROI to camera full image
        // this.roiWidth = this.supportedCamCaptures[this.camConfigSelected].Width;
        // this.roiHeight = this.supportedCamCaptures[this.camConfigSelected].Height;
        // keydown event listener for Submit/Cancel buttons
        this.clearForm();

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Enter") this.submitForm();
            else if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        
    },
    unmounted() {
        this.clearForm();
        console.log('New Project Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false);
    },
        
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    .defectInput {
        width: 15em;
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .addButton {
        padding: 10px 20px;
        margin-top: 10px;
        border-radius: 20px;
        color: white;
    }
    .removeButton {
        border: 1px solid;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>



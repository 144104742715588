// Created by SPe on 21/01/22
// Library to unify communication channels (WebSocket, WebRtc and SocketIO) for processing received messages
import store from '@/store/index.js';

let messageCallBacks = {} // dictionary with key=message type and value a list with callbacks


// Unified (WebSocket and WebRTC) process message function
export function unifiedProcessMessage(channel, devId, msgType, payLoad) {
  if (payLoad) {
    let payLoadStr = JSON.stringify(payLoad);
    if (payLoadStr.length > 200) payLoadStr = payLoadStr.substring(0, 200) + '...'; // Limit payload string to 200 characters
    console.log(`unifiedProcessMessage via: ${channel} from Device: ${devId}. Type: ${msgType}, Payload: ${payLoadStr}`);
  }
  else if (msgType === 'pong') console.log(`unifiedProcessMessage via: ${channel} from Device: ${devId}. Type: ${msgType}`)
  else console.error(`unifiedProcessMessage via: ${channel} from Device: ${devId}. Type: ${msgType}`);
  
    if (msgType === 'new_inspection_data') {
      // Store data in global store
      store.commit('devices/setInspectData', payLoad);
    } else if (msgType === 'new_gui_info') {
      console.log(`unifiedProcessMessage new_gui_info message received from device: ${devId} with data: ${JSON.stringify(payLoad)}`);
      store.commit('devices/setDeviceGUIInfo', payLoad);    
    } else if (msgType === 'new_dev_infererence') {
      // Store data in global store
      payLoad.devId = devId;
      store.commit('devices/setDevInference', payLoad);    
    } else if (msgType === 'new_image_class') {
      // Store data in global store
      store.commit('devices/setImageClass', payLoad);    
    } else if (msgType === 'new_image_view') {
      // Store data in global store
      store.commit('devices/setImageView', payLoad);
    } else if (msgType === 'latest_lcd_content') {
      // Do nothing    
    } else if (msgType === 'OutFromTerminal') {
      // Do nothing   
    } else if (msgType === 'setLogFilesInfo') {
      store.commit('devices/setLogFilesInfo', payLoad);  
    } else if (msgType === 'setLogFileContent') {
      // Do nothing   
    } else if (msgType === 'setLogFileRawContent') {
      // Do nothing   
    } else if (msgType === 'new_host_mode') {
      payLoad.devId = devId;
      store.commit('devices/setOperatingState', payLoad);  
    } else if (msgType === 'new_panel_info') {
      payLoad.devId = devId;
      store.commit('devices/setPanelInfo', payLoad);  
    } else if (msgType === 'new_alarm') {
      payLoad.devId = devId;
      store.commit('devices/setOperatingState', payLoad); 
    } else if (msgType === 'new_io_status') {
      payLoad.devId = devId;
      store.commit('devices/setIOStatus', payLoad);  
    } else if (msgType === 'setUserConfig') {
      // Do nothing
    } else if (msgType === 'setWiFiNetworks') {
      // Do nothing 
    } else if (msgType === 'setCurrentSwPackageName') {
      // Do nothing 
    } else if (msgType === 'new_keypad_config') {
      // Do nothing 
    } else if (msgType === 'setLatestSwPackageName') {
      // Do nothing 
    } else if (msgType === 'new_display_img') { 
      // Do nothing 
    } else if (msgType === 'SetAuthorization') { 
      // Do nothing 
    } else if (msgType === 'pong') {
      store.commit('devices/setLastWsPong', devId);
    } else {
      console.error(`Unified message Type not recognized: ${msgType}`);
    }

    // Call calbacks if any registered
    let callBacks = messageCallBacks[msgType];
    //console.log(`unifiedProcessMessage. callBacks: ${JSON.stringify(messageCallBacks)}`);
    if (callBacks) {
      for (var functionCB of callBacks) {
        console.log(`unifiedProcessMessage. Calling callback for message: ${msgType} and device: ${devId}`);
        functionCB(devId, payLoad);
      } 
    }
  
  }


// Register message callback function
export function registerUnifiedMessageCallBack(msgType, callBackFunction) {
  console.log(`ClientUnifiedReceive. Registering Unified Message CallBack on message type: ${msgType}`);
  let callBacks = messageCallBacks[msgType];
  //console.log(`callBacks: ${callBacks}`);
  if (callBacks && ! callBacks.includes(callBackFunction)) messageCallBacks[msgType].push(callBackFunction);
  else {
    messageCallBacks[msgType] = [callBackFunction];
    //console.log(`messageCallBacks[msgType]: ${messageCallBacks[msgType]}`);
  }
  //console.log(`CCCCCCCCCCCCCCCCCCCCCCC. callBacks: ${JSON.stringify(messageCallBacks)}`);
}

// Unregister message callback function
export function unRegisterUnifiedMessageCallBack(msgType, callBackFunction) {
  console.log(`ClientUnifiedReceive. unRegister Unified Message CallBack on message type: ${msgType}`);
  let callBacks = messageCallBacks[msgType];
  //console.log(`callBacks: ${callBacks}`);
  if (callBacks) messageCallBacks[msgType].pop(callBackFunction);
}

  
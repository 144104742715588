// Store module for WebSocket related info

const state = {
    servConnected: false, // Flag whether websocket is connected
    wrtcConnectedDevIds: [], // List of dev id of connected devices with WebRTC
    sioConnectedDevIds: [], // List of dev id of connected devices with Socket IO
    twilioIceToken: null, // Twilio ICE Servers Token from AWS WebRTC Signalling server
}

const getters = {
    isDevWrtcConnected: (state) => (devId) => {
        if (devId in state.wrtcConnectedDevIds && state.wrtcConnectedDevIds[devId]) return true;
        else return false;
    },
    isDevSioConnected: (state) => (devId) => {
        if (devId in state.sioConnectedDevIds && state.sioConnectedDevIds[devId]) return true;
        else return false;
    },
    isWscConnected: (state) => {
        return state.servConnected;
    }
}

const actions = {    
}

const mutations = {
    setWsConnected(state, newValue) { state.servConnected = newValue },
    setWrtcConnected(state, data) { 
        console.log(`setWrtcConnected. DevId: ${data['DevId']}, Value: ${data['Value']}`);
        state.wrtcConnectedDevIds[data['DevId']] = data['Value'];
    },
    setSioConnected(state, data) { 
        state.sioConnectedDevIds[data['DevId']] = data['Value'];
    },
    setTwilioIceToken(state, newValue) {
        console.log(`setTwilioIceToken: ${JSON.stringify(newValue)}`);
        state.twilioIceToken = newValue
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
//
// Developed by SPe @ 20/01/2023
//
// Library for data decryption

let keyPerPwsdSalt = {} // Cache of key per password and salt

// File is espected as bytearray with <SALT><IV><CIPHERTEXT><TAG></TAG>
export async function decryptImgUrl(imgUrl, password, onSuccess, onFailure) {

  fetch(imgUrl)
    .then(res => res.arrayBuffer())
    .then(buf => new Uint8Array(buf))
    .then(async encrypted => {
        // Split the buffer into separate variables for salt, iv, tag, and ciphertext
        let url = await getImageUrlFromEncryptedBytes(encrypted, password);
        // Call onSuccess call-back
        onSuccess(url);
    })
    .catch(error => {
        console.error(`Exception decrypting Image URL: ${imgUrl}: ${error}`);
        // Call onFailure call-back
        onFailure(imgUrl);
    });
}

// Decrypt image(imgByteArray) giben the password
// Input is espected as bytearray with <SALT><IV><CIPHERTEXT><TAG>
export async function decryptImageB64(imgEncryptedBase64, password, onSuccess, onFailure) {
  try {
    // Decode
    let imgByteArrayEncrypted = Buffer.from(imgEncryptedBase64, 'base64');
      let url = await getImageUrlFromEncryptedBytes(imgByteArrayEncrypted, password);
      if (url) {
        // Call onSuccess call-back
        onSuccess(url); 
    } else {
      console.error(`Could not decrypt image`);
      onFailure('Coul not decrypt image');
    }

  } catch(error) {
    console.error(`decryptImage exception: ${error}`);
    // Call onFailure call-back
    onFailure();
  }
}

async function getImageUrlFromEncryptedBytes(encryptedImgBytes, password) {
  // Split the buffer into separate variables for salt, iv, tag, and ciphertext
  const salt = encryptedImgBytes.slice(0, 16);
  const iv = encryptedImgBytes.slice(16, 28);
  const cyphertextNtag = encryptedImgBytes.slice(28);

  // Get the encryption key
  let encryptionKey = await getCahedKeyFromPassword(password, salt);
  if (encryptionKey) {
  //console.log(`KKKKKKKKKKKKKKKKKKKK: ${typeof(encryptionKey)}, ${encryptionKey}`);
    // Decrypt the image
    let decrypted = await decrypt(cyphertextNtag, iv, encryptionKey);
    
    if (decrypted) {
      // Build img source 
      const url = URL.createObjectURL(new Blob([decrypted], { type: "image/jpeg" }));
      return url;
    } else {
      return null;
    }

  } else {
    console.error(`getImageUrlFromEncryptedBytes Error. Could not get encryptionKey`);
    return null;
  }
}

async function getCahedKeyFromPassword(password, salt) {

  let encryptionKey = null;
  // Check if password in in cache
  if (keyPerPwsdSalt[password]) {
    // Check if also the salt in in cache
    if (keyPerPwsdSalt[password][salt]) { // Key is in cache
      //console.log(`In cache`);
      encryptionKey = keyPerPwsdSalt[password][salt];
    } else { // Key for salt is not in cache
      //console.log(`Not In cache`);
      encryptionKey = await createKeyFromPassword(password, salt);
      keyPerPwsdSalt[password][salt] = encryptionKey; // Insert in cache
    }
  } else { // Key for password is not in cache
    //console.log(`Not In cache`);
    encryptionKey = await createKeyFromPassword(password, salt);
    keyPerPwsdSalt[password] = {};          
    keyPerPwsdSalt[password][salt] = encryptionKey; // Insert in cache
  }

  return encryptionKey;
}




// Create a derived key (PBKDF2) with password and salt
async function createKeyFromPassword(password, salt) {
  try {
    const derivedKey = await window.crypto.subtle.importKey(
        "raw",
        new TextEncoder().encode(password),
        { name: "PBKDF2" },
        false,
        ["deriveBits", "deriveKey"]
    );

    const encryptionKey = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt,
            iterations: 10000,
            hash: "SHA-512"
        },
        derivedKey,
        { name: "AES-GCM", length: 256 },
        false,
        ["decrypt"]
    );
    return encryptionKey;
  
  } catch(error) {
    console.error(`createKeyFromPassword exception: ${error}`);
    return null;
  }
}

// Decrypt bytearray given a key
// encrypted bytearray should contain ciphertext and tag
export async function decrypt(cyphertextNtag, iv, encryptionKey) {
  try {
    const decrypted = await window.crypto.subtle.decrypt(
      {
          name: "AES-GCM",
          iv: iv,
          tagLength: 128,
      },
      encryptionKey,
      cyphertextNtag
      );

      return decrypted;
  
  } catch(error) {
    console.error(`decrypt exception: ${error}`);
    return null;
  }
}

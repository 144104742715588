import { createApp } from 'vue'
import store from './store/index'
import App from './App.vue'
import router from './router'
///// MixIns ///////

//import {Tabs, Tab} from 'vue3-tabs-component';
////////////  Bootstrap //////////
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
//import VueLoading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
///////// PrimeVue
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
// For modal windos
import VueUniversalModal from 'vue-universal-modal'
import 'vue-universal-modal/dist/index.css'
// For tooltip
import tooltip from "@/directives/tooltip.js";
import "@/assets/css/tooltip.css";
// For contextual menu
import contextmenu from "v-contextmenu";
import "v-contextmenu/dist/themes/default.css";

// For load-script
import LoadScript from "vue-plugin-load-script";

// For Tailwind CSS
import '@/assets/css/tailwind.css'

// For Konva
import VueKonva from 'vue-konva';


const app = createApp(App)
app.use(store);
app.use(router);
app.use(PrimeVue, {ripple: true});
//app.component('tabs', Tabs);
//app.component('tab', Tab);
app.use(VueUniversalModal, { teleportTarget: '#modals' });
app.use(contextmenu);
app.use(LoadScript);
app.use(VueKonva);
app.directive("tooltip", tooltip);
app.mount('#app');


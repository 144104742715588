// Util functions

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///     Date-Time related functions
///
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Return string with date relative to today in a very friendly form
/* export function relativeDateTime(TimeStampMs, seconds=false) {
    let date = new Date(TimeStampMs);
    
    const todayWTime = new Date();
    const today = new Date(todayWTime.getFullYear(), todayWTime.getMonth(), todayWTime.getDate());    
    const timeDiff = date.getTime() - today.getTime();
    const diffDays = Math.floor(timeDiff / (86400000));
    let out;

    if (seconds) {
        if (diffDays < -1) { out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }); } 
        else if (diffDays === -1) { out = $.i18n('Yesterday ') + date.toLocaleDateString(undefined, {  day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
        else if (diffDays === 0) { out = $.i18n('Today ') + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
        else if (diffDays === 1) { out = $.i18n('Tomorrow ') + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
        else {out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }); }

    } else {
        if (diffDays < -1) { out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }); } 
        else if (diffDays === -1) { out = $.i18n('Yesterday ') + date.toLocaleDateString(undefined, {  day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else if (diffDays === 0) { out = $.i18n('Today ') + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else if (diffDays === 1) { out = $.i18n('Tomorrow ') + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else {out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }); }
    }

    return out;
} */

// Function to convert seconds into human readable format with days, hours, minutes and seconds
export function secondsToDhms(seconds) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600*24));
    let h = Math.floor(seconds % (3600*24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 60);
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min., " : " min., ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " sec." : " sec.") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function relativeDateTime(TimeStampMs, seconds=false, miliseconds=true) {
    let date = new Date(TimeStampMs);
    
    const todayWTime = new Date();
    const today = new Date(todayWTime.getFullYear(), todayWTime.getMonth(), todayWTime.getDate());    
    const timeDiff = date.getTime() - today.getTime();
    const diffDays = Math.floor(timeDiff / (86400000));
    let out;

    if (seconds) { // Add seconds
        if (miliseconds) { // Add milliseconds
            if (diffDays < -1) { out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }); }
            else if (diffDays === -1) { out = 'Yesterday ' + date.toLocaleDateString(undefined, {  day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }); }
            else if (diffDays === 0) { out = 'Today ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }); }
            else if (diffDays === 1) { out = 'Tomorrow ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }); }
            else {out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }); }
        } else {
            if (diffDays < -1) { out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }); } 
            else if (diffDays === -1) { out = 'Yesterday ' + date.toLocaleDateString(undefined, {  day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
            else if (diffDays === 0) { out = 'Today ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
            else if (diffDays === 1) { out = 'Tomorrow ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
            else {out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }); }
        }
    } else {
        if (diffDays < -1) { out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }); } 
        else if (diffDays === -1) { out = 'Yesterday ' + date.toLocaleDateString(undefined, {  day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else if (diffDays === 0) { out = 'Today ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else if (diffDays === 1) { out = 'Tomorrow ' + date.toLocaleDateString(undefined, { day: 'numeric' , hour: '2-digit', minute: '2-digit' }); }
        else {out = date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }); }
    }

    return out;
}

// REturn date string as ISO 8601 without ms (like '2023-01-16T13:07:06' )
export function getDateAsISOStrWoMs(date) {
    if (!date) date = new Date;
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset*60*1000));
    return date.toISOString().split('.')[0];
}

export function timeStampToString(TimeStampMs) {
    let date = new Date(TimeStampMs);
    return date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }); 

}

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

export function formatDateTimeForHistory(dateTime) {

    const year = dateTime.getFullYear().toString().substr(-2); // Last 2 digits
    const month = (dateTime.getMonth()+1).padLeft(); 
    const day = dateTime.getDate().padLeft();
    const hour = dateTime.getHours().padLeft();
    const minute =  dateTime.getMinutes().padLeft();
    const second =  dateTime.getSeconds().padLeft();

    return `${year}-${month}-${day}T${hour}-${minute}-${second}`;
}
// Get the dataset Name from Dataset Id
// It is assumed that neither projectId nor datasetName has '@' character in it
export function getDatasetNameFromDatasetId(datasetId) {
    return datasetId.split('@')[1];
}

// Get the dataset Name from Dataset Id
// It is assumed that neither projectId nor datasetName has '@' character in it
export function getProjectIdFromDatasetId(datasetId) {
    return datasetId.split('@')[0];
}

// Build engineId from projectId and engine version
export function buildEngineId(projectId, engineVersion) {
    return `${projectId}$ENG$${engineVersion}`;
}

// Get Project Name from Project ID
export function  getProjectNameFromProjectId(projectId) {
    return projectId.split('+')[0];
}

// Get date obkject from date string (like 22-10-07T18-50-12)
export function getDateFromShortStr(dateStr) {
    console.log(`dateString: ${dateStr}`)
    try {
        let reggie = /(\d{2})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})/;
        let dateArray = reggie.exec(dateStr); 
        let dateObject = new Date(
            (2000+(+dateArray[1])), // Year is only 2 digits
            (+dateArray[2])-1, // Careful, month starts at 0!
            (+dateArray[3]),
            (+dateArray[4]),
            (+dateArray[5]),
            (+dateArray[6])
        );
        return dateObject;
    } catch(error){
        console.error(`getDateFromShortStr (from dateStr: ${dateStr})exception: ${error}`);
        return null;
    } 
}

// Set (modify) date object from date string (like 22-10-07T18-50-12)
export function setDateFromShortStr(date, dateStr) {
    //console.log(`dateString: ${dateStr}`)
    let reggie = /(\d{2})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})/;
    let dateArray = reggie.exec(dateStr); 
    date.setFullYear(2000+(+dateArray[1]), (+dateArray[2])-1, +dateArray[3])
    date.setHours(+dateArray[4], +dateArray[5], +dateArray[6]);

    return date;
}


// Get date obkject from date string (like 22-10-07T18-50-12-567)
export function getDateFromLongStr(dateStr) {
    try {
        //console.log(`dateString: ${dateStr}`)
        let reggie = /(\d{2})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})-(\d{3})/;
        let dateArray = reggie.exec(dateStr); 
        let dateObject = new Date(
            (2000+(+dateArray[1])), // Year is only 2 digits
            (+dateArray[2])-1, // Careful, month starts at 0!
            (+dateArray[3]),
            (+dateArray[4]),
            (+dateArray[5]),
            (+dateArray[6]),
            (+dateArray[7])
        );
        return dateObject;
    } catch(error){
        console.error(`getDateFromLongStr (from dateStr: ${dateStr})exception: ${error}`);
        return null;
    } 
}

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    // Sort arrays
    a.sort();
    b.sort();
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
    
  // Parse the content of CSV and return a list of objects. Keys is header
  export function parseCSV(csvText, separator=',') {
    try {
        const rows = csvText.split('\n');
        // Remove first row if it is a reprator definition
        if (rows[0].startsWith('sep=')) {
            separator = rows[0][4]; // Get separator
            rows.shift(); // Remove first row
            console.log(`Separator found in csv file: ${separator}`)
        }
        const headers = rows[0].split(separator);
        const objects = [];

        for (let i = 1; i < rows.length; i++) {
            const row = rows[i].split(separator);
            const obj = {};
            // console.log(`Header: ${JSON.stringify(headers)}`)
            console.log(`Row: ${JSON.stringify(rows[i])}`)

            if (headers.length === row.length) {
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j].trim()] = row[j].trim();
                }
                objects.push(obj);
            } else console.error(`CSV Row: ${i} has different number of columns. Header: ${JSON.stringify(headers)}, Row: ${JSON.stringify(row)}`);
        }

        return {Headers: headers, Objects: objects};

    } catch(error){
        console.error(`parseCSV exception: ${error}`);
        return null;
    } 
}

// Get time if exists
export function getTimeIfExist(date) {
    if (date) return date.getTime();
    else return null;
}

    

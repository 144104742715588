
import { createStore } from 'vuex'
import root from './modules/root.js'
import loginStore from './modules/login.js'
import connectionStore from './modules/connection.js'
import devices from './modules/devices.js'
import projects from './modules/projects.js'

export default createStore({
    modules: {
        login: loginStore,
        connection: connectionStore,
        devices: devices,
        projects: projects,
        root: root,
    },
    plugins: [],
    state: {
        count: 0,
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
  })
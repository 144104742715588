import auth from '@/library/auth'; 
import appConfig from '@/config.js';
import store from '@/store/index.js';


// Using labelImage send image annotation to history-service
export function annotateImage(imgMetaData, fileKey, pos, assgnLabels, assignBBoxes, originator, userId, projectId, datasetId, saveInStore) {
  console.log(`annotateImage. imgMetaData: ${JSON.stringify(imgMetaData)}, fileKey: ${fileKey}, pos: ${pos}, assgnLabels: ${assgnLabels}, assignBBoxes: ${JSON.stringify(assignBBoxes)}, originator: ${originator}, userId: ${userId}, projectId: ${projectId}, datasetId: ${datasetId}`);
  
  let pieces = fileKey.split('/');
  let fileName = pieces[pieces.length -1];  // Get the file name from key
  // let filePath = fileKey.replace(fileName, ''); // filePath = fileKey removing the fileName 
  if (fileName) {                
    // Build the body
    let body = {
      //FileKey: fileKey,
      Originator: originator,
      HstFilePath: imgMetaData['HstFilePath'],
      Customer: imgMetaData['Customer'],
      FileName: fileName,
      Encrypted: imgMetaData['Encrypted'],
      ImageId: imgMetaData['ImageId'],
      CamId: imgMetaData['CamId'],
      CamPos: pos,
      DevId: imgMetaData['DevId'],
      DevName: imgMetaData['DevName'],
      DevShortName: imgMetaData['DevShortName'],
      PredictLabels: imgMetaData['PredictLabels'],
      PredictBBoxes: imgMetaData['PredictBBoxes'],
      PredictAll: imgMetaData['PredictAll'],
      UserId: userId,
      AssignLabels: assgnLabels,
      AssignBBoxes: assignBBoxes,
      TimeStampStr: imgMetaData['TimeStampStr'],
      ProjectId: projectId,
      DatasetId: datasetId,
      EngineId: imgMetaData['EngineId'],
      ActTrig: imgMetaData['ActTrig'],
      Comment: imgMetaData['Comment'],
    };
    // Make API Request
    labelImage(body, saveInStore, null);
  }
}

// Make an API call to history-service/labelImage
export function labelImage(body, saveInStore=false, callBack=null) {
  let datasetId = body['DatasetId']
  // Make API Request
  fetchAPIData('history/v1/labelImage', body)
  .then(result => {
    console.log(`labelImage result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      if (saveInStore && result.Data && result.Data.DstData) {
        store.commit('projects/setDatasetData', {DatasetId: datasetId, Data: result.Data});
      }
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to history-service/setImageComment
export function setImageComment(userId, projectId, datasetId, imageId, comment, saveInHistory, callBack=null) {
  // Build the body
  let body = {
    UserId: userId,
    ProjectId: projectId,
    DatasetId: datasetId,
    ImageId: imageId,
    Comment: comment,
    SaveInHistory: saveInHistory
  };
  // Make API Request
  fetchAPIData('history/v1/setImageComment', body)
  .then(result => {
    console.log(`setImageComment result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to history-service/labelMultipleImages
export function labelMultipleImages(originator, userId, datasetId, hstFilePath, imageIdList, assignLabelsAdd, assignLabelsRemove, callBack=null) {
  // Build the body
  let body = {
    Originator: originator,
    UserId: userId,
    DatasetId: datasetId,
    HstFilePath: hstFilePath,
    ImgIds: imageIdList,
    AssignLabelsAdd: assignLabelsAdd,
    AssignLabelsRemove: assignLabelsRemove
  };
  // Make API Request
  fetchAPIData('history/v1/labelMultipleImages', body)
  .then(result => {
    console.log(`labelMultipleImages result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to project-service/getProjectsOfUser
export function getProjectsOfUser(userId, callBack=null) {

  // Build the body
  let body = {UserId: userId};
  // Make API Request
  fetchAPIData('projects/v1/getProjectsOfUser', body)
  .then(result => {
    console.log(`getProjectsOfUser result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('projects/setProjectList', result.Data);
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to project-service/getRootData
export function getRootData(userId, callBack=null) {
  // Build the body
  let body = {UserId: userId};
  // Make API Request
  fetchAPIData('projects/v1/getRootData', body)
  .then(result => {
    console.log(`getRootData result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('root/setRootData', result.Data);
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Get Customers Data
export function getCustomersData(userId, customers, callBack=null) {
  // Build the body
  let body = {UserId: userId, Customers: customers};
  // Make API Request
  fetchAPIData('projects/v1/getCustomersData', body)
  .then(result => {
    console.log(`getCustomersData result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('root/setCustomersData', result.Data);
      // Make call back if needed
      console.log(`getCustomersData. Calling Call Back: ${callBack}`);
      if (callBack) callBack(result.Data);
    }
  })
}

// Get Users Data
export function getUsersData(userId, users, callBack=null) {
  // Build the body
  let body = {UserId: userId, Users: users};
  // Make API Request
  fetchAPIData('projects/v1/getUsersData', body)
  .then(result => {
    console.log(`getUsersData result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('root/setUsersData', result.Data);
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Get Projects Data
export function getProjectsData(userId, projects, callBack=null) {
  // Build the body
  let body = {UserId: userId, Projects: projects};
  // Make API Request
  fetchAPIData('projects/v1/getProjectsData', body)
  .then(result => {
    console.log(`getProjectsData result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('root/setProjectsData', result.Data);
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Get Devices Data
export function getDevicesData(userId, devices, callBack=null) {
  // Build the body
  let body = {UserId: userId, Devices: devices};
  // Make API Request
  fetchAPIData('projects/v1/getDevicesData', body)
  .then(result => {
    console.log(`getDevicesData result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('root/setDevicesData', result.Data);
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}


// Make an API call to project-service/createProject
export function createProject(userId, projectName, defects, defectsWithDetection, camConfig, roiConfig, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectName: projectName, Defects: defects, DefectsWithDetection: defectsWithDetection, CamConfig: camConfig, RoiConfig: roiConfig};
  // Make API Request
  fetchAPIData('projects/v1/createProject', body)
  .then(result => {
    console.log(`createProject result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/removeProject
export function removeProject(userId, projectId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId};
  // Make API Request
  fetchAPIData('projects/v1/removeProject', body)
  .then(result => {
    console.log(`removeProject result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to history-service/removeProjectImages
export function removeHistory(userId, projectId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId};
  // Make API Request
  fetchAPIData('history/v1/removeProjectImages', body)
  .then(result => {
    console.log(`removeHistory result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/getDatasetsOfProject
export function getDatasetsOfProject(projectId, callBack=null) {

  // Build the body
  let body = {ProjectId: projectId};
  // Make API Request
  fetchAPIData('projects/v1/getDatasetsOfProject', body)
  .then(result => {
    console.log(`getDatasetsOfProject result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      
      store.commit('projects/setDatasetsOfProject', {ProjectId: projectId, Data: result.Data});
      // Make call back if needed
      
    }
    if (callBack) {
      callBack(result.Data);
    }
  })
}

// Make an API call to project-service/getEnginesOfProject
export function getEnginesOfProject(projectId, callBack=null) {
  // Build the body
  let body = {ProjectId: projectId};
  // Make API Request
  fetchAPIData('projects/v1/getEnginesOfProject', body)
  .then(result => {
    console.log(`getEnginesOfProject result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      store.commit('projects/setEnginesOfProject', {ProjectId: projectId, Data: result.Data});      
    }
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/getMachinesOfProject
export function getMachinesOfProject(projectId, callBack=null) {
  // Build the body
  let body = {ProjectId: projectId};
  // Make API Request
  fetchAPIData('projects/v1/getMachinesOfProject', body)
  .then(result => {
    console.log(`getMachinesOfProject result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {
      store.commit('projects/setMachinesOfProject', {ProjectId: projectId, Data: result.Data});
    }
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/getTask
export function getTask(taskId, callBack=null) {
  // Build the body
  let body = {TaskId: taskId};
  // Make API Request
  fetchAPIData('projects/v1/getTask', body)
  .then(result => {
    console.log(`getTask result: ${JSON.stringify(result)}`);
    // if (result.Status === 'Ok') {      
    //   store.commit('projects/setEnginesOfProject', {ProjectId: projectId, Data: result.Data});      
    // }
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/createDataset
export function createDataset(userId, projectId, datasetName, defects, defectsWithDetection, readOnly= false, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, DatasetName: datasetName, Defects: defects, DefectsWithDetection: defectsWithDetection, ReadOnly: readOnly};
  // Make API Request
  fetchAPIData('projects/v1/createDataset', body)
  .then(result => {
    console.log(`createDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/renameDataset
export function renameDataset(userId, projectId, srcDatasetName, dstDatasetName, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, SrcDatasetName: srcDatasetName, DstDatasetName: dstDatasetName};
  // Make API Request
  fetchAPIData('projects/v1/renameDataset', body)
  .then(result => {
    console.log(`renameDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/validateDataset
export function validateDataset(userId, engineId, datasetId, machineVendor, machineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, EngineId: engineId, DatasetId: datasetId, MachineVendor: machineVendor, MachineId: machineId};
  // Make API Request
  fetchAPIData('projects/v1/validateDataset', body)
  .then(result => {
    console.log(`validateDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/duplicateDataset
export function duplicateDataset(userId, projectId, datasetName, dstDatasetName, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, DatasetName: datasetName, DstDatasetName: dstDatasetName};
  // Make API Request
  fetchAPIData('projects/v1/duplicateDataset', body)
  .then(result => {
    console.log(`duplicateDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/splitteDataset
export function splitDataset(userId, projectId, srcDatasetName, trainDatasetName, validateDatasetName, validateDatasetFraction, groupByDevice, groupByDay, callBack=null) {
  // Build the body
  let body = {
    UserId: userId,
    ProjectId: projectId,
    SrcDatasetName: srcDatasetName,
    TrainDatasetName: trainDatasetName,
    ValidateDatasetName: validateDatasetName,
    ValidateDatasetFraction: validateDatasetFraction,
    GroupByDevice: groupByDevice,
    GroupByDay: groupByDay
  };
  // Make API Request
  fetchAPIData('projects/v1/splitDataset', body)
  .then(result => {
    console.log(`splitDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) {
      // console.log(`splitDataset. Calling Call Back`);
      callBack(result);
    }
  })
}


// Make an API call to project-service/trainEngine
export function trainEngine(userId, projectId, baseDatasetIds, baseTrainDatasetIds, baseValDatasetIds, modelFrameWork, modelName, modelGuiName, modelPTName, baseEngineVersion, numPretrainingImages, nis, encryptionPassword, machineVendor, machineId, callBack=null) {
  // Build the body
  let body = {
    UserId: userId,
    ProjectId: projectId,
    BaseDatasetIds: baseDatasetIds,
    BaseTrainDatasetIds: baseTrainDatasetIds,
    BaseValDatasetIds: baseValDatasetIds,
    ModelFrameWork: modelFrameWork,
    ModelName: modelName,
    ModelGuiName: modelGuiName,
    ModelPTName: modelPTName,
    BaseEngineVersion: baseEngineVersion,
    NumPretrainingImages: numPretrainingImages,
    NIS: nis,
    EncryptionPassword: encryptionPassword,
    MachineVendor: machineVendor,
    MachineId: machineId
  };
  console.log(`trainEngine body: ${JSON.stringify(body)}`);
  console.log(`Callback: ${callBack}`)
  // Make API Request
  fetchAPIData('projects/v1/trainEngine', body)
  .then(result => {
    console.log(`trainEngine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/removeProject
export function removeDataset(userId, projectId, datasetId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, DatasetId: datasetId};
  // Make API Request
  fetchAPIData('projects/v1/removeDataset', body)
  .then(result => {
    console.log(`removeDataset result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}
// Set Engine StopTraining flag to true using setEngineProperties API
// This cause to stop training after current epoch and do the rest of post-processing
export async function stopTrainEngine(userId, projectId, engineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, EngineId: engineId, StopTraining: true};
  // Make API Request
  fetchAPIData('projects/v1/setEngineProperties', body)
  .then(result => {
    console.log(`setEngineComment result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}
// Make an API call to project-service/stopEngine
// This cause to stop task inmediatley and terminate machine
export function stopEngine(userId, projectId, engineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, EngineId: engineId};
  // Make API Request
  fetchAPIData('projects/v1/stopEngine', body)
  .then(result => {
    console.log(`stopEngine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}
// Make an API call to project-service/removeEngine
export function removeEngine(userId, projectId, engineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, EngineId: engineId};
  // Make API Request
  fetchAPIData('projects/v1/removeEngine', body)
  .then(result => {
    console.log(`removeEngine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/createMachine
export function createMachine(userId, projectId, machineName, machineIp, machineSSHPort, machineSSHUser, machineSSHPrivateKey, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, MachineName: machineName, MachineIp: machineIp, MachineSSHPort: machineSSHPort, MachineSSHUser: machineSSHUser, MachineSSHPrivateKey: machineSSHPrivateKey};
  // Make API Request
  fetchAPIData('projects/v1/createMachine', body)
  .then(result => {
    console.log(`createMachine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/removeMachine
export function removeMachine(userId, projectId, machineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, MachineId: machineId};
  // Make API Request
  fetchAPIData('projects/v1/removeMachine', body)
  .then(result => {
    console.log(`removeMachine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/emptyMachineQueue
export function emptyMachineQueue(userId, projectId, machineId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, MachineId: machineId};
  // Make API Request
  fetchAPIData('projects/v1/emptyMachineQueue', body)
  .then(result => {
    console.log(`emptyMachineQueue result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/getEngineTempToken
export async function getProjectTempToken(userId, projectId) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId};
  // Make API Request
  let result = await fetchAPIData('projects/v1/getProjectTempToken', body)
  console.log(`getProjectTempToken result: ${JSON.stringify(result)}`);
  return result
}

// Make an API call to project-service/getEngineTempToken
export async function getEngineTempToken(userId, engineId) {
  // Build the body
  let body = {UserId: userId, EngineId: engineId};
  // Make API Request
  let result = await fetchAPIData('projects/v1/getEngineTempToken', body)
  console.log(`getEngineTempToken result: ${JSON.stringify(result)}`);
  return result
}

export async function setDatasetComment(userId, datasetId, comment, callBack=null) {
  // Build the body
  let body = {UserId: userId, DatasetId: datasetId, Comment: comment};
  // Make API Request
  fetchAPIData('projects/v1/setDatasetProperty', body)
  .then(result => {
    console.log(`setDatasetComment result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

export async function setEngineComment(userId, engineId, comment, callBack=null) {
  // Build the body
  let body = {UserId: userId, EngineId: engineId, Comment: comment};
  // Make API Request
  fetchAPIData('projects/v1/setEngineProperties', body)
  .then(result => {
    console.log(`setEngineComment result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

export async function setMachineComment(userId, machineId, comment, callBack=null) {
  // Build the body
  let body = {UserId: userId, MachineId: machineId, Comment: comment};
  // Make API Request
  fetchAPIData('projects/v1/setMachineProperties', body)
  .then(result => {
    console.log(`setMachineComment result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/shareProject
export function shareProject(userId, projectId, sharedUserId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, SharedUserId: sharedUserId};
  // Make API Request
  fetchAPIData('projects/v1/shareProject', body)
  .then(result => {
    console.log(`shareProject result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/cloneProject
export function cloneProject(userId, projectId, tgtUserId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, TgtUserId: tgtUserId};
  // Make API Request
  fetchAPIData('projects/v1/cloneProject', body)
  .then(result => {
    console.log(`cloneProject result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/cloneEngine
export function cloneEngine(userId, projectId, engineId, tgtProjectId, callBack=null) {
  // Build the body
  let body = {UserId: userId, ProjectId: projectId, EngineId: engineId, TgtProjectId: tgtProjectId};
  // Make API Request
  fetchAPIData('projects/v1/cloneEngine', body)
  .then(result => {
    console.log(`cloneEngine result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result);
  })
}

// Make an API call to project-service/getAllImagesDataFromDataset
export function getAllImagesDataFromDataset(userId, datasetId, callBack=null) {

  // Build the body
  let body = {UserId: userId, DatasetId: datasetId};
  // Make API Request
  fetchAPIData('projects/v1/getAllImagesDataFromDataset', body)
  .then(result => {
    console.log(`getAllImagesDataFromDataset result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      store.commit('projects/setImgsOfDataset', {DatasetId: datasetId, Data: result.Data});
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to history-service/getImageBlock
export function getImageBlockFromHistory(userId, projectId, startDateStr, numImages=1000, direction='Asc', callBack=null) {

  // Build the body
  let body = {UserId: userId, ProjectId: projectId, StartDateStr: startDateStr, NumImages: numImages, Direction: direction};
  // Make API Request
  fetchAPIData('history/v1/getImageBlock', body)
  .then(result => {
    //console.log(`getImageBlock result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      store.commit('projects/setHistoryBlockData', {ProjectId: projectId, Data: result.Data});
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to project-service/copyImagesBtwDatasets
export function copyImagesBtwDatasets(userId, srcDatasetId, dstDatasetId, imageIdList, operation, callBack=null) {

  // Build the body
  let body = {UserId: userId, SrcDatasetId: srcDatasetId, DstDatasetId: dstDatasetId, ImageIdList: imageIdList, Operation: operation};
  // Make API Request
  fetchAPIData('projects/v1/copyImagesBtwDatasets', body)
  .then(result => {
    console.log(`copyImagesBtwDatasets result: ${JSON.stringify(result)}`);
    if (result && result.Status === 'Ok') {      
      // Make call back if needed
      if (callBack) callBack(result.Data);
    } else {
      if (callBack) callBack('Error');
    }
  })
}

// Make an API call to project-service/removeImageFromDataset
export function removeImageFromDataset(userId, datasetId, imageId, fileName, callBack=null) {

  // Build the body
  let body = {UserId: userId, DatasetId: datasetId, ImageId: imageId, FileName: fileName};
  // Make API Request
  fetchAPIData('projects/v1/removeImageFromDataset', body)
  .then(result => {
    console.log(`removeImageFromDataset result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      store.commit('projects/setDatasetData', {DatasetId: datasetId, Data: result.Data});
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to project-service/removeImagesFromDataset
export function removeImagesFromDataset(userId, datasetId, imageIdList, callBack=null) {

  // Build the body
  let body = {UserId: userId, DatasetId: datasetId, ImageIdList: imageIdList};
  // Make API Request
  fetchAPIData('projects/v1/removeImagesFromDataset', body)
  .then(result => {
    console.log(`removeImagesFromDataset result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
}

// Make an API call to device-service/unregisterDevice
export function unregisterDevice(devId, callBack=null) {
  // Build the body
  let body = {DevId: devId};
  // Make API Request
  fetchAPIData('device/v1/unregisterdevice', body)
  .then(result => {
    console.log(`unregisterdevice result: ${JSON.stringify(result)}`);
    // Make call back if needed
    if (callBack) callBack(result.Data);
  })
}

// Make an API call to project-service/addImageToDataset
export async function addImageToDataset(userId, datasetId, predictAll, predictBBoxes, assignLabels, assignBBoxes, fileOrBlob, fileName) {
  // Get presigned URL
  // Build the body
  let body = {
    UserId: userId,
    DatasetId: datasetId,
    PredictAll: predictAll,
    PredictBBoxes: predictBBoxes,
    AssignLabels: assignLabels,
    AssignBBoxes: assignBBoxes,
    FileName: fileName,
    MergeAssignLabels: true
  };
  console.log(`addImageToDataset body: ${JSON.stringify(body)}`);
  // Make API Request
  let result = await fetchAPIData('projects/v1/addImageToDataset', body);
  //console.log(`addImageToDataset result: ${JSON.stringify(result)}`);
  if (result.Status === 'Ok') {      
    // Upload File to S3 using the returned presigned URL
    let presignedUploadUrl = result.presignedUrl.url;
    let fields = result.presignedUrl.fields
    //console.log(`Uploading file: ${fileName} with URL ${presignedUploadUrl}, Fields: ${fields}`);
    console.log(`Uploading file: ${fileName}`);
    
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
          formData.append(key, fields[key]);
    });
    formData.append("file", fileOrBlob, fileName);
    try {
      await fetch(presignedUploadUrl, {
        // Note: I found that using no headers works best, strange errors crop up otherwise....
        method: 'POST',
        body: formData,
      });
      //console.log(`fetch presignedUploadUrl result: ${result}`);      
      return 'Ok'
    } catch(error) {
      console.error(`fetch presignedUploadUrl exception: ${error}`);
      return 'Failed';
    }
  } else {
    console.error(`fetch addImageToDataset result.Status: ${result.Status}`);
    return 'Failed'
  }
}


// Make an API call to project-service/getTaskLog
export async function getTaskLogURL(userId, taskId, callBack=null) {
  // Get presigned URL
  // Build the body
  let presignedUrl = null;
  let body = {UserId: userId, TaskId: taskId};
  console.log(`getTaskLog body: ${JSON.stringify(body)}`);
  // Make API Request
  await fetchAPIData('projects/v1/getTaskLog', body)
  .then( async result => {
    console.log(`getTaskLog result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      // Upload File to S3 using the returned presigned URL
      presignedUrl = result.Data.presignedUrl;
      console.log(`Downloading  URL ${presignedUrl}`);
     
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
  return presignedUrl
}

// Make an API call to project-service/getHtmlNotebook
export async function getHtmlNotebookURL(userId, taskId, getHtmlNotebookURL, callBack=null) {
  // Get presigned URL
  // Build the body
  let presignedUrl = null;
  let body = {UserId: userId, TaskId: taskId, NoteBookType: getHtmlNotebookURL};
  console.log(`getTaskLog body: ${JSON.stringify(body)}`);
  // Make API Request
  await fetchAPIData('projects/v1/getHtmlNotebook', body)
  .then( async result => {
    console.log(`getHtmlNotebook result: ${JSON.stringify(result)}`);
    if (result.Status === 'Ok') {      
      // Upload File to S3 using the returned presigned URL
      presignedUrl = result.Data.presignedUrl;
      console.log(`Downloading URL ${presignedUrl}`);
     
      // Make call back if needed
      if (callBack) callBack(result.Data);
    }
  })
  return presignedUrl
}


// Low level fucntion to call Backend API
export function fetchAPIData(urlPath, body=null) {
  console.log(`fetchAPIData. urlPath: ${urlPath}, Body: ${JSON.stringify(body)}`);
  try {
    // Get jwtToken
    let jwtToken = auth.getJWTToken();
    // Build complete url
    let url = `${appConfig.HttpAPIRoot}/${urlPath}`;
    // Define if is a GET or POST request
    let method = 'GET'
    if (body) {
      method = 'POST'    
      if (typeof(body) !== 'string') { // Body is assumed to be a string or dictionary (objct)
        body = JSON.stringify(body);
      }
    }
    console.log(`Body: ${body}`)
    // Return the fetch call
    return fetch(url, {
      method: method,
      headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Source': 'Client', // For API Authentication
          'Authorizer': jwtToken // For API Authentication
      },
      body: body,
    })
    .then(response => response.json())  // Get Json
    .then(json => {
      console.log(`HTTP Fetch result: (${urlPath}): ` + JSON.stringify(json));
      // Return API result
      return json.message; // 'message' contains the result from API
    })    
    .catch(err => { // Error 
      console.log('HTTP Fetch Error: ' + err);
      return null;
    });    

  } catch(error) {
    console.error(`fetchAPIData exception: ${error}`);
    return null;
  }
}
// Created by SPe on 11/08/2022
// component for New Engine Form
<template>
  <div class="root">
    <h2>Train a new Engine</h2>
    <!-- List of datasets -->
    <h2 class="">Datasets for training and validation</h2>
    <h2>Dataset specification</h2>
    <input class="cursor-pointer" @click="onDatasetSpecChange" type="radio" name="toguether" v-model="datasetSpecification" :value="'toguether'" />
    <label class="pl-2">Train and Validation splitted from specified datasets</label> 
    <hr class="opacity-0">
    <input class="cursor-pointer" @click="onDatasetSpecChange" type="radio" name="separated" v-model="datasetSpecification" :value="'separated'" />
    <label class="pl-2">Train and Validation specified separately</label>
    <br><br>
    
    <table style="width:100%">
        <!-- Header -->
        <tr class="withBorder" style="width:100%;">
            <th v-if="datasetSpecification=='toguether'" class="withBorder">All</th>
            <th v-if="datasetSpecification=='separated'" class="withBorder">Train</th>
            <th v-if="datasetSpecification=='separated'" class="withBorder">Val.</th>
            <th class="withBorder"> Dataset</th>
            <th class="withBorder"># Images</th>
            <th class="withBorder" v-for="(defect, index) in defectList" :key="index">{{`# ${defect}`}}</th>
        </tr>
        <!-- Datasets -->
        <tr v-for="ds in datasetList" :key="ds.DatasetId" class="withBorder">
            <td v-if="datasetSpecification=='toguether'" class="withBorder items-center"><input type="checkbox" id="cbox" value="checkbox" v-model="ds.selected" @click="onSelect($event, ds, 'all')"></td>
            <td v-if="datasetSpecification=='separated'" class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="ds.selectedTrain" @click="onSelect($event, ds, 'train')"></td>
            <td v-if="datasetSpecification=='separated'" class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="ds.selectedVal" @click="onSelect($event, ds, 'val')"></td>
            <td class="withBorder">{{ds.DatasetName}}</td>
            <td class="withBorder">{{ds.NImgs}}</td>
            <td class="withBorder" v-for="(defect, index) in defectList" :key="index">{{ds[`N${defect}`]}}</td>
        </tr>
        <!-- Total -->
        <tr class="withBorder" v-if="datasetSpecification=='toguether'">            
            <td class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="allSelected" @click="onSelectAll('All')"></td>
            <td class="withBorder"><b>Total</b></td>
            <td class="withBorder">{{totalPerDefect['NImgs']}}</td>
            <td class="withBorder" v-for="(defect, index) in defectList" :key="index">{{totalPerDefect[`N${defect}`]}}</td>
        </tr>
        <tr class="withBorder" v-if="datasetSpecification=='separated'">            
            <td class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="allSelectedTrain" @click="onSelectAll('Train')"></td>
            <td class="withBorder"></td>
            <td class="withBorder"><b>Total Train</b></td>
            <td class="withBorder">{{totalTrainPerDefect['NImgs']}}</td>
            <td class="withBorder" v-for="(defect, index) in defectList" :key="index">{{totalTrainPerDefect[`N${defect}`]}}</td>
        </tr>
        <tr class="withBorder" v-if="datasetSpecification=='separated'">            
            <td class="withBorder"></td>
            <td class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="allSelectedVal" @click="onSelectAll('Val')"></td>
            <td class="withBorder"><b>Total Validation</b></td>
            <td class="withBorder">{{totalValPerDefect['NImgs']}}</td>
            <td class="withBorder" v-for="(defect, index) in defectList" :key="index">{{totalValPerDefect[`N${defect}`]}}</td>
        </tr>
        <tr class="withBorder" v-if="datasetSpecification=='separated'">            
            <td class="withBorder"></td>
            <td class="withBorder"></td>
            <td class="withBorder"><b>Total</b></td>
            <td class="withBorder">{{totalTrainPerDefect['NImgs'] + totalValPerDefect['NImgs']}}</td>
            <td class="withBorder" v-for="(defect, index) in defectList" :key="index">{{totalTrainPerDefect[`N${defect}`] + totalValPerDefect[`N${defect}`]}}</td>
        </tr>
    </table>
    <br>
    <hr>
    <!-- Network Core Model -->
    <h2 class="">Network Core Model</h2>
    <select class="border-2 border-gray-800 rounded-md px-1 mx-2" @change="onNewModelSelected($event)" v-model="modelSelected">
        <option v-for="coreModelId in Object.keys(appConfig.possibleBaseModels)" :key="coreModelId" :value="coreModelId">{{appConfig.possibleBaseModels[coreModelId].gui_name}}</option>
    </select>    
    <div style=""><small>Select the core model used for the Engine</small></div>
    <br>
    <hr>
    <!-- Base Engine Versions -->
    <h2 class="">Train on top of Engine</h2>
    <select class="border-2 border-gray-800 rounded-md px-1 mx-2" @change="onNewBaseEngineSelected($event)" v-model="baseEngineVersionSelected">
        <option v-if="appConfig.possibleBaseModels[modelSelected]" value="V0">{{appConfig.possibleBaseModels[modelSelected].gui_name}} - V0</option>
        <template v-for="version of engineVersionsPerModel[modelSelected]">
            <option  v-if="appConfig.possibleBaseModels[modelSelected]" :key="version" :value="version">{{appConfig.possibleBaseModels[modelSelected].gui_name}} - {{version}}</option>
        </template>
    </select>    
    <div style=""><small>Select the previous trained engine to use as base for this training </small></div>
    <br>
    <hr>
     <!-- Pretraining -->
    <!-- <h2 class="">Include pretraining fase</h2>
    <select class="border-2 border-gray-800 rounded-md px-1 mx-2" @change="onNewNumPretrainingImagesSelected($event)" v-model="numPretrainingImages">
        <option value=0>Skip Pretraining</option>
        <option value=2000>2 K Images</option>
        <option value=50000>50 K Images</option>
        <option value=100000>100 K Images</option>
        <option value=150000>150 K Images</option>
        <option value=200000>200 K Images</option>
        <option value=250000>250 K Images</option>
        <option value=300000>300 K Images</option>
        <option value=350000>350 K Images</option>
        <option value=400000>400 K Images</option>
        <option value=500000>500 K Images</option>
        <option value=600000>600 K Images</option>
        <option value=700000>700 K Images</option>
        <option value=800000>800 K Images</option>
        <option value=900000>900 K Images</option>
        <option value=1000000>1 M Images</option>
        <option value=1250000>1.25 M Images</option>
        <option value=1500000>1.5 M Images</option>
        <option value=1750000>1.75 M Images</option>
        <option value=2000000>2 M Images</option>
        <option value=2250000>2.25 M Images</option>
        <option value=2500000>2.5 M Images</option>
        <option value=2750000>2.75 M Images</option>
        <option value=3000000>3 M Images</option>
        <option value=3250000>3.25 M Images</option>
        <option value=3500000>3.5 M Images</option>
        <option value=3750000>3.75 M Images</option>
        <option value=4000000>4 M Images</option>
        <option value=4500000>4.5 M Images</option>
        <option value=5000000>5 M Images</option>
    </select> 
    <div style=""><small>Select whether do a pretraining fase and number of images for pretraining</small></div>
    <br>
    <hr> -->
    <!-- Network Input Image Size -->
    <h2 class="">Network Input Image Size</h2>
    <table class="my-2">
        <thead>
            <th class="text-center">Width</th>
            <th class="text-center">Height</th></thead>
        <tr>
            <td><input class="border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiWidth" type="number" :min="nisStep" :step="nisStep" :max="maxNis" v-model="nisWidth" @change="onNisChange($event)"></td>
            <td><input class="border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiHeight" type="number" :min="nisStep" :step="nisStep"  :max="maxNis" v-model="nisHeight" @change="onNisChange($event)"></td>
        </tr>
    </table>
    <div style=""><small>For better performance, Width and Heigth should be multiple of 32 pixels</small></div>
    <br>
    <hr>
    <!-- Encryption Password -->
    <h2>Encryption Password (Optional): </h2>
    <div >
    <input class="py-1 rounded-md border-2 border-gray-800 bg-green-100" :type="encPasswdInputType" v-model="encryptionPassword" placeholder="MySecretPassword">
    <i class="bi bi-eye font-bold mt-3 ml-2 cursor-pointer" @click="showEncPassword=!showEncPassword"></i>
    </div>    
    <div><small>Encryption password used to decrypt images encrypeted by devices. This should be the same as provided in devices configuration.</small></div>
    <br>
    <!-- Execution Machine -->
    <h2>Execution Machine</h2>
    <select class="border-2 border-gray-800 rounded-md px-1 mx-2" @change="onNewMachineSelected($event)" v-model="machineSelected">
        <option value="$AWS$">AWS EC2 Instance</option>
        <option value="$VAST$">Datacenter Instance</option>
        <option v-for="machine in machineList" :key="machine.MachineId" :value="machine.MachineId">{{machine.MachineName}}</option>
    </select>
    <div><small>Execution machine for this training.</small></div>


    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import appConfig from '@/config.js';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            baseDatasetIds: [], // List of Dataset Ids that has been selected
            baseTrainDatasetIds: [], // List of Dataset Ids that has been selected
            baseValDatasetIds: [], // List of Dataset Ids that has been selected
            allSelected: false, // Flag to store if all datasets has been selected for toguether
            allSelectedTrain: false, // Flag to store if all datasets has been selected for training datasets
            allSelectedVal: false, // Flag to store if all datasets has been selected for validation datasets
            totalPerDefect: {},
            totalTrainPerDefect: {},
            totalValPerDefect: {},

            nisStep: 32, // Network Input Size granularity (Width and Heigth)
            maxNis: 32*32*4, // Max Network Input Size (Width and Heigth)
            nisWidth: 640, // Selected NIS Width
            nisHeight: 480, // Selected NIS Width

            encryptionPassword: '', // Provided encryption password
            showEncPassword: false, 

            datasetSpecification: 'toguether',

            modelSelected: 'MobileNetV3Small',
            baseEngineVersionSelected: 'V0',

            machineSelected: '$AWS$',

            numPretrainingImages: 0,

            handleKeyDown: null,
        }
    },
    props: {
        defectList: {type: Array, default: []}, // List of defects
        datasetList: {type: Array, default: []}, // LIst of datasets
        engineVersionsPerModel: {type: Object, default: {}}, // Dictionary with the ready engine versions per model
        latestReadyModelName: {type: String, default: 'MobileNetV3Small'}, // Latest ready engine model name
        latestReadyengineVersion: {type: String, default: 'V0'}, // Latest ready engine version 
        latestReadyengineNis: {type: Object, default: {Width: 640, Height: 480}}, // Latest ready engine NIS
        machineList: {type: Array, default: []}, // List of machines
    },
    methods: {
        submitForm() {
            console.log(`Submit Form. Model: ${this.modelSelected}`);
            this.baseDatasetIds = [];
            for (let ds of this.datasetList) if (ds.selected) this.baseDatasetIds.push(ds.DatasetId);
            this.baseTrainDatasetIds = [];
            for (let ds of this.datasetList) if (ds.selectedTrain) this.baseTrainDatasetIds.push(ds.DatasetId);
            this.baseValDatasetIds = [];
            for (let ds of this.datasetList) if (ds.selectedVal) this.baseValDatasetIds.push(ds.DatasetId);
            this.$emit("newEngineSubmitted", 
                {   BaseDatasetIds: this.baseDatasetIds,
                    BaseTrainDatasetIds: this.baseTrainDatasetIds,
                    BaseValDatasetIds: this.baseValDatasetIds,
                    ModelName: this.modelSelected,
                    ModelFrameWork: this.appConfig.possibleBaseModels[this.modelSelected].framework,
                    ModelGuiName: this.appConfig.possibleBaseModels[this.modelSelected].gui_name,
                    ModelPTName: this.appConfig.possibleBaseModels[this.modelSelected].pt_name,
                    BaseEngineVersion: this.baseEngineVersionSelected,
                    NumPretrainingImages: Number(this.numPretrainingImages),
                    NIS: {Width: this.nisWidth, Height: this.nisHeight},
                    EncryptionPassword: this.encryptionPassword,
                    MachineId: this.machineSelected,
                });
            console.log('Form successfully submitted.');
        },
        onDatasetSpecChange() {
            console.log(`onDatasetSpecChange`);
            this.allSelected = false;
            for (let ds of this.datasetList) ds.selected = false;
            this.allSelectedTrain = false;
            for (let ds of this.datasetList) ds.selectedTrain = false;
            this.allSelectedVal = false;
            for (let ds of this.datasetList) ds.selectedVal = false;

            this.calculateTotals();
        },
        cancel() {
            this.$emit("newEngineSubmitted", null);
        },       
        onSelect(event, dataset, type) {
            let selected = event.target.checked;
            if (type === 'all') dataset.selected = selected;
            else if (type === 'train') dataset.selectedTrain = selected;
            else if (type === 'val') dataset.selectedVal = selected;
            console.log(`Dataset selected: ${dataset.DatasetName} (type: (${type}) --> ${selected}`);
            this.calculateTotals();
        },
        onSelectAll(type) {
            console.log(`onSelectAll Type: ${type}, allSelected: ${this.allSelected}, selectedTrain: ${this.allSelectedTrain}, selectedVal: ${this.allSelectedVal} `);
            if (type === 'All' && this.allSelected) {
                for (let ds of this.datasetList) ds.selected = false;
            } else if (type === 'All') {
                for (let ds of this.datasetList) ds.selected = true;
            }
            if (type === 'Train' && this.allSelectedTrain) {
                for (let ds of this.datasetList) ds.selectedTrain = false;
            } else  if (type === 'Train') {
                for (let ds of this.datasetList) ds.selectedTrain = true;
            }
            if (type === 'Val' && this.allSelectedVal) {
                for (let ds of this.datasetList) ds.selectedVal = false;
            } else  if (type === 'Val') {
                for (let ds of this.datasetList) ds.selectedVal = true;
            }
            this.calculateTotals();
        },
        calculateTotals() {
            // All
            let out = {};
            out['NImgs'] = 0;
            for (let defect of this.defectList) out[`N${defect}`] = 0;
            for (let ds of this.datasetList) {
                if (ds.selected) {
                    out['NImgs'] += ds.NImgs;
                    for (let defect of this.defectList) {
                        out[`N${defect}`] += ds[`N${defect}`];
                    }
                }
            }            
            this.totalPerDefect = out;
            // Train
            out = {};
            out['NImgs'] = 0;
            for (let defect of this.defectList) out[`N${defect}`] = 0;
            for (let ds of this.datasetList) {
                if (ds.selectedTrain) {
                    out['NImgs'] += ds.NImgs;
                    for (let defect of this.defectList) {
                        out[`N${defect}`] += ds[`N${defect}`];
                    }
                }
            }            
            this.totalTrainPerDefect = out;
            // Validation
            out = {};
            out['NImgs'] = 0;
            for (let defect of this.defectList) out[`N${defect}`] = 0;
            for (let ds of this.datasetList) {
                if (ds.selectedVal) {
                    out['NImgs'] += ds.NImgs;
                    for (let defect of this.defectList) {
                        out[`N${defect}`] += ds[`N${defect}`];
                    }
                }
            }            
            this.totalValPerDefect = out;
        },
        onNewModelSelected() {
            console.log(`onNewModelSelected: ${this.modelSelected}`);
            // Set default 
            let availableVersions = this.engineVersionsPerModel[this.modelSelected];
            console.log(`availableVersions: ${availableVersions}`)
            if (availableVersions && availableVersions.length > 0) {
                this.baseEngineVersionSelected = availableVersions.slice(-1)[0];
                console.log(`baseEngineVersionSelected: ${this.baseEngineVersionSelected}`)
            } else {
                this.baseEngineVersionSelected = 'V0';
            }
        },
        onNewBaseEngineSelected() {
            console.log(`onNewBaseEngineSelected: ${this.baseEngineVersionSelected}`);
        },
        onNewNumPretrainingImagesSelected() {
            console.log(`onNewNumPretrainingImagesSelected: ${this.numPretrainingImages}`);
        },
        onNewMachineSelected() {
            console.log(`onNewMachineSelected: ${this.machineSelected}`);
        },
        onNisChange() {
            console.log(`onNisChange. nisWidth: ${this.nisWidth}. nisHeight: ${this.nisHeight}`);
        },
        clearForm() {
            this.errorMsg = null;
            this.baseDatasetIds = [];
            this.baseTrainDatasetIds = [];
            this.baseValDatasetIds = [];
            this.modelSelected = 'MobileNetV3Small';
            this.baseEngineVersionSelected = 'V0';
            this.numPretrainingImages = 0;
            this.nisWidth = 640;
            this.nisHeight = 480
            this.encryptionPassword = '';
            this.showEncPassword = false;
        }
    },
    computed: {    
        encPasswdInputType() { if (this.showEncPassword) { return 'text'} else {return 'password'}},
        appConfig() {return appConfig},
    },
    
    // Lifecycle hooks
    mounted() {
        console.log('New Engine Form Mounting'); 
        this.calculateTotals();        

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Enter") this.submitForm();
            else if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);

        this.clearForm();

        // Set default model name and based engine
        console.log(`latestReadyModelName: ${this.latestReadyModelName}, latestReadyengineVersion: ${this.latestReadyengineVersion}`)
        if (this.latestReadyModelName) this.modelSelected = this.latestReadyModelName;
        if (this.latestReadyengineVersion) this.baseEngineVersionSelected = this.latestReadyengineVersion;
        if (this.latestReadyengineNis) {
            this.nisWidth = this.latestReadyengineNis.Width;
            this.nisHeight = this.latestReadyengineNis.Height;
        }
    },
    unmounted() {
        this.clearForm()
        console.log('New Engine Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class NewEngineForm extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        min-width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: rgb(0, 5, 0);
        background: rgb(249, 250, 249);
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
    .withBorder {
        border:1px solid black;
    }
</style>



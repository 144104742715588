// Created by SPe on 01/09/2023
// component for New Machine Form
<template>
  <div class="root">
    <h2>Create a new Machine</h2>
    <div><small>Machine can be used to train or validate Engines. It should be a Linux machine with SSH access and Docker installed.</small></div>
    <!-- Machine Name -->
    <h4>Machine name:</h4>
    <input type="text" id="machineName" placeholder="Machine Name" v-model="machineName" :class="{error: !validateInput('name', machineName)}"/>
    <div><small>Machine name for your reference.</small></div>
    <div v-if="!validateInput('name', machineName)" class="bg-yellow-200 p-2"><small style="color:blue">Machine name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed.</small></div>
    <!-- Machine Ip -->
    <h4>Machine IP:</h4>
    <input type="text" id="machineIp" placeholder="Machine IP" v-model="machineIp" :class="{error: !validateInput('ip', machineIp)}"/>
    <div><small>DNS address or IP address of the machine.</small></div>
    <!-- Machine SSH Port -->
    <h4>Machine SSH Port:</h4>
    <input type="text" id="machineSSHPort" placeholder="Machine SSH Port" v-model="machineSSHPort" :class="{error: !validateInput('port', machineSSHPort)}"/>
    <div><small>SSH Port.</small></div>
    <!-- Machine SSH Username -->
    <h4>Machine SSH Username:</h4>
    <input type="text" id="machineSSHUser" placeholder="Machine SSH Username" v-model="machineSSHUser" :class="{error: !validateInput('username', machineSSHUser)}"/>
    <div><small>SSH Username.</small></div>
    <!-- Machine SSH Private Key -->
    <h4>Machine SSH Private Key:</h4>
    <!-- <input :type="privateKeyInputType" id="machineSSHPrivateKey" placeholder="Machine SSH Private Key" v-model="machineSSHPrivateKey" :class="{error: !validateInput('privatekey', machineSSHPrivateKey)}"/> -->
    <textarea type="text" v-model="machineSSHPrivateKey" cols="40" rows="5"  :class="{error: !validateInput('privatekey', machineSSHPrivateKey)}" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
    <!-- <i class="bi bi-eye font-bold mt-3 ml-2 cursor-pointer" @click="showPrivateKey=!showPrivateKey"></i> -->
    <div><small>SSH Private Key. Paired public key should be placed in '~/.ssh/authorized_keys' file in the machine. It can be generated with 'ssh-keygen' command. It should be in PEM format. I.e. start with '-----BEGIN OPENSSH PRIVATE KEY-----' and end with '-----END OPENSSH PRIVATE KEY-----'</small></div>


    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div>
</template>

<script>
    import { Options, Vue } from 'vue-class-component';
 
    @Options({
        components: {
        },
        data: function(){
            return {
                errorMsg: null,
                machineName: '', // Machine Name    
                machineIp: '', // Machine IP
                machineSSHPort: '', // Machine SSH Port
                machineSSHUser: '', // Machine SSH Username
                machineSSHPrivateKey: '', // Machine SSH Private Key
                handleKeyDown: null,      
                showPrivateKey: false,
            }
        },
        props: {
            
        },
        methods: {
            submitForm() {
                console.log('Submit Form');
                // Check validation
                // Valid Name and at least one defect
                if (this.validateInput('name', this.machineName) && this.validateInput('ip', this.machineIp) && this.validateInput('port', this.machineSSHPort) && this.validateInput('username', this.machineSSHUser) && this.validateInput('privatekey', this.machineSSHPrivateKey)) {
                    // Send event
                    this.$emit("newMachineSubmitted", 
                        {
                            MachineName: this.machineName,
                            MachineIp: this.machineIp,
                            MachineSSHPort: this.machineSSHPort,
                            MachineSSHUser: this.machineSSHUser,
                            MachineSSHPrivateKey: this.machineSSHPrivateKey
                        }
                    );
                    console.log('New Machine Form successfully submitted.');
            
                } else {
                    console.log('New Machine Form failed validation'); 
                    this.errorMsg='Form Data not valid. Review it and re-submit'           
                }
            },
            cancel() {
                this.$emit("newMachineSubmitted", null);
            },       
            validateInput(format, inputValue) {
                if (format === null) return true; // No check if no format provided
                let re = null;
                if (format=='name') re = new RegExp("^[a-zA-Z][a-zA-Z0-9-_]{0,24}$"); // Name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed.
                //eslint-disable-next-line
                else if (format=='ip') re = new RegExp("^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:(?:(?:[0-9A-Fa-f]{1,4}:){7}(?:[0-9A-Fa-f]{1,4}|:))|(?:(?:[0-9A-Fa-f]{1,4}:){6}(?::[0-9A-Fa-f]{1,4}|(?!(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?::|$))))|(?:(?:[0-9A-Fa-f]{1,4}:){5}(?:(?::[0-9A-Fa-f]{1,4}){1,2}|:))|(?:(?:[0-9A-Fa-f]{1,4}:){4}(?:(?::[0-9A-Fa-f]{1,4}){1,3}|(?::[0-9A-Fa-f]{1,4})?:))|(?:(?:[0-9A-Fa-f]{1,4}:){3}(?:(?::[0-9A-Fa-f]{1,4}){1,4}|(?::[0-9A-Fa-f]{1,4}){0,2}:))|(?:(?:[0-9A-Fa-f]{1,4}:){2}(?:(?::[0-9A-Fa-f]{1,4}){1,5}|(?::[0-9A-Fa-f]{1,4}){0,3}:))|(?:(?:[0-9A-Fa-f]{1,4}:){1}(?:(?::[0-9A-Fa-f]{1,4}){1,6}|(?::[0-9A-Fa-f]{1,4}){0,4}:))|(?::(?:(?::[0-9A-Fa-f]{1,4}){1,7}|(?::[0-9A-Fa-f]{1,4}){0,5}:)))(?:(?:%[0-9A-Za-z]{1,})?[\w\.-]*)*|^(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$"); // IP v4 or v6
                else if (format=='port') re = new RegExp("^[0-9]{1,5}$"); // Port number
                //eslint-disable-next-line
                else if (format=='username') re = new RegExp("^[a-z_]([a-z0-9_-]{0,31}|[a-z0-9_-]{0,30}\$)$"); // Username
                //eslint-disable-next-line
                // else if (format=='privatekey') re = new RegExp("-----BEGIN (OPENSSH )?PRIVATE KEY-----\r?\n(?:[A-Za-z0-9+/=]\r?\n?)*-----END (OPENSSH )?PRIVATE KEY-----$");
                //eslint-disable-next-line
                else if (format=='privatekey') re = new RegExp("-----BEGIN ([A-Z]+ )?PRIVATE KEY-----\r?\n(?:[A-Za-z0-9+/=]\r?\n?)*-----END ([A-Z]+ )?PRIVATE KEY-----\r?\n?$");
                else return false;
                if (inputValue) {
                    // Regulatr expressión to check 
                    if (re.test(inputValue)) return true
                    else {
                        console.log('Input value: ' + inputValue + ' does not match format: ' + format);
                        return false;
                    }
                } else return false
            },
            clearForm() {
                this.errorMsg = null;
                this.machineName = '';
            }
        },
        computed: {    
            privateKeyInputType() { if (this.showPrivateKey) { return 'text'} else {return 'password'}},
        },
        
        // Lifecycle hooks
        mounted() {
            console.log('New Machine Form Mounting');
            this.clearForm();

            this.handleKeyDown = (event) => { 
                // console.log('Key Pressed: ' + event.code);           
                if (event.code === "Enter") this.submitForm();
                else if (event.code === "Escape") this.cancel();   
            };
            window.addEventListener("keydown", this.handleKeyDown, false); 
            
        },
        unmounted() {
            this.clearForm();
            console.log('New Machine Form Unmounted');
            window.removeEventListener("keydown", this.handleKeyDown, false); 
        },
            
    })
    export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>



// Created by SPe on 06/10/2022
// component for Browse History Form
<template>
  <div class="root">
    <h2>Browse History</h2>
    <!-- Dataset Name -->
    <h4 class="py-2">Start date:</h4>
    <div>
        <Datepicker v-model="date" @update:modelValue="onNedDate" enableSeconds ></Datepicker>
    </div>

    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
@Options({
    components: {
        Datepicker,
    },
    data: function(){
        return {
            errorMsg: null,
            date: new Date(Date.now() - 1 * (60 * 60 * 1000)),  // 1 hour less than now
        }
    },
    props: {
        
    },
    methods: {
        submitForm() {
            console.log('Browse History Submit Form');
            this.$emit("editHistorySubmitted", 
                {Date: this.date}
            );
            console.log('Browse History Form successfully submitted.');
        },
        cancel() {
            this.$emit("editHistorySubmitted", null);
        },       
        onNedDate(modelData) {
            console.log(`EditHistory. New Date selected(${typeof(modelData)}): ${modelData}`);
            this.date = modelData;
        },
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('New HistoryEditor Form Mounting'); 
        
    },
    unmounted() {
        console.log('New HistoryEditor Form Unmounted');
    },
        
})
export default class EditHistory extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 20em;        
        background-color: #fff;
        padding: 30px;
        margin-top: -20em;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: red;
        background: #FDD;
    }
</style>



// Created by SPe on 21/02/22
// Page to view inferenced images
<template>
    <div class="panel">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />      
        <!-- Header  -->
        <div id="panel">            
            <div class="panelAllCam">
                <table  v-if="devAllPred">
                    <tr>
                        <td v-if="guiInfo && guiInfo.PanelToolRemoteUrl">
                            <button id="toolButton" @click="onToolPressed()" :title="'Open Tool'">
                                <img :src="require('@/assets/pics/PanelTool.png')" style="height: 30px" />
                            </button>
                        </td>
                        <!-- <td><h1 :class="devNameClass">{{ devName }}</h1></td>                         -->
                        <td v-for="(label, labelIndex) of allLabels" :key="label">
                            <button v-if="devAllPred[label] && highestProbLabels.includes(label)" @click="onAllImgButtonCliked(label)" type="button" 
                            :style="[
                            {opacity: 0.1 + (1-0.1) * devAllPred[label]},
                            {color: 'black'},
                            {'backgroundColor': labelColors[labelIndex]}, 
                            ]"  v-bind:title="label">
                                <!-- <img :src="require('@/assets/pics/' + label + '.png')" loading="lazy"/> -->
                                <span class="percentInButton" >{{label}}</span>
                                <span class="percentInButton">{{"(" + Math.round(100 * devAllPred[label]) + "%)"}}</span>
                            </button>
                        </td>
                        <td>
                            <button id="lightStatusButton" :class="lightStatusButtonClass" @click="onLightPressed()" :title="deviceIOStatus && deviceIOStatus.Light == 'On' ? 'Switch Off lights' : 'Switch on lights for 10 minutes'">
                                <img :src="require('@/assets/pics/lightbulb.png')" style="height: 30px" />
                            </button>
                        </td>
                        <td>
                            <button id="alarmStatusButton" :class="alarmStatusButtonClass" @click="onAlarmPressed()" :title="deviceIOStatus && deviceIOStatus.Alarm == 'On' ? 'Acknowledge alarm' : 'Test Alarm light for 5 seconds'">
                                <img :src="require('@/assets/pics/siren.png')" style="height: 30px" />
                            </button>
                        </td>
                        <td>
                            <button id="buttonStatusButton" :class="buttonStatusButtonClass" @mousedown="onButtonPress('ReportProblem')" @mouseup="onButtonReleased('ReportProblem')">
                                <img :src="require('@/assets/pics/panic-button.png')" style="height: 30px" />
                            </button>
                        </td>
                        <td>
                            <button id="historyButton" @click="onOpenHistory()" :title="'Open 15 minutes history of images'">
                                <img :src="require('@/assets/pics/history.png')" style="height: 30px" />
                            </button>
                        </td>
                        <td v-if="devicePanelInfo && devicePanelInfo.PanelInfo">
                            <p>{{devicePanelInfo.PanelInfo}}</p>                            
                        </td>
                        <td>
                            <p>{{'State: ' +  deviceState}}</p>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- Cam Images  -->
            <div :class="'w-full grid grid-cols-' + numColumns">
                <div v-for="(imgClass, pos) in devAllimgClass" v-bind:id="'cam_image_cmp' + pos" class="justify-center container_cam_img" :key="pos" ref="inferImageML"> 
                    <div>
                        <InferImageML                               
                            :imgObj = "imgClass"
                            :camPos = "pos * 1.0"
                            :imgHeight = "imgHeight"
                            :imgSrc = "imgClass.image_jpg_base64 ? 'data:image/jpeg;base64, ' + imgClass.image_jpg_base64 : null"
                            :imgEnc = "imgClass.image_jpg_enc_base64"
                            :decryptPswd = "decryptionPassword"
                            :imgFileKey = "null"
                            :imgTimestamp = "imgClass.time_stamp * 1000"
                            :allLabels = "allLabels"
                            :labelsWithDetection = "labelsWithDetection"
                            :allPredictions = "imgClass.all_predictions"
                            :rotate180 = "false"
                            :numColumns = "numColumns"
                            :hideWhenLoading = "false"
                            @imgClicked="onImgClicked($event, pos, imgClass)"
                        />
                    </div>
                </div>   
            </div>
        </div> 

        <!--Modal Overlay-->
        <div
            class="fixed hidden inset-0 bg-gray-600 bg-opacity-50 h-full w-full"                
            id="ImgModal"
        >
            <div v-if="modalImgClass" class="relative h-full w-full text-white flex items-center justify-center">   
                <!-- Zoom Pan content  -->
                <div id="zoompan"> 
                    <InferImageML                               
                        :imgObj = "modalImgClass"
                        :camPos = "modalPos * 1.0"
                        :imgHeight = "imgHeight"
                        :imgSrc = "modalImgClass.image_jpg_base64 ? 'data:image/jpeg;base64, ' + modalImgClass.image_jpg_base64 : null"
                        :imgEnc = "modalImgClass.image_jpg_enc_base64"
                        :decryptPswd = "decryptionPassword"
                        :imgFileKey = "null"
                        :imgTimestamp = "modalImgClass.time_stamp * 1000"
                        :allLabels = "allLabels"
                        :labelsWithDetection = "labelsWithDetection"
                        :allPredictions = "modalImgClass.all_predictions"
                        :rotate180 = "false"
                        :numColumns = "1"
                        :hideWhenLoading = "false"
                    />
                </div>
                <div class="center-down">
                    <button class="bg-gray-500 bg-opacity-40 rounded-full w-10 h-10 text-orange-300"
                        title="Close [X]"
                        @click="closeModal">X</button>
                </div>
            </div> 
        </div>
        <!-- Modal Window for Entering Password Form -->
        <Modal
            v-model="enterPasswwordModalShow"
            ref="modal"
        >
            <EnterPasswordForm entityType='Device' :entityName="devName" @newPasswordSubmitted="newPasswordSubmitted"></EnterPasswordForm>        
        </Modal>
        <!-- Footernbar -->
        <Footer />
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import { mapGetters } from 'vuex'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { Options, Vue } from 'vue-class-component'; 
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import  WZoom from 'vanilla-js-wheel-zoom'
import { relativeDateTime, formatDateTimeForHistory } from '@/library/utils'
import { doKeepWsConnected, dontKeepWsConnected } from '@/library/websocket'
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import EnterPasswordForm from '@/components/EnterPasswordForm.vue';
import { openUnifiedChannel, sendMessageUnified, closeChannel,  joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'
import InferImageML from '@/components/InferImageML.vue';

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
        InferImageML,
        EnterPasswordForm,
    },
    data: function(){
        return {
            labelColors: ['#55a868', '#4c72b0', '#dd8452', '#c44e52', '#8172b3', '#937860', '#da8bc3', '#8c8c8c', '#ccb974', '#64b5cd', '#4c72b0', '#dd8452', '#55a868', '#c44e52', '#8172b3', '#937860'],
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            customer: this.$route.params.Customer, // Customer collected from Path Parameters
            devId: this.$route.params.DevId, // DevId collected from Path Parameters
            devIp: this.$route.params.DevIp, // DevIp collected from Path Parameters   
            loaderTask: null,
            appMode: process.env.VUE_APP_MODE,
            numColumns: 1, // Number of image columns
            // For modal zoom image windows
            imgModalIsShow: false, // Whether modal window for image is open
            modalImgClass: null, // imgClass showing in modal windows
            modalPos: 1, // position of modal image
            modalWZoom: null, // WZoom object in modal windows
            enterPasswwordModalShow: false,
            handleKeyDown: null, // Key down event handler

            windowWidth: null, // Window width
            windowHeight: null, // Window height
            // Original Image Size
            imgOriginalWidth: 200, // Image natural width
            imgOriginalHeight: 400, // Image natural height
            imgWidth: null, // Image width
            imgHeight: null, // Image height
            firstTimeDevConnected: true,
        }
    },
    props: {
    },
    watch: {
        devName: function() {
            console.log(`Panel. devName changed to: ${this.devName}`);
            this.setWindowdTitle();
        },
    },
    methods: {
        onDevConnected(devId) {
            if (!this.firstTimeDevConnected) {
                console.log(`Logs. Device: ${this.devId} is already connected`);
                return; // Do nothing if already connected
            }
            this.firstTimeDevConnected = false;
            console.log(`Panel. Device: ${devId} is connected through WebSocket`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('new_dev_infererence', this.onNewDevInference);
            registerUnifiedMessageCallBack('new_image_class', this.onNewImage);
            // Join CameraInferences & OperatingStatus room
            joinRoomUnified(this.devId, 'CameraInferences');  // Master
            joinRoomUnified(this.devId, 'OperatingStatus');  // Master
            for (let devId of this.slaveDevIds) {
                joinRoomUnified(devId, 'CameraInferences');  // Slave
                joinRoomUnified(devId, 'OperatingStatus');  // Slave
            }
        },
        onNewDevInference(devId) {
            console.log(`new_dev_infererence Received fron device: ${devId}`);
        },
        async onNewImage(devId, ImgClass) {
            console.log(`new_image_class Received fron device: ${devId}`);
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 5 seconds`);
                //this.showSpinner = true;
            }, 5000);
            this.loadingOverlay.show = false;
            this.showSpinner = false;
            // Get image size
            if (ImgClass.image_width) this.imgOriginalWidth = ImgClass.image_width;
            if (ImgClass.image_height) this.imgOriginalHeight = ImgClass.image_height;
            this.resizeImage();
            // Check if it is encrypted and need to get the decryption password
            if (ImgClass.image_jpg_enc_base64 && !this.decryptionPassword) { // It it has image_jpg_enc means that is encrypted
                this.loadingOverlay.show = false;
                //  Show Enter Password Modal 
                this.enterPasswwordModalShow = true;
                // Wait until modal is closed
                while (this.enterPasswwordModalShow) await new Promise(r => setTimeout(r, 100));
            }
        },
        newPasswordSubmitted(data) {
            console.log(`newPasswordSubmitted: ${JSON.stringify(data)}`);
            this.enterPasswwordModalShow = false;
            //console.log(`Storing Passwor: ${password} for Project: ${projectId}`)
            store.commit('projects/setProjectPassword', {ProjectId: this.projectId, Password: data.Password});
        },
        // onImgButtonCliked(_event, _imgObj, _imgFileKey, pos, label) {
        //     console.log(`onImgButtonCliked. Camera position: ${pos}, Label: ${label}`);
        //     const imgClss = this.devAllimgClass[pos];
        //     if (imgClss) {                
        //         const content = {filename: imgClss.file_name, label: label, boundBox: [0,0,0,0], timestamp: Date.now()};
        //         sendMessageUnified(this.devId, 'labelImage', content);             
        //     } else console.error('devAllimgClass not available')
        // },
        onAllImgButtonCliked(label) {
            console.log(`onAllImgButtonCliked. Label: ${label}`);            
            for (let pos in this.devAllimgClass) {
                let imgClss = this.devAllimgClass[pos];
                const content = {filename: imgClss.file_name, label: label, boundBox: [0,0,0,0], timestamp: Date.now()};
                sendMessageUnified(this.devId, 'labelImage', content);
            }            
        },
        onLightToggle(lightName) {
            try {
                console.log(`onLightToggle: ${lightName}`);
                sendMessageUnified(this.devId, 'toggleLight', lightName);
                for (let devId of this.slaveDevIds) {
                    console.log(`Sending toggleLight to slave device: ${devId}`);
                    sendMessageUnified(devId, 'toggleLight', lightName);
                }
            } catch(error) {
                console.error(`onLightToggle exception: ${error}`);
            }
        },
        onLightPressed() {
            try {
                console.log(`onLightPressed`);
                if (this.deviceIOStatus && this.deviceIOStatus.Light == 'Off') { // Light is Off
                    sendMessageUnified(this.devId, 'startCourtesyLight', {});
                    for (let devId of this.slaveDevIds) { 
                        console.log(`Sending startCourtesyLight to slave device: ${devId}`);
                        sendMessageUnified(devId, 'startCourtesyLight', {});
                    }
                } else { // Light is On
                    sendMessageUnified(this.devId, 'stopCourtesyLight', {});
                    for (let devId of this.slaveDevIds) {
                        console.log(`Sending stopCourtesyLight to slave device: ${devId}`);
                        sendMessageUnified(devId, 'stopCourtesyLight', {});
                    }
                }
            } catch(error) {
                console.error(`onLightPressed exception: ${error}`);
            }
        },
        onAlarmPressed() {
            try {
                console.log(`onAlarmPressed`);
                if (this.deviceIOStatus && this.deviceIOStatus.Alarm == 'On') { // Alarm is On
                    sendMessageUnified(this.devId, 'acknowledgeProblem', {});
                    for (let devId of this.slaveDevIds) { 
                        console.log(`Sending acknowledgeProblem to slave device: ${devId}`);
                        sendMessageUnified(devId, 'acknowledgeProblem', {});
                    }
                } else { // Alarm is Off
                    sendMessageUnified(this.devId, 'startAlarmTest', {});
                    for (let devId of this.slaveDevIds) {
                        console.log(`Sending startAlarmTest to slave device: ${devId}`);
                        sendMessageUnified(devId, 'startAlarmTest', {});
                    }
                }
            } catch(error) {
                console.error(`onAlarmPressed exception: ${error}`);
            }
        },
        onButtonPress(buttonName) {
            console.log(`onButtonPress: ${buttonName}`);
            const content = {};
            content[buttonName] = 'On';
            //console.log(`Sending buttonChange with content: ${content}`);            
            sendMessageUnified(this.devId, 'buttonChange', content);
            for (let devId of this.slaveDevIds) {
                console.log(`Sending buttonChange to slave device: ${devId}`);
                sendMessageUnified(devId, 'buttonChange', content);
            }
        },
        onButtonReleased(buttonName) {
            console.log(`onButtonReleased: ${buttonName}`);
            const content = {};
            content[buttonName] = 'Off';
            //console.log(`Sending buttonChange with content: ${content}`);            
            sendMessageUnified(this.devId, 'buttonChange', content);
            for (let devId of this.slaveDevIds) {
                console.log(`Sending buttonChange to slave device: ${devId}`);
                sendMessageUnified(devId, 'buttonChange', content);
            }
        },
        onOpenHistory() {
            console.log(`onOpenHistory`);
            let devName = this.devName;
            let deviceId = this.devId;
            let now = new Date();
            now.setMinutes(now.getMinutes() - 15); // 15 minutes before
            let ts = formatDateTimeForHistory(now)
            let path = `/history/${this.customer}/${this.projectId}/${devName}/${deviceId}/${ts}`;
            let routeData = this.$router.resolve({path: path});
            window.open(routeData.href, '_blank');
        },
        onToolPressed() {
            let toolUrl = this.guiInfo.PanelToolRemoteUrl;
            console.log(`onToolPressed. Opening URL: ${toolUrl}`);
            if (toolUrl && toolUrl !== '') {
                let params = `menubar=no, toolbar=no, location=no, status=no, width=400,height=800, left=100, top=100`;
                //let params = `menubar=no, toolbar=no, location=no, status=no, width=400`;
                window.open(toolUrl, 'Tool', params);
            }
        },
        relativeDateTime(TimeStampMs, seconds) {
            return relativeDateTime(TimeStampMs, seconds);
        },
        round(number, digits) {
            if (number) return number.toFixed(digits);
            else return 0;
        },
        joinAllRooms() {
            if (this.devId) {
                // Master/Single
                joinRoomUnified(this.devId, 'CameraInferences');
                joinRoomUnified(this.devId, 'OperatingStatus');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    joinRoomUnified(devId, 'CameraInferences');
                    joinRoomUnified(devId, 'OperatingStatus');
                }
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                // Master/Single
                console.log(`Leaving 'CameraInferences' and 'OperatingStatus' rooms for device: ${this.devId}`);
                leaveRoomUnified(this.devId, 'CameraInferences');
                leaveRoomUnified(this.devId, 'OperatingStatus');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    console.log(`Leaving 'CameraInferences' and 'OperatingStatus' rooms for device: ${devId}`);
                    leaveRoomUnified(devId, 'CameraInferences');
                    leaveRoomUnified(devId, 'OperatingStatus');
                }
            }
        },
        onWindowResize() {
            console.log(`Panel. onWindowResize. Width: ${this.windowWidth}, Height: ${this.windowHeight}`);
            if (this.modalWZoom && this.imgModalIsShow) this.modalWZoom.prepare();
            this.resizeImage();
        },
        resizeImage() {
            // Calculate the image size to accomodate in window
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            this.imgWidth = Math.round(this.windowWidth / this.numColumns); // Width to accomodate all images in a row
            this.imgHeight = this.imgWidth * this.imgOriginalHeight / this.imgOriginalWidth; // Height to keep aspect ratio
            console.log(`Panel. resizeImage. windowWidth: ${this.windowWidth}, windowHeight: ${this.windowHeight}, imgOriginalWidth: ${this.imgOriginalWidth}, imgOriginalHeight: ${this.imgOriginalHeight}, imgWidth: ${this.imgWidth}, imgHeight: ${this.imgHeight}, numColumns: ${this.numColumns}`);
        },
        onImgClicked(event, pos, imgClass) {
            console.log(`onImgClicked position: ${pos}`);
            this.imgModalIsShow = true;
            this.modalPos = pos;
            this.modalImgClass = {...imgClass}; // Clone imgClass to get static image
            document.getElementById("ImgModal").style.display = "block";
            // Start WZoom object
            setTimeout(() => {                
                this.modalWZoom = WZoom.create('#zoompan', {
                    type: 'html',
                    speed: 5,
                    minScale: 1,
                    maxScale: 5,
                    zoomOnClick: false,
                    zoomOnDblClick: true,
                });
            }, 10);
        },
        closeModal() {
            console.log(`closeModal`);
            this.imgModalIsShow = false;
            document.getElementById("ImgModal").style.display = "none";
        },
        onScroll() {
            console.log(`onScroll`);
            // Recreate modalWZoom if needed
            if (this.imgModalIsShow && this.modalWZoom) {
                this.modalWZoom.prepare();
            }
        },
        setWindowdTitle() {
            console.log(`Panel. setWindowdTitle. Device: ${this.devId}, Name: ${this.devName}`);
            if (this.devName && this.devName !== undefined && this.devName !== null && this.devName !== 'Unknown' && this.devName !== 'undefined') {
                console.log(`Setting Page title to: Panel-${this.devName}`);
                document.title = `Panel-${this.devName}`; // Set Page title
            } else {
                console.log(`Setting Page title to: Panel`);
                document.title = `Panel`; // Set Page title to default  
            }
        },
    },
    computed: {
        // preferedChannel: function () { if (this.devIp !== 'remote') return 'SocketIO'; else return 'WebRTC'; }, SocketIO does not work as it is not SSL
        //preferedChannel: function () { return this.appMode === 'LOCAL' ? 'SocketIO' : 'WebRTC'; },
        slaveDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return store.getters['devices/getSlaveDevIds'](this.$route.params.DevId); else return [];},
        allDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return [this.$route.params.DevId].concat(store.getters['devices/getSlaveDevIds'](this.$route.params.DevId)); else return []; },
        guiInfo: function () { if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo },
        projectId: function () { if (this.guiInfo) return this.guiInfo.ProjectId},
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                if (store.state.devices.deviceInfo[this.devId] &&
                store.state.devices.deviceInfo[this.devId].GUIInfo) return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
                else return 'Unknown';
            } else null;
        },
        ...mapGetters('devices', [
            'getDevName',
        ]),
        macroList: function () { if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo.MacroList; else return []; },
        macros: function () { if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo.Macros; else return []; },
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
        allLabels: function () { return store.state.devices.deviceInfo[this.devId] && store.state.devices.deviceInfo[this.devId].GUIInfo ? store.state.devices.deviceInfo[this.devId].GUIInfo.AllLabels : []},
        labelsWithDetection: function () { return store.state.devices.deviceInfo[this.devId] ? store.state.devices.deviceInfo[this.devId].GUIInfo.labelsWithDetection : []},
        devAllPred: function () { 
            if (this.devId && this.devId in store.state.devices.devInference) {
                return store.state.devices.devInference[this.devId].dev_all_pred;
            } else return {};
        },
        devAllimgClass: function () { 
            let out = {};
            if (this.devId && this.devId in store.state.devices.imgClass) {
                for (let devId of this.allDevIds) {
                    out = {...out, ...store.state.devices.imgClass[devId]};
                }
            }
            return out;
        },
        deviceOperatingState: function () { return store.state.devices.deviceOperatingState[this.devId] },
        deviceState: function () { if(!this.deviceOperatingState) return 'Unknown'; else return this.deviceOperatingState.State },
        devicePanelInfo: function () { return store.state.devices.devicePanelInfo[this.devId] },
        // Class for device name 
        devNameClass() {
            return {
                GreyFont: (!this.deviceOperatingState || this.deviceOperatingState.hostMode == 'Stop'),
                GreenFont: (this.deviceOperatingState && this.deviceOperatingState.hostMode == 'Inspecting' && ! this.deviceOperatingState.alarm),
                RedFont: (this.deviceOperatingState && this.deviceOperatingState.hostMode == 'Inspecting' && this.deviceOperatingState.alarm),
                blink: (this.deviceOperatingState && this.deviceOperatingState.alarm),
            }
        },
        deviceIOStatus: function () { return store.state.devices.deviceIOStatus[this.devId] },
        lightStatusButtonClass: function () {
            return {
                YellowBackGround: (this.deviceIOStatus && this.deviceIOStatus.Light == 'On'),
                GreyBackGround : !(this.deviceIOStatus && this.deviceIOStatus.Light == 'On')
            }
        },
        alarmStatusButtonClass: function () {
            return {
                OrangeBackGround: (this.deviceIOStatus && this.deviceIOStatus.Alarm == 'On'),
                blink: (this.deviceIOStatus && this.deviceIOStatus.Alarm == 'On'),
                GreyBackGround : !(this.deviceIOStatus && this.deviceIOStatus.Alarm == 'On')
            }
        },
        buttonStatusButtonClass: function () {
            return {
                GreenBackGround: (this.deviceIOStatus && this.deviceIOStatus.Button == 'On'),
                GreyBackGround : !(this.deviceIOStatus && this.deviceIOStatus.Button == 'On')
            }
        },
        // imageHeight: function () { if (this.$refs.InferImageML) return this.$refs.InferImageML.clientHeight; },
        highestProbLabels: function () {
            console.log(`juhkujhgjhgfjy: ${JSON.stringify(this.devAllPred)}`)
            if (this.devAllPred) {
                let keysSorted = Object.keys(this.devAllPred).sort((a,b) => {return this.devAllPred[b]-this.devAllPred[a]});
                //console.log(`keysSorted: ${keysSorted}`)
                return keysSorted.slice(0,2);  
            } else {
                return [];
            }          
        },
        decryptionPassword: function () { if (this.projectId) return store.state.projects.passwordPerProject[this.projectId] },
    },
    // Lifecycle hooks
    mounted() {
        console.log(`Panel View Created. Device: ${this.devId}, Mode: ${this.appMode}, IP: ${this.devIp}`);
        this.setWindowdTitle();
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};      
        // Connect to Master/Single and Slaves devices if any
        if (this.devId) {
            console.log(`Connecting via WebRTC to Master/Single device: ${this.devId}`)
            openUnifiedChannel(this.devId, this.onDevConnected);
            // Slaves
            for (let devId of this.slaveDevIds) {
                console.log(`Connecting via WebRTC to slave device: ${devId}`);
                openUnifiedChannel(devId, this.onDevConnected);
            }
        }    
        // Prevent scroll being driven by keys
        window.addEventListener('keydown', (e) => {
            console.log('Key Pressed: ' + e.code);            
            if (this.imgModalIsShow) {
                if(e.code === "Escape") this.closeModal();    // Close modal windows if 'Escape' key is pressed
            } else { // No modal winows open
                if (e.code === "ArrowRight") { 
                    this.numColumns = Math.min(Math.max(this.numColumns - 1, 1), Object.keys(this.devAllimgClass).length);
                    this.resizeImage();
                } else if (e.code === "ArrowLeft") {
                    this.numColumns = Math.min(Math.max(this.numColumns + 1, 1), Object.keys(this.devAllimgClass).length);
                    this.resizeImage();
                }
            }
        });
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Panel page being visible');
                // Join All rooms
                this.joinAllRooms();
                doKeepWsConnected();
            } else { //Hiden
                console.log('Panel page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
                dontKeepWsConnected();
            }
        };   
        //
        this.onWindowResize();
        window.addEventListener("resize", this.onWindowResize);   
        // Scroll event
        window.addEventListener("scroll", this.onScroll);  
    },
    unmounted() {
        console.log('Panel View Unmounted')
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close all WebRTC/SocketIO conections
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds); // Master            
            for (let devId of this.slaveDevIds) {
                console.log(`Closing WebRTC connection to slave device: ${devId}`);
                closeChannel('WebRTC', devId, appConfig.WebRTCCloseDelaySeconds);  // Slave
            }
        }
        // Cancel loaderTask if any
        if (this.loaderTask) clearTimeout(this.loaderTask); 
        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
})
export default class Panel extends Vue {}
</script>

<style scoped>
    * {
    box-sizing: border-box;
    }
    .column {
        float: left;
        padding: 0px;
    }
    .left {
        width: 70%;
    }
    .right {
        width: 30%;
    }
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    table, th, td {
        border: 0px;
        vertical-align: middle;
    }

    .image {
        width: 100%;
    }

    .container_cam_img {
        text-align: center;
        color: white;
        margin: 0 10 0 10;
        border-style: solid;
        border-width: 1px;
        border-color:rgb(127, 218, 150);
    }

    /* Bottom left text */
    .bottom-left {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }
    
    /* Top left text */
    .top-left {
        position: absolute;
        top: 8px;
        left: 16px;
    }
    
    /* Top right text */
    .top-right {
        position: absolute;
        top: 8px;
        right: 16px;
    }
    
    /* Bottom right text */
    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
    
    /* Centered text */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Center down content */
    .center-down {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    table {
        text-align: left;
        position: relative;
        border-collapse: collapse; 
        text-indent: initial;
        white-space: normal;
        line-height: normal;
        font-weight: normal;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 1px;
        font-variant: normal;
    }

    th, td {
        padding: 0.25rem;
    }

    .panelAllCam {
        background: rgb(182, 203, 235);
        /* z-index: 1; */
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        padding: 0.25rem;
        /*width: 100%;*/
    }

</style>



// Created by SPe on 27/11/22
// Page to view panel
<template>
    <div class="panel">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />
      
        <!-- Header  -->
        <div id="panel" >            

            <div style="display: flex; justify-content: center;">
                <h1 class="py-2" style="text-align: center; margin:0px;">{{devName}} - {{keypadConfig.Status}}</h1>
                <i class="bi bi-file-lock cursor-pointer" style="font-size: 24px" @click="keypadLocked = !keypadLocked" :class="{'redColor': keypadLocked, 'greenColor': !keypadLocked}"></i>
            </div>

            <button class="bg-green-400" v-if="keypadConfig && keypadConfig.ChangeStatusEnabled" @click="changeKPConfig('ChangeStatusEnabled', false)">Control Externo Habilitado</button>
            <button class="bg-red-400" v-if="keypadConfig && !keypadConfig.ChangeStatusEnabled" @click="changeKPConfig('ChangeStatusEnabled', true)">Control Externo Desabilitado</button>

            <!-- <canvas v-if="keypadConfig && !keypadConfig.statusForcedByExternal" style="padding-left: 10px; width:320px; margin-bottom:-80px;" id="lcd"></canvas>
            <canvas v-if="keypadConfig && keypadConfig.statusForcedByExternal" style="padding-left: 10px; width:320px; margin-bottom:-80px; border: 5px solid red;" id="lcd"></canvas> -->
            <canvas :style="displayStyle()" id="lcd"></canvas>

            <table style="width:320px; text-align: center">
                <tr align: center style="width:100%;">
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Media'], 'blue': justClicked['Media']}" @click="onKeyButton('Media')">MEDIA</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Cleaning'], 'blue': justClicked['Cleaning']}" @click="onKeyButton('Cleaning')">CLEANING</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Heater'], 'blue': justClicked['Heater']}" @click="onKeyButton('Heater')">HEATER</button></td>
                </tr>
            </table>  
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Adjust'], 'blue': justClicked['Adjust']}" @click="onKeyButton('Adjust')">ADJUST</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Maintenance'], 'blue': justClicked['Maintenance']}" @click="onKeyButton('Maintenance')">MAINT</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['DataClear'], 'blue': justClicked['DataClear']}" @click="onKeyButton('DataClear')">DATACLR</button></td>

                </tr>
            </table>  
            <br>
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px"><button style="width:96px;" class="keyButton text-center" :class="{'purple': !justClicked['UpArrow'], 'blue': justClicked['UpArrow']}" @mousedown="onPressButton('UpArrow')" @mouseup="onReleaseButton('UpArrow')"><img class="mx-auto" :src="require('@/assets/pics/up-arrow.png')" style="height: 16px" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['LeftArrow'], 'blue': justClicked['LeftArrow']}" @mousedown="onPressButton('LeftArrow')"  @mouseup="onReleaseButton('LeftArrow')"><img class="mx-auto" :src="require('@/assets/pics/left-arrow.png')" style="height: 16px" /></button></td>
                    <td style="width:128px"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['RightArrow'], 'blue': justClicked['RightArrow']}" @mousedown="onPressButton('RightArrow')"  @mouseup="onReleaseButton('RightArrow')"><img class="mx-auto" :src="require('@/assets/pics/right-arrow.png')" style="height: 16px" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['DownArrow'], 'blue': justClicked['DownArrow']}" @mousedown="onPressButton('DownArrow')"  @mouseup="onReleaseButton('DownArrow')"><img class="mx-auto" :src="require('@/assets/pics/down-arrow.png')" style="height: 16px" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Function'], 'blue': justClicked['Function']}" @click="onKeyButton('Function')">FUNCTION</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Remote'], 'blue': justClicked['Remote']}" @click="onKeyButton('Remote')">REMOTE</button></td>
                </tr>
            </table> 
            <table style="width:320px">
                <tr style="width:100%">
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['End'], 'blue': justClicked['End']}" @click="onKeyButton('End')">END</button></td>
                    <td style="width:128px; text-align: center"><button style="width:96px" class="keyButton" :class="{'purple': !justClicked['Enter'], 'blue': justClicked['Enter']}" @click="onKeyButton('Enter')">ENTER</button></td>
                </tr>
            </table>  
            <div v-if="macroList.length > 0" class="macros" id="macros" style="text-align: center">
                <p style="margin:0.16px">--------------- Macros ---------------</p>
                <table style="width:320px">
                    <tr v-for="row in numMacroRows" :key="row" style="width:100%">
                        <td v-for="col in numMacroColumns" :key="col" style="width:128px; text-align: center">
                            <!-- :disabled="!macroBeingExecuted && macroList[((row-1)*numMacroColumns + (col-1))]=='*CANCEL*'" -->
                            <button v-if="macroList.length > ((row-1)*numMacroColumns + (col-1))"
                                :title="JSON.stringify(macros[macroList[((row-1)*numMacroColumns + (col-1))]].Keys)"
                                style="width:96px" class="keyButton" 
                                :class="{
                                    'sand': macroList[((row-1)*numMacroColumns + (col-1))]!='*CANCEL*' && !justClicked[macroList[((row-1)*numMacroColumns + (col-1))]],
                                    'blue': macroList[((row-1)*numMacroColumns + (col-1))]!='*CANCEL*' && justClicked[macroList[((row-1)*numMacroColumns + (col-1))]],
                                    'red': macroList[((row-1)*numMacroColumns + (col-1))]=='*CANCEL*' 
                                }"
                                @click="onMacroButton(macroList[((row-1)*numMacroColumns + (col-1))])">
                                    {{macroList[((row-1)*numMacroColumns + (col-1))]}}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>       
        </div>
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import { mapGetters } from 'vuex'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { Options, Vue } from 'vue-class-component'; 
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel,  joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'
import { loadScript } from "vue-plugin-load-script";

// Parameters
let LCD_NUM_ROWS = 2;
let LCD_NUM_COLUMNS = 20;

@Options({
    components: {
        LoadingOverlay,
        NavBar,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters
            devIp: this.$route.params.DevIp, // DevIp collected from Path Parameters   
            loaderTask: null,
            appMode: process.env.VUE_APP_MODE,

            ctx: null,
            lcd: null, // LCD object
            renderDisplayTask: null,

            keypadConfig: {}, // Keypad comfig 

            justClicked: {}, // Dict with key=keyName and value = True if was just pressed
            nowCliked: {}, // Dict with key=keyName and value = True if it is pressed
            bussy: false, // Whether the keyboard is bussy pressing buttons
            firstMessage: true, // Flag to know if message received is first one after initialization
            latestLcdContent: [], // Latest LCD content. One element per row as string
            macroBeingExecuted: null, // Macro name being executed
            macroColorTask: null, // Task for keeping the macro button color during macro executio time
            numMacroColumns: 3, // Num of Macro columns
            keypadLocked: true, // Whether keypad is lock protected
        }
    },
    props: {
    },
    methods: {
        onDevConnected() {
            console.log(`Device: ${this.devId} is connected`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('latest_lcd_content', this.onLatestLcdContent);
            registerUnifiedMessageCallBack('new_keypad_data', this.onNewKeyPadData);
            registerUnifiedMessageCallBack('new_keypad_config', this.onNewKeyPadConfig);
            // Join KeypadData room
            joinRoomUnified(this.devId, 'KeypadData');
        },
        // Write LCD content ['<Content Line 1>', '<Content Line 2>']
        writeLcdContent(lcdContent) {
            console.log(`writeLcdContent: ${lcdContent}`);
            let row = 0;
            for (let line of lcdContent) {
                for (let col=0; col<line.length; col++) {
                    let data = line.charCodeAt(col);
                    let addr = row * 0x40 + col; // Calculate the LCD address for Row and Col
                    // eslint-disable-next-line
                    this.lcd.sendCommand(this.correctCommand(LCD_CMD_SET_DRAM_ADDR | addr)); //Go to position (row, col)
                    this.lcd.writeByte(data);                        
                }
                row++; // Increment row
            }
        },
        clearLcd() {
            console.log(`Clearing LCD`);
            let lcdContent = ['                    ', '                    '];
            this.writeLcdContent(lcdContent);
        },
        onLatestLcdContent(devId, payLoad) {
            console.log(`latest_lcd_content Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.loadingOverlay.show = false;
            this.showSpinner = false;
            let lcdContent = payLoad;
            console.log(`On Latest LCD Content: ${JSON.stringify(lcdContent)}`);
            // eslint-disable-next-line
            try {
                // Empty the LCD and inject this data
                // eslint-disable-next-line
                //this.lcd.sendCommand(LCD_CMD_CLEAR); It does not work
                this.firstMessage = false;
                this.clearLcd();
                this.writeLcdContent(lcdContent);
                this.renderDisplayDelayed();
            } catch (error) {
                console.error(error);
            }
        },
        onNewKeyPadData(devId, payLoad) {
            console.log(`new_keypad_data Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.loadingOverlay.show = false;
            this.showSpinner = false;
            let dataList = payLoad;
            console.log(`On New KeyPad Content: ${JSON.stringify(dataList)}`);
            try {
                let currentLcdContent = this.readLcd();
                /// Clear display if first Message
                if (this.firstMessage || currentLcdContent[0].includes('Welcome to RKP')) {
                    this.firstMessage = false;
                    console.log('First Message --> Clearing display');
                    // eslint-disable-next-line
                    //this.lcd.sendCommand(LCD_CMD_CLEAR | LCD_CMD_HOME); It does not work
                    this.clearLcd();
                }
                console.log(`On new_keypad_data: ${JSON.stringify(dataList)}`);
                this.latestLCDData = dataList;
                for (let data of dataList) {
                    // Send to display
                    if (data.Type == 'Command') {
                        console.log(`Command: ${data.Byte}`);
                        this.lcd.sendCommand(this.correctCommand(data.Byte));
                        this.renderDisplayDelayed();
                    } else { //data.Type == 'Character'
                        console.log(`Character: ${data.Byte} --> ${String.fromCharCode(data.Byte)}`);
                        this.lcd.writeByte(data.Byte);
                        this.renderDisplayDelayed();
                    }
                }
                // // Read display
                // this.latestLcdContent = this.readLcd();
                // console.log(`Current LCD Content: ${JSON.stringify(this.latestLcdContent)}`);
                // // Send to Backend
                // // Send LCD content to backend
                // sendMessageUnified(devId, 'setLcdContent', this.latestLcdContent);
                
            } catch (error) {
                console.error(error);
            }
        },
        onNewKeyPadConfig(devId, payLoad) {
            console.log(`new_keypad_config Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            try {
                this.keypadConfig = payLoad;                
            } catch (error) {
                console.error(error);
            }
        },
        changeKPConfig(config, value) {
            if (confirm('Estas seguro que quieres cambiar el estado de control remoto?')) {
                console.log(`changeKPConfig. Config: ${config} To Value: ${value}`);
                if (config === 'ChangeStatusEnabled') {
                    sendMessageUnified(this.devId, 'setUserConfig', {'Integration.ChangeStatus.Enabled': value});
                } else {
                    console.error(`changeKPConfig error. Config not recognized: ${config}`)
                }
            } else console.log(`changeKPConfig change not permitted`);
        },
        renderDisplayDelayed() {
            // If there is an ongoing timer --> Cancel it and create a new one
            if (this.renderDisplayTask !== null) clearTimeout(this.renderDisplayTask); // Cancel task
            // Create a delayed task to render the display
            this.renderDisplayTask = setTimeout(() => {
                this.renderDisplayTask = null;
                console.log(`Rendering Display`);
                this.lcd.render(this.ctx, 0, 0, 300, 100);
            }, 200);
        },        
        onKeyButton(keyName) {
            console.log(`onKeyButton: ${keyName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            if (!this.bussy) { // Prevent press button while keypad is bussy
                this.justClicked[keyName] = true;
                setTimeout(() => {this.justClicked[keyName]=false}, 350);
                console.log(`Sending Key Press: ${keyName}`);
                sendMessageUnified(this.devId, 'keyClick', keyName, 'WebSocket');
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },
        onPressButton(keyName) {
            console.log(`onPressButton: ${keyName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            if (!this.bussy) { // Prevent press button while keypad is bussy
                this.justClicked[keyName] = true;
                this.nowCliked[keyName] = true;
                this.bussy = true;
                // Start burst of keypress
                sendMessageUnified(this.devId, 'keyPress', keyName, 'WebSocket');
                setTimeout(() => {this.pressKeyAndCheckLater(keyName)}, 1000);
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },
        onReleaseButton(keyName) {
            console.log(`onReleaseButton: ${keyName}`);
            setTimeout(() => {this.justClicked[keyName]=false}, 150);
            this.nowCliked[keyName] = false;
            this.bussy = false;
            console.log(`Resending Key keyRelease: ${keyName}`);
            sendMessageUnified(this.devId, 'keyRelease', keyName, 'WebSocket');
        },
        // Check periodically if key is still pressed --> send keyPress
        pressKeyAndCheckLater(keyName) {
            console.log(`pressKeyAndCheck: ${keyName}, nowCliked: ${this.nowCliked[keyName]}`);
            if (this.nowCliked[keyName]) {
                console.log(`Resending Key Press: ${keyName}`);
                sendMessageUnified(this.devId, 'keyPress', keyName, 'WebSocket');
                setTimeout(() => {this.pressKeyAndCheckLater(keyName)}, 1000);
            }
        },
        onMacroButton(macroName) {
            console.log(`onMacroButton: ${macroName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            // Cancel ongoing macro execution
            if (macroName == '*CANCEL*' && this.macroBeingExecuted) {
                this.justClicked[macroName] = true;
                console.log(`Sending Macro CANCEL: ${macroName}`);
                sendMessageUnified(this.devId, 'keyClick', macroName, 'WebSocket');
                this.justClicked[this.macroBeingExecuted]=false; 
                this.bussy=false;
                this.macroBeingExecuted=null;
                if (this.macroColorTask) {
                    clearTimeout(this.macroColorTask);
                    this.macroColorTask = null;
                } 
            // Any normal macro
            } else if (!this.bussy || macroName == '*CANCEL*') { // Prevent press button while keypad is bussy
                this.bussy = true;
                this.justClicked[macroName] = true;
                console.log(`Sending Macro Press: ${macroName}`);
                sendMessageUnified(this.devId, 'keyClick', macroName, 'WebSocket');
                let colorTimeSeconds = 0;
                let macroKeys = this.macros[macroName].Keys;
                for (var keyNTime of macroKeys) {
                    console.log(`keyNTime: ${JSON.stringify(keyNTime)}`);
                    let time = Math.max(keyNTime[1], 0.5); // Get the key time (in seconds)
                    colorTimeSeconds += time;
                }
                console.log(`onMacroButton: ${macroName}. Macro time: ${colorTimeSeconds} seconds`);
                this.macroBeingExecuted = macroName;
                this.macroColorTask = setTimeout(() => {this.justClicked[macroName]=false; this.bussy=false; this.macroBeingExecuted=null;}, colorTimeSeconds * 1000);
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },        
        // There is (at least) an error in vrEmuLcd library
        // Command LCD_CMD_SET_DRAM_ADDR does not work well if DDRAM >= 40
        correctCommand(command) {
            // eslint-disable-next-line
            if (command & LCD_CMD_SET_DRAM_ADDR ) {
                let ddram = command & 0x7f;
                // console.log(`LCD_CMD_SET_DRAM_ADDR command. DDRAM: ${ddram}`);
                // eslint-disable-next-line
                if (ddram >= 40) return LCD_CMD_SET_DRAM_ADDR | (ddram - 24); // 24 = 0x40 (64) - 40
                else return command; // Nothing to correct
            } else return command; // Nothing to correct
        },
        // Read the content of the display
        readLcd() {
            let latestLcdContent = [];
            let currentAddr = this.lcd.readAddress();
            console.log(`readLcd. Current Address = ${currentAddr}`);
            for (let row=0; row < LCD_NUM_ROWS; row++) {
                let line = '';
                for (let col=0; col < LCD_NUM_COLUMNS; col++) {
                    let addr = row * 0x40 + col;
                    // eslint-disable-next-line
                    this.lcd.sendCommand(this.correctCommand(LCD_CMD_SET_DRAM_ADDR | addr)); //Go to position (row, col)
                    line += String.fromCharCode(this.lcd.readByte()); // Read data in this position                    
                }
                latestLcdContent.push(line); // Add line
            }
            // Go to initial position
            // eslint-disable-next-line
            this.lcd.sendCommand(this.correctCommand(LCD_CMD_SET_DRAM_ADDR | currentAddr)); 
            return latestLcdContent;
        },
        joinAllRooms() {
            if (this.devId) {
                joinRoomUnified(this.devId, 'KeypadData');
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                leaveRoomUnified(this.devId, 'KeypadData');
            }
        },
        displayStyle() {
            if (this.keypadConfig && this.keypadConfig.statusForcedByExternal) return {'padding-left': '10px', width: '320px', 'margin-bottom': '-80px', 'border-top': '5px solid red'};
            else return {'padding-left': '10px', width: '320px', 'margin-bottom': '-80px'};
        }
    },
    computed: {
        guiInfo: function () { if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo },
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
                else return 'Unknown';
            } else null;
        },
        ...mapGetters('devices', [
            'getDevName',
        ]),
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not        
        macroList: function () { if (this.guiInfo) { return this.guiInfo.MacroList; } else return {}; },
        macros: function () { if (this.guiInfo) { return this.guiInfo.Macros; } else return {}; },
        numMacroRows: function () { if (this.macroList.length > 0) { return  Math.ceil(this.macroList.length / this.numMacroColumns);} else return 0;}
    },
    // Lifecycle hooks
    async mounted() {
        console.log(`PanelRKP-CHR View Created. Device: ${this.devId}, Mode: ${this.appMode}, IP: ${this.devIp}`);
        document.title = `Panel-${this.devName}`; // Set Page title

        console.log(`Location: ${location.origin}`);
        let baseUrl = location.origin;
        loadScript(`${baseUrl}/vrEmuLcd.js`)
            .then(() => {
                loadScript(`${baseUrl}/vrEmuLcdWasm.js`)
                    .then(() => {
                        // LCD
                        var canv = document.getElementById('lcd');
                        this.ctx = canv.getContext('2d');
                        // eslint-disable-next-line
                        vrEmuLcd.setLoadedCallback(async () => {
                            // create a new LCD object
                            // eslint-disable-next-line
                            this.lcd = vrEmuLcd.newLCD(LCD_NUM_COLUMNS, LCD_NUM_ROWS, vrEmuLcd.CharacterRom.Eurpoean);
                            // eslint-disable-next-line
                            this.lcd.colorScheme = vrEmuLcd.Schemes.BlackOnGreen;
                    
                            // set up the display
                            // eslint-disable-next-line
                            this.lcd.sendCommand(LCD_CMD_DISPLAY | LCD_CMD_DISPLAY_ON);
                    
                            this.lcd.writeString("   Welcome to RKP");
                            // eslint-disable-next-line
                            this.lcd.sendCommand(this.correctCommand(LCD_CMD_SET_DRAM_ADDR | 0x40));
                            this.lcd.writeString("     by AInspect");
                            this.renderDisplayDelayed();

                            //joinRoomUnified(this.deviceSio, 'KeypadData');
                        });
                    })
                    .catch(() => {
                        console.error(`Could not download vrEmuLcdWasm.js script`);
                })
                .catch(() => {
                    console.error(`Could not download vrEmuLcdWasm.js script`)
                });
            });

        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};        
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('PanelRKP-CHR page being visible');
                // Join All rooms
                this.joinAllRooms();
            } else { //Hiden
                console.log('PanelRKP-CHR page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
            }
        };        
    },
    unmounted() {
        console.log('PanelRKP-CHR View Unmounted')
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close WebRTC/SocketIO conections
            console.log(`Closing WebRTC connection to device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);          
        }
    },
})
export default class Panel_RKP_CHR extends Vue {}
</script>

<style scoped>
    body {background-color: rgb(220, 221, 218);}
    .keyButton {
        background-color: #d16de6;
        border-color: black;
        border-radius: 16px;
        color: white;
        padding: 0.16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 0px 0px;
    }
    .panel {
        width: 330px;
        border-color: rgb(161, 25, 100);
        border-radius: 16px;
        background-color: rgb(145, 230, 241);
    }
    .white {
        background-color: white;     
    }
    .blue {
        background-color: rgb(101, 134, 241);     
    }
    .purple {
        background-color: #d16de6;
    }
    .sand {
        background-color: #91752a;
    }
    .red {
        background-color: #f86611;
    }
    .redColor {
        color: #f86611;
    }
    .greenColor {
        color: #79ad4f;
    }
</style>



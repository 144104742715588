// Created by SPe on 08/11/21
// Page to remoete view and control ProInspector devices

<template>
  <div class="terminal" ref="divContainer" style="height: 100%">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <NavBar 
        :devName="devName" 
        :showBackButton="showBackButton" 
        :showSpinner="false"
        :servConnected="servConnected" 
        :devConnected="devConnected"
    />
     
    <div id="xterm" ref="xterm" ></div>
    <!-- Footernbar -->
    <Footer />

  </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import store from '@/store/index.js';
import appConfig from '@/config.js';
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import { sendWsMessage } from '@/library/websocket'
import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel } from '@/library/client-unified-send'
import { Terminal as XTerminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { SearchAddon } from "xterm-addon-search";
import { WebLinksAddon } from "xterm-addon-web-links";
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import "xterm/css/xterm.css";
import "xterm/lib/xterm.js";

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters        
            
            term: null, // Terminal component
            fitAddon: null, // Terminal Fit Addon
            termResObs: null, // Terminal ResizeObserver
            termWidth: 0, // Terminal width
            termHeight: 0, // Terminal height
        }
    },
    props: [],
    methods: {
        async onDevConnected(devId) {
            console.log(`Device: ${devId} is connected`);
            if (!this.term) { // If term does not exists
                // send SendSetClientPermissions message to backend
                sendWsMessage('SendSetClientPermissions', {ClientId: this.clientId, DevId: this.devId, UserId: store.state.login.email});
                this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
                // Create terminal
                this.initXterm();
                // Attch terminal ResizeObserver 
                this.termResObs = new ResizeObserver(this.fitToscreen)
                this.termResObs.observe(this.$refs.xterm);
                // Register Call-Backs
                registerUnifiedMessageCallBack('OutFromTerminal', this.onOutFromTerminal);
                // TODO: Improve this. Small delay to ensure SendSetClientPermissions takes effect before sending openTerminal
                // await new Promise(r => setTimeout(r, 1000));
                // Open Terminal in device
                sendMessageUnified(this.devId, 'openTerminal', {});
                this.fitToscreen();
            }
        },
        onOutFromTerminal(devId, payLoad) {
            console.log(`OutFromTerminal Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            if (devId === this.devId) { // My device
                console.log(`OutFromTerminal Received`);
                this.loadingOverlay.show = false;
                this.term.write(payLoad.output);
            }
        },
        initXterm() {
            this.term = new XTerminal({
                rendererType: "canvas", // "dom" or "canvas" (default)
                rows: 35, // Number of rows in the terminal
                convertEol: true, // When enabled, the cursor will be set to the beginning of the next line
                scrollback: 1000, // The amount of scrollback in the terminal
                macOptionIsMeta: true, // Option (alt) acts as meta key
                disableStdin: false, // Whether to allow input
                cursorStyle: "underline", // The style of the cursor (block, underline, bar)
                cursorBlink: true, // Whether the cursor blinks
                theme: {
                    foreground: "#eeeeee", //"yellow", // Text color
                    background: "#060101", // Background color
                    cursor: "help" // Cursor 
                }
            });
            // Add addons
            this.fitAddon = new FitAddon();
            this.term.loadAddon(this.fitAddon);
            this.term.loadAddon(new WebLinksAddon());
            this.term.loadAddon(new SearchAddon());
            // Resize and Open terminal
            //this.term.resize(100, 30);
            this.fitToscreen();
            this.term.open(document.getElementById("xterm"));
            // On data  
            this.term.onData((data) => {
                console.log(`Data to terminal: ${data}`);
                sendMessageUnified(this.devId, 'inToTerminal', {input: data});
            });
            // Write initialization
            this.term.write(`\u001b[33mOpening terminal to device: ${this.devId} ... `)
        },
        fitToscreen() {
            this.fitAddon.fit();
            const dims = { cols: this.term.cols, rows: this.term.rows };
            console.log("fitToscreen. New dimensions to server's pty", dims);
            // Send to device
            sendMessageUnified(this.devId, 'resizeTerminal', dims);
        },
        debounce(func, wait_ms) {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait_ms);
            };
        },
    },
    computed: {        
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else return this.devId;
        },
        clientId: function () { return store.state.login.clientId },
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('Terminal View Mounting');
        document.title = `Terminal-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`}; 
        // Connect with Device
        const onDataCB = null;
        const onWRtcConnectedCB = null;
        const openWRtc = false;
        openUnifiedChannel(this.devId, this.onDevConnected, onDataCB, onWRtcConnectedCB, openWRtc);        
    },
    unmounted() {
        console.log('Terminal View Unmounted')
        if (this.devId) {
            // Close Terminal
            sendMessageUnified(this.devId, 'closeTerminal', {});
            // Close WebRTC connection
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);
        }
    },
    beforeUnmount() {
        console.log('Terminal View beforeUnmount')
        // Detach Terminal ResizeObserver
        if (this.termResObs) this.termResObs.unobserve(this.$refs.xterm);
    },
    errorCaptured() {
        console.log('Terminal View errorCaptured')
    },    
})
export default class Terminal extends Vue {}
</script>

<style>
    
</style>



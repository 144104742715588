// Created by SPe on 4/10/2023
// component for Getting the Dev Id to register a new User
<template>
  <div class="root">
    <!-- Dataset Name -->
    <h4>Enter Device Id of your Pro-Inspector</h4>
    
    <div class="flex mb-4">
        <h4 class="mt-3 px-2 w-1/2">Device Id:</h4>
        <input class="w-1/2" id="devid" placeholder="A1B2C3D4E5F6" v-model="devId" :class="{error: !validateInput(devId)}"/>
    </div>

    <p>The user: "{{ userId }}" is not registered. You need to provide a valid Device Id for one of your Pro-Inspector device. Device Is an alphanumeric string like "A1B2C3D4E5F6"</p>
    <br>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Save</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Close</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            devId: '', // Comment
        }
    },
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    methods: {
        submitForm() {
            console.log('Save GetDevIdForm');
            // Send event
            this.$emit("getDevIdFormSubmitted", this.devId);
            console.log('GetDevIdForm successfully saved.');
        },
        cancel() {
            this.$emit("getDevIdFormSubmitted", null);
        },       
        validateInput(inputValue) {
            if (inputValue) {
                // Regulatr expressión to check is an string like "A1B2C3D4E5F6". 'A-Z + 0-9 + A-Z + 0-9 + A-Z + 0-9 + A-Z + 0-9 + A-Z + 0-9 +A-Z + 0-9'
                let re = /^[A-Z]\d[A-Z]\d[A-Z]\d[A-Z]\d[A-Z]\d[A-Z]\d$/;
                if (re.test(inputValue)) return true
                else return false;
            } else return false
        }
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('GetDevIdForm Form Mounting');

        this.newComment = this.comment;

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        
    },
    unmounted() {
        console.log('GetDevIdForm Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class GetDevIdForm extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>



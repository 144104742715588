<template>
<div class="row">
    <NavBar/>
    <div class="col">
        <img class="mx-auto" alt="Pro-Inspectr logo" src="../assets/pics/logo.png">
        <br><br><br>
        <h2>User not registered or email not verified</h2>
        <!-- <br><br>
        <button type="button" @click="logIn" class="btn btn-success btn-lg d-grid gap-2 col-3 mx-auto">
        Login    
        </button> -->
    </div>
</div>


</template>
<script>
import { Options, Vue } from 'vue-class-component'; 
import auth from '@/library/auth';
import NavBar from '@/components/NavBar.vue';

@Options({
  components: {
    NavBar,
  },
  data: function(){
    return {
    }
  },
  props: [],
  methods: {
    logIn() {
        
    }
  },
  computes: {},
  created() {},
  mounted() {

    // Delay 5 seconds before redirecting to logout page
    setTimeout(() => {
        auth.logout();
    }, 5000);

  },
})

export default class UserNotRegistered extends Vue{}
</script>
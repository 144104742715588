/* eslint-disable */
/* 
Created by SPe: on 10/01/2022
Web RTC module
It support multiple WebRTC connection to different sevices
*/

import store from '@/store/index.js';
import appConfig from '@/config.js';

import { sendWsMessageToClient, registerWsMessageCallBack} from '@/library/websocket';
import { unifiedProcessMessage } from '@/library/client-unified-receive';

// Glabal (module) variables
let peerConnectionPerDevice = {}; // Web RTC Peer Connectin object per device Id
let dataChannelPerDevice = {}; // WRTC Data Channel object per device Id
let connectedDevices = []; // List of conneced devices
//let connected = false;
let pingTaskPerDevice = {}; // Ping task per device Id
let latestMsgReceivedTsPerDev = {}; // Timestamp of latest message received per device
let delayedCloseTaskPerDev = {}; // Delayed close task per device

let msgBuffer = ''; //Buffer to store partial messages

// Connect to device
export function connect(devId, onConnectedCB=null, onDataCB=null, onCloseCB=null) {  
  console.log(`Connecting Web RTC Peer Connection to device: ${devId}`);
  // Cancel delayedCloseTaskPerDev if any
  if (delayedCloseTaskPerDev[devId]) {
    console.log(`Cancelling delayed close task for device: ${devId}`);
    clearTimeout(delayedCloseTaskPerDev[devId]);
    delete delayedCloseTaskPerDev[devId];
  }
  // If already exist a connection --> exit
  if (devId in peerConnectionPerDevice) {
    console.error(`WebRTC connect error for device: ${devId}. There is an existing connection`);
    return;
  }
  // Register callbacks to Web Socket for receiving signalling messages from device
  registerWsMessageCallBack('WRTCAnswer', onAnswer);
  registerWsMessageCallBack('WRTIceCandidate', onICECandidate);
  // Create Peer Connection
  let peerConnection = createPeerConnection(devId, onConnectedCB, onDataCB, onCloseCB);
  peerConnection.terminate = false; // Flag to indicate the the peer connection should not be open again
  peerConnectionPerDevice[devId] = peerConnection;
  // Create a delayed task to retry open if not open
  peerConnection.retryOpenTask = setTimeout(function() {
    if  (peerConnection.iceConnectionState !== 'connected') {
      console.error(`WebRTC connection Error. No connection in 10 seconds. Closing and retrying...`);
      close(devId); // Close connection
      // Reopen
      setTimeout(function() {connect(devId, onConnectedCB, onDataCB, onCloseCB);}, 500);
    } else {
      console.error(`WebRTC. connect error. This message should not appear`);
      // Call calback if any
      if (onConnectedCB) onConnectedCB(devId);
    }
  }, 10 * 1000);

  // If document is not visble --> do not connect --> will retry later
  if (document.visibilityState !== "visible") {
    console.log(`Document not visible --> Skipping connecting Web RTC Peer Connection to device: ${devId}`);
    return;
  }

  // Add Data Chanel
  //let dataChannelParameters = {ordered: true, maxPacketLifeTime: 3000}
  let dataChannelParameters = {ordered: true, reliable: false, maxPacketLifeTime: 5000} 
  let dataChannel = peerConnection.createDataChannel('datachannel', dataChannelParameters);
  dataChannelPerDevice[devId] = dataChannel

  dataChannel.onclose = () => {
    console.log(`WebRTC data channel closed for device: ${devId}`);
    store.commit('connection/setWrtcConnected', {DevId: devId, Value: false});
      //delete dataChannelPerDevice[devId];
      if (devId in pingTaskPerDevice) {
        clearInterval(pingTaskPerDevice[devId]);
        delete pingTaskPerDevice[devId];
      }
      if (!peerConnection.terminate) {
        console.log(`WebRTC data channel is closed. Reconecting`);
        close(devId); // Close connection
        // Reopen
        setTimeout(function() {connect(devId, onConnectedCB, onDataCB);}, 500);
      } else console.log(`Not reconnecting WebRTC as has been requested to terminate`);
      // Remove from connectedDevices
      connectedDevices.splice(connectedDevices.indexOf(devId), 1);
      // Call onCloseCBcall-back if any
      if (onCloseCB) onCloseCB(devId);
  };
  dataChannel.onopen = () => {
      console.log(` with Device: ${devId}`);
      store.commit('connection/setWrtcConnected', {DevId: devId, Value: true});
      latestMsgReceivedTsPerDev[devId] = Date.now(); // Initialize
      pingTaskPerDevice[devId] = setInterval(function() {
        // Check if long time since last ping --> assume channel closed
        if (Date.now() - latestMsgReceivedTsPerDev[devId] > 10 * appConfig.WebRTCPingPeriod) { // Last 10 ping was not received
          console.error(`WebRTC connection Error. No ping received. Closing and retrying...`);
          close(devId); // Close connection
          // Reopen
          setTimeout(function() {connect(devId, onConnectedCB, onDataCB, onCloseCB);}, 500);
        } else {
          var message = {Type: 'ping', PayLoad: {PingTimeStamp: Date.now()}}
          // console.log(`Sending Pind to device: ${devId}`);
          dataChannel.send(JSON.stringify(message));
        }
      }, appConfig.WebRTCPingPeriod);

      // Add to connectedDevices
      connectedDevices.push(devId);
      // Call calback if any
      if (onConnectedCB) onConnectedCB(devId);
  };
  dataChannel.onmessage = function(evt) {
      //console.log(`Data Channel Message Received: ${evt.data}`)
      try {
        latestMsgReceivedTsPerDev[devId] = Date.now();
        let msgLength = evt.data.length;
        //console.log(`dataChannel.onmessage. Length: ${msgLength}`);
        // Accumulate data in string buffer
        msgBuffer = msgBuffer + evt.data;          
        // Convert from Json to object
        try {
          //console.log(`Final message received. Total Length: ${msgBuffer.length}`);
          let data = JSON.parse(msgBuffer);
          msgBuffer = ''; // Clear the buffer
          let type = data.Type;
          let payLoad = data.PayLoad
          processMessage(devId, type, payLoad);
          if (onDataCB) onDataCB(devId, type, payLoad);
        } catch(error) {
          // If "Unexpected end of JSON input" exception --> assume that the message is incomplete
          if (`${error}`.includes('Unexpected end of JSON input')) {
            //console.log(`Partial message received`)
            return;
          } else {
            console.error(`dataChannel.onmessage JSON.parse exception: ${error}`);
            msgBuffer = ''; // Clear the buffer
          }
        }             
      } catch(error) {
        console.error(`dataChannel.onmessage exception: ${error}`);
      }
  };

  // Negotatiate
  negotiate(peerConnection, devId);  
}
// Close connection to device
// If delayed > 0 it will do after the delay 
export function close(devId, delayed=0) {
  console.log(`Closing Peer Connection for device: ${devId}. Delayed: ${delayed} seconds`);
  // Cancel delayedCloseTaskPerDev if any
  if (delayedCloseTaskPerDev[devId]) {
    console.log(`Cancelling delayed close task for device: ${devId}`);
    clearTimeout(delayedCloseTaskPerDev[devId]);
    delete delayedCloseTaskPerDev[devId];
  }
  // Check if it is delayed --> schedule a task to close inmediatly later
  if (delayed > 0) {
    delayedCloseTaskPerDev[devId] = setTimeout(()=>{close(devId, 0)}, delayed*1000)
    return;
  }

  if (devId in peerConnectionPerDevice) {
    peerConnectionPerDevice[devId].terminate=true;
    peerConnectionPerDevice[devId].close();
    delete peerConnectionPerDevice[devId];
    delete dataChannelPerDevice[devId];
    console.log(`Closed Peer Connection for device: ${devId}`);
    // setTimeout(() => {
    //   console.log(`Removing Peer Connection for device: ${devId}`);
    //   peerConnectionPerDevice[devId].close();
    //   delete peerConnectionPerDevice[devId];
    //   delete dataChannelPerDevice[devId];
    // }, 500);
  } else {
    console.error(`Error closing Peer Connection for device: ${devId}. No connection found`);
  }
}
// Return true if is connected (has a data channel)
export function isDeviceWrtconnected(devId) {
  return connectedDevices.includes(devId);
}
// Function to send mesage to device
// Return true if ok
export function sendWRtcMessage(devId, msgType, payLoad) {
  try {
    console.log(`Sending WebRTC message to device: ${devId}, Type: ${msgType}, PayLoad: ${JSON.stringify(payLoad)}`);
    var message = {Type: msgType, PayLoad: payLoad}
    if (devId in dataChannelPerDevice) {
      dataChannelPerDevice[devId].send(JSON.stringify(message));
      return true;
    } else {
      console.error(`sendWRtcMessage error. Device: ${devId} does not have a WebRTC data channel`);
      return false;
    }
  } catch(error) {
    console.error(`WebRtc.sendWRtcMessage exception: ${error}`);
    return false;
  }
}
// Create Peer Connection
function createPeerConnection(devId, onConnectedCB, onDataCB) {
  console.log(`createPeerConnection. DevId: ${devId}`);
  var config = {
      sdpSemantics: 'unified-plan'
  };
  // Get Twilio ICE Servers
  let twilioIceToken = store.state.connection.twilioIceToken;
  console.log(`twilioIceToken: ${JSON.stringify(twilioIceToken)}`);
  if (twilioIceToken && 'ice_servers' in twilioIceToken) {
    let ice_servers = twilioIceToken.ice_servers;
    config.iceServers = ice_servers;
  }
  else {
    console.error(`Not valid ICE server found in token: ${twilioIceToken}`);
    // Only stun servers
    config.iceServers = [{
      url : "stun:global.stun.twilio.com:3478?transport=udp",
      urls : "stun:global.stun.twilio.com:3478?transport=udp"
    }]
  }
  
  // config.iceServers = [
  //   {"url": "stun:global.stun.twilio.com:3478?transport=udp", "urls": "stun:global.stun.twilio.com:3478?transport=udp"},
  //   {"url": "turn:global.turn.twilio.com:3478?transport=udp", "username": "aab6ef8e01bde51404d528ae5d286afd14c3d821defd3ceea0e5c08d4bd8d1a4", "urls": "turn:global.turn.twilio.com:3478?transport=udp", "credential": "+Co60Eqy/z1rzXsx76pe5g0j7pAWYw8wGDhzsC6iyhY="},
  //   {"url": "turn:global.turn.twilio.com:3478?transport=tcp", "username": "aab6ef8e01bde51404d528ae5d286afd14c3d821defd3ceea0e5c08d4bd8d1a4", "urls": "turn:global.turn.twilio.com:3478?transport=tcp", "credential": "+Co60Eqy/z1rzXsx76pe5g0j7pAWYw8wGDhzsC6iyhY="},
  //   {"url": "turn:global.turn.twilio.com:443?transport=tcp", "username": "aab6ef8e01bde51404d528ae5d286afd14c3d821defd3ceea0e5c08d4bd8d1a4", "urls": "turn:global.turn.twilio.com:443?transport=tcp", "credential": "+Co60Eqy/z1rzXsx76pe5g0j7pAWYw8wGDhzsC6iyhY="}
  // ]
  //console.log(`config: ${JSON.stringify(config)}`);
  let pc = new RTCPeerConnection(config);

  // register some listeners to help debugging
  pc.addEventListener('icegatheringstatechange', function() {
    console.log(`New icegatheringstatechange: ${pc.iceGatheringState}`);
  }, false);
  console.log(`Initial icegatheringstatechange: ${pc.iceGatheringState}`);

  pc.addEventListener('iceconnectionstatechange', function() {
    console.log(`New iceconnectionstatechange: ${pc.iceConnectionState}`);
    if (pc.iceConnectionState === 'connected') {
        clearInterval(pc.retryOpenTask);
    } else if (pc.iceConnectionState === 'disconnected') {
      console.log(`WebRTC connection is closed. Reconecting`);
      //close(devId); // Close connection
      // Reopen      
      //setTimeout(function() {connect(devId, onConnectedCB, onDataCB);}, 500);
    }
  }, false);
  console.log(`Initial iceconnectionstatechange: ${pc.iceConnectionState}`);

  pc.addEventListener('connectionstatechange', function() {
    console.log(`New connectionStatechange: ${pc.connectionState}`);
  }, false);
  console.log(`Initial connectionStatechange: ${pc.connectionState}`);


  pc.addEventListener('signalingstatechange', function() {
    console.log(`New signalingstatechange: ${pc.signalingState}`);
  }, false);
  console.log(`Initial signalingstatechange: ${pc.signalingState}`);

  pc.addEventListener('icecandidateerror', function(e) {
    console.log(`icecandidateerror: ${JSON.stringify(e)}`);
  }, false);

  // On new ICE Candidate is detected --> send to device
  pc.onicecandidate = (event) => {
    console.log(`New candidate detected: ${JSON.stringify(event.candidate)}`);
    // let payLoad = {
    //   sdp: event.candidate
    // }
    // console.log(payLoad);
    // sendWsMessageToClient(devId, 'WRTCIceCandidate', payLoad);
  }

  return pc;
}
/* eslint-disable */
// Negotiate
function negotiate(pc, devId) {
  return pc.createOffer().then(function(offer) { // Create Offer
      return pc.setLocalDescription(offer);  // set offer as local description
  }) 
  // Offer is created and set as local
  .then(function() {
      // wait for ICE gathering to complete
      return new Promise(function(resolve) {
          if (pc.iceGatheringState === 'complete') {
              resolve();
          } else {
              function checkState() {
                  if (pc.iceGatheringState === 'complete') {
                      pc.removeEventListener('icegatheringstatechange', checkState);
                      resolve();
                  }
              }
              pc.addEventListener('icegatheringstatechange', checkState);
          }
      });
  })
  //ICE Gathering is completed
  .then(function() {
      var offer = pc.localDescription;
      console.log(`Offer SDP: ${offer.sdp}`);

      let payLoad = {
        sdp: offer.sdp,
        type: offer.type,
        twilio_ice_token: store.state.connection.twilioIceToken,
      }
      sendWsMessageToClient(devId, 'WRTCOffer', payLoad);

  }).catch(function(e) {
      console.error(`negotiate exception: ${e}`);
  });
}

// On Answer (to offer) received from device
function onAnswer(data) {
  let devId = data.DevId;
  console.log(`Answer message received from device ${devId} with Data: ${JSON.stringify(data)}`);
  if (devId in peerConnectionPerDevice) peerConnectionPerDevice[devId].setRemoteDescription(data['PayLoad'])
    .catch(e => {console.log("Failure during addIceCandidate(): " + e);});
}

// On ICE Candidate received from device
function onICECandidate(data) {
  let devId = data.DevId;
  console.log(`ICE Candidate message received from device ${devId} with Data: ${JSON.stringify(data)}`);
  if (devId in peerConnectionPerDevice) peerConnectionPerDevice[devId].addIceCandidate(data)
    .catch(e => {console.log("Failure during addIceCandidate(): " + e.name);});
}

// Process all incomming WebRTC messages
function processMessage(devId, msgType, payLoad) {
  //console.log(`Processing message from Device: ${devId}. Type: ${msgType}, Payload: ${JSON.stringify(payLoad)}`);

  if (msgType === 'pong') {
    let rtt = Date.now() - parseInt(payLoad.PingTimeStamp)
    // console.log(`Pong Received from Device: ${devId}. Round Trip Time: ${rtt} ms.`);
  } else {
    // Process as unified message from device
    unifiedProcessMessage('WebRTC', devId, msgType, payLoad); 
  }
}

// Function to join room
export function joinRoom(devId, room) {
  console.log(`Sending WebRTC Join Room message to device: ${devId}, Room: ${room}`);
  let message = {room: room}
  sendWRtcMessage(devId, 'joinRoom', message)
}
// Function to leave room
export function leaveRoom(devId, room) {
  console.log(`Sending WebRTC Leave Room message to device: ${devId}, Room: ${room}`);
  let message = {room: room}
  sendWRtcMessage(devId, 'leaveRoom', message)
}




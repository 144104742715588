// Created by SPe on 22/02/22
// Page to manage app settings
<template>
    <div class="settings">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />
        
        <div v-if="Object.keys(this.configFromBackend).length" class="d-flex align-items-start">
            <div class="nav flex-column nav me-3" id="tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link menuButton active" id="network-tab" data-bs-toggle="pill" data-bs-target="#network" type="button" role="tab" aria-controls="network" aria-selected="true">Network</button>
                <button class="nav-link menuButton" id="remote-tab" data-bs-toggle="pill" data-bs-target="#remote" type="button" role="tab" aria-controls="remote" aria-selected="false">Remote</button>
                <button class="nav-link menuButton" id="cameras-tab" data-bs-toggle="pill" data-bs-target="#cameras" type="button" role="tab" aria-controls="cameras" aria-selected="false">Cameras</button>                
                <button class="nav-link menuButton" id="statecontrol-tab" data-bs-toggle="pill" data-bs-target="#statecontrol" type="button" role="tab" aria-controls="statecontrol" aria-selected="false">State Control</button>
                <button class="nav-link menuButton" id="trigger-tab" data-bs-toggle="pill" data-bs-target="#trigger" type="button" role="tab" aria-controls="trigger" aria-selected="false">Trigger</button>
                <button class="nav-link menuButton" id="engine-tab" data-bs-toggle="pill" data-bs-target="#engine" type="button" role="tab" aria-controls="engine" aria-selected="false">Engine</button>               
                <button class="nav-link menuButton" id="actions-tab" data-bs-toggle="pill" data-bs-target="#actions" type="button" role="tab" aria-controls="actions" aria-selected="false">Actions</button>
                <button class="nav-link menuButton" id="integration-tab" data-bs-toggle="pill" data-bs-target="#integration" type="button" role="tab" aria-controls="integration" aria-selected="false">Integration</button>
                <button class="nav-link menuButton" id="system-tab" data-bs-toggle="pill" data-bs-target="#system" type="button" role="tab" aria-controls="system" aria-selected="false">System</button>
                <button class="nav-link menuButton" id="impexp-tab" data-bs-toggle="pill" data-bs-target="#impexp" type="button" role="tab" aria-controls="system" aria-selected="false">Imp/Exp</button>
                <button class="nav-link menuButton" id="calibration-tab" data-bs-toggle="pill" @click="openCalibration" type="button" role="tab" aria-controls="calibration" aria-selected="false">Calibration</button>
            </div>
            <div class="tab-content" id="tabContent">
                <!-- ###############################################  Network Settings ############################################### -->
                <div class="tab-pane fade show active" id="network" role="tabpanel" aria-labelledby="network-tab">

                    <h2>Network Configuration</h2>
                    <!-- <h3>WiFi</h3>
                    <div id="wifiNetworks" style="overflow: hidden; width:100%">
                        <table style="height:6em; background-color: aquamarine;text-align: center;">
                        <thead style="vertical-align: top; font-size: 1.2em; font-weight: bold;"><tr><td>SSID</td><td>Channel</td><td>Speed</td><td>Signal</td><td>Security</td></tr></thead>
                        <tbody id="WiFiNetworkTableBody">
                            <tr v-for="wifi in wifiInfo" :key="wifi"><td>{{wifi["SSID"]}}</td><td>{{wifi["CHAN"]}}</td><td>{{wifi["RATE"]}}</td><td>{{wifi["SIGNAL"]}}</td><td>{{wifi["SECURITY"]}}</td></tr>
                        </tbody>
                        </table>
                    </div>
                    <small>Configure WiFi connection parameters. You can add up to 3 different WiFi Networks</small><br>
                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi1ssid" class="configName">WiFi 1 SSID: </label>
                        <select name="wifi1ssid" v-model="wifi1ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi1ssid', 'Network.WiFi.SSID1')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi1ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID1']" data-path="Network.WiFi.SSID1" placeholder="MyWiFi1"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi1passwd" class="configName">WiFi 1 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi1passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD1']" data-path="Network.WiFi.PASSWD1" placeholder="MyWiFi1Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul>

                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi2ssid" class="configName">WiFi 2 SSID: </label>
                        <select name="wifi2ssid" v-model="wifi2ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi2ssid', 'Network.WiFi.SSID2')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi2ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID2']" data-path="Network.WiFi.SSID2" placeholder="MyWiFi2"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi2passwd" class="configName">WiFi 2 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi2passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD2']" data-path="Network.WiFi.PASSWD2" placeholder="MyWiFi2Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul>

                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi3ssid" class="configName">WiFi 3 SSID: </label>
                        <select name="wifi3ssid" v-model="wifi3ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi3ssid', 'Network.WiFi.SSID3')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi3ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID3']" data-path="Network.WiFi.SSID3" placeholder="MyWiFi3"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi3passwd" class="configName">WiFi 3 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi3passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD3']" data-path="Network.WiFi.PASSWD3" placeholder="MyWiFi3Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul><hr>
                    <p>Select a connection type for WiFi:</p>
                    <div>
                        <input type="radio" id="wifidhcp" name="wifinettype" @input="onInputChange" v-model="configInGUI['Network.WiFi.ConType']" data-path="Network.WiFi.ConType" value="DHCP">
                        <label for="wifidhcp" name="wifinettype" class="configName">WiFi DHCP</label>        
                        <input type="radio" id="wifistatic" name="wifinettype" @input="onInputChange" v-model="configInGUI['Network.WiFi.ConType']" data-path="Network.WiFi.ConType" value="Static">
                        <label for="wifistatic" name="wifinettype" class="configName">WiFi Static</label>
                    </div>
                    <br>
                    <hr>
                    <div v-if="configInGUI['Network.WiFi.ConType']=='Static'" id="wifistaticip" class="wifistaticip">
                        <label for="wifiipAddress" class="configName">WiFi IP Address: </label>
                            <input type="text" data-format="cidr" id="wifiipaddress" name="wifiipaddress" @input="onInputChange" v-model="configInGUI['Network.WiFi.IpAddress']" data-path="Network.WiFi.IpAddress" placeholder="192.168.1.32/24"><small>Example: 192.168.1.32/24</small><br>
                        <label for="wifiipGateway" class="configName">WiFi IP Gateway: </label>
                            <input type="text" data-format="ip" id="wifiipgateway" name="wifiipgateway" @input="onInputChange" v-model="configInGUI['Network.WiFi.Gateway']" data-path="Network.WiFi.Gateway" placeholder="192.168.1.1"><small>Example: 192.168.1.1</small><br>
                        <label for="wifidns" class="configName">WiFi DNS: </label>
                            <input type="text" data-format="2ips" id="wifidns" name="wifidns" @input="onInputChange" v-model="configInGUI['Network.WiFi.DNS']" data-path="Network.WiFi.DNS" placeholder="1.1.1.1,1.0.0.1"><small>Example: 1.1.1.1,1.0.0.1</small><br>
                    </div> -->
                    <h3>Ethernet</h3>
                    <small>Configure Ethernet connection parameters</small>
                    <p>Select a connection type for Ethernet:</p>
                    <div>
                        <input type="radio" id="ethdhcp" name="ethnettype" @input="onInputChange" v-model="configInGUI['Network.Ethernet.ConType']" data-path="Network.Ethernet.ConType" value="DHCP">
                        <label for="ethdhcp" name="ethnettype" class="configName">Ethernet DHCP</label>        
                        <input type="radio" id="ethstatic" name="ethnettype" @input="onInputChange" v-model="configInGUI['Network.Ethernet.ConType']" data-path="Network.Ethernet.ConType" value="Static">
                        <label for="ethstatic" name="ethnettype" class="configName">Ethernet Static</label>
                    </div>
                    <br>
                    <div v-show="configInGUI['Network.Ethernet.ConType']=='Static'" id="ethstaticip" class="ethstaticip">
                        <label for="ethipAddress" class="configName">Ethernet IP Address: </label>
                            <input type="text" data-format="cidr" id="ethipaddress" name="ethipaddress" @input="onInputChange" v-model="configInGUI['Network.Ethernet.IpAddress']" data-path="Network.Ethernet.IpAddress" placeholder="192.168.1.33/24"><small>Example: 192.168.1.33/24</small>
                        <br><i><label for="currenthipAddress" class="configName">Current Eth Ip Address: </label>
                            <input disabled type="text" class="border-0 bg-gray-300" id="currenthipAddress" name="currenthipAddress" v-model="configInGUI['Network.Ethernet.CurrentIpAddress']" data-path="Network.Ethernet.CurrentIpAddress">
                        </i>
                        <br>
                        <label for="ethipGateway" class="configName">Ethernet IP Gateway: </label>
                            <input type="text" data-format="ip" id="ethipgateway" name="ethipgateway" @input="onInputChange" v-model="configInGUI['Network.Ethernet.Gateway']" data-path="Network.Ethernet.Gateway" placeholder="192.168.1.1"><small>Example: 192.168.1.1</small>
                        <br><i><label for="currentgateway" class="configName">Current Eth Gateway: </label>
                            <input disabled type="text" class="border-0 bg-gray-300" id="currentgateway" name="currentgateway" v-model="configInGUI['Network.Ethernet.CurrentGateway']" data-path="Network.Ethernet.CurrentGateway">
                        </i>
                        <br>
                        <label for="ethdns" class="configName">Ethernet DNS: </label>
                            <input type="text" data-format="2ips" id="ethdns" name="ethdns" @input="onInputChange" v-model="configInGUI['Network.Ethernet.DNS']" data-path="Network.Ethernet.DNS" placeholder="1.1.1.1,1.0.0.1"><small>Example: 1.1.1.1,1.0.0.1</small>
                        <br><i><label for="currentdns" class="configName">Current Eth DNS: </label>
                            <input disabled type="text" class="border-0 bg-gray-300" id="currentdns" name="currentdns" v-model="configInGUI['Network.Ethernet.CurrentDNS']" data-path="Network.Ethernet.CurrentDNS">
                        </i>
                        <br>
                    </div>
                    <br>
                    <hr>
                    <h3>Proxy</h3>
                    <small>Configure Proxy server parameters if required</small>
                    <div>
                        <div>
                            <input type="radio" id="proxydisabled" name="proxydis" @input="onInputChange" @click="configInGUI['Network.Proxy.Disabled'] = true; configInGUI['Network.Proxy.Enabled'] = false;" :checked="configInGUI['Network.Proxy.Disabled']" data-path="Network.Proxy.Disabled" value=true>
                            <label for="proxydisabled" name="proxydis" class="configName">Disabled</label>        
                            <input type="radio" id="proxyenabled" name="proxydis" @input="onInputChange" @click="configInGUI['Network.Proxy.Disabled'] = false; configInGUI['Network.Proxy.Enabled'] = true;" :checked="configInGUI['Network.Proxy.Enabled']" data-path="Network.Proxy.Enabled" value=true>
                            <label for="proxyenabled" name="proxydis" class="configName">Enabled</label>
                        </div>
                        <div v-show="configInGUI['Network.Proxy.Enabled']" id="proxyenabled" class="proxyenabled">
                            <label for="proxyType" class="configName">Proxy Type: </label>
                            <select name="proxyType" id="proxyType" class='proxyType' data-path="Network.Proxy.Type" @input="onInputChange" :value="configInGUI['Network.Proxy.Type']">
                                <option value='http'>HTTP</option>
                                <option value='socks4'>SOCKS4</option>
                                <option value='socks5'>SOCKS5</option>
                            </select>
                            <br>
                            <label for="proxyHost" class="configName">Proxy Server Host: </label><input type="text" data-format="ip" id="proxyhost" name="proxyhost" @input="onInputChange" v-model="configInGUI['Network.Proxy.Host']" data-path="Network.Proxy.Host" placeholder="72.34.12.34"><small>Example: 72.34.12.34</small>
                            <br>
                            <label for="proxyPort" class="configName">Proxy Server port: </label><input type="number" content-type='number' min="1" max="65535" id="proxyport" name="proxyport" @input="onInputChange" v-model="configInGUI['Network.Proxy.Port']" data-path="Network.Proxy.Port" placeholder="3443"><small>Eg.: 3443</small>
                            <br>
                            <label for="proxyUser" class="configName">Proxy User Name: </label><input type="text" data-format="max200chars" id="proxyuser" name="proxyuser" @input="onInputChange" v-model="configInGUI['Network.Proxy.UserName']" data-path="Network.Proxy.UserName" placeholder="MyProxyUserName"><small>Example: MyProxyUserName</small>
                            <br>
                            <div id="proxyPasswordDiv" class="configName">
                            <label for="proxyPassword" class="configName">Proxy Password: </label>
                                <input :type="proxyPasswdInputType" data-format="max200chars" id="proxyPassword" name="proxyPassword" @input="onInputChange" v-model="configInGUI['Network.Proxy.Password']"  data-path="Network.Proxy.Password" placeholder="MyProxyPassword">
                                <i class="bi bi-eye font-bold mt-3 ml-2 cursor-pointer" @click="showProxyPassword=!showProxyPassword"></i>
                                <small class="pl-2">Eg.: MyProxyPassword</small><br>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="flex-parent jc-center">
                        <button class="actionButton" id="saveNetworkConfig" type="button" @click="setConfiguration('Network')" style="align: center">Save and apply Network Configuration</button>
                    </div>

                </div>
                <!-- ###############################################  Remote Settings ############################################### -->
                <div class="tab-pane fade" id="remote" role="tabpanel" aria-labelledby="remote-tab">
                    <h2>Remote Connection Configuration</h2>
                    <h3>Users allowed to remote control</h3>
                    <p>Add email of users that are allowed to remote view and control the device</p>
                    <div id="allowedusers" class="allowedusers">
                        <label for="alloweduser1" class="configName">Email User 1: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser1" name="alloweduser1" @input="onInputChange" v-model="configInGUI['Remote.Users.1']" data-path="Remote.Users.1" placeholder="user1@example.com"><small>Eg.: user1@example.com</small><br>
                        <label for="alloweduser2" class="configName">Email User 2: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser2" name="alloweduser2" @input="onInputChange" v-model="configInGUI['Remote.Users.2']" data-path="Remote.Users.2" placeholder="user2@example.com"><small>Eg.: user2@example.com</small><br>
                        <label for="alloweduser3" class="configName">Email User 3: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser3" name="alloweduser3" @input="onInputChange" v-model="configInGUI['Remote.Users.3']" data-path="Remote.Users.3" placeholder="user3@example.com"><small>Eg.: user3@example.com</small><br>
                        <label for="alloweduser4" class="configName">Email User 4: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser4" name="alloweduser4" @input="onInputChange" v-model="configInGUI['Remote.Users.4']" data-path="Remote.Users.4" placeholder="user4@example.com"><small>Eg.: user4@example.com</small><br>
                        <label for="alloweduser5" class="configName">Email User 5: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser5" name="alloweduser5" @input="onInputChange" v-model="configInGUI['Remote.Users.5']" data-path="Remote.Users.5" placeholder="user5@example.com"><small>Eg.: user5@example.com</small><br>
                    </div>
                    <br>
                    <div class="flex-parent jc-center">
                        <button class="actionButton" id="saveRemoteConfig" type="button" @click="setConfiguration('Remote')" style="align: center">Save Remote Configuration</button>
                    </div>
                </div>
                <!-- ###############################################  Cameras Settings ############################################### -->
                <div class="tab-pane fade" id="cameras" role="tabpanel" aria-labelledby="cameras-tab">
                    <h2>Images & Mic Configuration</h2>

                    <div v-show="hasCSICamera">
                        <h3>Box Camera Settings</h3>

                        <br>
                        <label for="boxCamEnabled" class="configName">Enable Box Camera: </label>
                        <input class="w-12" type="checkbox" @change="boxCamIsEnabled=$event.target.checked" v-model="configInGUI['Cameras.Box.Enabled']" data-path="Cameras.Box.Enabled">
                        <br>

                        <br>   
                        <div v-show="boxCamIsEnabled">               
                            <h4>Capture config:</h4>
                            <select @change="(e)=>{camCapture=e.target.value; onNewBoxConfigSelected(e.target.value)}" v-model="configInGUI['Cameras.Box.Capture.Config']" data-path="Cameras.Box.Capture.Config" class="bg-gray-100 py-1 border-2 border-gray-800">
                                <option v-for="(conf, id) in supportedBoxCamCaptures" :key="conf" :value="id">{{conf.Name}}</option>
                            </select>
                            <hr class="my-2">
                            <!-- Image ROI config -->
                            <h4>Image Region of Interest:</h4>
                            <div class="flex justify-center">
                                <table class="my-2">
                                    <thead>
                                        <th class="text-center">Width</th>
                                        <th class="text-center">Height</th></thead>
                                    <tr>
                                        <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiWidth" type="number" @input="onInputChange" v-model="configInGUI['Cameras.Box.ROI.Width']" data-path="Cameras.Box.ROI.Width" :min="roiStep" :step="roiStep" :max="maxBoxRoiWidth" @change="onBoxRoiChange($event)"></td>
                                        <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiHeight" type="number" @input="onInputChange" v-model="configInGUI['Cameras.Box.ROI.Height']" data-path="Cameras.Box.ROI.Height" :min="roiStep" :step="roiStep" :max="maxBoxRoiHeight" @change="onBoxRoiChange($event)"></td>
                                    </tr>
                                </table>
                            </div>
                            <div style="background-color:antiquewhite"><small>Region of Interest is a cropped subset of complete image where the deffect is identified. Position within whole image is defined by Tilt and Pan device settings. For better performance, Width and Heigth should be multiple of 32 pixels</small></div>
                            <hr class="my-2">
                            <div id="TiltDiv" class="TiltDiv">
                                <label for="tilt" class="configName">Cameras RoI Tilt (%): </label>
                                <input type="number" data-format="percentage" content-type='number' min="0" max="100" id="tilt" name="tilt" @input="onInputChange" v-model="configInGUI['Cameras.Box.Tilt']" data-path="Cameras.Box.Tilt" placeholder="50"><small>Eg.: 50</small>
                            </div>
                            <div><small>Cameras RoI tilt %. E.g.: 0% to look up, 50% to look straight, 100% to look down.:</small></div>
                            <br><hr><br>
                            <div id="PanDiv" class="PanDiv">
                                <label for="pan" class="configName">Cameras RoI Pan (%): </label>
                                <input type="number" data-format="percentage" content-type='number' min="0" max="100" id="pan" name="pan" @input="onInputChange" v-model="configInGUI['Cameras.Box.Pan']" data-path="Cameras.Box.Pan" placeholder="50"><small>Eg.: 50</small>
                            </div>
                            <div><small>Cameras RoI pan %. E.g.: 0% to look left, 50% to look straight, 100% to look right.:</small></div>
                            <br><hr><br>
                            <div id="imageHistoryPeriod" class="imageHistoryPeriod">
                                <label for="MicRMSLevel" class="configName">History image interval: </label>
                                    <input type="number" data-format="float" content-type='number' min="5" max="9999999" id="imageHistoryPeriod" name="imageHistoryPeriod" @input="onInputChange" v-model="configInGUI['Cameras.Box.ImageHistoryPeriod']" data-path="Cameras.Box.ImageHistoryPeriod" placeholder="8"><small>Eg.: 8</small><br>
                            </div>
                            <div><small>Image history seconds between images</small></div>
                            <hr><br>
                        </div>  
                    </div>

                    <h3>Vision Bar Settings</h3>
                    <br>
                    <label for="visionBarNumCam" class="configName">Number Cameras: </label>
                    <select name="visionBarNumCam" id="visionBarNumCam" class='visionBarNumCam' content-type='number' data-path="Cameras.Bar.NumCameras" @input="onInputChange" :value="configInGUI['Cameras.Bar.NumCameras']">
                        <option value=0>0</option>
                        <option value=1>1</option>
                        <option value=2>2</option>
                        <option value=3>3</option>
                        <option value=4>4</option>
                    </select>
                    <div><small>Number of cameras in the Vision Bar</small></div>
                    <br><hr><br>
                    <h4>Capture config:</h4>
                    <select @change="(e)=>{camCapture=e.target.value; onNewBarConfigSelected(e.target.value)}" v-model="configInGUI['Cameras.Bar.Capture.Config']" data-path="Cameras.Bar.Capture.Config" class="bg-gray-100 py-1 border-2 border-gray-800">
                        <option v-for="(conf, id) in supportedBarCamCaptures" :key="conf" :value="id">{{conf.Name}}</option>
                    </select>
                    <hr class="my-2">
                    <!-- Image ROI config -->
                    <h4>Image Region of Interest:</h4>
                    <div class="flex justify-center">
                        <table class="my-2">
                            <thead>
                                <th class="text-center">Width</th>
                                <th class="text-center">Height</th></thead>
                            <tr>
                                <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiWidth" type="number" @input="onInputChange" v-model="configInGUI['Cameras.Bar.ROI.Width']" data-path="Cameras.Bar.ROI.Width" :min="roiStep" :step="roiStep" :max="maxBarRoiWidth" @change="onBarRoiChange($event)"></td>
                                <td><input class="bg-gray-100 border-2 border-gray-800 rounded-md px-1 mx-2" id="RoiHeight" type="number" @input="onInputChange" v-model="configInGUI['Cameras.Bar.ROI.Height']" data-path="Cameras.Bar.ROI.Height" :min="roiStep" :step="roiStep" :max="maxBarRoiHeight" @change="onBarRoiChange($event)"></td>
                            </tr>
                        </table>
                    </div>
                    <div style="background-color:antiquewhite"><small>Region of Interest is a cropped subset of complete image where the deffect is identified. Position within whole image is defined by Tilt and Pan device settings. For better performance, Width and Heigth should be multiple of 32 pixels</small></div>
                    <hr class="my-2">
                    <div id="TiltDiv" class="TiltDiv">
                        <label for="tilt" class="configName">Cameras RoI Tilt (%): </label>
                        <input type="number" data-format="percentage" content-type='number' min="0" max="100" id="tilt" name="tilt" @input="onInputChange" v-model="configInGUI['Cameras.Bar.Tilt']" data-path="Cameras.Bar.Tilt" placeholder="50"><small>Eg.: 50</small>
                    </div>
                    <div><small>Cameras RoI tilt %. E.g.: 0% to look up, 50% to look straight, 100% to look down.:</small></div>
                    <br><hr><br>
                    <div id="PanDiv" class="PanDiv">
                        <label for="pan" class="configName">Cameras RoI Pan (%): </label>
                        <input type="number" data-format="percentage" content-type='number' min="0" max="100" id="pan" name="pan" @input="onInputChange" v-model="configInGUI['Cameras.Bar.Pan']" data-path="Cameras.Bar.Pan" placeholder="50"><small>Eg.: 50</small>
                    </div>
                    <div><small>Cameras RoI pan %. E.g.: 0% to look left, 50% to look straight, 100% to look right.:</small></div>
                    <br><hr><br>
                    <label for="visionBarOrientation" class="configName">Orientation: </label>
                    <select name="visionBarOrientation" id="visionBarOrientation" class='visionBarOrientation' @input="onInputChange" :value="configInGUI['Cameras.Bar.Orientation']" data-path="Cameras.Bar.Orientation">
                        <option value="LeftToRight">Left to right</option>
                        <option value="RightToLeft">Right to left</option>
                    </select>
                    <div><small>Orientation of Vision Bar (starting from cable end)</small></div>
                    <br><hr><br>
                    <div>
                        <label for="visionBarComp" class="configName">Composition: </label>
                        <select name="visionBarComp" id="visionBarComp" class='visionBarComp' @input="onInputChange" :value="configInGUI['Cameras.Bar.Composition']" data-path="Cameras.Bar.Composition">
                            <option value="Single">Single</option>
                            <option value="Master">Master</option>
                            <option value="Slave">Slave</option>
                        </select>
                    </div>
                    <div><small>How device compose the inspection system:</small></div>
                    <div><small>&emsp;- Single: One device for inspection system</small></div>
                    <div><small>&emsp;- Master: Main device in multi-device inspection system</small></div>
                    <div><small>&emsp;- Slave: Secondary device in multi-device inspection system</small></div>
                    <div id="masterComp" class="masterComp" v-show="configInGUI['Cameras.Bar.Composition']==='Master'">
                        <br><hr><br>
                        <label for="slaveIPs" class="configName">Slave IPs: </label>
                            <input type="text" data-format="listips" id="slaveIPs" name="slaveIPs" @input="onInputChange" v-model="configInGUI['Cameras.Bar.SlaveIPs']" data-path="Cameras.Bar.SlaveIPs" placeholder="192.168.1.45, 192.168.1.46"><small>Eg.: 192.168.1.45, 192.168.1.46</small>
                        <div><small>Ip of slave devices. List separated by comma.</small></div>
                        <label for="slaveIDs" class="configName">Slave IDs: </label>
                            <input type="text" data-format="listdevids" id="slaveIDs" name="slaveIDs" @input="onInputChange" v-model="configInGUI['Cameras.Bar.SlaveIDs']" data-path="Cameras.Bar.SlaveIDs" placeholder="A1B2C3D4E5F6, G7H8I9K1L2M3"><small>Eg.: A1B2C3D4E5F6, G7H8I9K1L2M3</small>
                        <div><small>Id of slave devices. List separated by comma.</small></div>
                    </div>
                    <div id="slaveComp" class="slaveComp" v-show="configInGUI['Cameras.Bar.Composition']==='Slave'">
                        <br><hr><br>
                        <label for="masterIP" class="configName">Master IP: </label>
                            <input type="text" data-format="ip" id="masterIP" name="masterIP" @input="onInputChange" v-model="configInGUI['Cameras.Bar.MasterIP']" data-path="Cameras.Bar.MasterIP" placeholder="192.168.1.44"><small>Eg.: 192.168.1.44</small>
                        <div><small>Ip of master device.</small></div>
                        <label for="masterID" class="configName">Master ID: </label>
                            <input type="text" data-format="devid" id="masterID" name="masterID" @input="onInputChange" v-model="configInGUI['Cameras.Bar.MasterID']" data-path="Cameras.Bar.MasterID" placeholder="A1B2C3D4E5F6"><small>Eg.: A1B2C3D4E5F6</small>
                        <div><small>Id of master device.</small></div>
                        <br>
                        <hr><br>
                        <div id="FirstCameraNumberDiv" class="FirstCameraNumberDiv">
                            <label for="FirstCameraNumber" class="configName">First Camera Number: </label>
                            <input type="number" data-format="max24" content-type='number' min="1" max="10" id="FirstCameraNumber" name="FirstCameraNumber" @input="onInputChange" v-model="configInGUI['Cameras.Bar.FirstCameraNumber']" data-path="Cameras.Bar.FirstCameraNumber" placeholder="5"><small>Eg.: 5</small>
                            <div><small>Number of first camera for this device.</small></div>
                        </div>
                    </div>
                    <br>
                    <hr><br>
                    <div id="imageHistoryPeriod" class="imageHistoryPeriod">
                        <label for="MicRMSLevel" class="configName">History image interval: </label>
                            <input type="number" data-format="float" content-type='number' min="5" max="9999999" id="imageHistoryPeriod" name="imageHistoryPeriod" @input="onInputChange" v-model="configInGUI['Cameras.Bar.ImageHistoryPeriod']" data-path="Cameras.Bar.ImageHistoryPeriod" placeholder="8"><small>Eg.: 8</small><br>
                    </div>
                    <div><small>Image history seconds between images per camera</small></div>
                    <hr><br>
                    <!-- <h3>Microphone settings</h3>
                    <br>
                    <div id="Microphone" class="Microphone">
                    <label for="MicRMSLevel" class="configName">Microphone Motor level: </label>
                        <input type="number" data-format="max65535" content-type='number' id="MicRMSLevel" name="MicRMSLevel" @input="onInputChange" v-model="configInGUI['Cameras.Bar.MicFilter.RMSLevelThreshold']" data-path="Cameras.Bar.MicFilter.RMSLevelThreshold" placeholder="8000"><small>Eg.: 8000</small><br>
                    <label for="MicFiltRMSLevel" class="configName">Microphone Motor level (Filtered): </label>
                        <input type="number" data-format="max65535" content-type='number' id="MicFiltRMSLevel" name="MicFiltRMSLevel" @input="onInputChange" v-model="configInGUI['Cameras.Bar.MicFilter.RMSFiltLevelThreshold']" data-path="Cameras.Bar.MicFilter.RMSFiltLevelThreshold" placeholder="5000"><small>Eg.: 5000</small><br>
                    </div>
                    <div><small>Microphone level thresholds to detect motor movement</small></div> -->
                    <br>
                    <div class="p-1 flex-parent jc-center">
                    <button class="actionButton" id="saveCamerasConfig" type="button" @click="setConfiguration('Cameras')" style="align: center">Save Cameras Configuration</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                    <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                </div>
                <!-- ###############################################  State Control Settimgs ############################################### -->
                <div class="tab-pane fade" id="statecontrol" role="tabpanel" aria-labelledby="statecontrol-tab">
                    <h2>State Control</h2>
                    <br>
                    <div id="Activation" class="Activation">
                        <label for="ActivationSel" class="configName">Activate on: </label>
                        <select id="ActivationSel" name="ActivationSel" @input="onInputChange" v-model="configInGUI['StateControl.Activate.Trigger']" data-path="StateControl.Activate.Trigger">
                            <option value="Noise">Noise</option>
                            <option value="Button">Button</option>
                        </select>
                    </div>
                    <div><small>Trigger to activate</small></div>
                    <div v-show="configInGUI['StateControl.Activate.Trigger']==='Noise'" class="stateControlComp">
                        <label for="NoiseLevel" class="configName">Noise level Th. (%): </label><input type="number" data-format="percentage" content-type='number' min="0" max="100" id="NoiseLevel" name="NoiseLevel" @input="onInputChange" v-model="configInGUI['StateControl.Noise.Level']" data-path="StateControl.Noise.Level" placeholder="12"><small>Eg.: 12</small><br>
                        <div><small>Wideband Noise level threshold to activate</small></div>
                        <br>
                        <label for="FiltNoiseLevel" class="configName">Filtered Noise level Th. (%): </label><input type="number" data-format="percentage" content-type='number' min="0" max="100" id="FiltNoiseLevel" name="FiltNoiseLevel" @input="onInputChange" v-model="configInGUI['StateControl.Noise.FilterLevel']" data-path="StateControl.Noise.FilterLevel" placeholder="7"><small>Eg.: 7</small><br>
                        <div><small>Filtered Noise level threshold to activate</small></div>
                    </div>
                    <br>
                    <br>
                    <div id="Deactivation" class="Deactivation">
                        <label for="DeactivationSel" class="configName">Deactivate on: </label>
                        <select id="DeactivationSel" name="DeactivationSel" @input="onInputChange" v-model="configInGUI['StateControl.Deactivate.Trigger']" data-path="StateControl.Deactivate.Trigger">
                            <option value="Never">Never</option>
                            <option value="Inactivity">Inactivity</option>
                        </select>
                    </div>
                    <div><small>Trigger to deactivate</small></div>
                    <div v-show="configInGUI['StateControl.Deactivate.Trigger']==='Inactivity'" class="stateControlComp">
                        <label for="InactivityTime" class="configName">Inactivity time (s): </label><input type="number" data-format="max65535" content-type='number' id="InactivityTime" name="InactivityTime" @input="onInputChange" v-model="configInGUI['StateControl.Inactivity.Time']" data-path="StateControl.Inactivity.Time" placeholder="15"><small>Eg.: 15</small><br>
                        <div><small>Period of inactivity to deactivate</small></div>
                    </div>
                    <br>
                    <br>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="saveStateControlConfig" type="button" @click="setConfiguration('StateControl')" style="align: center">Save State Control Configuration</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                </div>
                <!-- ###############################################  Trigger Settimgs ############################################### -->
                <div class="tab-pane fade" id="trigger" role="tabpanel" aria-labelledby="trigger-tab">
                    <h2>Camera capture trigger</h2>

                    <div id="Trigger" class="Trigger">
                        <label for="TriggerMode" class="configName">Trigger Mode: </label>
                        <select id="TriggerMode" name="TriggerMode" @input="onInputChange" v-model="configInGUI['Trigger.Mode']" data-path="Trigger.Mode">
                            <option value="OnRest">On Rest</option>
                            <option value="Continious">Continious</option>
                            <option value="GpioEdge">Digital Input Edge</option>
                        </select>
                    </div>
                    <div><small>Trigger mode to process images</small></div>
                    <br>
                    <div v-show="configInGUI['Trigger.Mode']==='OnRest'">
                    </div>
                    <div v-show="configInGUI['Trigger.Mode']==='Continious'">
                        <label for="TriggerFps" class="configName">Trigger Fps: </label><input type="number" content-type='number' id="TriggerFps" name="TriggerFps" @input="onInputChange" v-model="configInGUI['Trigger.Continious.Fps']" data-path="Trigger.Continious.Fps" placeholder="5"><small>Eg.: 5</small><br>
                    </div>
                    <div v-show="configInGUI['Trigger.Mode']==='GpioEdge'">
                        <div id="TriggerEdge" class="TriggerEdge">
                            <label for="TriggerEdge" class="configName">Trigger Edge: </label>
                            <select id="TriggerEdge" name="TriggerEdge" @input="onInputChange" v-model="configInGUI['Trigger.GpioEdge.Edge']" data-path="Trigger.GpioEdge.Edge">
                            <option value="Falling">Falling</option>
                            <option value="Rising">Rising</option>
                            <option value="Both">Both</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="saveTriggerConfig" type="button" @click="setConfiguration('Trigger')" style="align: center">Save Trigger Configuration</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                </div>
                <!-- ###############################################  Actions Settings ############################################### -->
                <div class="tab-pane fade" id="actions" role="tabpanel" aria-labelledby="actions-tab">
                    <h2>Actions Configuration</h2>
                    <table class="actions">
                        <thead>
                            <tr>
                                <th class="border border-slate-300" title="Defect Name">Defect</th>
                                <th class="border border-slate-300" title="Active detection probability (%)">Active Prob.</th>
                                <th class="border border-slate-300" title="Number of images in decission window">Total Images</th>
                                <th class="border border-slate-300" title="Minimum number of active images in windows">Active Images</th>
                                <th class="border border-slate-300" title="Whether analisys is done per camera or per complete system">Per Camera</th>
                                <th class="border border-slate-300" title="Enable Action">Enable</th>
                                <th class="border border-slate-300" title="Action URL">Action URL</th>
                                <th class="border border-slate-300" title="Action URL">Email List</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="label in onlyDefects" :key="label">
                                <td>{{label}}</td>
                                <td>
                                    <input class="w-12" type="number" data-format="percentage" content-type='number' min="0" max="100" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.ThresholdPerc']" :data-path="'Actions.' + label + '.ThresholdPerc'" placeholder="50">
                                </td>
                                <td>
                                    <input class="w-12" type="number" content-type='number' min="1" max="500" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.Nx']" :data-path="'Actions.' + label + '.Nx'" placeholder="1">
                                </td>
                                <td>
                                    <input class="w-12" type="number" content-type='number' min="1" max="500" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.Np']" :data-path="'Actions.' + label + '.Np'" placeholder="1">
                                </td>
                                <td>
                                    <input class="w-12" type="checkbox" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.PerCamera']" :data-path="'Actions.' + label + '.PerCamera'">
                                </td>
                                <td>
                                    <input class="w-12" type="checkbox" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.Enabled']" :data-path="'Actions.' + label + '.Enabled'">
                                </td>     
                                <td>
                                    <input class="" type="text" data-format="max200chars" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.URL']" :data-path="'Actions.' + label + '.URL'">
                                </td>  
                                <td>
                                    <input class="" type="text" data-format="max200chars" @input="onInputChange" v-model="configInGUI['Actions.' + label + '.EmailList']" :data-path="'Actions.' + label + '.EmailList'">
                                </td>                      
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <div>
                        <ol style="list-style-type: none">
                            <li><small>- Active Prob.: Active detection probability (%)</small></li>
                            <li><small>- Total Imgs.: Number of images in decission window</small></li>
                            <li><small>- Active Imgs.: Minimum number of active images in windows</small></li>
                            <li><small>- Per Camera: Whether analisys is done per camera or per complete system</small></li>
                            <li><small>- Enable: Enable Action. If enabled, the Action URL will be called on action trigger</small></li>
                            <li><small>- Action URL: Action URL</small></li>
                            <li><small>- Email List: Email List. Separate by comma list of emails to be sent the image when action is triggered</small></li>
                        </ol>
                    <br>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="saveCamerasConfig" type="button" @click="setConfiguration('Actions')" style="align: center">Save Actions Configuration</button>
                    </div>                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>                    
                </div>
                <!-- ###############################################  Integration Settings ############################################### -->
                <div class="tab-pane fade" id="integration" role="tabpanel" aria-labelledby="integration-tab">
                    <h2>Integration Configuration</h2>
                    <h3>Status Notification API</h3>
                    <div id="StatusNotificationAPIDiv" class="StatusNotificationAPIDiv">
                        <label for="statusNotificationAPI" class="configName">Status API URL: </label>
                        <input type="text" data-format="max200chars" id="notificationurl" name="notificationurl" @input="onInputChange" v-model="configInGUI['Integration.StatusNotification.URL']"  data-path="Integration.StatusNotification.URL"><small>Eg.: http://192.168.1.23/api/v1/printer/1/setstatus/</small><br>
                    </div>
                    <div><small>This Api URL will be call every time a new image is processsed.</small></div>
                    <br>
                    <br>
                    <h3>Panel Tool Links</h3>
                    <div id="PanelToolLocalLinkDiv" class="PanelToolLocalLinkDiv">
                        <label for="PanelToolLocalLink" class="configName">Local Panel Tool Link: </label>
                        <input type="text" data-format="max200chars" id="localpaneltoollink" name="PanelToolLocalLink" @input="onInputChange" v-model="configInGUI['Integration.PanelTool-Local.URL']"  data-path="Integration.PanelTool-Local.URL"><small>Eg.: http://192.168.1.35/panel</small><br>
                    </div>
                    <div><small>This Link will be open when Tool button in local panel window is pressed.</small></div>
                    <br>
                    <h3>Panel Tool Link</h3>
                    <div id="PanelToolRemoteLinkDiv" class="PanelToolRemoteLinkDiv">
                        <label for="PanelToolRemoteLink" class="configName">Remote Panel Tool Link: </label>
                        <input type="text" data-format="max200chars" id="remotepaneltoollink" name="PanelToolRemoteLink" @input="onInputChange" v-model="configInGUI['Integration.PanelTool-Remote.URL']"  data-path="Integration.PanelTool-Remote.URL"><small>Eg.: https://proinspector.ainspect.io/panel_rkp_chr/A1B2C3D4E5/remote</small><br>
                    </div>
                    <div><small>This Link will be open when Tool button in remote panel window is pressed.</small></div>
                    <br>
                    <div class=" p-1 flex-parent jc-center">
                        <button class="actionButton" id="saveIntegrationConfig" type="button" @click="setConfiguration('Integration')" style="align: center">Save Integration Configuration</button>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                </div>
                <!-- ###############################################  Engine Settings ############################################### -->
                <div class="tab-pane fade" id="engine" role="tabpanel" aria-labelledby="engine-tab">
                    <h2>Engine Configuration</h2>
                    <h3>Current Engine</h3>
                    <div id="projectNameDiv">
                        <label class="configName">Project: </label><input disabled type="text" name="deviceId" @input="onInputChange" :value="configInGUI['Neural.Project.Name']"  data-path="Neural.Project.Name"><br>
                        <div><small></small></div>
                    </div>
                    <br>
                    <div id="projectNameDiv">
                        <label class="configName">Version: </label><input disabled type="text" name="deviceId" @input="onInputChange" :value="configInGUI['Neural.Engine.Version']"  data-path="Neural.Engine.Version"><br>
                        <div><small></small></div>
                    </div>                    
                    <br>
                    <div id="defectsDiv">
                        <label class="configName">Defects: </label><input disabled type="text" name="deviceId" @input="onInputChange" :value="configInGUI['Neural.Defects']"  data-path="Neural.Defects"><br>
                        <div><small></small></div>
                    </div>  
                    <br>
                    <div id="defectsWithDetectionDiv">
                        <label class="configName">Defects With Detection: </label><input disabled type="text" name="deviceId" @input="onInputChange" :value="configInGUI['Neural.DefectsWithDetection']"  data-path="Neural.DefectsWithDetection"><br>
                        <div><small></small></div>
                    </div>       
                    <br>
                    <div id="modelDiv">
                        <label class="configName">Model: </label><input disabled type="text" name="deviceId" @input="onInputChange" :value="configInGUI['Neural.Model.GuiName']"  data-path="Neural.Model.GuiName"><br>
                        <div><small></small></div>
                    </div>        
                    <br>
                    <p>You can change project and/or engine version from Project page or Device local app settings page</p>
                </div>
                <!-- ###############################################  System Settings ############################################### -->
                <div class="tab-pane fade" id="system" role="tabpanel" aria-labelledby="system-tab">
                    <h2>System Configuration</h2>
                    <h3>Device Identificarion</h3>
                    <div id="deviceIdDiv" class="deviceIdDiv">
                        <label for="deviceId" class="configName">Device Id: </label>
                            <input disabled type="text" id="deviceId" name="deviceId" @input="onInputChange" v-model="configInGUI['System.Device.ID']"  data-path="System.Device.ID" placeholder="A1A1A1"><br>
                    </div>
                    <div><small>Identification of the device. Can not be changed.</small></div>
                    <br>
                    <hr><br>
                    <div id="deviceCustomerDiv" class="deviceCustomerDiv">
                    <label for="deviceCustomer" class="configName">Device Customer: </label>
                        <input disabled type="text" data-format="max50chars" id="deviceCustomer" name="deviceCustomer" v-model="configInGUI['System.Device.Customer']"  data-path="System.Device.Customer" ><br>
                    </div>
                    <div><small>Customer of the device. Can not be changed.</small></div>
                    <br>
                    <hr><br>
                    <div id="deviceNameDiv" class="deviceNameDiv">
                    <label for="deviceName" class="configName">Device Name: </label>
                        <input type="text" data-format="max50restchars" id="deviceName" name="deviceName" @input="onInputChange" v-model="configInGUI['System.Device.Name']"  data-path="System.Device.Name" placeholder="Device1"><small>Eg.: Device1</small><br>
                    </div>
                    <div><small>Name of the device. Set a meaningfull name for you.</small></div>
                    <br>
                    <hr><br>
                    <div id="deviceShortNameDiv" class="deviceShortNameDiv">
                    <label for="deviceShortName" class="configName">Device Short Name: </label>
                        <input type="text" data-format="max50chars" id="deviceShortName" name="deviceShortName" @input="onInputChange" v-model="configInGUI['System.Device.ShortName']"  data-path="System.Device.ShortName" placeholder="D1"><small>Eg.: D1</small><br>
                    </div>
                    <div><small>Short name of the device. Set a meaningfull name for you. Recomended max 2-3 characters</small></div>
                    <br>
                    <hr><br>
                    <div id="LicenseExpDateDiv" class="LicenseExpDateDiv">
                    <label for="LicenseExpDate" class="configName">License Expiration Date: </label>
                        <input disabled type="text" data-format="max50chars" id="LicenseExpDate" name="LicenseExpDate" v-model="configInGUI['System.Device.LicenseExpDate']"  data-path="System.Device.LicenseExpDate"><small>License expiration date. The system will stop working after this date</small><br>
                    </div>
                    <div><small>The system will stop working after this date</small></div>
                    <br><br>
                    <hr><br>
                    <div id="encyptionPasswordDiv" class="encyptionPasswordDiv">
                    <label for="encyptionPassword" class="configName">Encryption Password: </label>
                        <input :type="encPasswdInputType" data-format="max200chars" id="encyptionPassword" name="encyptionPassword" @input="onInputChange" v-model="configInGUI['System.Device.EncryptionPassword']"  data-path="System.Device.EncryptionPassword" placeholder="MySecretPassword">
                        <i class="bi bi-eye font-bold mt-3 ml-2 cursor-pointer" @click="showEncPassword=!showEncPassword"></i>
                        <small class="pl-2">Eg.: MySecretPassword</small><br>
                    </div>
                    <div><small>Encryption password used to encrypt images. Leave empty for no encryption.</small></div>
                    <br>
                    <hr>
                    <h3>Software Upgrade</h3>
                    <div style="display: flex; justify-content: space-between;" id="CurrentSwPkg" class="CurrentSwPkg">
                        <p class="configName">Current Sw Package: &nbsp;&nbsp;</p><p data-path="System.CurrentSwPkg">{{currSwPackName}}</p>
                    </div>
                    <br>
                    <hr>
                    <div style="display: flex; justify-content: space-between;" id="LatestSwPkg" class="LatestSwPkg">
                        <p class="configName">Latest available Sw Package: &nbsp;&nbsp;</p><p data-path="System.LatestSwPkg">{{latestSwPackageName}}</p>
                        <button class="actionButton pi pi-sync" style="height: 1.6em; font-size: 1rem;" @click="getLatestSwPackageName"></button>
                    </div>
                    <br>
                    <div>
                        <div style="display: flex; justify-content: space-between;" id="AutoSwUpdate" class="AutoSwUpdate"></div>
                        <p class="configName">Auto Software Update</p>
                        <div><small>If enabled, software will check for any new software update every morining at arround 3:30 a.m.</small></div> 
                        <br>
                        <label for="AutoSwUpgrade" class="configName">Enable Software Upgrade: </label>
                        <input class="w-12" type="checkbox"  v-model="configInGUI['System.Device.AutoSwUpgrade']" data-path="System.Device.AutoSwUpgrade">
                        <br>
                        <hr>
                    </div>    
                    <br>
                    <div class="flex-parent jc-center">
                        <button class="actionButton" id="saveCamerasConfig" type="button" @click="setConfiguration('System')" style="align: center">Save System Configuration</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton focus:outline-none" id="checkForSwUpgrade" type="button" @click="checkForSwUpgrade" style="align: center" :disabled="disableCheckForSwUpgrade">Upgrade Software</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                    
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="rebootSystem" type="button" @click="rebootSystem" style="align: center">Reboot AI Computer</button>
                    </div>
<!--                     
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="factoryReset" type="button" @click="factoryReset" style="align: center">Factory Reset</button>
                    </div> -->
                </div>
                <!-- ###############################################  Import / Export ############################################### -->
                <div class="tab-pane fade" id="impexp" role="tabpanel" aria-labelledby="impexp-tab">
                    <h2>Import/Export Configuration</h2>
                    <h3>Import Configuration from file</h3>
                    <div>
                        <label for="importfile"></label>
                        <input type="file" id="importfile" class="actionButton" name="importfile" @change="onImportFile($event)"/>
                    </div>
                    <br>
                    <h3>Export Configuration to file</h3>
                    <br>
                    <div class="flex-parent jc-center">
                        <button class="actionButton" style="align: center" @click="onExportConfig()">Save Configuration to file</button>
                    </div>
                    <br>
                    <br>
                    <div class="flex-parent jc-center">
                        <button id="saveConfig" type="button" class="actionButton" @click="setConfiguration('')" style="align: center" :disabled="!configFileImported">Apply All Configuration</button>
                    </div>
                </div>
            </div>
        </div>    
        <br class="my-2">
        <!-- Footernbar -->
        <Footer />         
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import { Options, Vue } from 'vue-class-component'; 
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { relativeDateTime } from '@/library/utils'
import { doKeepWsConnected, dontKeepWsConnected } from '@/library/websocket'
import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel } from '@/library/client-unified-send'


@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: true,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters
            devName: this.$route.params.DevName, // DevId collected from Path Parameters
            configFromBackend: {}, // Config as is taken from device
            configInGUI: {}, // Config in GUI
            wifiInfo: null, // Wifi info received from device
            wifiSSIDs: null, // List of WiFI SSIDs
            currSwPackName: 'Loading...', // Current Sw package Name
            latestSwPackageName: 'Loading...', // Latest available Sw package name
            wifi1ssid: '-Select-', // Selected wifi 1 ssid
            wifi2ssid: '-Select-', // Selected wifi 2 ssid
            wifi3ssid: '-Select-', // Selected wifi 3 ssid

            getUserConfigTask: null, // setInterval task to get user config until is done
            getCurrentSwPackageNameTask: null, // setInterval task to get current sw package until is done
            getLatestSwPackageNameTask: null, // setInterval task to get latest sw package until is done
            getWiFiInfoTask: null, // setInterval task to get wifi info from device

            camBoxCapture: null, // Box Camera capture configuration
            camBarCapture: null, // Bar Cameras capture configuration
            roiStep: 32, // Region of Interest definition step

            activateStateControl: null,
            deactivateStateControl: null,
            triggerMode: null,

            loaderTask: null,
            reloadTask: null,
            showEncPassword: false,
            getDataTasksCreated: false, // Flag to indicate whether getDatatasks has been created

            configFileImported: false, // Flag to indicate whether config file has been imported

            showProxyPassword: false,

            boxCamIsEnabled: false, // Flag to indicate whether box camera is enabled

            firstTimeDevConnected: true, // Flag to indicate whether device is connected for first time
        }
    },
    props: [],
    methods: {
        onDevConnected(devId) {
            if (!this.firstTimeDevConnected) {
                console.log(`Logs. Device: ${this.devId} is already connected`);
                return; // Do nothing if already connected
            }
            this.firstTimeDevConnected = false;
            console.log(`Device: ${devId} is connected`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('setUserConfig', this.onSetUserConfigReceived);
            registerUnifiedMessageCallBack('setWiFiNetworks', this.onSetWiFiNetworks);
            registerUnifiedMessageCallBack('setCurrentSwPackageName', this.onSetCurrentSwPackageName);
            registerUnifiedMessageCallBack('setLatestSwPackageName', this.onSetLatestSwPackageName);
            // Create (if not yet getDataTasks)
            if (!this.getDataTasksCreated) {
                this.createGetDataTasks();
                this.getDataTasksCreated = true;
            } else console.log(`createGetDataTasks already created`);  
        },
        onSetUserConfigReceived(devId, payLoad) {
            console.log(`onSetUserConfigReceived Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.showSpinner = false;
            this.loadingOverlay.show = false;
            for (let config in payLoad) {
                let firstTime = ! (config in this.configInGUI);
                this.configFromBackend[config] = payLoad[config];
                this.configInGUI[config] = payLoad[config];
                var element = this.getHtmlElelemnt(config);
                //if (element) element.value = payLoad[config];
                // Call change event if not first time
                if (! firstTime) {
                    
                    if (element) {
                        let e = {target: element};
                        this.onInputChange(e);
                    }
                }
                // Process some config
                var value = payLoad[config]
                if (config === 'Cameras.Box.Enabled') {
                    console.log(`displayConfig. Setting Cameras.Box.Enabled to: ${value}`);
                    if (value) this.boxCamIsEnabled = true;
                    else this.boxCamIsEnabled = false;
                }
            }
            // Read some config
            if ('Cameras.Box.Capture.Config' in payLoad) this.camBoxCapture = payLoad['Cameras.Box.Capture.Config'];
            if ('Cameras.Bar.Capture.Config' in payLoad) this.camBarCapture = payLoad['Cameras.Bar.Capture.Config'];
        },
        onSetWiFiNetworks(devId, payLoad) {
            console.log(`setWiFiNetworks Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.showSpinner = false;
            this.wifiInfo = [];
            this.wifiSSIDs = []; 
            //let wifiInUse = null;
            payLoad.forEach((wifi) => {
                if (this.wifiSSIDs.includes(wifi["SSID"])) return; // Skip it if alredy in list
                //if (wifi["IN-USE"] === "*") wifiInUse = wifi["SSID"]; // Detect with (if any) SSID is connected
                else if (wifi["IN-USE"] === "IN-USE");// Header repetaed. Do nothing
                else if (wifi["SSID"] === "--" && wifi["SECURITY"] === "--");// Phantom network. skip it
                else {
                    // Add to Available WiFi table
                    this.wifiInfo.push(wifi);
                    // Add to wifi selects if not open
                    //if (!$('wifiselect').data('open')) $('.wifiselect').append($('<option>', {value: wifi["SSID"], text: wifi["SSID"]}));
                    this.wifiSSIDs.push(wifi["SSID"]); // Add to the list
                }
            });
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 10 seconds`);
                this.showSpinner = true;
            }, 10000);
            
        },
        onSetCurrentSwPackageName(devId, payLoad) {
            console.log(`setCurrentSwPackageName Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.currSwPackName = payLoad['CurrentSwPackageName'];            
        },
        onSetLatestSwPackageName(devId, payLoad) {
            console.log(`setLatestSwPackageName Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.latestSwPackageName = payLoad['LatestSwPackageName'];
        },
        createGetDataTasks() {
            // Get config data
            const content = {ParRootPath: ''};
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getUserConfig', content);
            this.getUserConfigTask = setInterval(() => {
                if (Object.keys(this.configFromBackend).length === 0) sendMessageUnified(this.devId, 'getUserConfig', content);
                else clearInterval(this.getUserConfigTask);
            }, 2000);
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
            this.getCurrentSwPackageNameTask = setInterval(() => {
                console.log(`this.currSwPackName: ${this.currSwPackName}`)
                if (! this.currSwPackName || this.currSwPackName.includes('Loading')) sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
                else clearInterval(this.getCurrentSwPackageNameTask);
            }, 2000);
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
            this.getLatestSwPackageNameTask = setInterval(() => {
                if (! this.latestSwPackageName || this.currSwPackName.includes('Loading')) sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
                else clearInterval(this.getLatestSwPackageNameTask);
            }, 2000);
            // // Get periodically wifi networks
            // this.getWiFiInfoTask = setInterval(() => {
            //     if (this.getWiFiInfoTask) {
            //         if (document.visibilityState === "visible") {
            //             console.log(`Getting WiFi Info from device`);
            //             sendMessageUnified(this.devId, 'getWiFiNetworks', {});
            //         }
            //     } else {
            //         console.log(`XXXXXXXXXXXXXXXXXXXX  This message should not appear    XXXXXXXXXXXXXXXXX`);
            //         clearInterval(this.getWiFiInfoTask);
            //         this.getWiFiInfoTask = null;
            //     }
            // }, 5000); // Repeat every 5 seconds
        },
        relativeDateTime(TimeStampMs, seconds) {
            return relativeDateTime(TimeStampMs, seconds);
        },
        round(number, digits) {
            if (number) return number.toFixed(digits);
            else return 0;
        },
        // Return if exits the HTML element for the given path
        getHtmlElelemnt(parPath) {
            var elements = document.querySelectorAll(`[data-path="${parPath}"]`);
            if (elements.length > 2) console.error(`getHtmlElelemnt. More than one element with path: ${parPath}`);
            if (elements.length > 0) return elements[0];
            else return null;
        },
        setConfiguration(rootPath) {
            if (this.checkSubConfigurationOnlyChanges(rootPath)) { // Chaeck if all configuration are valid
                console.log(`setConfiguration for path prefix: ${rootPath}`);
                var newConfig = this.getSubConfigurationOnlyChanges(rootPath)
                console.log(`setConfiguration. Setting newConfig: ${JSON.stringify(newConfig)}`);
                if (Object.keys(newConfig).length > 0) sendMessageUnified(this.devId, 'setUserConfig', newConfig);
                // In case Network configuration --> force reload the page
                if (rootPath == 'Network') {
                    alert("Network configuration will be apply inmediatly and application will restart");
                    this.$router.go();
                }
            } else {
                console.error(`setConfiguration. Invalid configuration for path prefix: ${rootPath}`);
            }
        },
        getSubConfigurationOnlyChanges(rootPath) {
            var out = {}
            //console.log(`rootPath: ${rootPath}`)
            for (var path in this.configFromBackend) { // Loop over all possible configurations
                if (path.startsWith(rootPath)) {
                    if (this.getHtmlElelemnt(path)) {
                        var oldValue = this.configFromBackend[path];
                        var newValue = this.configInGUI[path];
                        console.log(`getSubConfigurationOnlyChanges. Path: ${path} Old Value: ${oldValue}, New Value: ${newValue}}`)
                        if (newValue !== undefined &&  newValue !== oldValue) {
                            out[path] = newValue;
                            console.log(`Adding configuration for path: ${path}, Value: ${out[path]}, Old Value: ${oldValue}`);
                        }
                    } else  console.error(`getSubConfigurationOnlyChanges. HTML Element not found for path: ${path}`);
                }
            }
            return out;
        },
        checkSubConfigurationOnlyChanges(rootPath) {
            for (var path in this.configFromBackend) { // Loop over all possible configurations
                if (path.startsWith(rootPath)) {
                    if (this.getHtmlElelemnt(path)) {
                        var format = this.getFormatFromUI(path);
                        var oldValue = this.configFromBackend[path];
                        var newValue = this.configInGUI[path];
                        console.log(`checkSubConfigurationOnlyChanges. Path: ${path} Old Value: ${oldValue}, New Value: ${newValue}}`)
                        if (newValue !== undefined &&  newValue !== oldValue) {
                            if (! this.checkFormat(format, newValue)) {
                                console.error(`Configuration for: ${path} is invalid: ${newValue} with format: ${format}`);
                                return false;
                            }
                        }
                    } else  console.error(`checkSubConfigurationOnlyChanges. HTML Element not found for path: ${path}`);
                }
            }
            return true;
        },
        getFormatFromUI(path) {
            var element = this.getHtmlElelemnt(path);
            if (element) {
                return element.getAttribute("data-format");
            } else {
                console.error(`getFormatFromUI. Element not found for path: ${path}`);
                return null;
            }
        },
        onOptionSelected(value, targeId, targetPath) {
            console.log(`On Option Selected. Value: ${value}, Target Id: ${targeId}, Target Path: ${targetPath}`);
            if (value !== '-Select-') {
                this.configInGUI[targetPath] = value;
                let targetElement = document.querySelector(`input[id="${targeId}"]`);
                targetElement.value = value;
                let e = {target: targetElement};
                this.onInputChange(e);
            }
        },
        onInputChange(e) {
            let element = e.target;
            this.onInputChangeElement(element);
        },
        onInputChangeElement(element) {
            
            console.log(`onInputChange on: ${element.getAttribute('data-path')}, Name: ${element.name}, Value: ${element.value}, Type: ${element.type}, Format: ${element.getAttribute('data-format')}`);
            var path = element.getAttribute('data-path');
            var oldValue = this.configFromBackend[path];
            var newValue = element.value;
            if (element.type === 'radio') {
                newValue = this.configInGUI[element.getAttribute('data-path')]
                console.log(`Radio in path: ${element.getAttribute('data-path')} with value: ${newValue}`);
            }            
            if (element.getAttribute("content-type") === 'number') newValue = Number(newValue);            
            console.log(`Old: ${oldValue} (${typeof oldValue}), New: ${newValue} (${typeof newValue}), Different: ${newValue !== oldValue}`);
            if (element.type === 'checkbox') {
                if (element.checked) newValue = true;
                else newValue = false;
            }

            if (String(newValue) !== String(oldValue)) {
                if (element.type === 'radio') element.nextElementSibling.style.backgroundColor = '#d2ff4d'; // Yellow
                else {
                    if (this.checkFormat(element.getAttribute('data-format'), String(newValue))) element.style.backgroundColor = "#d2ff4d"; // Yellow
                    else element.style.backgroundColor = "#ff531a"; // Red
                }
            } else {  // Same value as in global configuration
                if (element.type === 'radio') {
                    //var divParent = element.parentElement;
                    var allLabels = document.querySelectorAll(`label[name="${element.name}"]`)
                    allLabels.forEach(label => { label.style.backgroundColor = "white";});
                } else {
                    if (this.checkFormat(element.getAttribute('data-format'), String(newValue))) element.style.backgroundColor = "white"; // Yellow
                    else element.style.backgroundColor = "#ff531a"; // Red
                }
            }
            // Assign
            this.configInGUI[path] = newValue;
            // console.log(`configInGUI: ${JSON.stringify(this.configInGUI)}`);
        },
        getCurrentSwPackageName() {
            console.log(`getCurrentSwPackageName`);
            sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
        },
        getLatestSwPackageName() {
            console.log(`getLatestSwPackageName`);
            this.latestSwPackageName = 'Loading...';
            sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
        },
        checkForSwUpgrade() {
            console.log(`checkForSwUpgrade`);
            let text = "Do you really want to install latest software version?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'checkForSwUpgrade', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Upgrading Software... System will restart soon`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 60000);
            } else {
                console.log(`Check for Software Upgrade was cancelled`)
            }              
        },
        restartApp() {
            console.log(`restartApp`);
            let text = "Do you really want to restart application?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'restartApp', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Restarting application...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 5000);
            } else {
                console.log(`Restart App was cancelled`)
            }     
        },
        rebootSystem() {
            console.log(`rebootSystem`);
            let text = "Do you really want to Reboot System?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'rebootSystem', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Restating system...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 10000);
            } else {
                console.log(`Factory Reset was cancelled`)
            }  
        },
        factoryReset() {
            console.log(`factoryReset`);
            let text = "Do you really want to Factory Reset?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'factoryReset', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Factory Reset...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 10000);
            } else {
                console.log(`Factory Reset was cancelled`)
            }             
        },
        checkFormat(format, value) {
            if (format === null) return true; // No check if no format provided
            let re = null
            // Ip V4 address in CIDR format. E.g. 192.168.1.123/24
            if (format === 'cidr') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\/([1-9]|[1-5][0-9]|6[0-4])$/;
            // Ip V4 address. E.g. 192.168.1.123
            else if (format === 'ip') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
            // 2 IP V4 separated by comma. E.g 1.1.1.1,1.0.0.1
            else if (format === '2ips') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]),(\s*)((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/; 
            // Email address
            else if (format === 'email') re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            // Empty or email address
            else if (format === 'emptyoremail') re = /^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            // Any string with max 50 characters
            else if (format === 'max50chars') re = /^.{0,50}$/;
            // Any string with max 50 characters restricted to alphanumeric and '-' and '_'
            else if (format === 'max50restchars') re = /^[a-zA-Z0-9_-]{0,50}$/;
            // Any string with max 50 characters
            else if (format === 'max200chars') re = /^.{0,200}$/;
            // Any integer number [0, 24] for percentage
            else if (format === 'max24') re = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
            // Any integer number [0, 100] for percentage
            else if (format === 'percentage') re = /^(0|[1-9][0-9]?|100)$/;
            // Any integer number [0, 65535] 
            else if (format === 'max65535') re = /^(0|6[0-5][0-5][0-3][0-5]|[1-5][0-9][0-9][0-9][0-9]|[1-9][0-9]{0,3})$/;
            // Any float number [0, 1000] for percentage
            else if (format === 'floatmax1000') re = /^(0|[1-9][0-9]?|1000)(\.\d{1,2})?$/;
            // Any float number
            else if (format === 'float') re = /^(\d+(\.\d{1,2})?)$/;
            // Device Id. eg. K9X7F5Q8H1N9
            else if (format === 'devid') re = /^[ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789]$/;
            // List (comma separated) of DevIds
            else if (format === 'listdevids') {
                re = /^[ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789]$/;
                var elements = value.split(',')
                for (var element of elements) {
                    console.log(`ELEMENT: ${element}`);
                    if (! re.test(element.trim())) return false // If regular expression test fail in one of the elemnt --> not ok
                }
                return true
            }
            // List (comma separated) of Ips
            else if (format === 'listips') {
                re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/; // IP regular expression
                elements = value.split(',')
                for (element of elements) {
                    if (! re.test(element.trim())) return false // If regular expression test fail in one of the elemnt --> not ok
                }
                return true
            }
            
            if (re) return re.test(value)
            else return false
        },
        stopCommunication() {
            console.log(`Stopping Settings communication to device: ${this.devId}`);
            clearInterval(this.getWiFiInfoTask);
            this.getWiFiInfoTask = null;
            clearInterval(this.getUserConfigTask);
            clearInterval(this.getCurrentSwPackageNameTask);
            clearInterval(this.getLatestSwPackageNameTask);
            
            if (this.devId) {
                // Close all WebRTC conections
                console.log(`Closing WebRTC connection to device: ${this.devId}`);
                //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
                closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);
            }
        },
        openCalibration: function() {
            console.log(`Opening Calibration for device: ${this.devId}`);
            this.$router.push({path: `/calibration/${this.devId}`});
            //let route = this.$router.resolve({ path: `/inspection/${devId}`});
            //window.open(route.href);
        },
        onNewBarConfigSelected(value) {
            console.log(`onNewBarConfigSelected: ${value}`);
            let roiWidth = this.configInGUI['Cameras.Bar.ROI.Width']
            roiWidth = Math.min(Math.max(roiWidth, this.roiStep), this.maxBarRoiWidth); // Limit to min/max. Min is roiStep
            roiWidth = Math.ceil(roiWidth/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            this.configInGUI['Cameras.Bar.ROI.Width'] = roiWidth;
            let roiHeight = this.configInGUI['Cameras.Bar.ROI.Height']
            roiHeight = Math.min(Math.max(roiHeight, this.roiStep), this.maxBarRoiHeight); // Limit to min/max. Min is roiStep
            roiHeight = Math.ceil(roiHeight/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            this.configInGUI['Cameras.Bar.ROI.Height'] = roiHeight;
        },
        onNewBoxConfigSelected(value) {
            console.log(`onNewBoxConfigSelected: ${value}`);
            let roiWidth = this.configInGUI['Cameras.Box.ROI.Width']
            roiWidth = Math.min(Math.max(roiWidth, this.roiStep), this.maxBoxRoiWidth); // Limit to min/max. Min is roiStep
            roiWidth = Math.ceil(roiWidth/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            this.configInGUI['Cameras.Box.ROI.Width'] = roiWidth;
            let roiHeight = this.configInGUI['Cameras.Box.ROI.Height']
            roiHeight = Math.min(Math.max(roiHeight, this.roiStep), this.maxBoxRoiHeight); // Limit to min/max. Min is roiStep
            roiHeight = Math.ceil(roiHeight/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            this.configInGUI['Cameras.Box.ROI.Height'] = roiHeight;
        },
        onBarRoiChange(event) {
            // Limit the value to [roiStep, maxRoiXXX]
            if (event.target.id === 'RoiWidth') {
                event.target.value = Math.min(Math.max(event.target.value, this.roiStep), this.maxBoxRoiWidth); // Limit to min/max. Min is roiStep
                event.target.value = Math.floor(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            }
            else if (event.target.id === 'RoiHeight') {
                event.target.value = Math.min(Math.max(event.target.value, this.roiStep), this.maxBoxRoiHeight); // Limit to min/max. Min is roiStep
                event.target.value = Math.floor(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            }
        },
        onBoxRoiChange(event) {
            // Limit the value to [roiStep, maxRoiXXX]
            if (event.target.id === 'RoiWidth') {
                event.target.value = Math.min(Math.max(event.target.value, this.roiStep), this.maxBarRoiWidth); // Limit to min/max. Min is roiStep
                event.target.value = Math.floor(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            }
            else if (event.target.id === 'RoiHeight') {
                event.target.value = Math.min(Math.max(event.target.value, this.roiStep), this.maxBarRoiHeight); // Limit to min/max. Min is roiStep
                event.target.value = Math.floor(event.target.value/this.roiStep) * this.roiStep; // Ensure is multiple of RoI Step
            }
        },
        onImportFile(e) {
            console.log('onImportFile');

            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = (event) => {
                var fileContent = event.target.result;
                console.log("File content:", fileContent);
                // Merge fileconent into GUI configuration
                try {
                    let newConfig = JSON.parse(fileContent);
                    for (var prop in this.configInGUI) {
                        console.log(`Property: ${prop}. Value in GUI: ${this.configInGUI[prop]}. Imported Value: ${newConfig[prop]}`);
                        if (Object.prototype.hasOwnProperty.call(newConfig, prop)) {
                            if (this.configInGUI[prop] !== newConfig[prop]) {
                                console.log(`Imported config: ${prop} changed from: ${this.configInGUI[prop]} (${typeof(this.configInGUI[prop])}) to ${newConfig[prop]} (${typeof(newConfig[prop])})`)
                                //this.configuration[prop] = newConfig[prop];
                                //this.setConfigToUI(prop, newConfig[prop]);

                                this.configInGUI[prop] = newConfig[prop];
                                // Call change event to update the UI                
                                var element = this.getHtmlElelemnt(prop);
                                if (element) {
                                    element.value = newConfig[prop];
                                    let e = {target: element};
                                    this.onInputChange(e);
                                }
                            }
                        }
                    }
                    this.configFileImported = true;
                } catch (error) {
                    console.error(`onImportFile exception: ${error}`);
                    window.alert(`Error importing configuration file: ${file.name}`);
                }
                
            };
            reader.readAsText(file);
        },
        onExportConfig() {
            console.log('onExportConfig'); 

            var allConfigJson = JSON.stringify(this.configFromBackend);
            // Remove Password from exported configuration
            let allConfig = JSON.parse(allConfigJson)
            for (let config in allConfig) {
                if (config.toLowerCase().includes('password')) {
                    console.log(`onExportConfig. Removing configuration: ${config}`);
                    delete allConfig[config];
                }
            }
            let data = JSON.stringify(allConfig);
            var filename = `AInspDev-${this.devName}.config`;
            var blob = new Blob([data], {type: "application/json"});
            var url = window.URL.createObjectURL(blob);
            
            // Create a link element to download the file
            var link = document.createElement("a");
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            
            // Click the link to download the file and remove the link element
            link.click();
            document.body.removeChild(link);

            window.alert(`Device configuration has been exported to file: ${filename}.\nNote that passwords are not exported for secuirty reason.`)
          }
    },
    computed: {
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
        disableCheckForSwUpgrade: function () { return ! this.latestSwPackageName || this.currSwPackName === this.latestSwPackageName},
        allLabels: function () { return store.state.devices.deviceInfo[this.devId] ? store.state.devices.deviceInfo[this.devId].GUIInfo.AllLabels : []},
        hasCSICamera: function () { return store.state.devices.deviceInfo[this.devId] ? store.state.devices.deviceInfo[this.devId].GUIInfo.HasCSICamera : []},
        onlyDefects: function () { return this.allLabels.filter(el => {return el != 'Ok'})},
        encPasswdInputType() { if (this.showEncPassword) { return 'text'} else {return 'password'}},
        proxyPasswdInputType() { if (this.showProxyPassword) { return 'text'} else {return 'password'}},
        supportedBoxCamCaptures() { return appConfig.supportedBoxCamCaptures },
        supportedBarCamCaptures() { return appConfig.supportedBarCamCaptures },
        maxBoxRoiWidth() { if (this.camBoxCapture && this.supportedBoxCamCaptures[this.camBoxCapture]) return  Math.floor(this.supportedBoxCamCaptures[this.camBoxCapture].Width/this.roiStep) * this.roiStep},
        maxBoxRoiHeight() { if (this.camBoxCapture && this.supportedBoxCamCaptures[this.camBoxCapture]) return  Math.floor(this.supportedBoxCamCaptures[this.camBoxCapture].Height/this.roiStep) * this.roiStep},
        maxBarRoiWidth() { if (this.camBarCapture && this.supportedBarCamCaptures[this.camBarCapture]) return  Math.floor(this.supportedBarCamCaptures[this.camBarCapture].Width/this.roiStep) * this.roiStep},
        maxBarRoiHeight() { if (this.camBarCapture && this.supportedBarCamCaptures[this.camBarCapture]) return  Math.floor(this.supportedBarCamCaptures[this.camBarCapture].Height/this.roiStep) * this.roiStep},
    },
    // Lifecycle hooks
    mounted() {
        console.log('Settings View Created');
        document.title = `Settings-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};                
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);
        // Click button with id="network-tab" to open Network tab
        if (document.getElementById("network-tab") !== null)  document.getElementById("network-tab").click();
        // Page visibility control
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Settings page being visible');
                doKeepWsConnected();
            } else { //Hidden
                console.log('Settings page being hidden');
                dontKeepWsConnected();
            }
        };
    },
    unmounted() {
        console.log('Settings View Unmounted');        
    },
    beforeUnmount() {
        console.log('Settings View beforeUnmount');
        this.stopCommunication();
        if (this.reloadTask) clearInterval(this.reloadTask)
    },
    errorCaptured() {console.log('Settings View errorCaptured')},
    // renderTracked() {console.log('Settings View renderTracked')},
})
export default class Settings extends Vue {}
</script>

<style scoped>
    .configName {
        font-weight: bold;
    }
    input {
        @apply border-gray-800 border-2;
    }
    select {
        @apply border-gray-800 border-2;
    }
    li {
        @apply p-1
    }
    label {
        @apply p-1
    }
    .menuButton {
        @apply border-gray-800 border-2 bg-gray-200  hover:bg-gray-400
    }
    .actionButton {
        @apply border-gray-800 border-2 bg-gray-200  hover:bg-gray-400  disabled:opacity-25 rounded-lg py-1 px-2;
    }

    table.actions {
        @apply table-auto border-collapse border border-gray-800
    }
    table.actions th {
        @apply border-collapse border border-gray-800 bg-gray-400 py-0 px-1
    }
    table.actions td {
        @apply border-collapse border border-gray-800 bg-gray-200 py-1 px-1
    }
    button.active { @apply bg-green-200}
    

</style>



/* eslint-disable */
import {CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js';
var AWS = require('aws-sdk');
import IndexRouter from '../router/index';
import store from '@/store/index.js';
import UserInfoApi from '@/library/user-info-api';

const AWS_REGION = process.env.VUE_APP_AWS_REGION;
const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const IDENTITYPOOL_ID = process.env.VUE_APP_COGNITO_IDENTITYPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
const APP_URL = process.env.VUE_APP_APP_URL;

console.log(`APP URL: ${APP_URL}, REDIRECT_URI: ${REDIRECT_URI}`)

var authData = {
    ClientId : CLIENT_ID,
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
}

// console.log(`authDataaaaaaa: ${JSON.stringify(authData)}`)

var auth = new CognitoAuth(authData);
auth.userhandler = {
    onSuccess: function(result) {
        console.log("On Success result", result);
        store.commit('login/setLoggedIn', true);
        UserInfoApi.getUserInfo().then(response => {
            IndexRouter.push('/'); // Redirect to home page
        })
        .catch(error => {
            console.log(`Error getting user info: ${error}`);
            IndexRouter.go({ path: '/error', query: { message: 'Error getting user info' } });
        });
    },
    onFailure: function(err) {
        store.commit('login/setLoggedOut');
        IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

function getAWSCredentials() {
    console.log(`Getting AWS credentials`);
    var logins = {};
    logins['cognito-idp.' + AWS_REGION + '.amazonaws.com/' + USERPOOL_ID] = auth.signInUserSession.getIdToken().getJwtToken();
    var params = {
        IdentityPoolId: IDENTITYPOOL_ID, 
        Logins: logins
    };
    var credentials = new AWS.CognitoIdentityCredentials(params);
    return credentials;
}

function getS3Client(bucket) {
    AWS.config.region = AWS_REGION; // Region
    AWS.config.credentials = getAWSCredentials();
    var s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: {Bucket: bucket}
    });

    return s3;
}
// This function is needed because the getSignedUrl function of the S3 client need to be async with async credentials
function getS3Url(s3Client, bucket, key, expiresIn = 900) {
    return new Promise((resolve, reject) => {
        s3Client.getSignedUrl('getObject', {
            Bucket: bucket,
            Key: key,
            Expires: expiresIn
        }, (error, url) => {
            if (error) {
                reject(error);
            } else {
                resolve(url);
            }
        });
    });
}




var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default{
    auth: auth,
    login(){
        console.log(`Loging in`)
        auth.getSession();
    },
    logout(){
        console.log(`Loging out`)
        if (auth.isUserSignedIn()) {
            var userInfoKey = this.getUserInfoStorageKey();
            auth.signOut();

            storage.removeItem(userInfoKey);
        }
    },
    signOut(){
        console.log(`Signing out`)
        auth.signOut();
    },
    getUserInfoStorageKey,
    getJWTToken() {
        const curSession = auth.getSignInUserSession();
        const cognitoIdToken = curSession.getIdToken();
        const jwtToken = cognitoIdToken.getJwtToken();
        //console.log(`jwtToken: ${jwtToken}`);
        return jwtToken;
    },
    getAWSCredentials,
    getS3Client,
    getS3Url,
}
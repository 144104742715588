// Created by SPe on 
// Page to 
<template>
    <div v-if="show">
        <div class="overlay"></div>
        <div class="loading">
            <div id="circularG">
                <div id="circularG_1" class="circularG"></div>
                <div id="circularG_2" class="circularG"></div>
                <div id="circularG_3" class="circularG"></div>
                <div id="circularG_4" class="circularG"></div>
                <div id="circularG_5" class="circularG"></div>
                <div id="circularG_6" class="circularG"></div>
                <div id="circularG_7" class="circularG"></div>
                <div id="circularG_8" class="circularG"></div>
                <br><br><br>
            </div>            
        </div>
        <div>
            <h1 class="text text-center">{{text}}</h1>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
        }
    },
    props: {
        show: {type: Boolean, default: true},
        text: {type: String, default: 'Loading...'},

    },
    methods: {
    },
    computed: {     
        tl: function () { return this.text.length; }
    },
    // Lifecycle hooks
    // mounted() {
    //     console.log('NavBar View Mounting'); 
    // },
    // unmounted() {
    //     console.log('NavBar View Unmounted')
    // },
    // beforeCreate() {console.log('NavBar View beforeCreate')},
    // beforeMount() {console.log('NavBar View beforeMount')},
    // created() {console.log('NavBar View created')},
    // beforeUpdate() {console.log('NavBar View beforeUpdate')},
    // updated() {console.log('NavBar View updated')},
    // activated() {console.log('NavBar View activated')},
    // deactivated() {console.log('NavBar View deactivated')},
    // beforeUnmount() {console.log('NavBar View beforeUnmount')},
    // errorCaptured() {console.log('NavBar View errorCaptured')},
    // renderTracked() {console.log('NavBar View renderTracked')},
    
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width:100%;
        height:100%;
        opacity:0.7;
        z-index: 998;
        background: rgb(242, 252, 218);
    }

    .loading {
        position: fixed;
        top: calc(50% - 29px);
        left: calc(50% - 29px);
        z-index: 999;
    }

    .text{
        position: fixed;
        top: calc(50% + 29px + 5%);
        width:100%;
        z-index: 999;
    }

   #circularG{
        position: absolute;
        top: 50%;
        left: 50%;
        width:58px;
        height:58px;
        margin: auto;
    }

    .circularG{
        position:absolute;
        background-color:rgb(0,0,0);
        width:14px;
        height:14px;
        border-radius:9px;
            -o-border-radius:9px;
            -ms-border-radius:9px;
            -webkit-border-radius:9px;
            -moz-border-radius:9px;
        animation-name:bounce_circularG;
            -o-animation-name:bounce_circularG;
            -ms-animation-name:bounce_circularG;
            -webkit-animation-name:bounce_circularG;
            -moz-animation-name:bounce_circularG;
        animation-duration:1.1s;
            -o-animation-duration:1.1s;
            -ms-animation-duration:1.1s;
            -webkit-animation-duration:1.1s;
            -moz-animation-duration:1.1s;
        animation-iteration-count:infinite;
            -o-animation-iteration-count:infinite;
            -ms-animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite;
            -moz-animation-iteration-count:infinite;
        animation-direction:normal;
            -o-animation-direction:normal;
            -ms-animation-direction:normal;
            -webkit-animation-direction:normal;
            -moz-animation-direction:normal;
    }

    #circularG_1{
        left:0;
        top:23px;
        animation-delay:0.41s;
            -o-animation-delay:0.41s;
            -ms-animation-delay:0.41s;
            -webkit-animation-delay:0.41s;
            -moz-animation-delay:0.41s;
    }

    #circularG_2{
        left:6px;
        top:6px;
        animation-delay:0.55s;
            -o-animation-delay:0.55s;
            -ms-animation-delay:0.55s;
            -webkit-animation-delay:0.55s;
            -moz-animation-delay:0.55s;
    }

    #circularG_3{
        top:0;
        left:23px;
        animation-delay:0.69s;
            -o-animation-delay:0.69s;
            -ms-animation-delay:0.69s;
            -webkit-animation-delay:0.69s;
            -moz-animation-delay:0.69s;
    }

    #circularG_4{
        right:6px;
        top:6px;
        animation-delay:0.83s;
            -o-animation-delay:0.83s;
            -ms-animation-delay:0.83s;
            -webkit-animation-delay:0.83s;
            -moz-animation-delay:0.83s;
    }

    #circularG_5{
        right:0;
        top:23px;
        animation-delay:0.97s;
            -o-animation-delay:0.97s;
            -ms-animation-delay:0.97s;
            -webkit-animation-delay:0.97s;
            -moz-animation-delay:0.97s;
    }

    #circularG_6{
        right:6px;
        bottom:6px;
        animation-delay:1.1s;
            -o-animation-delay:1.1s;
            -ms-animation-delay:1.1s;
            -webkit-animation-delay:1.1s;
            -moz-animation-delay:1.1s;
    }

    #circularG_7{
        left:23px;
        bottom:0;
        animation-delay:1.24s;
            -o-animation-delay:1.24s;
            -ms-animation-delay:1.24s;
            -webkit-animation-delay:1.24s;
            -moz-animation-delay:1.24s;
    }

    #circularG_8{
        left:6px;
        bottom:6px;
        animation-delay:1.38s;
            -o-animation-delay:1.38s;
            -ms-animation-delay:1.38s;
            -webkit-animation-delay:1.38s;
            -moz-animation-delay:1.38s;
    }



    @keyframes bounce_circularG{
        0%{
            transform:scale(1);
        }

        100%{
            transform:scale(.3);
        }
    }

    @-o-keyframes bounce_circularG{
        0%{
            -o-transform:scale(1);
        }

        100%{
            -o-transform:scale(.3);
        }
    }

    @-ms-keyframes bounce_circularG{
        0%{
            -ms-transform:scale(1);
        }

        100%{
            -ms-transform:scale(.3);
        }
    }

    @-webkit-keyframes bounce_circularG{
        0%{
            -webkit-transform:scale(1);
        }

        100%{
            -webkit-transform:scale(.3);
        }
    }

    @-moz-keyframes bounce_circularG{
        0%{
            -moz-transform:scale(1);
        }

        100%{
            -moz-transform:scale(.3);
        }
    } 
</style>



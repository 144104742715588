// Created by SPe on 13/09/2022
// component for Duplicate Dataset Form
<template>
  <div class="root">
    <h2>Duplicate Dataset</h2>
    <!-- Dataset Name -->
    <h4>New Dataset name:</h4>
    <!-- <label for="datasetName">Dataset name:</label><br> -->
    <input type="text" id="datasetName" placeholder="Dataset Name" v-model="datasetName" :class="{error: !validateInput(datasetName)}"/>
    <div v-if="!validateInput(datasetName)" class="bg-yellow-200 p-2"><small style="color:blue">Dataset name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed.</small></div>
    <!-- List of defects -->
    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            datasetName: '', // Dataset Name          
        }
    },
    props: {
        
    },
    methods: {
        submitForm() {
            console.log('Submit Form');
            // Check validation
            // Valid Name and at least one defect
            if (this.validateInput(this.datasetName)) {
                // Send event
                this.$emit("duplicateDatasetSubmitted", 
                    {DatasetName: this.datasetName}
                );
                console.log('Form successfully submitted.');
        
            } else {
                console.log('Form failed validation'); 
                this.errorMsg='Form Data not valid. Review it and re-submit'           
            }
        },
        cancel() {
            this.$emit("duplicateDatasetSubmitted", null);
        },       
        validateInput(inputValue) {
            if (inputValue) {
                // Regulatr expressión to check that string has length >= 1. Only letters and numbers and start with a letter
                let re = new RegExp("^[a-zA-Z][a-zA-Z0-9-_]*$");
                if (re.test(inputValue) && inputValue.length < 24) return true
                else return false;
            } else return false
        }
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Duplicate Dataset Form Mounting'); 
        
    },
    unmounted() {
        console.log('Duplicate Dataset Form Unmounted');
    },
        
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: red;
        background: #FDD;
    }
</style>



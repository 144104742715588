// Created by SPe on 21/07/2022
// component for New Dataset Form
<template>
  <div class="root">
    <!-- Dataset Name -->
    <h4>{{object_name}} Comment:</h4>
    <!-- <label for="datasetName">Dataset name:</label><br> -->
    <textarea type="text" v-model="newComment" cols="40" rows="10"  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>

    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Save</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Close</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            newComment: '', // Comment
            handleKeyDown: null,
        }
    },
    props: {
        object_name: {
            type: String,
            required: true
        },
        comment: {
            type: String,
            required: true
        }
    },
    methods: {
        submitForm() {
            console.log('Save comment');
            // Send event
            this.$emit("commentFormSubmitted", 
                {Comment: this.newComment}
            );
            console.log('Comment successfully saved.');
        },
        cancel() {
            this.$emit("commentFormSubmitted", null);
        },       
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Comment Form Mounting');

        this.newComment = this.comment;

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        
    },
    unmounted() {
        console.log('Comment Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class CommentForm extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>



// Created by SPe on 04/09/22
// Page to edit a history images

<template>
  <div class="historyeditor" ref="divContainer">
    <!-- LoadingOverlay -->
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <!-- NavBar -->
    <div class="sticky-top" ref="navBar">        
        <NavBar 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"            
            :showInfo="helpInfo"
            :showDatePicker="true"
            :selectTitle="'Dataset'"
            :selectPopUp="'Select a dataset to add labeled images'"
            :selectOptions="datasetOptions"
            :dateForDatePicker="date"
            @newOptionSelected="onNewOptionSelected"  
            @newDateSelected="onNewDateSelected"
        />
        <div v-if="errorMsgPre !== ''" style="background-color: red;">
            <h1 style="font-size: 2em;">{{errorMsgPre}}</h1>
        </div>
        <!-- Image controls -->
        <div id="prompt" class="bg-gray-200">
            <i class="bi bi-funnel cursor-pointer border-r-2 border-gray-400 px-2" title="Filter images" @click="()=>{if (!anyModalShowing) {filterModalShow =! filterModalShow;}}"></i>
            <i class="bi bi-sort-down cursor-pointer border-r-2 border-gray-400 px-2" title="Sort images" @click="()=>{if (!anyModalShowing) {sortModalShow = ! sortModalShow;}}"></i>
            <i class="bi bi-check2-square cursor-pointer border-r-2 border-gray-400 px-2" title="Select images" @click="()=>{if (!anyModalShowing) {selectImagesModalShow = ! selectImagesModalShow;}}"></i>
            <i class="bi bi-tag cursor-pointer border-r-2 border-gray-400 px-2" title="Select images" @click="()=>{if (!anyModalShowing) {openLabelSelectedImages()}}"></i>
            <i class="bi bi-info-circle px-2" :title="infoTooltip"></i>
            <p>Num. Images: Sel.: {{numSelectedImgs}} - Filt.:{{numImagesFiltered}} - Total: {{numImagesInBlock}}</p>
        </div>

    </div>  
    <!-- Get previous block button -->
    <div>
        <button v-if="minStartDateTimeAsDateStr" class="text-base px-5 py-1 m-0 bg-green-500" @click="getPreviousBlock">{{`Get previous Block of ${numBlockImages} Images. Up to ${minStartDateTimeAsDateStr}`}}</button>
    </div>
    <!-- Image container -->
    <div>            
        <div v-if="numImagesFiltered && !redrawing" ref="mainScroller">
        <!-- <p>{{numImagesFiltered}}</p> -->
            <!-- :item-size="imgHeight+8" // +8 is for margin. Not sure why exactly 8 -->
            <RecycleScroller
                class="scroller"
                :items="camRowList"
                :item-size="imgHeight+8" 
                key-field="id"
                :pageMode="true"            
                :buffer="500"
                :emitUpdate="true"
                @update="onScrollerUpdate"     
            >
                <template v-slot="{ item }">
                    <!-- <p>AAAAA: {{item}}</p> -->
                    <div class="cam_row">
                        <!-- <p v-for="(n, col) in numColumns " :key="col">{{item[`${col}`]}}</p> -->
                        <InferImageML v-for="(n, col) in numColumns " :key="col"                 
                            :imgObj = "item[col]"
                            :imgObjChanged = "item[col].chng"
                            :devName = "item[col].dn"
                            :camPos = "item[col].ps"
                            :imgHeight = "imgHeight"
                            :imgSrc = "item[col].url"
                            :decryptPswd = "decryptionPassword"
                            :imgFileKey = "item[col].fk"
                            :imgTimestamp = "getTimeIfExist(item[col].dt)"
                            :predictLabels = "item[col].pls"
                            :predictBBoxes = "item[col].pbbs"
                            :assignLabels = "item[col].asls"
                            :assignBBoxes = "item[col].asbbs"
                            :allLabels = "defects"
                            :labelsWithDetection = "defectsWithDetection"
                            :allPredictions = "item[col].ap"
                            :imgMetaData = "item[col].md"
                            :rotate180 = "rotate180"
                            :hideImg = "item[col].hide"
                            :showSelection = "true"
                            :selected = "item[col].selected"
                            :showBBoxButtons = "true"
                            :datasetIdSelected = "datasetIdSelected"
                            :innerBorderColor = "imageInnerBorderColor(item[col])"
                            :outerBorderColor = "imageOuterBorderColor(item, col)"
                            @onImgLoaded = "imgData => { onImgLoaded(item[col], imgData)}"
                            @onAnnotateImage = "onAnnotateImage"
                            @onSelection = "onSelection"
                            @imgClicked="onCamImgClick(item, col)"
                            @onImgExpired = "onImgExpired"
                        />
                    </div>
                </template>               
            </RecycleScroller>
        </div>
        <!-- Botton error message -->
        <div v-if="errorMsgPost !== ''" style="background-color: red;">
            <h1 style="font-size: 2em;">{{errorMsgPost}}</h1>
        </div>
        <!-- Get next block button -->
        <div>
            <button v-if="maxStartDateTimeAsDateStr" class="text-base px-5 py-1 m-0 bg-green-500" @click="getNextBlock">{{`Get Next Block of of ${numBlockImages} Images. From ${maxStartDateTimeAsDateStr}`}}</button>
        </div>
        <!-- Footernbar -->
        <Footer />
    </div>
    <!-- Filter Modal Window -->
    <Modal
        v-model="filterModalShow"
        ref="modal"
    >
        <div class="filterModalContent">
            <h1>Filter</h1>
            <h2>Asigned Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.assgnLabel.$all" @click="onFilterAssgnLabelClk($event, '$all')" ><b>Toggle all</b></label><br>
                <li v-for="defect in defects" :key="defect" >
                    <label><input type="checkbox" v-model="filter.assgnLabel[defect]" @click="onFilterAssgnLabelClk($event, defect)">{{defect}}</label><br>
                </li>
                <li><br></li>
                <li>
                    <label><input type="checkbox" v-model="filter.assgnLabel.$unlabeled" @click="onFilterAssgnLabelClk($event, '$unlabeled')" >Unlabeled</label><br>
                </li>
                <li>
                    <label><input type="checkbox" v-model="filter.assgnLabel.$multilabeled" @click="onFilterAssgnLabelClk($event, '$multilabeled')" >Multiple labels</label><br>
                </li>
            </ul>
            <h2>Inferred Label</h2>
            <ul style="list-style-type:none">
                <div>
                    <h4>Predefined Filters:</h4>
                    <select name="select" v-model="filter.inferLabel.$predefinedSelected" class='select border-2 border-gray-800 rounded-md' @change="(e)=>{onFilterInferSelected(e.target.value)}">                        
                        <option v-for="option in filter.inferLabel.$predefinedOptions" :key="option.id" :value="option.id">{{option.name}}</option>
                    </select>
                </div>
                <div v-if="filter.inferLabel.$predefinedSelected == 'selectable'">
                    <br>
                    <table >
                        <tr>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$all" @click="onFilterInferLabelClk($event, '$all', null)" ><b>Toggle all</b></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allSF" @click="onFilterInferLabelClk($event, '$allSF', null)" >{{'No'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allDF" @click="onFilterInferLabelClk($event, '$allDF', null)" >{{'~No'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allDT" @click="onFilterInferLabelClk($event, '$allDT', null)" >{{'~Yes'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allST" @click="onFilterInferLabelClk($event, '$allST', null)" >{{'Yes'}}</label></td>
                        </tr>
                        <tr v-for="defect in defects" :key="defect">
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].$all" @click="onFilterInferLabelClk($event, defect, '$all')" ><b>{{defect}}</b></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].SF" @click="onFilterInferLabelClk($event, defect, 'SF')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].DF" @click="onFilterInferLabelClk($event, defect, 'DF')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].DT" @click="onFilterInferLabelClk($event, defect, 'DT')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].ST" @click="onFilterInferLabelClk($event, defect, 'ST')" ></label></td>
                        </tr>
                    </table>
                    <!-- <label><input type="checkbox" v-model="filter.inferLabel.$all" @click="onFilterInferLabelClk($event, '$all')" ><b>Toggle all</b></label><br>
                    <li v-for="defect in defects" :key="defect" >
                        <label><input type="checkbox" v-model="filter.inferLabel[defect]" @click="onFilterInferLabelClk($event, defect)">{{defect}}</label><br>
                    </li> -->
                    <li><br></li>
                    <li>
                        <label><input type="checkbox" v-model="filter.inferLabel.$singlelabeled" @click="onFilterInferLabelClk($event, '$singlelabeled', null)" >Single labeled</label><br>
                    </li>
                    <li>
                        <label><input type="checkbox" v-model="filter.inferLabel.$multilabeled" @click="onFilterInferLabelClk($event, '$multilabeled', null)" >Multiple labels</label><br>
                    </li>
                </div>
            </ul>
            <h2>Action</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.action.$triggered" @click="onFilterAction($event, '$triggered')" >Triggered</label><br>
                <label><input type="checkbox" v-model="filter.action.$notTriggered" @click="onFilterAction($event, '$notTriggered')" >Not triggered</label><br>
                
            </ul>
            <h2>Device</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.devName.$all" @click="onFilterDeviceClk($event, '$all')" ><b>Toggle all</b></label><br>
                <li v-for="devName in imgDevNames" :key="devName" >
                    <label><input type="checkbox" v-model="filter.devName[devName]" @click="onFilterDeviceClk($event, devName)">{{devName}}</label><br>
                </li>
            </ul>
            <!-- Apply/Cancel Buttons -->
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-3 mx-4" @click="onApplyFilter">Apply</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="onCancelFilter">Cancel</button>
            </div>
        </div>        
    </Modal>
    <!-- Sort Modal Window -->
    <Modal
        v-model="sortModalShow"
        ref="modal"
    >
        <div class="sortModalContent">
            <h1>Sort</h1>
            <h2>Capture Time</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.time.asc" @click="onSortClk($event, 'time', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.time.dsc" @click="onSortClk($event, 'time', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Asigned Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.assgnLabel.asc" @click="onSortClk($event, 'assgnLabel', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.assgnLabel.dsc" @click="onSortClk($event, 'assgnLabel', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Inferred Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.inferLabel.asc" @click="onSortClk($event, 'inferLabel', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.inferLabel.dsc" @click="onSortClk($event, 'inferLabel', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Devices</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.devName.asc" @click="onSortClk($event, 'devName', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.devName.dsc" @click="onSortClk($event, 'devName', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <!-- Apply/Cancel Buttons -->
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-3 mx-4" @click="onApplySort">Apply</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="onCancelSort">Cancel</button>
            </div>
        </div>        
    </Modal>

    <!-- Select images Windows -->
    <Modal
        v-model="selectImagesModalShow"
        ref="modal"
    >
        <div class="selectImagesModalContent">
            <h1>Select Images</h1>
            <h2>Num Images: {{numImagesFiltered}}</h2>
            <h2>Select Images %</h2>
            <select class="select border-2 border-gray-800 rounded-md" v-model="selectImagesPercent">
                <option v-for="option in selectImagesPercentOptions" :key="option" :value="option">{{option + '% ( ' + Math.round(numImagesFiltered * option / 100) + ' )'}}</option>
            </select>
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-2 mx-4" @click="selectImages">Select</button>
                <button class="float-left text-center bg-blue-500 px-2 mx-4" @click="unselectAllImages">Unselect all</button>
                <button class="float-right text-center bg-red-500 px-2 mx-2" @click="onCancelSelect">Cancel</button>
            </div>
        </div>
    </Modal>

    <!-- Label Images Windows -->
    <Modal
        v-model="labelImagesModalShow"
        ref="modal"
    >
        <LabelSelectedImageForm :defectList="defects" @labelSelectedImages="labelSelectedImages" @cancelLabelSelectedImages="cancelLabelSelectedImages"></LabelSelectedImageForm>

    </Modal>

    <!--Modal Overlay-->
    <div
        class="fixed hidden inset-0 bg-gray-600 bg-opacity-50 h-full w-full"                
        id="ImgModal"
    >
        <div v-if="imgModalIsShow && rowIdSelected != null && colIdSelected != null" class="relative h-full w-full text-white flex items-center justify-center"> 
            <!-- Zoom Pan content  -->
            <div id="zoompan"> 
                <InferImageML
                    :imgObj = "camRowList[rowIdSelected][colIdSelected]"
                    :devName = "camRowList[rowIdSelected][colIdSelected].dn"
                    :camPos = "camRowList[rowIdSelected][colIdSelected].ps"
                    :imgHeight = "imgHeightInModal"
                    :imgSrc = "camRowList[rowIdSelected][colIdSelected].url"
                    :decryptPswd = "decryptionPassword"
                    :imgFileKey = "camRowList[rowIdSelected][colIdSelected].fk"
                    :imgTimestamp = "getTimeIfExist(camRowList[rowIdSelected][colIdSelected].dt)"
                    :predictLabels = "camRowList[rowIdSelected][colIdSelected].pls"
                    :predictBBoxes = "camRowList[rowIdSelected][colIdSelected].pbbs"
                    :assignLabels = "camRowList[rowIdSelected][colIdSelected].asls"
                    :assignBBoxes = "camRowList[rowIdSelected][colIdSelected].asbbs"
                    :allLabels = "defects"
                    :labelsWithDetection = "defectsWithDetection"
                    :allPredictions = "camRowList[rowIdSelected][colIdSelected].ap"
                    :imgMetaData = "camRowList[rowIdSelected][colIdSelected].md"
                    :rotate180 = "rotate180"
                    :showSelection = "true"
                    :selected = "camRowList[rowIdSelected][colIdSelected].selected"
                    :showBBoxButtons = "true"
                    :datasetIdSelected = "datasetIdSelected"
                    :innerBorderColor = "imageInnerBorderColor(camRowList[rowIdSelected][colIdSelected])"
                    :showComment = "true"
                    @onImgLoaded = "imgData => { onImgLoaded(camRowList[rowIdSelected][colIdSelected], imgData)}"
                    @onAnnotateImage = "onAnnotateImage"
                    @onSetComment = "onSetComment"
                    @onImgExpired = "onImgExpired"
                />     
            </div>
            <div class="center-down">
                <button class="bg-gray-500 bg-opacity-50 rounded-full w-10 h-10 text-red-500"
                    title="Close [X]"
                    @click="closeModal">X</button>
            </div>
        </div> 
    </div>

    <!-- Modal Window for Entering Password Form -->
      <Modal
          v-model="enterPasswwordModalShow"
          ref="modal"
      >
        <EnterPasswordForm entityType='Project' :entityName="projectName" @newPasswordSubmitted="newPasswordSubmitted"></EnterPasswordForm>        
      </Modal>
  </div>  
</template>

<script>
//import { EXIF } from 'exif-js';

import { RecycleScroller } from 'vue3-virtual-scroller';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css'
import { Options, Vue } from 'vue-class-component'; 
import { mapGetters } from 'vuex'
import { formatDateTimeForHistory } from '@/library/utils.js';
//import { sendMessageUnified } from '@/library/client-unified-send'
import store from '@/store/index.js';
import auth from '@/library/auth'; 
//import store from '@/store/index.js';
//import { sendWsMessage } from '@/library/websocket'
import LoadingOverlay from '@/components/LoadingOverlay.vue';
//import { connect as connectWrtc, close as closeWrtc } from '@/library/webrtc'
//import { sendMessageUnified } from '@/library/client-unified-send'
//import { registerUnifiedMessageCallBack } from '@/library/client-unified-recei.ve'
import  WZoom from 'vanilla-js-wheel-zoom'
import { connect as connectWs, removeOnConnectedCB } from '@/library/websocket.js';
import { getImageBlockFromHistory, getDatasetsOfProject, annotateImage, labelMultipleImages, setImageComment } from '@/library/http-api-client';
import EnterPasswordForm from '@/components/EnterPasswordForm.vue';
import { getProjectNameFromProjectId, getDateFromShortStr, setDateFromShortStr, getDateFromLongStr, relativeDateTime, getTimeIfExist } from '@/library/utils'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Button from 'primevue/button';
import InferImageML from '@/components/InferImageML.vue';
import LabelSelectedImageForm from '@/components/LabelSelectedImageForm.vue';
import appConfig from '@/config.js';

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
        Button,
        EnterPasswordForm,
        RecycleScroller,
        InferImageML,
        LabelSelectedImageForm,
    },
    data: function(){
        return {
            errorMsgPre: '',
            errorMsgPost: '',
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showRefreshButton: false,
            showSpinner: false,
        
            startDateTime: this.$route.params.startDateTime,
            projectId: this.$route.params.projectId,
            customer: this.$route.params.customer,
            projectName: null, 

            minStartDateTime: null, // To store min date in block of images
            maxStartDateTime: null, // To store max date in block of images
            blockStartDateTime: null, // First date in block (can be max or min)
            numBlockImages: 5000, // Number of images to download in block
            blockDirection: 'Asc', // Direction when taken the images in block with date
            // For Datasets
            datasetOptions: null, // For select
            datasetIdSelected: null, // DatasetId selected
            
            // For datepicker
            date: new Date(),            
                                  
            gettingData: false,
            justGotData: false,
            lastScrollEndIndex: 0,

            redrawing: false,
                        
            // Viewport size
            windowWidth: null,
            windowHeight: null,
            // Image size so accomadate a row in window
            imgWidth: 3200 / 10, // Initial value
            imgHeight: 800 / 10, // Initial value
            rotate180: true,
            imgScale: 1, // Scale to rescale the image when zoom in

            camRowWidth: null, // Cam row width recalculated according to changes

            numColumns: 4, // Number of columns
            // 
            s3Client: auth.getS3Client(appConfig.HistoryS3Bucket), // S3 client
            camPositions: [], // Sorted list of camera positions
            imgPerKey: {}, // Img object per file key
            camHeads: {}, // Dict with key camera position and value the head index for that camera. First camera row empty for this camera
            camRowHead: 0, // index of camera row head. First completely empty camera row
            camRowList: [], // List of camera rows

            allSelected: false,
            imagesInBlock: [], // Images to show
            imagesFiltered: [], // Images filtered
            navBarHeight: 20, // Height of Nav Bar

            helpInfo: '',

            imgDevNames: [], // List of dev names 

            cleanFiltersSorts: false, // Flag to indicate tha filters and sort should be clean

            numImagesInBlock: 0,
            numImagesFiltered: 0,

            labelCounter: {}, // Num of images with label in block

            /////////// Filter 
            filter: {assgnLabel: {}, inferLabel: {}, action: {}, devName: {}},
            lastAppliedFilter: {}, // Store last applied filter to be able to cancel
            filterModalShow: false,            
            
            //////////// Sort
            sortModalShow: false,
            sort: {
                time: {asc: true, dsc: false},
                assgnLabel: {asc: false, dsc: false},
                inferLabel: {asc: false, dsc: false},
                devName: {asc: false, dsc: false}
            },
            lastAppliedSort: {time: {asc: true, dsc: false}, assgnLabel: {asc: false, dsc: false}, inferLabel: {asc: false, dsc: false}, devName: {asc: false, dsc: false}}, // Store last applied sort to be able to cancel         
            /// Select images
            selectImagesModalShow: false,
            selectImagesPercent: 100,
            selectImagesPercentOptions: [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 7.5, 5, 4, 3, 2, 1],


            desiredScrollY: -1,
            updatingScrolling: false,

            // Original Image Size
            imgOriginalWidth: 200, // Image natural width
            imgOriginalHeight: 400, // Image natural height

            ///// Image Modal
            rowIdSelected: -1, // Row selected to show in modal window
            colIdSelected: -1, // Column selected to show in modal window
            imgModalIsShow: false,
            imgWidthtInModal: 400,
            imgHeightInModal: 400,
            initialScrollYModal: 0, // window scrollY when open modal
            initialRowSelectedModal: 0, // Initial row selected when open modal

            modalWZoom: null, // WZoom object in modal window

            labelImagesModalShow: false, // Label images modal window

            // Encryption
            enterPasswwordModalShow: false,
            anyImgIsEncrypted: false, // Whether is detected that some images are encrypted

            handleKeyDown: null, // Handler for key down

            appConfig: appConfig, // General config

            redirectinToLogin: false, // Flag to indicate that user is redirected to login page
        }
    },
    props: [],
    methods: {
        getTimeIfExist,
        imageInnerBorderColor(imgData) {
            // console.log(`imageBorderColor. item: ${JSON.stringify(item)}, col: ${col}`);
            return imgData.acttrig ? 'red' : 'white';
        },
        imageOuterBorderColor(item, col) {
            // console.log(`imageBorderColor. item: ${JSON.stringify(item)}, col: ${col}`);
            return item.id==this.rowIdSelected && col==this.colIdSelected? 'blue' : 'black';
        },
        async onImgLoaded(imgObj, imgData) { // (imgObj, imgData)
            // console.log(`onImgLoaded. imgData: ${JSON.stringify(imgData)}`);
            // Update original size if changed
            const imgOriginalWidth = imgData.imgOriginalWidth;
            const imgOriginalHeight = imgData.imgOriginalHeight;
            if (imgOriginalWidth !== this.imgOriginalWidth || imgOriginalHeight !== this.imgOriginalHeight) {
                console.log(`onImgLoaded. imgOriginalWidth changed from ${this.imgOriginalWidth} to ${imgOriginalWidth} and imgOriginalHeight changed from ${this.imgOriginalHeight} to ${imgOriginalHeight}`);
                this.imgOriginalWidth = imgOriginalWidth;
                this.imgOriginalHeight = imgOriginalHeight;
                this.resizeImage();
            }
        },
        onImgExpired(imgObj) {
            console.log(`onImgExpired. imgObj: ${JSON.stringify(imgObj)}`);
            if (!this.redirectinToLogin) {
                // Imgage can not be read from S3 --> inform user that session has expired and go to login
                alert(`Session has expired. Please login again`);
                // Redirect to login page
                this.$router.push('/login');
                this.redirectinToLogin = true;
            } else {
                console.error(`onImgExpired. Redirected to login page. No more alerts`);
            }
        },
        // onImgButtonCliked(imgObj, fileKey, pos, label, imgMetaData) {
        //     console.log(`DatasetEditor.onImgButtonCliked. imgObj: ${JSON.stringify(imgObj)} Key: ${fileKey}, Pos: ${pos}, Label: ${label}, Metadata: ${JSON.stringify(imgMetaData)}`);
        //     // Check that any daatset is selected
        //     if (!this.datasetIdSelected) {
        //         alert(`To be able to label images you need to select first the Data Set`);
        //         return;
        //     }
        //     // If clicked any label already selected --> remove assign label
        //     if (imgObj.asls.includes(label)) {
        //         console.log(`Label: ${label} clicked again. Unassigning label`);
        //         imgObj.asls.splice(imgObj.asls.indexOf(label), 1);
        //     } else { // Clicked new label
        //         console.log(`New Label: ${label} clicked. Previous: ${imgObj.asls}`);
        //         imgObj.asls.push(label);
        //         console.log(`New : ${imgObj.asls}`);
        //         // Ensure exclusivity between 'Ok' and rest of defects
        //         if (label === 'Ok') imgObj.asls = ['Ok']; // Remove the rest of labels
        //         else if (imgObj.asls.includes('Ok')) imgObj.asls.splice(imgObj.asls.indexOf('Ok'), 1); // --> remove 'Ok if exist'
        //     }
        //     let encrypted = fileKey.endsWith('.enx');
        //     let pieces = fileKey.split('/');
        //     let fileName = pieces[pieces.length -1];  // Get the file name from key
        //     if (fileName) {                
        //         let body = {
        //             //FileKey: fileKey,
        //             Originator: 'HistoryEditor',
        //             HstFilePath: imgMetaData['HstFilePath'],
        //             FileName: fileName,
        //             ImageId: imgMetaData['ImageId'],
        //             Encrypted: encrypted,
        //             CamPos: pos,
        //             DevId: imgMetaData['DevId'],
        //             DevName: imgMetaData['DevName'],
        //             PredictLabels: imgMetaData['PredictLabels'],
        //             PredictAll: imgMetaData['PredictAll'],
        //             UserId: this.userId,
        //             AssignLabels: imgObj.asls,
        //             TimeStampStr: imgMetaData['TimeStampStr'],
        //             ProjectId: this.projectId,
        //             DatasetId: this.datasetIdSelected,
        //             EngineId: imgMetaData['EngineId'],                  
        //         };
        //         labelImage(body, true);
                
        //     } else console.error(`onImgButtonCliked. Filename not available from file key: ${fileKey}`);            
        // },
        onAnnotateImage(imgObj, imgMetaData, fileKey, pos, assgnLabels, assignBBoxes) {

            // Check if it is readonly
            if (imgObj.ReadOnly) {
                console.error(`onAnnotateImage. This dataset is Read Only`);  
                alert(`This dataset is in read only mode. You can not change labels`);
                return;
            }

            // Save annotations to image object
            imgObj.asls = assgnLabels;            
            imgObj.asbbs = assignBBoxes;

            imgObj.md = imgMetaData;       

            imgObj.chng = !imgObj.chng; // Toggle change flag to force re-render

            const originator = 'HistoryEditor';
            const userId = this.userId;
            const projectId = this.projectId;
            const datasetId = this.datasetIdSelected;
            const saveInStore = true;        
            
            // console.log(`projectId: ${projectId}, datasetId: ${datasetId}, imgMetaData: ${JSON.stringify(imgMetaData)}`);
            // console.log(`onAnnotateImage. Key: ${fileKey}, Pos: ${pos}, Label: ${assgnLabels}, Metadata: ${JSON.stringify(imgMetaData)}`);

            annotateImage(imgMetaData, fileKey, pos, assgnLabels, assignBBoxes, originator, userId, projectId, datasetId, saveInStore);
        },
        onSetComment(imgObj, imgMetaData) {
            // Save comment to image object
            console.log(`imgMetaData: ${JSON.stringify(imgMetaData)}`)
            const imageId = imgMetaData.ImageId;
            const comment = imgMetaData.Comment
            imgObj.cm = comment;
            imgObj.chng = !imgObj.chng; // Toggle change flag to force re-render

            const userId = this.userId;
            const projectId = this.projectId;
            const datasetId = null;  
            const saveInHistory = true; // Save in history      

            setImageComment(userId, projectId, datasetId, imageId, comment, saveInHistory);
        },
        onWindowResize() {
            this.windowWidth = document.documentElement.scrollWidth;
            this.windowHeight = document.documentElement.scrollHeight;
            console.log(`onWindowResize. Width: ${this.windowWidth}, Height: ${this.windowHeight}`);
            this.resizeImage();
            if (this.modalWZoom && this.imgModalIsShow) this.modalWZoom.prepare();
        },
        resizeImage() {
            // Calculate the image size to accomodate in window
            this.imgWidth = this.imgScale * Math.round((this.windowWidth) / this.numColumns) - 7; // WIdth to accomodate all images in a row. -7 for border. Dont know why 7 exactly
            this.imgHeight = this.imgWidth * this.imgOriginalHeight / this.imgOriginalWidth;
            console.log(`resizeImage. imgWidth: ${this.imgWidth}, ${this.imgHeight}`)
        },
        onScrollerUpdate(startIndex, endIndex) {
            console.log(`onScrollerUpdate. startIndex: ${startIndex}, endIndex: ${endIndex}, desiredScrollY: ${this.desiredScrollY}, Num Camera Rows: ${this.numCamRows}`);
            // Scroll to desired position
            if (this.desiredScrollY > 0) window.scrollTo({left: window.scrollX, top: this.desiredScrollY, behavior: 'instant'} );
            
            console.log(`Scroll: ${window.scrollY}, desiredScrollY: ${this.desiredScrollY}, window.innerHeight: ${window.innerHeight}`);
            if (Math.abs(this.desiredScrollY - window.scrollY) < window.innerHeight / 2) { // Reset if scroll already there
                console.log(`Reseting desiredScrollY`); 
                this.desiredScrollY = -1;
                this.updatingScrolling = false;
                setTimeout(() => { this.loadingOverlay = { show: false };}, 10);
            }
        },
        getNewBlock() {
            console.log(`getNewBlock. ProjectId: ${this.projectId}, BlockStartDateTime: ${this.blockStartDateTime}, BlockDirection: ${this.blockDirection}`);
            this.gettingData = true;
            this.loadingOverlay = {show: true, text: `Getting block of ${this.numBlockImages} images`};            
            getImageBlockFromHistory(this.userId, this.projectId, this.blockStartDateTime, this.numBlockImages, this.blockDirection, this.onAllImgsData);
        },
        // Called once all images data for block has been taken
        async onAllImgsData() {
            console.log(`onAllImgsData. Customer: ${this.customer}, ProjectId: ${this.projectId}, BlockStartDateTime: ${this.blockStartDateTime}, BlockDirection: ${this.blockDirection}`);
            this.imagesInBlock = []; // Empty images to show
            this.labelCounter = {}; // Empty label counter
            let id = 1;
            this.minStartDateTime = '99-12-31T23_59_59_999'; // Reset minStartDateTime to max date
            this.maxStartDateTime = '00-00-00T00_00_00_000'; // Reset maxStartDateTime to min date
            this.numImagesInBlock = this.blockImages.length;
            console.log(`Block images loaded. Num Images: ${this.numImagesInBlock}`) 
            let numImagesWithUrl = 0;
            for (let img of this.blockImages) {
                //console.log(`IMG Data: ${JSON.stringify(img)}`)
                const fileName = img.FileName;
                const dateStr = img.TimeStampStr;
                const date = getDateFromLongStr(img.TimeStampStr);
                const pos = img.CamPos;
                //console.log(`File Name: ${fileName}, dateStr: ${dateStr}, date: ${date}, pos: ${pos}`);
                const fileKey = `${appConfig.HistImgsBucketPrefix(this.customer, this.projectId)}/${fileName}`;// `cst/${this.customer}/prj/${this.projectId}/img/${fileName}`; //`hstimgs/${this.projectId}/${fileName}`;
                // Check if image is encrypted
                if (fileKey.endsWith('.enx')) { // Encrypted image
                    if (!this.anyImgIsEncrypted && !this.decryptionPassword) { // First time encrypted image found and not defined yet
                        // Remove overlay
                        this.loadingOverlay.show = false;
                        //  Show Enter Password Modal 
                        this.enterPasswwordModalShow = true;
                        // Wait until modal is closed
                        while (this.enterPasswwordModalShow) await new Promise(r => setTimeout(r, 100));
                        this.loadingOverlay.show = true;
                    }
                    this.anyImgIsEncrypted = true; // Check if file has encryption extension
                }
                //const predictLabels = img.PredictLabels;
                const assignLabels = img.AssignLabels;
                const assignBBoxes = img.AssignBBoxes;
                let allPredictions = img.PredictAll;
                const predictBBoxes = img.PredictBBoxes;
                const devName = img.DevName;
                // Get the limit datetime
                if (dateStr < this.minStartDateTime) this.minStartDateTime = dateStr;
                if (dateStr > this.maxStartDateTime) this.maxStartDateTime = dateStr;
                //console.log(`DevName: ${devName}`)
                if (devName && !this.imgDevNames.includes(devName)) this.imgDevNames.push(devName); // Add DevName to list
                // Default allPredictions if not provided
                if (!allPredictions) {
                    allPredictions = {};
                    for (let label of this.defects) {
                        allPredictions[label] = 0;
                    }
                    img.PredictAll = allPredictions;
                }
                // Temporary patch to convert "NoProblem" label to "Ok"
                if (img.PredictLabels.includes('NoProblem')) { img.PredictLabels.splice(img.PredictLabels.indexOf('NoProblem')); img.PredictLabels.push('Ok');}
                if ('NoProblem' in allPredictions) allPredictions['Ok'] = allPredictions['NoProblem'];
                ////  Borrar estas dos ultimas lineas
                // Update labelCounter
                if (img.PredictLabels) for (let label of img.PredictLabels) this.labelCounter[label] = (this.labelCounter[label] || 0) + 1;
                // Build image data object
                let imgData = {
                    id: id, dn: devName, md: img, chng: false, ps: pos, dt: date, ds: dateStr,
                    fk: fileKey, url: null, asls: assignLabels, asbbs: assignBBoxes, ap: allPredictions,
                    pls: img.PredictLabels, pbbs: predictBBoxes, selected: false,
                    acttrig: img.ActTrig
                };
                if (imgData.acttrig) console.log(`Action Trigered in image: ${JSON.stringify(imgData)}`);
                // Add imgData to imagesInBlock
                this.imagesInBlock.push(imgData);
                id += 1;
                // Get signed URL asynchronously and add to imgData
                this.s3Client.getSignedUrl('getObject', {
                        Bucket: appConfig.HistoryS3Bucket,
                        Key: fileKey,
                        Expires: 60 * 60 // 1 hour
                    }, (error, url) => {
                        if (error) {
                            console.error(`Error getting signed URL for file: ${fileKey}. Error: ${error.message}`);
                            numImagesWithUrl++;    
                        } else {
                            // console.log(`getSignedUrl URL: ${url}`);
                            // Update url in imgData
                            imgData.url = url;
                            imgData['PreSignExprTs'] = Date.now() + 60 * 60 * 1000; // 1 hour
                            numImagesWithUrl++;    
                        }
                    }
                );
            }
            // Wait until all images are loaded
            console.log(`Waiting until all (${this.imagesInBlock.length}) images has signed URL`);
            const startTime = new Date().getTime();
            while (numImagesWithUrl < this.imagesInBlock.length) await new Promise(r => setTimeout(r, 100));
            console.log(`All images has signed URL. Took: ${new Date().getTime() - startTime} ms`);
            //console.log(`imagesInBlock: ${JSON.stringify(this.imagesInBlock)}`);
            console.log(`Num imagesInBlock: ${this.imagesInBlock.length}`);
            console.log(`projectData: ${JSON.stringify(this.projectData)}`);
            console.log(`defects: ${JSON.stringify(this.defects)}`);
            console.log(`defectsWithDetection: ${JSON.stringify(this.defectsWithDetection)}`);

            console.log(`cleanFiltersSorts: ${this.cleanFiltersSorts}`);
            if (this.cleanFiltersSorts) {
                this.cleanFiltersSorts = false;
                // Initialize filter
                this.initializeFilters();
            } else {
                this.redefineDevicesInFilter();
            }

            this.sortImages();
            this.loadingOverlay = {show: false}; 
            this.organizeInColumns();            
        },
        newPasswordSubmitted(data) {
            console.log(`newPasswordSubmitted: ${JSON.stringify(data)}`);
            this.enterPasswwordModalShow = false;
            store.commit('projects/setProjectPassword', {ProjectId: this.projectId, Password: data.Password});
        },
        sortImages() {
            function compare(a, b) {
                if (a > b) return -1;
                if (b > a) return 1;
                return 0;
            }
            console.log(`sortImages: ${JSON.stringify(this.sort)}`);
            if (this.sort.time.asc) {
                console.log(`sortImages time.asc`);
                //this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(b.md.TimeStampStr, a.md.TimeStampStr));
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(b.dt, a.dt));
            } else if (this.sort.time.dsc) {
                console.log(`sortImages time.dsc`);
                //this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(a.md.TimeStampStr, b.md.TimeStampStr));
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(a.dt, b.dt));
            } else if (this.sort.assgnLabel.asc) {
                console.log(`sortImages assgnLabel.dsc`);                
                for (let img of this.imagesInBlock) {
                    img.md.AssignLabels.sort((a,b) => compare(b, a)); // Sort AssignLabels list                    
                    img['SortIndex'] = img.md.AssignLabels.join(''); // Join all AssignLabels toguether
                } 
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(b.SortIndex, a.SortIndex));
            } else if (this.sort.assgnLabel.dsc) {
                console.log(`sortImages assgnLabel.dsc`);                
                for (let img of this.imagesInBlock) {                    
                    img.md.AssignLabels.sort((a,b) => compare(a, b)); // Sort AssignLabels list                    
                    img['SortIndex'] = img.md.AssignLabels.join(''); // Join all AssignLabels toguether
                } 
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(a.SortIndex, b.SortIndex));
            }  else if (this.sort.inferLabel.asc) {
                console.log(`sortImages inferLabel.dsc`);                
                for (let img of this.imagesInBlock) {
                    img.md.PredictLabels.sort((a,b) => compare(b, a)); // Sort PredictLabels list                    
                    img['SortIndex'] = img.md.PredictLabels.join(''); // Join all PredictLabels toguether
                } 
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(b.SortIndex, a.SortIndex));
            } else if (this.sort.inferLabel.dsc) {
                console.log(`sortImages inferLabel.dsc`);                
                for (let img of this.imagesInBlock) {                    
                    img.md.PredictLabels.sort((a,b) => compare(a, b)); // Sort PredictLabels list                    
                    img['SortIndex'] = img.md.PredictLabels.join(''); // Join all PredictLabels toguether
                } 
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(a.SortIndex, b.SortIndex));
            }  else if (this.sort.devName.asc) {
                console.log(`sortImages devName.dsc`);                
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(b.md.devName, a.md.devName));
            } else if (this.sort.devName.dsc) {
                console.log(`sortImages devName.dsc`);                
                this.imagesInBlock = this.imagesInBlock.sort((a,b) => compare(a.md.devName, b.md.devName));
            }
            //console.log(`sortImages: ${JSON.stringify(this.imagesInBlock)}`);
        },
        async organizeInColumns() {
            console.log(`organizeInColumns`);
            this.camRowHead = 0;
            // Organize in camRowList
            this.redrawing = true;
            this.loadingOverlay = {show: true, text: `Drawing images`};
            await new Promise(r => setTimeout(r, 50)); // Small delay to ensure loadingOverlay is renderedonDatasetsData
            // Build filter lists
            let assgnLabelList = Object.keys(this.filter.assgnLabel).filter(key => this.filter.assgnLabel[key] && !['$all', '$unlabeled', '$multilabeled'].includes(key));
            //let inferLabelList = Object.keys(this.filter.inferLabel).filter(key => this.filter.inferLabel[key] && !['$all', '$unlabeled', '$multilabeled'].includes(key));
            this.numSelectedImgs = 0;
            this.imagesFiltered = []; 
            this.camRowList = [];
            let col = 0;
            console.log(`organizeInColumns. Pre Filters Num images to show: ${this.imagesInBlock.length}`);
            let numImagesInColumns = 0;
            for (let img of this.imagesInBlock) {
                //console.log(`IMG: ${JSON.stringify(img)}`)
                /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Apply filters
                img.showing = false; // set initailly to false
                img.selected = false; // Unselect
                ////////////////////////////////// Assign Label
                if (!this.filter.assgnLabel.$all) { // Some conditions to check
                    let remove = false;
                    //console.log(`AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA: ${this.filter.assgnLabel.$all}`);
                    //console.log(`img.md.AssignLabels.length: ${img.md.AssignLabels.length}`);
                    let imgIsAssgnUnlabeled = img.md.AssignLabels.length === 0; 
                    let imgIsAssgnMultilabeled = img.md.AssignLabels.length > 1;
                    if (imgIsAssgnUnlabeled && !this.filter.assgnLabel.$unlabeled) remove = true; // Skip labeled images if filter does not inclued unlabeled
                    if (imgIsAssgnMultilabeled && !this.filter.assgnLabel.$multilabeled) remove = true; // Skip multilabeled images
                    if (!imgIsAssgnUnlabeled) { // Image has some labels
                        //console.log(`assgnLabelList: ${JSON.stringify(assgnLabelList)}`)  
                        //console.log(`img.md.AssignLabels: ${JSON.stringify(img.md.AssignLabels)}`)                   
                        let labelIntersection = img.md.AssignLabels.filter(label => assgnLabelList.includes(label)); // Get the intersection between img labels and filter
                        //console.log(`labelIntersection: ${JSON.stringify(labelIntersection)}`)
                        if (labelIntersection.length === 0) remove = true; // Skip images with no labels in filter
                    }
                    // Add multilabeled images if needed
                    if (imgIsAssgnMultilabeled && this.filter.assgnLabel.$multilabeled) remove = false;
                    // Skip it if needed
                    if (remove) continue;
                    //console.log(`iNCLUDE THIS IMAGE`)
                }
                ////////////////////////////////// Inferred Label
                // Calculate list of sublabels if not yet
                if (img.md.PredictSubLabels === undefined) { // Not calculates
                    img.md.PredictSubLabels = {}
                    if (img.md.PredictAll) {
                        for (let defect of this.defects) {
                            if (img.md.PredictAll[defect] < 0.25) img.md.PredictSubLabels[defect] = 'SF';
                            else if (img.md.PredictAll[defect] < 0.5) img.md.PredictSubLabels[defect] = 'DF';
                            else if (img.md.PredictAll[defect] < 0.75) img.md.PredictSubLabels[defect] = 'DT';
                            else img.md.PredictSubLabels[defect] = 'ST'
                        }
                    }
                }
                let predefinedFilter = this.filter.inferLabel.$predefinedSelected;
                let skip = true;
                // Selectable 
                if (predefinedFilter === 'selectable') {
                    // console.log(`PredictSubLabels: ${JSON.stringify(img.md.PredictSubLabels)}`)
                    // console.log(`this.filter.inferLabel: ${JSON.stringify(this.filter.inferLabel)}`)
                    // console.log(`this.filter.inferLabel.$singlelabeled: ${JSON.stringify(this.filter.inferLabel.$singlelabeled)}`)
                    // console.log(`this.filter.inferLabel.$multilabled: ${JSON.stringify(this.filter.inferLabel.$multilabled)}`)
                    // console.log(`img.md.PredictLabels.length: ${JSON.stringify(img.md.PredictLabels.length)}`)
                    // console.log(`img.md.PredictLabels: ${JSON.stringify(img.md.PredictLabels)}`)
                    skip = true;
                    if ((this.filter.inferLabel.$singlelabeled && img.md.PredictLabels.length <= 1) || (this.filter.inferLabel.$multilabeled && img.md.PredictLabels.length > 1)) {
                        skip = false;
                        for (let defect of this.defects) {
                            // console.log(`Defect: ${defect}`)
                            // console.log(`this.filter.inferLabel[defect].$asList: ${JSON.stringify(this.filter.inferLabel[defect].$asList)}`)
                            // console.log(`img.md.PredictSubLabels[defect]: ${JSON.stringify(img.md.PredictSubLabels[defect])}`)
                            if (!this.filter.inferLabel[defect].$asList.includes(img.md.PredictSubLabels[defect])) { // Image label subclass is not included in filter --> skip
                                skip = true; // skip
                                break; // Stop searching
                            } //else console.log(`Not Skiping this image`);
                        }
                    }
                // noLabel --> Skip if any defect is not 'SF', 'DF'
                } else if (predefinedFilter === 'noLabel') {
                    skip = false;
                    for (let defect of this.defects) {
                        if (!['SF', 'DF'].includes(img.md.PredictSubLabels[defect])) { // Image label subclass is not included in ['SF', 'DF'] --> skip
                            skip = true; // skip
                            break; // Stop searching
                        } //else console.log(`Not Skiping this image`);
                    }

                // okAndDefect --> Skip 'Ok' is active ('DT', 'ST') and any other defect is also active ('DT', 'ST')
                } else if (predefinedFilter === 'okAndDefect') {
                    skip = true;
                    if (['DT', 'ST'].includes(img.md.PredictSubLabels['Ok'])) { // Ok is active
                        for (let defect of this.defects) {
                            if (defect !== 'Ok') { // Check rest of labels
                                if (['DT', 'ST'].includes(img.md.PredictSubLabels[defect])) {
                                    //console.log(`Ok and ${defect} active`);
                                    skip = false; // skip
                                    break; // Stop searching
                                }
                            }
                        }
                    }
                }
                //// Skip (filter out) if needed
                if (skip) continue; // Skip this image

                ////////////////////////////////// Action
                if (!this.filter.action.$triggered) { // Filter out images with Action Triggered         
                    if (img.md.ActTrig) continue; // Skip images with Action Triggered
                }
                if (!this.filter.action.$notTriggered) { // Filter out images with Action Not Triggered         
                    if (!img.md.ActTrig) continue; // Skip images with Action Not Triggered
                }

                ////////////////////////////////// Device Name
                if (!this.filter.devName.$all) { // Some conditions to check         
                    if (!this.filter.devName[img.md.DevName]) continue; // Skip images with no devName in filter
                }
                //////////////////////////////////
                // Not filtered
                img.showing = true;
                numImagesInColumns ++;
                if (col === 0) this.camRowList.push({id: this.camRowHead})
                //console.log(`col: ${col} this.camRowList 1: ${JSON.stringify(this.camRowList)}`)
                this.imagesFiltered.push(img);
                this.camRowList[this.camRowList.length - 1][col] = img; // Insert img in last row and col
                col ++;
                if (col === this.numColumns) {
                    col = 0;
                    this.camRowHead ++;
                    //console.log(`this.camRowList: ${JSON.stringify(this.camRowList)}`)
                }                
            }
            this.numImagesFiltered = numImagesInColumns;
            console.log(`organizeInColumns. Post Filters Num images in columns: ${numImagesInColumns}`);
            // Complete last row
            if (this.camRowList.length > 0) {
                let lastImgRow = this.camRowList[this.camRowList.length - 1];
                for (let col=0; col < this.numColumns; col++) {
                    if (!lastImgRow[col]) {
                        lastImgRow[col] = { ...this.imagesInBlock[this.imagesInBlock.length - 1]}; // Clone last image
                        lastImgRow[col].hide = true; // Hide it
                    }
                }
            }
            // Force image resize
            window.dispatchEvent( new Event('resize'));
            setTimeout(() => { this.redrawing = false; this.loadingOverlay = { show: false };}, 50);
            
        },        
        onKeyRight() {
            console.log('onKeyRight --> Zoom in');
            if (this.updatingScrolling) {
                console.log('onKeyRight not possible. Still updating scroll');
                return;
            }
            let scrollYPerc = window.scrollY / this.scrollMaxY; // Current scroll Y percentage
            let newScrollMaxY = this.scrollMaxY; // estimated new scroll max after changes
            if (this.numColumns > 1) {
                window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10);
                let newNumColumns = this.numColumns - 1;
                let newNumCamRows = Math.ceil(this.numImagesFiltered / newNumColumns);
                console.log(`this.numImagesFiltered: ${this.numImagesFiltered}, newNumColumns: ${newNumColumns}, newNumCamRows: ${newNumCamRows}`);
                newScrollMaxY = newScrollMaxY * newNumCamRows /  this.numCamRows * this.numColumns / newNumColumns;
                this.numColumns --;
                console.log(`onKeyRight. Num Columns: ${this.numColumns}`);
                this.organizeInColumns();
            } else {
                if (this.imgScale < 10) { // Limit the rescale to 10 times
                    window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10);
                    let factor = 1.2;
                    this.imgScale = this.imgScale * factor;
                    this.imgHeight = this.imgHeight * factor;
                    this.imgWidth = this.imgWidth * factor;
                    newScrollMaxY = newScrollMaxY * factor;
                    this.camRowWidth = this.imgWidth * this.numColumns;
                    console.log(`imgWidth: ${this.imgWidth}, numColumns: ${this.numColumns}, camRowWidth: ${this.camRowWidth}`)
                }                
            }            
            let newScrollY = scrollYPerc * newScrollMaxY;
            console.log(`scrollYPerc: ${scrollYPerc}, scrollMaxY: ${this.scrollMaxY}, newScrollMaxY: ${newScrollMaxY}, newScrollY: ${newScrollY}`);
            this.desiredScrollY = newScrollY;
            if (this.imgScale === 1) this.camRowWidth = null; // Remove X scroll bar
        },
        onKeyLeft () {
            console.log('onKeyLeft --> Zoom out');  
            if (this.updatingScrolling) {
                console.log('onKeyRight not possible. Still updating scroll');
                return;
            }         
            let scrollYPerc = window.scrollY / this.scrollMaxY; // Current scroll Y percentage
            let newScrollMaxY = this.scrollMaxY; // estimated new scroll max after changes
            if (this.imgScale > 1) { // Image is scaled
                window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10); 
                let factor = 1.2;
                if (this.imgScale < 1.2) factor = this.imgScale
                this.imgScale = this.imgScale / factor;
                this.imgHeight = this.imgHeight / factor;
                this.imgWidth = this.imgWidth / factor;
                newScrollMaxY = newScrollMaxY / factor;
                this.camRowWidth = this.imgWidth * this.numColumns;
                console.log(`imgWidth: ${this.imgWidth}, numColumns: ${this.numColumns}, camRowWidth: ${this.camRowWidth}`)

            } else { // Image is not scaled --> add one column
                if (this.numColumns < 6) {
                    window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10); 
                    let newNumColumns = this.numColumns + 1;
                    let newNumCamRows = Math.ceil(this.numImagesFiltered / newNumColumns);
                    newScrollMaxY = newScrollMaxY * newNumCamRows /  this.numCamRows * this.numColumns / newNumColumns;
                    //console.log(`newScrollMaxY: ${newScrollMaxY}`);
                    this.numColumns ++;
                    this.organizeInColumns();                    
                }
                console.log(`onKeyLeft. Num Columns: ${this.numColumns}`);
            }            
            let newScrollY = scrollYPerc * newScrollMaxY;
            console.log(`newScrollY: ${newScrollY}`);
            this.desiredScrollY = newScrollY;
            if (this.imgScale === 1) this.camRowWidth = null; // Remove X scroll bar
        },
        onSelection(imgObj, selected) {
            console.log(`onSelection. Img: ${imgObj.fk}, Selected: ${selected}`);
            imgObj.selected = selected;
            // Call this.countSelectedImages(); delayed
            setTimeout(() => {this.countSelectedImages();}, 100);
        },
        onMenuToggle() {
            this.showMenu = ! this.showMenu;
        },
        onFilterAssgnLabelClk(event, label) {
            let selected = event.target.checked;
            console.log(`onFilterAssgnLabelClk. Label: ${label}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (label === '$all') {
                if (this.filter.assgnLabel.$all) { // Already all
                    this.filter.assgnLabel.$all = false;
                    this.filter.assgnLabel.$unlabeled = false;
                    this.filter.assgnLabel.$multilabeled = false;
                    for (let defect of this.defects) this.filter.assgnLabel[defect] = false;
                } else { // None
                    this.filter.assgnLabel.$all = true;
                    this.filter.assgnLabel.$unlabeled = true;
                    this.filter.assgnLabel.$multilabeled = true;
                    for (let defect of this.defects) this.filter.assgnLabel[defect] = true;
                }

            } else { // Amy normal label 
                if (!selected) this.filter.assgnLabel.$all = false; // Set All off if any label is unset
            }      
        },
        onFilterInferLabelClk(event, label, subLabel) {
            let selected = event.target.checked;
            console.log(`onFilterInferLabelClk. Label: ${label}, SubLabel: ${subLabel}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (label === '$all' && subLabel === null) {
                this.filter.inferLabel.$all = !this.filter.inferLabel.$all;
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[`$all${sl}`] = selected}); 
                for (let defect of this.defects) {
                    this.filter.inferLabel[defect].$all = selected;
                    ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[defect][sl] = selected}); 
                }
            } else if (label === '$allSF' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].SF = selected;
            else if (label === '$allDF' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].DF = selected;
            else if (label === '$allDT' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].DT = selected;
            else if (label === '$allST' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].ST = selected;
            // Any normal label 
            else if (subLabel === '$all') 
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[label][sl] = selected});   
            else if (['SF', 'DF', 'DT', 'ST'].includes(subLabel))
                this.filter.inferLabel[label][subLabel] = selected;
            // Conver to list of sub-labels
            for (let defect of this.defects) {
                this.filter.inferLabel[defect].$asList = []; // Empty list
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => { if (this.filter.inferLabel[defect][sl]) this.filter.inferLabel[defect].$asList.push(sl)}); 
            }  
            console.log(`New Infer FFFFilter: ${JSON.stringify(this.filter.inferLabel)}`);
        },
        onFilterInferSelected(value) {
            console.log(`onFilterInferSelected: ${value}`);
        },
        onFilterDeviceClk(event, devName) {
            let selected = event.target.checked;
            console.log(`onFilterDeviceClk. Device Name: ${devName}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (devName === '$all') {
                if (this.filter.devName.$all) { // Already all
                    this.filter.devName.$all = false;
                    for (let devName of this.imgDevNames) this.filter.devName[devName] = false;
                } else { // None
                    this.filter.devName.$all = true;
                    for (let devName of this.imgDevNames) this.filter.devName[devName] = true;
                }
            } else { // Amy normal label 
                if (!selected) this.filter.devName.$all = false; // Set All off if any label is unset
            }        
        },
        onFilterAction(event, condition) {
            let selected = event.target.checked;
            console.log(`onFilterAction. Condition: ${condition}, Selected: ${selected}`);
        },
        onApplyFilter() {
            console.log(`onApplyFilter`);
            this.filterModalShow=false;
            this.lastAppliedFilter = JSON.parse(JSON.stringify(this.filter)); // Save it
            this.organizeInColumns();
        },
        onCancelFilter() {
            console.log(`onCancelFilter`);
            this.filterModalShow=false;
            this.filter = JSON.parse(JSON.stringify(this.lastAppliedFilter)); // Restore last applied filters
        },
        onSortClk(event, section, direction) {
            let selected = event.target.checked;
            console.log(`onSortClk. Section: ${section}, Direction: ${direction}, Selected: ${selected}`);
            if (selected) {
                // Initailize all to false
                this.sort = {time: {asc: false, dsc: false}, assgnLabel: {asc: false, dsc: false}, inferLabel: {asc: false, dsc: false}, devName: {asc: false, dsc: false}},
                // set the selected section.direction
                this.sort[section][direction] = true;
            }
        },
        onApplySort() {
            console.log(`onApplySort`);
            this.sortModalShow=false;
            this.lastAppliedSort = JSON.parse(JSON.stringify(this.sort));
            this.sortImages();
            this.organizeInColumns();
        },
        onCancelSort() {
            console.log(`onCancelSort`);
            this.sortModalShow=false;
            this.sort = JSON.parse(JSON.stringify(this.lastAppliedSort)); // Restore last applied sort
        },
        selectImages() {
            console.log(`selectImages. selectImagesPercent: ${this.selectImagesPercent}`);
            this.selectImagesModalShow = false;
            let NumImgsToSelect = Math.round(this.numImagesFiltered * this.selectImagesPercent / 100);
            console.log(`NumImgsToSelect: ${NumImgsToSelect}`);
            // If all images selected
            if (NumImgsToSelect === this.numImagesFiltered) {
                for (let img of this.imagesFiltered) img.selected = true; // Select all
            } else {
                // Select NumImgsToSelect from this.imagesFiltered randomly
                let selectedImgs = [];
                let numImgs = this.imagesFiltered.length;
                console.log(`numImgs: ${numImgs}`);
                while (selectedImgs.length < NumImgsToSelect) {
                    let idx = Math.floor(Math.random() * numImgs);
                    if (!selectedImgs.includes(idx)) selectedImgs.push(idx);
                }
                console.log(`selectedImgs(${selectedImgs.length}): ${JSON.stringify(selectedImgs)}`);
                for (let img of this.imagesFiltered) img.selected = false; // Unselect
                for (let idx of selectedImgs) {
                    let img = this.imagesFiltered[idx];
                    if (img.showing) img.selected = true; // Select
                }
            }
            this.countSelectedImages();
        },
        countSelectedImages() {
            console.log(`countSelectedImages`);
            this.numSelectedImgs = 0;
            for (let img of this.imagesInBlock) if(img.selected) this.numSelectedImgs ++;
            // I dont know why I need to do this to update the UI with numSelectedImgs
            setTimeout(() => { this.loadingOverlay = { show: false };}, 100);
            console.log(`numSelectedImgs: ${this.numSelectedImgs}`);
        },
        unselectAllImages() {
            console.log(`unselectAllImages`);
            this.selectImagesModalShow = false;
            for (let img of this.imagesInBlock) {
                img.selected = false; // Unselect
            }
            this.countSelectedImages();
        },
        onCancelSelect() {
            console.log(`onCancelSelect`);
            this.selectImagesModalShow = false;
            for (let img of this.imagesInBlock) {
                img.selected = false; // Unselect
            }
            this.countSelectedImages();
        },
        onNewDateSelected(newDate) {
            console.log(`onNewDateSelected. New Date: ${newDate}`);
            if (formatDateTimeForHistory(newDate) !== this.startDateTime) { // Real new date
                // Set startDateTime
                this.startDateTime = formatDateTimeForHistory(newDate);
                // Clean dataset
                this.errorMsgPre = '';
                this.errorMsgPost = '';
                // this.camRowList = [];
                // this.camHeads = {};
                // this.camRowHead = 0;
                this.blockStartDateTime = this.startDateTime; // Start block date
                this.blockDirection = 'Asc' // Ascending order
                this.getNewBlock();
            }
        },
        onDatasetsData(datasetsDataResult) {
            if (datasetsDataResult === null || datasetsDataResult.length == 0) {
                alert(`No datasets created in project: ${this.projectName}. At least one dataset should be created`);
                this.loadingOverlay = {show: false};
            }
            console.log(`onDatasetsData: ${JSON.stringify(datasetsDataResult)}`);
            this.datasetOptions = [];
            for (let ds of datasetsDataResult) {
                // Exclude internal Datasets
                if (ds.DatasetName[0] !='[') {
                    this.datasetOptions.push({id: ds.DatasetId, name: ds.DatasetName})
                }
            }
            console.log(`Dataset Options: ${JSON.stringify(this.datasetOptions)}`);
        },
        refreshDatasetsData(onDataReceivedCB=null) {
            console.log('Refreshing Datasets');            
            this.loadingOverlay = {show: true, text: 'Loading Datasets'};
            // Get Project Data for the user
            getDatasetsOfProject(this.projectId, (datasetsDataResult) => {
                this.onDatasetsData(datasetsDataResult);
                // Call callback if defined
                if (onDataReceivedCB) onDataReceivedCB();
            });
        },
        onNewOptionSelected(newOption) {
            console.log(`newOptionSelected: ${newOption}`);
            this.datasetIdSelected = newOption;
        },
        onCamImgClick(row, col) {
            console.log(`onCamImgClick. Row: ${row.id}, col: ${col}`);
            this.rowIdSelected = row.id;
            this.colIdSelected = col;
            this.imgHeightInModal = Math.min(this.windowHeight - 200, this.windowWidth * this.imgOriginalHeight / this.imgOriginalWidth - 5);
            this.imgWidthtInModal = this.windowWidth;
            console.log(`Nav Bar Height: ${this.$refs.navBar.clientHeight}`);
            this.navBarHeight = this.$refs.navBar.clientHeight;
            this.initialScrollYModal = window.scrollY;
            this.initialRowSelectedModal = this.rowIdSelected;
            this.showImgModal();
        },
        getPreviousBlock() {
            console.log(`getPreviousBlock. minStartDateTime: ${this.minStartDateTime}, maxStartDateTime: ${this.maxStartDateTime}`);
            this.blockStartDateTime = this.minStartDateTime; // Start block with min of current block
            this.blockDirection = 'Desc' // In descendent order
            this.getNewBlock(); 
        },
        getNextBlock() {
            console.log(`getNextBlock. minStartDateTime: ${this.minStartDateTime}, maxStartDateTime: ${this.maxStartDateTime}`);
            this.blockStartDateTime = this.maxStartDateTime + 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'; // Start block with max of current block +FFFFFFF to skip last image
            this.blockDirection = 'Asc' // In descendent order
            this.getNewBlock(); 
        },
        openLabelSelectedImages() {
            console.log(`openLabelSelectedImages`);
            // Check that any dataset has been selected
            if (this.datasetIdSelected === null) {
                alert(`To be able to label images you need to select first the Data Set`);
                return;
            }
            this.labelImagesModalShow = true;
        },
        labelSelectedImages(labelData) {
            console.log(`labelSelectedImages. LabelData: ${JSON.stringify(labelData)}`);
            this.labelImagesModalShow = false;
            // Call API to label images
            const originator = 'HistoryEditor';
            const userId = this.userId;
            const datasetId = this.datasetIdSelected;
            const hstFilePath = appConfig.HistImgsBucketPrefix(this.customer, this.projectId);
            let imageIdList = [];
            for (let img of this.imagesInBlock) {
                if (img.selected) imageIdList.push(img.md.ImageId);
            }                
            const assignLabelsAdd = labelData.defectsSelectedAdd;
            const assignLabelsRemove = labelData.defectsSelectedRemove;
            const numImages = imageIdList.length;
            // Ask for confirmation
            if (numImages > 0) {
                if (confirm(`Do you want to label ${numImages} images?`)) {
                    this.loadingOverlay = {show: true, text: `Labeling ${numImages} images`};
                    labelMultipleImages(originator, userId, datasetId, hstFilePath, imageIdList, assignLabelsAdd, assignLabelsRemove, this.onLabelSelectedImagesResult);
                }
            } else {
                alert(`No images selected to label`);
            }
        },
        onLabelSelectedImagesResult(result) {
            console.log(`onLabelSelectedImagesResult: ${JSON.stringify(result)}`);
            this.loadingOverlay = {show: false};
            alert(result);
        },
        cancelLabelSelectedImages() {
            console.log(`cancelLabelSelectedImages`);
            this.labelImagesModalShow = false;
        },
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        //      Filters Functions
        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        initializeFilters () {
            console.log(`initializeFilters`);
            this.filter = {assgnLabel: {}, inferLabel: {}, action: {}, devName: {}};
            // Initialize assgnLabel
            this.filter.assgnLabel.$all = true;
            this.filter.assgnLabel.$unlabeled = true;
            this.filter.assgnLabel.$multilabeled = true;
            for (let defect of this.defects) this.filter.assgnLabel[defect] = true;
            // Initialize inferLabel // SF = 'SureFalse' DF = 'DoubtFalse' DT = 'DoubtTrue' ST = 'SureTrue' DA = 'DoubtAll'
            this.filter.inferLabel.$predefinedOptions = [{id: 'selectable', name: 'Selectable'}, {id: 'noLabel', name: 'Inconsistency: No label'}, {id: 'okAndDefect', name: 'Inconsistency: Ok and defect simultaneously'}]
            this.filter.inferLabel.$predefinedSelected = 'selectable'
            this.filter.inferLabel.$all = true;
            this.filter.inferLabel.$allSF = true;
            this.filter.inferLabel.$allDF = true;
            this.filter.inferLabel.$allDT = true;
            this.filter.inferLabel.$allST = true;
            this.filter.inferLabel.$singlelabeled = true;
            this.filter.inferLabel.$multilabeled = true;
            for (let defect of this.defects) {
                this.filter.inferLabel[defect] = {};
                this.filter.inferLabel[defect].$all = true;
                this.filter.inferLabel[defect].SF = true;
                this.filter.inferLabel[defect].DF = true;
                this.filter.inferLabel[defect].DT = true;
                this.filter.inferLabel[defect].ST = true;
                this.filter.inferLabel[defect].$asList = ['SF', 'DF', 'DT', 'ST'];
            }            
            // Initializa Action
            this.filter.action.$triggered = true;
            this.filter.action.$notTriggered = true;
            // Initialize Device
            this.filter.devName.$all = true;
            for (let devName of this.imgDevNames) this.filter.devName[devName] = true;
            // Store as lastAppliedFilter
            this.lastAppliedFilter = JSON.parse(JSON.stringify(this.filter)); // Clone filter
            console.log(`LAST FILTER: ${JSON.stringify(this.lastAppliedFilter)}`);

        },
        redefineDevicesInFilter() {
            console.log(`redefineDevicesInFilter`);
            // Add mising devices to filter
            for (let devName of this.imgDevNames) 
                if (this.filter.devName[devName] === undefined) this.filter.devName[devName] = true; // Add as True (not filtered) if not there
            // Remove unseen devices from filter
            for (let devName in this.filter.devName) {
                if (devName !== '$all' && !this.imgDevNames.includes(devName)) {
                    console.log(`Removing device filter: ${devName}`);
                    delete this.filter.devName[devName]; // Remove from filter
                }
            }
            console.log(`redefineDevicesInFilter: ${JSON.stringify(this.filter)}`);
        },
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        //      Image Modal Window Functions
        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // showImgModal () {
        //     console.log(`Showing Image modal window`);
        //     this.imgModalIsShow = true;
        //     this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
        // },
        // closeImgModal () {
        //     console.log(`Closing Image modal window. Row Selected: ${this.rowIdSelected0}, Column: ${this.colIdSelected}`);
        //     this.imgModalIsShow = false;
        //     this.helpInfo = 'Click on any image to enlarge';
        //     //window.scroll(0, this.imgHeight * this.rowIdSelected + 100);
        // },
        onKeyUpInImgModal (event) {
            console.log('onKeyUpInImgModal');
            if (this.rowIdSelected > 0 || this.colIdSelected > 0) {
                if (this.colIdSelected > 0) this.colIdSelected --; // Decrease column
                else { // Row exausted --> decrease onne row
                    this.rowIdSelected --;
                    this.colIdSelected = this.numColumns-1;
                    window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
                }               
            }
            event.stopPropagation();
            event.preventDefault();
        },
        onKeyDownInImgModal (event) {
            console.log('onKeyDownInImgModal');
            let lastRowNumColumns = this.numImagesFiltered % this.numColumns;
            if (lastRowNumColumns == 0) lastRowNumColumns = this.numColumns;
            console.log(`lastRowNumColumns: ${lastRowNumColumns}`)
            if (this.rowIdSelected < this.camRowList.length-1 || this.colIdSelected < lastRowNumColumns-1) {
                if (this.colIdSelected < this.numColumns-1) this.colIdSelected ++;
                else {
                    this.rowIdSelected ++;
                    this.colIdSelected = 0;
                    window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
                }
            }
            event.stopPropagation();
            event.preventDefault();
        },
        // onKeyRightInImgModal () {
        //     console.log('onKeyRightInImgModal');
        //     this.imgHeightInModal = this.imgHeightInModal * 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal * 1.2;
        // },
        // onKeyLeftInImgModal () {
        //     console.log('onKeyLeftInImgModal');
        //     this.imgHeightInModal = this.imgHeightInModal / 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal / 1.2;
        // },
        showImgModal() {
            console.log(`showImgModal`);
            this.imgModalIsShow = true;
            // this.modalPos = pos;
            // this.modalImgClass = {...imgClass}; // Clone imgClass to get static image
            document.getElementById("ImgModal").style.display = "block";
            // Start WZoom object
            setTimeout(() => {                
                this.modalWZoom = WZoom.create('#zoompan', {
                    type: 'html',
                    speed: 5,
                    minScale: 1,
                    maxScale: 10,
                    zoomOnClick: false,
                    zoomOnDblClick: true,
                });
            }, 10);
        },
        closeModal() {
            console.log(`closeModal`);
            this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
            this.imgModalIsShow = false;
            this.rowIdSelected = null;
            this.colIdSelected = null;
            document.getElementById("ImgModal").style.display = "none";
            this.countSelectedImages();
        },
        onScroll() {
            console.log(`onScroll`);
            // Recreate modalWZoom if needed
            if (this.imgModalIsShow && this.modalWZoom) {
                this.modalWZoom.prepare();
            }
        },
        onWsConnected() {
            console.log(`onWsConnected`);
            let customeAuthorized = this.isCustomerAuthorized(this.customer);
            if (customeAuthorized) {
                console.log(`Customer authorized: ${this.customer}`);                
                this.onUserAuthorized();
            } else { // Redirect to login
                console.log(`Customer not authorized: ${this.customer}`);
                this.loadingOverlay.show = false;
                // Redirect to login page
                window.location.href = '/login';
            }
        },
        onUserAuthorized() {
            console.log(`onUserAuthorized`);
            this.loadingOverlay.show = false;
            // this.refreshDatasetsData(() => {
            //     // Get initial data set
            //     console.log(`Getting first block`);
            //     this.getNewBlock();
            // });
            this.refreshDatasetsData();
            // Get initial data set
            console.log(`Getting first block`);
            this.getNewBlock();
        },
    },
    computed: {   
        userId: function () { return store.state.login.email },
        projectData: function () { if (store.state.projects.projectData) return store.state.projects.projectData[this.projectId]},
        defects: function() { if (this.projectData) {return this.projectData.Defects}},
        defectsWithDetection: function() { if (this.projectData) {return this.projectData.DefectsWithDetection || []}},
        numCamRows: function () { return this.camRowList.length; },
        // origImgWidth: function() { if (this.projectData) {return this.projectData.RoiConfig.Width} else return 3200;},
        // origImgHeight: function() { if (this.projectData) {return this.projectData.RoiConfig.Height} else return 800;},
        // TODO: fix this
        // origImgWidth: function() { return 3200;},
        // origImgHeight: function() { return 800;},
        blockImages: function () { return store.state.projects.historyBlock[this.projectId] }, 
        minStartDateTimeAsDateStr: function () { if (this.minStartDateTime) return relativeDateTime(getDateFromShortStr(this.minStartDateTime), true) },
        maxStartDateTimeAsDateStr: function () { if (this.maxStartDateTime) return relativeDateTime(getDateFromShortStr(this.maxStartDateTime), true) },
        scrollMaxY: function () { return Math.max( document.body.scrollHeight, document.body.offsetHeight, 
                   document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );},
        infoTooltip: function () {  
            if (this.defects && this.defects.length > 0) {
                let text = `Num. Images: ${this.numImagesInBlock}\nInferred label distribution:`;
                for (let defect of this.defects) {
                    let numLabels = (this.labelCounter[defect] || 0);
                    text += `\nNum ${defect}: ${numLabels} (${(100 * numLabels/this.numImagesInBlock).toFixed(1)}%)`;                    
                }
                return text;  
            } else return 'Inferred label distribution';   
        },
        decryptionPassword: function () { if (this.projectId) return store.state.projects.passwordPerProject[this.projectId] },
        anyModalShowing: function () {return this.filterModalShow || this.sortModalShow }, 
        ...mapGetters('login', ['isCustomerAuthorized']),
    },
    // Lifecycle hooks
    mounted() {
        console.log(`History Editor View Mounting for project: ${this.projectId}, starting at: ${this.startDateTime}`);
        // Connect to AWS signalling server through Web Socket to get authorization data
        this.loadingOverlay = {show: true, text: 'Authorizing...'};
        connectWs(this.onWsConnected);
        this.helpInfo = 'Select a dataset';
        this.showBackButton = this.$router.options.history.state.back != null ? true : false;
        this.projectName = getProjectNameFromProjectId(this.projectId);

        // Set date picker date to startDateTime
        this.date = setDateFromShortStr(this.date, this.startDateTime);
                
        // // Get datasets of this project
        // this.refreshDatasetsData();
    
        document.title = `History-${this.projectName}`; // Set Page title
        
        window.addEventListener("resize", this.onWindowResize);        
          
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Dataset Editor page being visible');
                
            } else { //Hiden
                console.log('Dataset Editor page being hidden');                
            }
        };
        // Prevent scroll being driven by keys
        let thisis = this;
        this.handleKeyDown = (event) => { 
            console.log('Key Pressed: ' + event.code);           
            console.log(`Image Modal visibility: ${thisis.imgModalIsShow}`);
            if (thisis.imgModalIsShow) {
                if (event.code === "ArrowUp") thisis.onKeyUpInImgModal(event);
                else if (event.code === "ArrowDown") thisis.onKeyDownInImgModal(event);
                // else if (event.code === "ArrowRight") thisis.onKeyRightInImgModal(event);
                // else if (event.code === "ArrowLeft") thisis.onKeyLeftInImgModal(event);
                else if(event.code === "Escape") this.closeModal();    // Close modal windows if 'Escape' key is pressed
            } else if (thisis.filterModalShow) { // Filter modal open
                if (event.code === "Enter") this.onApplyFilter();
                else if (event.code === "Escape") this.onCancelFilter();      
            } else if (thisis.sortModalShow) { // Sort modal open
                if (event.code === "Enter") this.onApplySort();
                else if (event.code === "Escape") this.onCancelSort();                          
            } else { // No modal window shown
                if (event.code === "ArrowRight") this.onKeyRight(event);
                else if (event.code === "ArrowLeft") this.onKeyLeft(event);
            }     
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        // Start getting data
        this.cleanFiltersSorts = true; // force cleand filters and sort
        this.blockStartDateTime = this.startDateTime; // First block start from startDateTime
        // this.getNewBlock();
        // Scroll event
        addEventListener("scroll", this.onScroll);
    },
    unmounted() {
        console.log('History Editor View Unmounted')
        window.removeEventListener("resize", this.onWindowResize);       
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("keydown", this.handleKeyDown, false); 
        removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
    },   
})
export default class DatasetEditor extends Vue {}
</script>

<style>

</style>

<style scoped>
    .scroller {
    height: 100%;
    }

    .cam_row {
    display: flex;
    align-items: center;
    }

    .hide {
        display: none;
    }

    .filterModalContent {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }

    .sortModalContent {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }

    .selectImagesModalContent {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }

    /* button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }} */

    .imgModal {
        z-index: 2000;
    }

    /* Center down content */
    .center-down {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

</style>



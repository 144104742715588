// Store module for Login related info

const state = {
    cognitoInfo: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
    email: '',
    clientId: '', // Unique (random) id gnerated when user log in
    clientPublicIp: null, // Public Ip set from AWS signalling server
    authorization: {}, // Authorization info from AWS signalling server
    authorizedCustomer: null, // Customer info from AWS signalling server through Authorization
    regDevId: null, // Registered device id

    portalMenuSelected: 'PIPGlobal', // Selected menu item in portal
    rootMenuSelected: 'Customers', // Selected menu item in root
}

const getters = {   
    isAuthorized: state => {
        return state.authorization && state.authorization.UserAuthorized;
    },
    authorizedCustomer: state => {
        return state.authorizedCustomer;
    },
    isCustomerAuthorized: state => (customer) => {
        return state.authorization && state.authorization.UserAuthorized && state.authorization.Customer === customer;
    }
}

const actions = {    
}

const mutations = {
    setLoggedIn(state, newValue) { state.loggedIn = newValue },
    setLoggedOut(state) { state.loggedIn = false;  state.cognitoInfo = {} },
    setCognitoInfo(state, newValue) { 
        console.log(`setCognitoInfo. email: ${state.email}, New Email: ${newValue.email}`);
        console.log(`New Cognito Info: ${JSON.stringify(newValue)}`);
        state.cognitoInfo = newValue;
        // Recreate client ID if new user
        if (state.email !== newValue.email) {
            this.commit('login/createClientId', 12);
        }
        state.email = newValue.email;        
    },
    createClientId(state, length) {
        var clientId = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for ( var i = 0; i < length; i++ ) {
            clientId += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        console.log(`Client Id created: ${clientId}`);
        state.clientId = clientId;
    },
    setClientPublicIp(state, newValue) {
        console.log(`New ClientPublicIp Info: ${JSON.stringify(newValue)}`);
        state.clientPublicIp = newValue;
    },
    setPortalMenuSelected(state, newValue) {
        console.log(`New PortalMenuSelected Info: ${JSON.stringify(newValue)}`);
        state.portalMenuSelected = newValue;
    },
    setRootMenuSelected(state, newValue) {
        console.log(`New RootMenuSelected Info: ${JSON.stringify(newValue)}`);
        state.rootMenuSelected = newValue;
    },
    setAuthorization(state, newValue) {
        console.log(`New setAuthorization Info: ${JSON.stringify(newValue)}`);
        state.authorization = {...newValue}; // Copy object
        delete state.authorization.Type; // Delete Type property
        state.authorizedCustomer = state.authorization.Customer; // Get Customer info
    },
    setRegDevId(state, newValue) {
        console.log(`New setRegDevId Info: ${JSON.stringify(newValue)}`);
        state.regDevId = newValue;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


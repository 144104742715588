// Created by SPe on 21/01/22
// Library to unify communication pipes (WebSocket and WebRtc) for sending
import { connect as connectWs, doKeepWsConnected, sendWsMessageToClient, sendWsMessage, registerWsMessageCallBack } from '@/library/websocket'
import { connect as connectWrtc, close as closeWrtc, sendWRtcMessage, isDeviceWrtconnected } from '@/library/webrtc'
import { sendSioMessage, isDeviceSioConnected } from '@/library/socketio'
import store from '@/store/index.js';
//import { unifiedProcessMessage } from '@/library/client-unified-receive';

const MAX_PONG_TIME = 40 * 1000; // Maximum time to wait for a pong response from device before request to disconnect
const MAX_NUM_PINGS = 40; // Maximum number of pings to send to device before request to disconnect

const unifiedChannelCallBacks = {}; // Object to store callback functions for each device

// Open an unified channel with a device (both WebSocket through Back-End and directly WebRTC)
export function openUnifiedChannel(devId, onConnectedCB=null, onDataCB=null, onWRtcConnectedCB=null, openWRtc=true) {

    // Add callback functions to unifiedChannelCallBacks
    unifiedChannelCallBacks[devId] = {onConnectedCB, onDataCB, onWRtcConnectedCB, openWRtc};

    ///////////////////////////////// Web Socket Call Back Functions //////////////////////////////////////////////
    // onSetAuthorizationCallBack
    function onSetDevicesInfoCallBack() {
        console.log(`openUnifiedChannel.onSetDevicesInfoCallBack`);
        // Connect to device through WebRTC if openWRtc is true
        let openWRtc = unifiedChannelCallBacks[devId].openWRtc; // Get openWRtc from unifiedChannelCallBacks
        if (openWRtc) connectWrtc(devId, onWrtcConnected, onWrtcDataReceived, onWrtcClosed);
        // Call onConnectedCB if any
        let onConnectedCB = unifiedChannelCallBacks[devId].onConnectedCB; // Get onConnectedCB from unifiedChannelCallBacks
        if (onConnectedCB) onConnectedCB(devId);
    }
    // On WebSocket channel connected
    function onWsConnected() {
        let authorized = store.getters['login/isAuthorized'];
        console.log(`openUnifiedChannel. Web Socket connected. User is authorized: ${authorized}`);
        if (authorized) {
            console.log(`User is authorized. Sending GetDevicesInfoForUser`);
            doKeepWsConnected(); // Ensure Websocket keep connected if disconnected
            const getDevicesInfoForUserData = {ClientId: store.state.login.clientId, UserId: store.state.login.email}
            sendWsMessage('GetDevicesInfoForUser', getDevicesInfoForUserData);
        } else {
            console.log(`User is not authorized. Sending GetDevicesInfoForClient`);
            // Redirect to login page
            window.location.href = '/login';
        }
    }
    // On Websocket channel closed
    function onWsClosed() {
        console.log(`openUnifiedChannel. WebSocket closed`);
    }
    // On WebSocket data received
    function onWsDataReceived(data) {
        console.log(`openUnifiedChannel. WebSocket data received: ${JSON.stringify(data).substring(0, 100)}...`);
        // Process as unified message from device
        let devId = data.DevId;
        let msgType = data.Type;
        let payLoad = data.PayLoad;
        onDataReceived('WebSocket', devId, msgType, payLoad); 
    }
    ///////////////////////////////// WebRTC Call Back Functions //////////////////////////////////////////////
    // On WebRTC channel connected
    function onWrtcConnected() {
        console.log(`openUnifiedChannel. WebRTC connected with device: ${devId}`);
        // Call onWRtcConnectedCB if any
        let onWRtcConnectedCB = unifiedChannelCallBacks[devId].onWRtcConnectedCB; // Get onWRtcConnectedCB from unifiedChannelCallBacks
        if (onWRtcConnectedCB) onWRtcConnectedCB(devId);
    }
    // On WebRTC channel closed
    function onWrtcClosed() {
        console.log(`openUnifiedChannel. WebRTC closed`);
    }
    // On WebRTC data received
    function onWrtcDataReceived(devId, msgType, payLoad) {
        if (msgType !== 'pong') console.log(`openUnifiedChannel. WebRTC data received from Device: ${devId} with Message Type: ${msgType} and payLoad: ${JSON.stringify(payLoad).substring(0, 100)}...`);
        // Process as unified message from device
        onDataReceived('WebRTC', devId, msgType, payLoad); 
    }
    ///////////////////////////////// Unified  Data Received //////////////////////////////////////////////
    function onDataReceived(channel, devId, msgType, payLoad) {
        if (msgType !== 'pong') {
            if (payLoad) console.log(`openUnifiedChannel. Data received through channel: ${channel} from Device: ${devId} with Message Type: ${msgType} and payLoad: ${JSON.stringify(payLoad).substring(0, 100)}...`);
            else console.log(`openUnifiedChannel. Data received through channel: ${channel} from Device: ${devId} with Message Type: ${msgType} and payLoad: ${JSON.stringify(payLoad)}`)
        }
        // Process as unified message from device
        //unifiedProcessMessage('ANY', devId, msgType, payLoad); 
        // Call onDataCB if any
        let onDataCB = unifiedChannelCallBacks[devId] ? unifiedChannelCallBacks[devId].onDataCB: null; // Get onDataCB from unifiedChannelCallBacks
        if (onDataCB) onDataCB(msgType, payLoad);
    }
    ///////////////////////////////// Initiate connection //////////////////////////////////////////////
    // Register callback for SetDevicesInfo message
    registerWsMessageCallBack('SetDevicesInfo', onSetDevicesInfoCallBack);
    
    connectWs(onWsConnected, onWsDataReceived, onWsClosed); // Connect to Back-End with WebSocket
}

// Close WebRTC Channel
// And set the callbacks
export function closeChannel(channel, devId, delayed=0, onConnectedCB=null, onDataCB=null, onWRtcConnectedCB=null, openWRtc=true) {
    // Set the callbacks
    unifiedChannelCallBacks[devId] = {onConnectedCB, onDataCB, onWRtcConnectedCB, openWRtc};
    // Close the channel
    console.log(`closeWrtc. Closing ${channel} channel connection with Device: ${devId}. Delayed: ${delayed} seconds`);
    if (channel === 'WebRTC') closeWrtc(devId, delayed);
    else console.log(`closeWrtc. Error Closing ${channel} channel connection with Device: ${devId}. Can not close this channel`);
}
// Unified (WebSocket and WebRTC) send message function
// if channel specified --> use that channel.
// Othyerwise priority to WebRTC pipe
export function sendMessageUnified(devId, msgType, payLoad, channel=null) {
    
    if (channel === 'WebRTC') sendWRtcMessage(devId, msgType, payLoad);
    else if (channel === 'SocketIO') {
        if (isDeviceSioConnected(devId)) sendSioMessage(devId, msgType, payLoad);
        else console.error(`sendMessageUnified error. Device: ${devId} is not Sio connected`);
    }
    else if (channel === 'WebSocket') sendWsMessageToClient(devId, msgType, payLoad);
    else { // Channel not specified
        let result = false;
        // // First try to send via SocketIO channel
        if (isDeviceSioConnected(devId)) result = sendSioMessage(devId, msgType, payLoad);
        // If not Sio connected or fails --> try to send via WebRTC channel or WebSocket
        if ( ! result) {
            if (isDeviceWrtconnected(devId)) {
                console.log(`Device: ${devId} is WebRTC connected`);
                result = sendWRtcMessage(devId, msgType, payLoad);
            }
            // If not WebRTC connected or fails --> try to send via WebSocket
            if ( ! result) sendWsMessageToClient(devId, msgType, payLoad);
        }
    }
}

// Unified (WebSocket and WebRTC) joinRoom message function
export function joinRoomUnified(devId, room, channel=null) {
    console.log(`Sending Join Room message to device: ${devId}, Room: ${room}`);
    let message = {room: room};
    sendMessageUnified(devId, 'joinRoom', message, channel);
}

// Unified (WebSocket and WebRTC) leaveRoom message function
export function leaveRoomUnified(devId, room, channel=null) {
    console.log(`Sending Leave Room message to device: ${devId}, Room: ${room}`);
    let message = {room: room};
    sendMessageUnified(devId, 'leaveRoom', message, channel);
}

// Check that a device is connected.
// It sends a ping message through websocket and waits for a pong response
// If the device is not connected, it reuqets to backend to remove connection
export function checkDeviceConnection(devId) {
    console.log(`checkDeviceConnection. Checking connection with device: ${devId}`);
    // Send ping message through websocket
    for (let i=0; i<MAX_NUM_PINGS; i++) setTimeout(() => {
                                                        let lastWsPong = store.getters['devices/getLastWsPong'](devId);
                                                        let now = new Date().getTime() || 0;
                                                        let timeSinceLastPong = now - lastWsPong;
                                                        if (timeSinceLastPong < MAX_PONG_TIME) {
                                                            console.log(`checkDeviceConnection. Device: ${devId} is connected. No need to send more pings`);
                                                            return;
                                                        }
                                                        sendWsMessageToClient(devId, 'ping', null);
                                                    }, i * MAX_PONG_TIME/(MAX_NUM_PINGS+1));
    // Wait for pong response
    setTimeout(() => {
        let lastWsPong = store.getters['devices/getLastWsPong'](devId);
        let now = new Date().getTime() || 0;
        let timeSinceLastPong = now - lastWsPong;
        console.log(`checkDeviceConnection. Time since last pong from device: ${devId} is: ${timeSinceLastPong} ms. Last Pong: ${lastWsPong}, Now: ${now}`);
        if (timeSinceLastPong > MAX_PONG_TIME) {
            // Device is not connected
            console.log(`checkDeviceConnection. Device: ${devId} is not connected. Requesting to backend to remove connection`);
            // Request to backend to remove connection
            let message = {ClientId: store.state.login.clientId, UserId: store.state.login.email, DevId: devId};
            sendWsMessage('DisconnectDevice', message);
        }
    }, MAX_PONG_TIME);
}

<!--
  Created by SPe on 20/07/2022
 -->
<template>
  <div class="ProjectSummary">
    <h1 class="font-bold">{{projectName}}</h1>
    <h2>Defects:</h2>
    <div class="">
      <p class="rounded-md px-2 mx-2 float-left" v-for="(defect, defectIndex) of defects" :key="defect" :style="[{'backgroundColor': labelColors[defectIndex]}]">
        {{defect}} 
        <i v-if="defectsWithDetection && defectsWithDetection.includes(defect)" class="bi bi-aspect-ratio mx-1"></i>
      </p>
    </div>
    <div class="clear-both py-2"></div>
    <div class="grid sm:grid-cols-1 xl:grid-cols-1"> 
      <!-- <p class="text-xs text-left">Camera: {{projectData.CamConfig.Name + ' -- RoI: ' + projectData.RoiConfig.Width + ' X ' +projectData.RoiConfig.Height }}</p> -->
      <p class="text-xs text-left">Created by: {{projectData.Creator + ' @ ' + relativeDateTime(projectData.CreaDate, true)}}</p>
      <p class="text-xs text-left">Shared with:</p>
      <div>
        <p v-for="user in sharedUsers" :key="user" class="text-xs rounded-md px-2 mx-2 float-left bg-gray-400">{{user}}</p>
      </div>      
    </div>
    <hr class="mt-2">
    <div class="pt-3">
      <i class="bi bi-pencil-square text-3xl px-3 float-none border-r-2 border-gray-400 cursor-pointer" @click="openProject" title="Edit Project"></i>
      <i class="bi bi-share text-3xl px-3 float-none border-r-2 border-gray-400 cursor-pointer" @click="shareProject" title="Share Project"></i>
      <i class="bi bi-x-square text-3xl px-3 float-none cursor-pointer" @click="removeProject" title="Remove Project"></i>
    </div>     

    <!-- Modal Window for Share Project Form -->
    <Modal
        v-model="shareProjectModalShow"
        :close="closeShareProjectModal"
        ref="modal"
    >
      <ShareProjectForm @newSharedUser="newUserSubmitted"></ShareProjectForm>
        
    </Modal>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import store from '@/store/index.js';
import '@/assets/css/global.css';
import { relativeDateTime } from '@/library/utils'
import { getProjectsOfUser, removeProject, shareProject } from '@/library/http-api-client'; 
import ShareProjectForm from '@/components/ShareProjectForm.vue';
//import { formatDateTimeForHistory } from '@/library/utils.js';

@Options({
  components: {
    ShareProjectForm
  },
  props: {
    projectData: Object,
  },
  data: function(){
      return {
          // labelColors: ['green', 'orange', 'firebrick', 'goldenrod', 'hotpink', 'mediumslateblue', 'navajowhite', 'peru', 'rosybrown', 'silver', 'thistle', 'skyblue', 'sandybrown', 'midnightblue', 'mediumvioletred'],
          labelColors: ['#55a868', '#4c72b0', '#dd8452', '#c44e52', '#8172b3', '#937860', '#da8bc3', '#8c8c8c', '#ccb974', '#64b5cd', '#4c72b0', '#dd8452', '#55a868', '#c44e52', '#8172b3', '#937860'],
          projectId: this.projectData.ProjectId,
          projectName: this.projectData.ProjectName,
          defects: this.projectData.Defects,
          defectsWithDetection: this.projectData.DefectsWithDetection,
          sharedUsers: this.projectData.SharedUsers,
          shareProjectModalShow: false,
      }
  },
  methods: {
    openProject() {
      console.log(`Opening page for Project Id: ${this.projectId}`);
      this.$router.push({path: `/projecteditor/${this.projectId}`});
    },

    removeProject() {
      console.log(`Remove project Id: ${this.projectId}`);
      let text = `Do you really want to Remove Project: ${this.projectName}?\nAll History Images, Datasets and Engines will be removed!. Action can not be recovered.`;
      if (confirm(text) == true) {
          console.log(`Removing Project: ${this.projectId}`)
          this.loadingOverlay = {show: true, text: 'Removing Project'};
          removeProject(this.userId, this.projectId, () => getProjectsOfUser(this.userId));
      } else {
          console.log(`Remove Project was cancelled`)
      }      
    },
    shareProject() {
      console.log(`Share Project: ${this.projectId}`);
      this.shareProjectModalShow = true; 
    },
    closeShareProjectModal() {
      console.log(`closeShareProjectModal`);
      this.shareProjectModalShow = false;
    },
    newUserSubmitted(userData) {
      console.log(`newUserSubmitted: ${JSON.stringify(userData)}`);
      this.shareProjectModalShow = false;
      // Send request to BackEnd
      let sharedUserId = userData['UserId'];
      shareProject(this.userId, this.projectId, sharedUserId, this.OnSharedProjectResult);
    },
    OnSharedProjectResult(result) {
      console.log(`OnSharedProjectResult: ${JSON.stringify(result)}`);
      if (result && result.startsWith('Error')) { // Errror result  
        alert(result);
      }
    },
    relativeDateTime(TimeStampMs, seconds) {
        return relativeDateTime(TimeStampMs, seconds);
    },
  },
  computed: {
    userId: function () { return store.state.login.email },
  },
})
export default class ProjectSummary extends Vue {
  msg
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ProjectSummary {
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  margin-top: 10px;
  border-radius: 20px;
  border-width:2px;
  border-style:solid;
  border-color: rgb(9, 159, 204);
  background-color: rgb(148, 197, 212);
}

button {
  margin: 10px 10px
}


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

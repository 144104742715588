// Created by SPe on 31/01/2023
// component for Entering Password
<template>
  <div class="root">
    <h2>Enter Password for {{entityType}}:<span class="font-bold text-indigo-400">{{' ' + entityName}}</span></h2>
    <!-- User Id -->
    <div class="flex">
        <h4 class="mt-3 px-2">Password:</h4>
        <input class="" :type="passwdInputType" id="password" placeholder="MySecretPassword" v-model="password" :class="{error: !validateInput(password)}"/>
        <i class="bi bi-eye font-bold mt-3 ml-2 cursor-pointer" @click="showPassword=!showPassword"></i>
    </div>
    <small class="bg-yellow-200 rounded-md px-3 py-2 my-2">Insert the password used to encrypt project images. The password will not be stored in this device or transmitted to any other system. It will be removed when browser or tab are closed.</small>
    <div v-if="!validateInput(password)" class="bg-yellow-200 p-2"><small style="color:blue">Inser the password used to encrypt images</small></div>
    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            password: '', // Password
            showPassword: false, 
            handleKeyDown: null,
        }
    },
    props: {
        entityType: {type: String, default: null},
        entityName: {type: String, default: null}, 
    },
    methods: {
        submitForm() {
            console.log('Submit Form');
            // Check validation
            // Valid Name and at least one defect
            if (this.validateInput(this.password)) {
                // Send event
                this.$emit("newPasswordSubmitted", 
                    {Password: this.password}
                );
                console.log('Form successfully submitted.');
        
            } else {
                console.log('Form failed validation'); 
                this.errorMsg='Form Data not valid. Review it and re-submit'           
            }
        },
        cancel() {
            this.$emit("newPasswordSubmitted", null);
        },       
        validateInput() {
            return true;
        }
    },
    computed: {    
        passwdInputType() { if (this.showPassword) { return 'text'} else {return 'password'}}
    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Enter Password Form Mounting'); 

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Enter") this.submitForm();
            else if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        
    },
    unmounted() {
        console.log('Enter Password Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class ShareProjectForm extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 35em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>



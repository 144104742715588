// Created by SPe on 21/07/22
// Page to view and manage project

<template>
  <div class="Project">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <div class="min-h-screen flex flex-col">
      <NavBar :servConnected="servConnected" />

      <div class="flex-1 flex flex-col sm:flex-row">
        <!-- Main content -->
        <main class="flex-1 bg-gray-200">
          <div class="p-2 my-2 mx-4 border-2 rounded-md border-gray-500 bg-gray-300">
            <h1>Project Editor: {{projectName}}</h1>
            <div class="">
              <p class="rounded-md px-2 mx-2 float-left" v-for="(defect, defectIndex) of defects" :key="defect" :style="[{'backgroundColor': labelColors[defectIndex]}]">
                {{defect}}
                <i v-if="defectsWithDetection && defectsWithDetection.includes(defect)" class="bi bi-aspect-ratio mx-1"></i>
              </p>
            </div>
            <div class="clear-both py-2"></div>
            <div class="grid sm:grid-cols-1 xl:grid-cols-1"> 
              <p class="text-xs text-left"><b>Created by: </b>{{projectCreator + ' @ ' + relativeDateTime(projectCreationDate, true)}}</p>
              <p class="text-xs text-left"><b>Shared with:</b></p>
              <div>
                <p v-for="user in projectSharedUsers" :key="user" class="text-xs rounded-md px-2 mx-2 float-left bg-gray-400">{{user}}</p>
              </div>   
            </div>
          </div>
          <hr class="my-2 mx-3">
          <!-- Datasets -->
          <div v-if="showDatasets" class="">
            <p v-if="datasetMsg" class="notificationBanner">{{datasetMsg}}</p> 
            <div class="p-2 my- mx-4 border-2 rounded-md border-gray-500 bg-gray-300">
              <h1 class="inline-block my-2">Datasets</h1>
              <i class="bi bi-arrow-clockwise inline-block border-2  border-gray-600 px-2 mx-3 float-right my-2 cursor-pointer"  title="Refresh Datasets" @click="refreshDatasetsData()"></i>
              <p class="border-2 bg-yellow-500 border-gray-600 px2 px-2 mx-2 float-right my-2 cursor-pointer" @click="createDataset" title="Create New Dataset">Create New Dataset</p>
              <DataTable showGridlines stripedRows :value="trainableDatasetList" tableStyle="min-width: 50rem margin-left:auto;margin-right:auto;">
                <Column field="DatasetName" header="Dataset" :sortable="true" :style="{width: '12rem'}"></Column> <!-- Dataset Column -->
                <Column field="Creator" header="Creator" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Creator Column -->
                <Column field="CreaDate" header="Time" :sortable="true" :style="{width: '10rem'}"> 
                  <template #body="slotProps">
                    {{relativeDateTime(slotProps.data.CreaDate, true)}}
                  </template>
                </Column> <!-- Time Column -->
                <Column field="NImgs" header="Images" :sortable="true" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span :title="datasetInfo(slotProps.data)" @click="getEngineLog(slotProps.data.ValTaskId);" class="cursor-pointer">{{slotProps.data.NImgs}}</span>
                  </template>
                </Column> <!-- Images Column -->
                <Column field="Validation" header="Report" :sortable="true" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.Validation" :title="slotProps.data.Validation.State" @click="slotProps.data.Validation.State=='Ready' && getHtmlNotebook(slotProps.data.ValTaskId, 'Report')" class="font-bold" :class="validationClass(slotProps.data.Validation.State)">{{slotProps.data.Validation.EngineVer}}</span>
                  </template>
                </Column> <!-- Report Column -->
                <Column field="Comment" header="Comment" :sortable="false" :style="{width: '2rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <i class="bi bi-chat-right-text text-2xl px-0 float-none cursor-pointer" @click="openDatasetComment(slotProps.data)" title="Comment"></i>
                  </template>
                </Column> <!--  Comment column -->
                <Column field="Actions" header="Actions" :sortable="false" :style="{width: '18rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <img class="icon h-6 float-left pr-2 cursor-pointer" :src="require('@/assets/pics/rename.png')" @click="renameDataset(slotProps.data)" title="Rename Dataset"/>
                    <img class="icon h-6 float-left pr-2 cursor-pointer" :src="require('@/assets/pics/duplicate.png')" @click="duplicateDataset(slotProps.data)" title="Duplicate Dataset"/>
                    <img class="icon h-6 float-left pr-2 cursor-pointer" :src="require('@/assets/pics/split.png')" @click="splitDataset(slotProps.data)" title="Split Dataset"/>
                    <img class="icon h-6 float-left pr-2 cursor-pointer" :src="require('@/assets/pics/validate.png')" @click="validateDataset(slotProps.data)" title="Validate Dataset"/>
                    <i class="bi bi-pencil-square text-2xl px-2 float-none cursor-pointer" @click="openDataset(slotProps.data.DatasetId)" title="Edit Dataset"></i>
                    <i class="bi bi-x-square text-2xl px-0 float-none cursor-pointer" @click="removeDataset(slotProps.data.DatasetId, slotProps.data.DatasetName)" title="Remove Dataset"></i>
                  </template>
                </Column> <!--  Actions column -->
              </DataTable>
            </div>            
          </div>
          <!-- Engines -->
          <div v-if="showEngines" class="">
            <p v-if="engineMsg" class="notificationBanner">{{engineMsg}}</p> 
            <div class="p-2 my- mx-4 border-2 rounded-md border-gray-500 bg-gray-300">
              <h1 class="inline-block my-2">Engines</h1>
              <p class="border-2 bg-yellow-500 border-gray-600 px2 px-2 mx-2 float-right my-2 cursor-pointer" @click="trainEngine" title="Train New Engine">Train New Engine</p>
              <i class="bi bi-arrow-clockwise inline-block border-2  border-gray-600 px-2 mx-3 float-right my-2 cursor-pointer"  title="Refresh Datasets" @click="refreshEnginesData()"></i>                      
              <i class="bi bi-key inline-block border-2  border-gray-600 px-2 mx-3 float-right my-2 cursor-pointer" @click="getProjectTempToken(projectId)" title="Get Temporary Token"></i>
              <DataTable showGridlines stripedRows :value="engineList" tableStyle="min-width: 50rem margin-left:auto;margin-right:auto;">
                <Column field="EngineVer" header="Version" :sortable="true" :style="{width: '2rem'}"></Column> <!-- Version Column -->
                <!-- <Column field="Creator" header="Creator" :sortable="true" :style="{width: '5rem'}"></Column> --> <!-- Creator Column -->
                <!-- <Column field="CreaDate" header="Time" :sortable="true" :style="{width: '10rem'}"> 
                  <template #body="slotProps">
                    {{relativeDateTime(slotProps.data.CreaDate, true)}}
                  </template>
                </Column> --> <!-- Time Column -->
                <Column field="Datasets" header="Datasets" :sortable="false" :style="{width: '20rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span>{{ datasetInTable(slotProps.data) }}</span>
                  </template>
                </Column> <!-- Datasets Column -->
                <Column field="CoreModel" header="Core Model" :sortable="true" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span>{{slotProps.data.ModelGuiName}}</span>
                  </template>
                </Column> <!-- Core Model Column -->
                <Column field="Base" header="Base" :sortable="true" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span>{{slotProps.data.BaseEngineVersion}}</span>
                  </template>
                </Column> <!-- Base Column -->
                <Column field="InputSize" header="Input Size" :sortable="false" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span>{{slotProps.data.NIS? slotProps.data.NIS.Width : ''}}{{slotProps.data.NIS && slotProps.data.NIS.Height? 'X': ''}}{{slotProps.data.NIS? slotProps.data.NIS.Height: ''}}</span>
                  </template>
                </Column> <!-- Input Size Column -->
                <Column field="Images" header="Images" :sortable="true" :style="{width: '4rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span :title="engineDatasetsSummary(slotProps.data)" @click="getEngineLog(slotProps.data.TaskId);" class="cursor-pointer">{{slotProps.data.NumTotalImgs > 0? slotProps.data.NumTotalImgs: ''}}</span>
                  </template>
                </Column> <!-- Images Column -->
                <Column field="State" header="State" :sortable="false" :style="{width: '4rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <p @click="slotProps.data.State && getHtmlNotebook(slotProps.data.TaskId, 'Report');" :class="validationClass(slotProps.data.State)" class="font-bold">{{slotProps.data.State}}</p>
                    <i v-if="slotProps.data.TrainMetrics"
                      class="bi bi-info-circle text-xs px-1" 
                      :title="trainMetricsInfo(slotProps.data)" 
                      @click="infoModalShow=true; infoTitle=getProjectNameFromProjectId(slotProps.data.ProjectId) + ' - ' + slotProps.data.EngineVer; infoContent=trainMetricsInfo(slotProps.data)"></i>
                  </template>
                </Column> <!-- State Column -->
                <Column field="Comment" header="Comment" :sortable="false" :style="{width: '2rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span><i @click="openEngineComment(slotProps.data)" :title="'Comment:\n' + slotProps.data.Comment" class="bi bi-chat-right-text text-2xl px-0 float-none cursor-pointer"></i></span>
                  </template>
                </Column> <!--  Comment column -->
                <Column field="Actions" header="Actions" :style="{width: '10rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <i class="bi bi-stop-circle text-2xl px-2 float-none cursor-pointer" @click="stopEngine(slotProps.data)" title="Stop training Engine"></i>
                    <i class="bi bi-x-square text-2xl px-2 float-none cursor-pointer" @click="removeEngine(slotProps.data)" title="Remove Engine"></i>
                    <i class="bi bi-key text-2xl border-2  border-gray-600 px-2 float-none cursor-pointer" @click="getEngineTempToken(slotProps.data)" title="Get Temporary Token"></i>
                  </template> <!-- Actions column -->
                </Column>             
              </DataTable>
            </div>
          </div>
          <!-- Machines -->
          <div v-if="showMachines" class="">
            <p v-if="machineMsg" class="notificationBanner">{{machineMsg}}</p> 
            <div class="p-2 my- mx-4 border-2 rounded-md border-gray-500 bg-gray-300">
              <h1 class="inline-block my-2">Machines</h1>
              <p class="border-2 bg-yellow-500 border-gray-600 px2 px-2 mx-2 float-right my-2 cursor-pointer" @click="createMachine" title="Add New MAchine">Add New Machine</p>
              <i class="bi bi-arrow-clockwise inline-block border-2  border-gray-600 px-2 mx-3 float-right my-2 cursor-pointer"  title="Refresh Machines" @click="refreshMachinesData()"></i>                      
              <DataTable showGridlines stripedRows :value="machineList" tableStyle="min-width: 50rem margin-left:auto;margin-right:auto;">
                <Column field="MachineName" header="Name" :sortable="true" :style="{width: '2rem'}"></Column> <!-- Name Column -->
                <Column field="MachineIp" header="Ip" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Ip Column -->
                <Column field="MachineSSHPort" header="Port" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Port Column -->
                <Column field="MachineSSHUser" header="User" :sortable="true" :style="{width: '5rem'}"></Column> <!-- User Column -->
                <Column field="Comment" header="Comment" :sortable="false" :style="{width: '2rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <span><i @click="openMachineComment(slotProps.data)" :title="'Comment:\n' + slotProps.data.Comment" class="bi bi-chat-right-text text-2xl px-0 float-none cursor-pointer"></i></span>
                  </template>
                </Column> <!--  Comment column -->
                <Column field="Actions" header="Actions" :style="{width: '5rem'}" :body-style="{textAlign: 'center'}">
                  <template #body="slotProps">
                    <i class="bi bi-trash text-2xl px-2 float-none cursor-pointer" @click="emptyMachineQueue(slotProps.data)" title="Empty Machine Queue"></i>
                    <i class="bi bi-x-square text-2xl px-2 float-none cursor-pointer" @click="removeMachine(slotProps.data)" title="Remove Machine"></i>
                  </template> <!-- Actions column -->
                </Column>
              </DataTable>
            </div>
          </div>

          <!-- Devices -->
          <div v-if="showDevices" class="">
            <p v-if="deviceMsg" class="notificationBanner">{{deviceMsg}}</p> 
            <div class="p-2 my- mx-4 border-2 rounded-md border-gray-500 bg-gray-300">
              <h1 class="inline-block my-2">Devices</h1>
              <DataTable showGridlines stripedRows :value="pipDeviceForUserListInfo" tableStyle="min-width: 50rem margin-left:auto;margin-right:auto;">
                <Column field="Customer" header="Customer" :sortable="true" :style="{width: '2rem'}"></Column> <!-- Customer Column -->
                <Column field="Name" header="Name" :sortable="true" :style="{width: '10rem'}"></Column> <!-- Name Column -->
                <Column field="DevId" header="Device Id" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Device Id Column -->
                <Column field="PublicIp" header="Public Ip" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Public Ip Column -->
                <Column field="HostIp" header="Local Ip" :sortable="true" :style="{width: '5rem'}"></Column> <!-- Local Ip Column -->
                <Column field="SwRelease" header="SW Release" :sortable="true" :style="{width: '10rem'}"></Column> <!-- SW Release Column -->
                <!-- <Column field="ProjectName" header="Project" :sortable="true" :style="{width: '5rem'}"></Column> --> <!-- Project Column -->
                <!-- <Column field="NewProject" header="New Project" :sortable="true" :style="{width: '5rem'}"></Column> --> <!-- New Project Column -->
                <Column field="EngineId" header="Engine" :sortable="false" :style="{width: '5rem'}">
                  <template #body="slotProps">
                    <select @change="onNewEngineSelectedForDev($event, slotProps.data.DevId)" class="bg-gray-200 py-2">
                      <option :value="slotProps.data.EngineId">{{getProjectEngVer(slotProps.data.EngineId)}}</option>
                      <!-- <option v-if="engineReadyList.length === 0" :value="getEngineIdfromProjectIdNVersion(projectId, 'V0')">{{projectName + '-V0'}}</option> -->
                      <option v-for="eng in engineReadyList" :key="eng" :value="eng.EngineId">{{getProjectEngVer(eng.EngineId)}}</option>
                    </select>
                  </template>
                </Column> <!-- Engine Column -->
              </DataTable>
            </div>
          </div>
        </main>
        <!-- Left Side bar -->
        <nav class="order-first sm:w-32 md:w-40 bg-gray-100">
          <!-- MENU -->
          <ul class="text-left text-sm  ml-1 my-2">
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{editHistory();}" >
              <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/history.png')" />
              <span class="font-bold">History</span>
            </li>
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); showDatasets=true;}" :class="{'text-blue-600 font-bold' : showDatasets}">
              <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/dataset.png')" />
              <span class="font-bold">Datasets</span>
            </li>
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); showEngines=true;}" :class="{'text-blue-600 font-bold' : showEngines}">
              <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/engine.png')" />
              <span class="font-bold">Engines</span>
            </li>
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); showMachines=true;}" :class="{'text-blue-600 font-bold' : showMachines}">
              <i class="bi bi-pc-horizontal px-2"></i>
              <span class="font-bold">Machines</span>
            </li>
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); showDevices=true;}" :class="{'text-blue-600 font-bold' : showDevices}">
              <i class="bi bi-hdd-network px-2"></i>
              <span class="font-bold">Devices</span>
            </li>
            <hr class="my-2">
          </ul>
        </nav>
        <!-- Right Side bar -->
        <aside class="sm:w-48 bg-gray-100 px-2">
          <i class="bi bi-info-circle px-2"></i>
          <!-- Empty content -->
          <div class=" text-left" v-if="!showDatasets&&!showEngines&&!showDevices">            
            <h2 class="text-base font-bold">Project Editor</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">Manage this project: Create/Edit datasets, Train Engines and download Engines to devices.</p>
            <p class="text-sm py-2">Select in left menu:</p>
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/history.png')" />
            <span><b>History:</b> Browse project image history. Label new images and add them to datasets. History contain all stored images captured by devices during last few days assigned to this project.</span>
            <hr  class="mx-4 my-2">
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/dataset.png')" />
            <span><b>Datasets:</b> Create, rename, duplicate, validate, edit, remove datasets. Datasets contain labeled images for training and testing engines.</span>
            <hr  class="mx-4 my-2">
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/engine.png')" />
            <span><b>Engines:</b> Train, remove engines. Engine is a the AI brain that detect anomalies in captured images. Engines are trained with labeled images organized in datasets.</span>
            <hr  class="mx-4 my-2">
            <i class="bi bi-hdd-network px-2"></i>
            <span><b>Devices:</b> Download engines to devices. Devices are the physical devices deployed in the factory that capture and process through the engines the production images</span>
          </div>
          <!-- Datasets content -->
          <div class=" text-left" v-if="showDatasets">            
            <h2 class="text-base font-bold">Datasets</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">Manage datasets: Rename, duplicate, validate, edit, remove datasets.</p>
            <p class="text-sm py-2">Actions:</p>
            <i class="bi bi-arrow-clockwise border-2 border-gray-600 px-2 py-1 mr-3 my-2"></i>
            <span><b>Reload: </b> Reload all available Datasets.</span>
            <hr class="my-2">
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/rename.png')"/>
            <span><b>Rename:</b> Rename the Dataset.</span>
            <hr class="my-2">
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/duplicate.png')"/>
            <span><b>Duplicate: </b> Make a copy of the complete Dataset.</span>
            <hr class="my-2">
            <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/validate.png')"/>
            <span><b>Validate:</b> Using an specific Engine, make inference on all images in the Dataset. Before downloading an Engine to devices it can be tested on a dataset.</span>
            <hr class="my-2">
            <i class="bi bi-pencil-square text-2xl pr-2 float-none"></i>
            <span><b>Edit:</b> Open a Dataset edition windows. Any image con be reviewed, relabeled or removed. </span>
            <hr class="my-2">
            <i class="bi bi-x-square text-2xl pr-2 float-none"></i>
            <span><b>Remove</b></span>
          </div>
          <!-- Engines content -->
          <div class=" text-left" v-if="showEngines">            
            <h2 class="text-base font-bold">Engines</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">Manage engines: Train, stop, remove.</p>
            <p class="text-sm py-2">Actions:</p>
            <i class="bi bi-arrow-clockwise border-2 border-gray-600 px-2 py-1 mr-3 my-2"></i>
            <span><b>Reload</b></span>
            <hr class="my-2">
            <i class="bi bi-stop-circle text-2xl px-2 float-none"></i>                    
            <span><b>Stop training</b></span>
            <hr class="my-2">
            <i class="bi bi-x-square text-2xl px-2 float-none"></i>
            <span><b>Remove</b></span>            
          </div>
          <!-- Deivices content -->
          <div class=" text-left" v-if="showDevices">            
            <h2 class="text-base font-bold">Devices</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">Check and download engines to devices.</p>
            <p class="text-sm">Once the engine is "Ready", it can be enforced to devices.</p>
            <p class="text-sm">Note that the device will stop working during few minutes (aprox. 10 minutes) for the first device. For consecutives devices they will stop working for few seonds.</p>
          </div>
          <!-- Machines content -->
          <div class=" text-left" v-if="showMachines">            
            <h2 class="text-base font-bold">Machines</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">Create, remove training machines.</p>
            <p class="text-sm">Machines are linux computers with GPU(s) for Engine Training and validation</p>
          </div>
        </aside>
      </div>
      <!-- Footernbar -->
      <Footer />

      <!-- Modal Window for Create New Dataset Form -->
      <Modal
          v-model="createNewDatasetModalShow"
          ref="modal"
      >
        <NewDatasetForm @newDatasetSubmitted="newDatasetSubmitted"></NewDatasetForm>        
      </Modal>

      <!-- Modal Window for Rename Dataset Form -->
      <Modal
          v-model="renameDatasetModalShow"
          ref="modal"
      >
        <RenameDatasetForm @renameDatasetSubmitted="renameDatasetSubmitted"></RenameDatasetForm>        
      </Modal>

      <!-- Modal Window for Duplicate Dataset Form -->
      <Modal
          v-model="duplicateDatasetModalShow"
          ref="modal"
      >
        <DuplicateDatasetForm @duplicateDatasetSubmitted="duplicateDatasetSubmitted"></DuplicateDatasetForm>        
      </Modal>

      <!-- Modal Window for Split Dataset Form -->
      <Modal
          v-model="splitDatasetModalShow"
          ref="modal"
      >
        <SplitDatasetForm :baseDatasetName="lastDatasetClicked.DatasetName" @splitDatasetSubmitted="splitDatasetSubmitted"></SplitDatasetForm>        
      </Modal>

      <!-- Modal Window for Create New Engine Form -->
      <Modal
          v-model="createNewEngineModalShow"
          ref="modal"
      >
        <NewEngineForm 
          :defectList="defects"
          :defectsWithDetection="defectsWithDetection"
          :datasetList="trainableDatasetList"
          :engineVersionsPerModel="getEngineVersionsPerModel()"
          :latestReadyModelName="getLatestReadyModelName()"
          :latestReadyengineVersion="getLatestReadyengineVersion()"
          :latestReadyengineNis="getLatestReadyengineNis()"
          :machineList="machineList"
          @newEngineSubmitted="newEngineSubmitted"></NewEngineForm>        
      </Modal>

      <!-- Modal Window for Create New Machine Form -->
      <Modal
          v-model="createNewMachineModalShow"
          ref="modal"
      >
        <NewMachineForm 
          @newMachineSubmitted="newMachineSubmitted">
        </NewMachineForm>
      </Modal>

      <!-- Modal Window for Validate Dataset Form -->
      <Modal
          v-model="validateDatasetModalShow"
          ref="modal"
      >
        <ValidateDatasetForm 
          :availableEngines="engineReadyList" 
          :machineList="machineList"
          @validateDatasetSubmitted="validateDatasetSubmitted">
        </ValidateDatasetForm>        
      </Modal>

      <!-- Modal Window for Browse History Form -->
      <Modal
          v-model="editHistoryModalShow"
          ref="modal"
      >
        <EditHistoryForm @editHistorySubmitted="editHistorySubmitted"></EditHistoryForm>        
      </Modal>

      <!-- Modal Window for Dataset Comment Form -->
      <Modal
          v-model="datasetCommentModalShow"
          ref="modal"
      >
        <CommentForm :object_name="lastDatasetClicked.DatasetName" :comment="lastDatasetClicked.Comment || ''" @commentFormSubmitted="datasetCommentFormSubmitted"></CommentForm>        
      </Modal>

      <!-- Modal Window for Engine Comment Form -->
      <Modal
          v-model="engineCommentModalShow"
          ref="modal"
      >
        <CommentForm :object_name="getProjectNameFromProjectId(lastEngineClicked.ProjectId) + ' - ' + lastEngineClicked.EngineVer" :comment="lastEngineClicked.Comment || ''" @commentFormSubmitted="engineCommentFormSubmitted"></CommentForm>        
      </Modal>

      <!-- Modal Window for Machine Comment Form -->
      <Modal
          v-model="machineCommentModalShow"
          ref="modal"
      >
      <CommentForm :object_name="lastMachineClicked.MachineName" :comment="lastMachineClicked.Comment || ''" @commentFormSubmitted="machineCommentFormSubmitted"></CommentForm>        
      </Modal>
      
      <!-- Modal Window for Informatio Form -->
      <Modal
          v-model="infoModalShow"
          ref="modal"
      >
        <InfoForm :title="infoTitle" :content="infoContent" @infoFormSubmitted="infoModalShow=false"></InfoForm>        
      </Modal>

    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import { mapState } from 'vuex'
import ProjectSummary from '@/components/ProjectSummary.vue'; 
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import store from '@/store/index.js';
import auth from '@/library/auth'; 
import { formatDateTimeForHistory, getDatasetNameFromDatasetId, secondsToDhms } from '@/library/utils.js';
//import { connect as connectWs, doKeepWsConnected, dontKeepWsConnected, registerWsMessageCallBack, sendWsMessage } from '@/library/websocket.js';
import { connect as connectWs, doKeepWsConnected, dontKeepWsConnected, sendWsMessage, removeOnConnectedCB } from '@/library/websocket.js';
import { sendMessageUnified } from '@/library/client-unified-send';
import { relativeDateTime } from '@/library/utils'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';  
import NewDatasetForm from '@/components/NewDatasetForm.vue';
import RenameDatasetForm from '@/components/RenameDatasetForm.vue';
import DuplicateDatasetForm from '@/components/DuplicateDatasetForm.vue';
import SplitDatasetForm from '@/components/SplitDatasetForm.vue';
import NewEngineForm from '@/components/NewEngineForm.vue';
import ValidateDatasetForm from '@/components/ValidateDatasetForm.vue';
import EditHistoryForm from '@/components/EditHistoryForm.vue';
import CommentForm from '@/components/CommentForm.vue';
import InfoForm from '@/components/InfoForm.vue';
import NewMachineForm from '@/components/NewMachineForm.vue';
import { getProjectsOfUser, getDatasetsOfProject, getEnginesOfProject, createDataset, renameDataset, duplicateDataset, splitDataset, removeDataset, trainEngine, stopTrainEngine, removeEngine, getEngineTempToken, getProjectTempToken, setDatasetComment, setEngineComment, getTaskLogURL, getHtmlNotebookURL, validateDataset } from '@/library/http-api-client'; 
import { getMachinesOfProject, createMachine, removeMachine, emptyMachineQueue, setMachineComment } from '@/library/http-api-client';


@Options({
  components: {
    LoadingOverlay,
    NavBar,
    Footer,
    ProjectSummary,
    NewDatasetForm,
    RenameDatasetForm,
    DuplicateDatasetForm,
    SplitDatasetForm,
    ValidateDatasetForm,
    NewEngineForm,
    EditHistoryForm,
    CommentForm,
    InfoForm,
    NewMachineForm,
    DataTable,
    Column,
    Row,
  },
  data: function(){
    return {
      labelColors: ['#55a868', '#4c72b0', '#dd8452', '#c44e52', '#8172b3', '#937860', '#da8bc3', '#8c8c8c', '#ccb974', '#64b5cd', '#4c72b0', '#dd8452', '#55a868', '#c44e52', '#8172b3', '#937860'],
      projectId: this.$route.params.ProjectId,
      loadingOverlay: {show: false, text: 'Loading'},
      devicesMessage: null,
      jwtToken: auth.getJWTToken(),
      createNewDatasetModalShow: false,
      renameDatasetModalShow: false,
      duplicateDatasetModalShow: false,
      splitDatasetModalShow: false,
      createNewEngineModalShow: false,
      validateDatasetModalShow: false,
      createNewMachineModalShow: false,
      editHistoryModalShow: false,
      engineCommentModalShow: false,
      datasetCommentModalShow: false,
      machineCommentModalShow: false,
      newEngineSelected: null,
      lastDatasetClicked: null,  
      lastEngineClicked: null,  
      showDatasets: false,
      showEngines: false,
      showDevices: false,   
      showMachines: false,
      datasetMsg: null,
      engineMsg: null,
      deviceMsg: null,
      infoModalShow: false,
      infoTitle: null,
      infoContent: null,
    }
  },
  props: [],
  methods: {
    isMobile() {
      this.isMobile = window.innerWidth <= 768; // 768px is the bootstrap breakpoint for mobile
    },
    onProjectsData(projectsDataResult) {
      console.log(`onProjectsData: ${JSON.stringify(projectsDataResult)}`);
      this.loadingOverlay.show = false;
    },
    onDatasetsData(datasetsDataResult) {
      console.log(`onDatasetsData: ${JSON.stringify(datasetsDataResult)}`);
      this.loadingOverlay.show = false;
      this.datasetMsg = '';
    },
    onEnginesData(enginesDataResult) {
      console.log(`onEnginesData: ${JSON.stringify(enginesDataResult)}`);
      this.loadingOverlay.show = false;
      this.engineMsg = '';
    },
    onMachinesData(machinesDataResult) {
      console.log(`onMachinesData: ${JSON.stringify(machinesDataResult)}`);
      this.loadingOverlay.show = false;
      this.machineMsg = '';
    },
    onWsConnected() {
      let authorized = store.getters['login/isAuthorized'];
      console.log(`ProjectEditor. WebSocket connected. User is authorized: ${authorized}`);
      // If user is authorized, then get devices info for user
      if (authorized) {
        console.log(`User is authorized. Sending GetDevicesInfoForUser`);
        const getDevicesInfoForUserData = {ClientId: store.state.login.clientId, UserId: store.state.login.email}
        sendWsMessage('GetDevicesInfoForUser', getDevicesInfoForUserData);

        this.loadingOverlay = {show: true, text: 'Loading Project'};
        this.refreshProjectsData();
        this.refreshDatasetsData();
        this.refreshEnginesData();
        this.refreshMachinesData();

      } else { // User is not authorized
        console.log(`User is not authorized. Redirewcting to login page`);
        // Redirect to login page
        this.$router.push('/login');
      }
    },
    onWsDataReceived(data) {
      console.log(`WebSocket message received: ${JSON.stringify(data)}`);
      const msgType = data.Type;
      if (msgType === 'SetDevicesInfo') {
        this.devicesMessage = null;
      }
    },
    onWsClosed() {
      console.log(`WebSocket closed`);
    },
    cleanContent() {
      console.log(`cleanContent`);
      this.showDatasets= false;
      this.showEngines = false;
      this.showDevices = false;
      this.showMachines = false;
    },
    onPageVisible() {
      console.log(`Project page start being visible`);
      doKeepWsConnected();
    },
    onPageHidden() {
      console.log(`Project page start being hidden`);
      dontKeepWsConnected();
    },

    refreshProjectsData() {
      console.log(`refreshProjectsData`);
      this.loadingOverlay = {show: true, text: 'Loading Project'};
      // Get Project Data for the user
      getProjectsOfUser(this.userId, this.onProjectsData)
    },

    refreshDatasetsData() {
      console.log(`refreshDatasetsData`);
      //this.loadingOverlay = {show: true, text: 'Loading Datasets'};
      this.datasetMsg = 'Loading Datasets'
      // Get Datasets Data for the project
      getDatasetsOfProject(this.projectId, this.onDatasetsData)
    },

    refreshEnginesData() {
      console.log(`refreshEnginesData`);
      //this.loadingOverlay = {show: true, text: 'Loading Engines'};
      this.engineMsg = 'Loading Engines'
      // Get Engines Data for the project
      getEnginesOfProject(this.projectId, this.onEnginesData)
    },

    refreshMachinesData() {
      console.log(`refreshMachinesData`);
      //this.loadingOverlay = {show: true, text: 'Loading Machines'};
      this.machineMsg = 'Loading Machines'
      // Get Machines Data for the project
      getMachinesOfProject(this.projectId, this.onMachinesData)
    },

    createDataset() {
      console.log(`Create Dataset in Project Id: ${this.projectId}`);
      this.createNewDatasetModalShow = true;      
    },
    // closeCreateNewDatasetModal() {
    //   console.log(`closeCreateNewDatasetModal`);
    //   this.createNewDatasetModalShow = false;
    // },
    newDatasetSubmitted(datasetData) {
      this.createNewDatasetModalShow = false;
      if (datasetData) {
        console.log(`newDatasetSubmitted: ${JSON.stringify(datasetData)}`);        
        this.loadingOverlay = {show: true, text: 'Loading Datasets'};
        // Send request to BackEnd
        let datasetName = datasetData['DatasetName'];
        createDataset(this.userId, this.projectId, datasetName, this.defects, this.defectsWithDetection, false, this.OnCreateDatasetResult);
      }
    },
    OnCreateDatasetResult(result) {
      console.log(`OnCreateDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },
    validateDataset(dataset) {
      let datasetId = dataset['DatasetId']
      console.log(`validateDataset. Dataset Id: ${datasetId}`);
      this.lastDatasetClicked = dataset;
      this.validateDatasetModalShow = true;
    },
    validateDatasetSubmitted(formData) {
      this.validateDatasetModalShow = false;
      if (formData && formData.EngineId) {
        console.log(`validateDatasetSubmitted: ${JSON.stringify(formData)}`);        
        this.loadingOverlay = {show: true, text: 'Validating Dataset. It will take few minutes depending on number of images in dataset'};
        // Send request to BackEnd
        let datasetId = this.lastDatasetClicked.DatasetId;
        let engineId = formData.EngineId
        let machineId = formData.MachineId;
        let machineVendor = 'SSH'; // 'VAST', 'AWS' or 'SSH'
        if (machineId == '$AWS$') machineVendor = 'AWS';
        else if (machineId == '$VAST$') machineVendor = 'VAST';
        validateDataset(this.userId, engineId, datasetId, machineVendor, machineId, this.OnValidateDatasetResult);
      } else {
        console.error(`No valid data submitted`);
      }
    },
    OnValidateDatasetResult(result) {
      console.log(`OnValidateDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },
    engineCommentFormSubmitted(formData) {
      console.log(`engineCommentFormSubmitted: ${JSON.stringify(formData)}`);        
      this.engineCommentModalShow = false;
      if (formData && formData.Comment!==undefined && formData.Comment!== null) {
        this.loadingOverlay = {show: true, text: 'Adding comment to engine'};
        // Send request to BackEnd
        let engineId = this.lastEngineClicked.EngineId;
        let comment = formData.Comment;
        setEngineComment(this.userId, engineId, comment, this.OnSetCommentToEngineResult);
      } else {
        console.error(`No valid data submitted`);
      }
    },

    OnSetCommentToEngineResult(result) {
      console.log(`OnSetCommentToEngineResult: ${JSON.stringify(result)}`);
      this.refreshEnginesData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },

    datasetCommentFormSubmitted(formData) {
      console.log(`datasetCommentFormSubmitted: ${JSON.stringify(formData)}`);        
      this.datasetCommentModalShow = false;
      if (formData && formData.Comment !== undefined && formData.Comment !== null) {        
        this.loadingOverlay = {show: true, text: 'Adding comment to dataset'};
        // Send request to BackEnd
        let datasetId = this.lastDatasetClicked.DatasetId;
        let comment = formData.Comment;
        setDatasetComment(this.userId, datasetId, comment, this.OnSetCommentToDatasetResult);
      } else {
        console.error(`No valid data submitted`);
      }
    },

    OnSetCommentToDatasetResult(result) {
      console.log(`OnSetCommentToDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },

    machineCommentFormSubmitted(formData) {
      console.log(`machineCommentFormSubmitted: ${JSON.stringify(formData)}`);        
      this.machineCommentModalShow = false;
      if (formData && formData.Comment !== undefined && formData.Comment !== null) {        
        this.loadingOverlay = {show: true, text: 'Adding comment to machine'};
        // Send request to BackEnd
        let machineId = this.lastMachineClicked.MachineId;
        let comment = formData.Comment;
        setMachineComment(this.userId, machineId, comment, this.OnSetCommentToMachineResult);
      } else {
        console.error(`No valid data submitted`);
      }
    },

    OnSetCommentToMachineResult(result) {
      console.log(`OnSetCommentToMachineResult: ${JSON.stringify(result)}`);
      this.refreshMachinesData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },

    validationClass(state) {
      if (state === 'Ready') return "text-green-500 cursor-pointer"; // Ready
      else if (state === 'Failed') return "text-red-500"; // Failed
      else return "text-blue-500"; // Running
    },
    duplicateDataset(dataset) {
      let datasetId = dataset['DatasetId'];
      console.log(`duplicateDataset. Dataset Id: ${datasetId}`);
      this.lastDatasetClicked = dataset;
      this.duplicateDatasetModalShow = true;      
    },
    duplicateDatasetSubmitted(datasetData) {
      this.duplicateDatasetModalShow = false;
      if (datasetData) {
        console.log(`duplicateDatasetSubmitted: ${JSON.stringify(datasetData)}`);   
        let dstDatasetName = datasetData['DatasetName'];
        let datasetName = this.lastDatasetClicked.DatasetName;     
        this.loadingOverlay = {show: true, text: `Duplicating ${datasetName} Dataset into: ${datasetData['DatasetName']}`};
        // Send request to BackEnd        
        duplicateDataset(this.userId, this.projectId, datasetName, dstDatasetName, this.OnDuplicateDatasetResult);
      }
    },
    OnDuplicateDatasetResult(result) {
      console.log(`OnDuplicateDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },
    splitDataset(dataset) {
      let datasetId = dataset['DatasetId'];
      console.log(`splitDataset. Dataset Id: ${datasetId}`);
      this.lastDatasetClicked = dataset;
      this.splitDatasetModalShow = true;      
    },
    splitDatasetSubmitted(splitDatasetData) {
      this.splitDatasetModalShow = false;

      if (splitDatasetData) {
        console.log(`splitDatasetSubmitted: ${JSON.stringify(splitDatasetData)}`);   
        let trainDatasetName = splitDatasetData['TrainDatasetName'];
        let validateDatasetName = splitDatasetData['ValidateDatasetName'];   
        let validateDatasetFraction = splitDatasetData['ValidateDatasetFraction']; 
        let groupByDevice = splitDatasetData['GroupByDevice'];
        let groupByDay = splitDatasetData['GroupByDay'];
        let trainteDatasetFraction = 1 - validateDatasetFraction;
        let baseDatasetName = this.lastDatasetClicked.DatasetName;   
        this.loadingOverlay = {show: true, text: `Splitting ${baseDatasetName} Dataset into: ${trainDatasetName} (${(trainteDatasetFraction*100).toFixed(0)}%) and ${validateDatasetName} (${(validateDatasetFraction*100).toFixed(0)}%)`};
        // Send request to BackEnd        
        splitDataset(this.userId, this.projectId, baseDatasetName, trainDatasetName, validateDatasetName, validateDatasetFraction, groupByDevice, groupByDay, this.OnSplitDatasetResult);
      }
    },
    OnSplitDatasetResult(result) {
      console.log(`OnSplitDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      if (result && result.Status === 'Error') { // Errror result  
        alert(result.Msg);
      }
    },
    renameDataset(dataset) {
      let datasetId = dataset['DatasetId']
      console.log(`renameDataset. Dataset Id: ${datasetId}`);
      this.lastDatasetClicked = dataset;
      this.renameDatasetModalShow = true;
    },
    renameDatasetSubmitted(datasetData) {
      this.renameDatasetModalShow = false;
      if (datasetData) {
        console.log(`renameDatasetSubmitted: ${JSON.stringify(datasetData)}`);        
        this.loadingOverlay = {show: true, text: 'Renaming Dataset'};
        // Send request to BackEnd
        let dstDatasetName = datasetData['DatasetName'];
        let srcDatasetName = this.lastDatasetClicked.DatasetName;
        renameDataset(this.userId, this.projectId, srcDatasetName, dstDatasetName, this.OnRenameDatasetResult);
      }
    },
    OnRenameDatasetResult(result) {
      console.log(`OnRenameDatasetResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData()
      let resultAsStr = JSON.stringify(result);
      if (resultAsStr && resultAsStr.includes('Error')) { // Errror result  
        alert(result.Msg);
      }
    },
    trainEngine() {
      console.log(`Create Engine in Project Id: ${this.projectId}`);
      for (let ds of this.datasetList) ds.selected = false;
      this.createNewEngineModalShow = true; 
    }, 
    stopEngine(engine) {
      let version = engine.EngineVer;
      let engineId = engine.EngineId;
      let text = `Do you really want to stop trining Engine with version: ${version}?`;
      if (confirm(text) == true) {
          console.log(`Stopping Engine: ${engineId}`)
          this.loadingOverlay = {show: true, text: 'Stopping Engine'};
          stopTrainEngine(this.userId, this.projectId, engineId, this.refreshEnginesAndDatasets);
      } else {
          console.log(`Stopping Engine was cancelled`)
      }
    },
    removeEngine(engine) {
      let version = engine.EngineVer;
      let engineId = engine.EngineId;
      let text = `Do you really want to Remove Engine with version: ${version}?`;
      if (confirm(text) == true) {
          console.log(`Removing Engine: ${engineId}`)
          this.loadingOverlay = {show: true, text: 'Removing Engine'};
          removeEngine(this.userId, this.projectId, engineId, this.refreshEnginesAndDatasets);
      } else {
          console.log(`Remove Engine was cancelled`)
      }
    },  
    async getProjectTempToken(projectId) {
      this.loadingOverlay = {show: true, text: `Getting Token for Project: ${this.projectName}`};
      let result = await getProjectTempToken(this.userId, projectId);
      this.loadingOverlay = {show: false};
      console.log(`getProjectTempToken result: ${JSON.stringify(result)}`);
      let tempToken = result.Data.Token;
      let validTime = result.Data.ValidTime;
      let projectName = this.getProjectNameFromProjectId(projectId);
      prompt(`Here you have a temporary key for Project: ${projectName}. You can use it to assign this project to the device and start collecting images in history. It will be valid for ${validTime} seconds.`, `${tempToken}`);
    },
    async getEngineTempToken(engine) {
      let engineVer = engine.EngineVer;
      let engineId = engine.EngineId;
      let projectName = this.getProjectNameFromEngineId(engineId);
      this.loadingOverlay = {show: true, text: `Getting Token for Engine: ${projectName}-${engineVer}`};
      let result = await getEngineTempToken(this.userId, engineId);
      this.loadingOverlay = {show: false};
      console.log(`getEngineTempToken result: ${JSON.stringify(result)}`);
      let tempToken = result.Data.Token;
      let validTime = result.Data.ValidTime;
      prompt(`Here you have a temporary key for Engine: ${projectName}-${engineVer}. You can use it to download this engine in your devices. It will be valid for ${validTime} seconds.`, `${tempToken}`)
    },
    newEngineSubmitted(engineData) {
      // console.log(`newEngineSubmitted: ${JSON.stringify(engineData)}`);
      this.createNewEngineModalShow = false;
      if (engineData) {
        this.loadingOverlay = {show: true, text: 'Creating Engine (May take several minutes)'};
        console.log(`newEngineSubmitted: ${JSON.stringify(engineData)}`);        
        // Send request to BackEnd
        let baseDatasetIds = engineData['BaseDatasetIds'];
        let baseTrainDatasetIds = engineData['BaseTrainDatasetIds'];
        let baseValDatasetIds = engineData['BaseValDatasetIds'];
        let modelFrameWork = engineData['ModelFrameWork'];
        let modelName = engineData['ModelName'];
        let modelGuiName = engineData['ModelGuiName'];
        let modelPTName = engineData['ModelPTName'];
        let baseEngineVersion = engineData['BaseEngineVersion'];
        let numPretrainingImages = engineData['NumPretrainingImages'];
        let nis = engineData['NIS'];
        let encryptionPassword = engineData['EncryptionPassword'];        
        let machineId = engineData['MachineId'];
        let machineVendor = 'SSH'
        if (machineId == '$AWS$') machineVendor = 'AWS';
        else if (machineId == '$VAST$') machineVendor = 'VAST';
        if (baseDatasetIds.length > 0 || (baseTrainDatasetIds.length > 0 && baseValDatasetIds.length > 0)) trainEngine(this.userId, this.projectId, baseDatasetIds, baseTrainDatasetIds, baseValDatasetIds, modelFrameWork, modelName, modelGuiName, modelPTName, baseEngineVersion, numPretrainingImages, nis, encryptionPassword, machineVendor, machineId, this.OnTrainEngineResult);
        else {
          alert(`New Engine will not be trained. Missing datasets`);
          this.loadingOverlay = {show: false};
        }
      }
    },
    OnTrainEngineResult(result) {
      console.log(`OnTrainEngineResult: ${JSON.stringify(result)}`);
      this.refreshDatasetsData();
      this.refreshEnginesData();
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },
    removeDataset(datasetId, datasetName) {
      let text = `Do you really want to Remove Dataset: ${datasetName}?`;
      if (confirm(text) == true) {
          console.log(`Removing Dataset: ${datasetId}`)
          this.loadingOverlay = {show: true, text: 'Removing Dataset'};
          removeDataset(this.userId, this.projectId, datasetId, this.refreshDatasetsData)
      } else {
          console.log(`Remove Dataset was cancelled`)
      }
    },
    refreshEnginesAndDatasets() {
      this.refreshEnginesData();
      this.refreshDatasetsData();
    },    
    openDataset(datasetId) {
      console.log(`openDataset: ${datasetId}`);
      let path = `/dataset/${this.customer}/${datasetId}`;
      let routeData = this.$router.resolve({path: path});
      window.open(routeData.href, '_blank');
    },

    createMachine() {
      console.log(`Create Machine in Project Id: ${this.projectId}`);
      this.createNewMachineModalShow = true; 
    },

    removeMachine(machine) {
      let machineId = machine['MachineId']
      let machineName = machine['MachineName']
      let text = `Do you really want to Remove Machine: ${machineName}?`;
      if (confirm(text) == true) {
          console.log(`Removing Machine: ${machineId}`)
          this.loadingOverlay = {show: true, text: 'Removing Machine'};
          removeMachine(this.userId, this.projectId, machineId, this.refreshMachinesData)
      } else {
          console.log(`Remove Machine was cancelled`)
      }
    },

    emptyMachineQueue(machine) {
      let machineId = machine['MachineId']
      let machineName = machine['MachineName']
      let text = `Do you really want to Empty Queue of Machine: ${machineName}?`;
      if (confirm(text) == true) {
          console.log(`Emptying Queue of Machine: ${machineId}`)
          this.loadingOverlay = {show: true, text: 'Emptying Queue of Machine'};
          emptyMachineQueue(this.userId, this.projectId, machineId, this.refreshMachinesData)
      } else {
          console.log(`Empty Queue of Machine was cancelled`)
      }
    },

    newMachineSubmitted(machineData) {
      this.createNewMachineModalShow = false;
      if (machineData) {
        console.log(`newMachineSubmitted: ${JSON.stringify(machineData)}`);        
        this.loadingOverlay = {show: true, text: 'Creating Machine'};
        // Send request to BackEnd
        let machineName = machineData['MachineName'];
        let machineIp = machineData['MachineIp'];
        let machineSSHPort = machineData['MachineSSHPort'];
        let machineSSHUser = machineData['MachineSSHUser'];
        let machineSSHPrivateKey = machineData['MachineSSHPrivateKey'];
        createMachine(this.userId, this.projectId, machineName, machineIp, machineSSHPort, machineSSHUser, machineSSHPrivateKey, this.OnCreateMachineResult);
      }
    },

    OnCreateMachineResult(result) {
      console.log(`OnCreateMachineResult: ${JSON.stringify(result)}`);
      this.refreshMachinesData()
      if (result && JSON.stringify(result).includes('Error')) { // Errror result  
        alert(result);
      }
    },

    // setProjectToDevice(devId) {
    //   let devName = store.state.devices.deviceInfo[devId].Name
    //   console.log(`Setting ProjectName: ${this.projectName}, ProjectId: ${this.projectId} To Device: ${devId} with name: ${devName}`);
    //   let text = `Do you really want to Set Project: ${this.projectName} to Device: ${devName}?`;
    //   if (confirm(text) == true) {
    //     let newConfig = {
    //       'Neural.Project.Id' : this.projectId,
    //       'Neural.Project.Name': this.projectName,
    //       'Neural.Defects': this.defects,
    //       'Neural.Engine.Id': '', 'Neural.Model.Name':'',
    //       'Cameras.Bar.Capture.Width': this.projectData.CamConfig.Width,
    //       'Cameras.Bar.Capture.Height': this.projectData.CamConfig.Height,
    //       'Cameras.Bar.Capture.FPS': this.projectData.CamConfig.FPS,
    //       'Cameras.Bar.Capture.Format': this.projectData.CamConfig.Format,
    //       'Cameras.Bar.ROI.Width': this.projectData.RoiConfig.Width,
    //       'Cameras.Bar.ROI.Height': this.projectData.RoiConfig.Height,
    //       'System.Execute.Action': 'RestartApp'};
    //     sendMessageUnified(devId, 'setUserConfig', newConfig, 'WebSocket');
    //   } else {
    //       console.log(`Set project was cancelled`)
    //   }
    // },

    editHistory() {
      console.log(`Browse History in Project Id: ${this.projectId}`);
      this.editHistoryModalShow = true; 
    },

    editHistorySubmitted(formData) {
      this.editHistoryModalShow = false;
      if (formData && formData.Date) {
        console.log(`editHistorySubmitted: ${JSON.stringify(formData)}`);        
        // Open EditHistory page
        let startDateTime = formatDateTimeForHistory(formData.Date);
        let routeData = this.$router.resolve({path: `/historyeditor/${this.customer}/${this.projectId}/${startDateTime}`});
        window.open(routeData.href, '_blank');
        
      } else {
        console.error(`No valid data submitted`);
      }
    },    

    openHistory(devId) {
      console.log(`openHistory of device: ${devId}`);
      let deviceInfo = store.state.devices.deviceInfo[devId]
      let devName = deviceInfo.Name
      let prjId = deviceInfo.ProjectId
      let now = new Date();
      now.setHours(now.getHours() - 24*365*10); // Since beggining
      let startDateTime = formatDateTimeForHistory(now);
      let allDevices = this.allDevIds(devId).join('+');
      console.log(`Opening history V2 for device: ${devId}. All Devices: ${allDevices} with name: ${devName} starting: ${startDateTime}`);
      let routeData = this.$router.resolve({path: `/history/${this.customer}/${prjId}/${devName}/${allDevices}/${startDateTime}`});
      window.open(routeData.href, '_blank');
    },

    allDevIds(devId) {
      let devIds = [devId]
      let deviceInfo = store.state.devices.deviceInfo[devId]
      if (deviceInfo.GUIInfo.ClusterRole === 'Master') devIds = devIds.concat(deviceInfo.GUIInfo.ClusterSlaveIDs);
      return devIds;
    },

    onDrop(acceptFiles, rejectReasons) {
      alert('On drop')
      console.log('onDrop')
      console.log(acceptFiles);
      console.log(rejectReasons);
    },

    datasetInfo(datasetData) {
      let numImages = datasetData.NImgs;
      let text = `Num. Images: ${numImages}`;
      text += `\nNum. Labeled: ${datasetData.NLbs} (${(datasetData.NLbs/numImages*100).toFixed(1)}%)`;
      for (let defect of datasetData.Defects) {
          let varName = `N${defect}`;
          text += `\nNum ${defect}: ${datasetData[varName]} (${(datasetData[varName]/numImages*100).toFixed(1)}%)`;               
      }
      return text;
    },

    trainMetricsInfo(engine) {
      if (!engine.TrainMetrics) return '';

      let text = `Train Metrics:\n`;
      if (engine.TrainMetrics.Epochs != undefined) text += `  Epochs: ${engine.TrainMetrics.Epochs}\n`;
      if (engine.TrainMetrics.TrainTime != undefined) text += `  Time: ${secondsToDhms(engine.TrainMetrics.TrainTime)}\n`;
      if (engine.TrainMetrics.Loss != undefined) text += `  Loss: ${engine.TrainMetrics.Loss.toFixed(5)}\n`;
      if (engine.TrainMetrics.AccImages != undefined) text += `  Image Accuracy: ${(engine.TrainMetrics.AccImages * 100).toFixed(2)} %\n`;
      if (engine.TrainMetrics.AccLabels != undefined) text += `  Label Accuracy: ${(engine.TrainMetrics.AccLabels * 100).toFixed(2)} %\n`;
      if (engine.TrainMetrics.AccDefects != undefined) text += `  Defects Accuracy: ${(engine.TrainMetrics.AccDefects * 100).toFixed(2)} %\n`;
      if (engine.TrainMetrics.ValLoss != undefined) text += `  Val Loss: ${engine.TrainMetrics.ValLoss.toFixed(5)}\n`;
      if (engine.TrainMetrics.ValAccImages != undefined) text += `  Val Image Accuracy: ${(engine.TrainMetrics.ValAccImages * 100).toFixed(2)} %\n`;
      if (engine.TrainMetrics.ValAccLabels != undefined) text += `  Val Label Accuracy: ${(engine.TrainMetrics.ValAccLabels * 100).toFixed(2)} %\n`;
      if (engine.TrainMetrics.ValAccDefects != undefined) text += `  Val Defects Accuracy: ${(engine.TrainMetrics.ValAccDefects * 100).toFixed(2)} %\n`;

      return text;
    },

    onNewEngineSelectedForDev(event, devId) {
      let devName = store.state.devices.deviceInfo[devId].Name
      let newEngineId = event.target.value
      let currentEngineId = store.state.devices.deviceInfo[devId].EngineId;
      console.log(`onNewEngineSelectedForDev. New Engine Id: ${newEngineId}, Current Engine Id: ${currentEngineId}, Device Id: ${devId}`);
      if (newEngineId !== currentEngineId) {
        let newVer = this.getVersionFromEngineId(newEngineId);
        let newProjectName = this.getProjectNameFromEngineId(newEngineId);
        let currVer = this.getVersionFromEngineId(currentEngineId);
        let currProjectName = this.getProjectNameFromEngineId(currentEngineId);
        let engineData = this.engineData(newEngineId);
        console.log(`EngineData: ${JSON.stringify(engineData)}`);
        let text = `Do you really want to change Engine Version from: ${currProjectName} - ${currVer} to: ${newProjectName} - ${newVer} for Device: ${devName}?\nThe device will restart and apply new Engine. This can take more than 10 minutes`;
        if (confirm(text) == true) {
            console.log(`Changing Engine Version from: ${currVer} to: ${newVer} for device: ${devId}. ProjectData: ${JSON.stringify(this.projectData)}`);
            // let engineData = getEngineData(newEngineId)
            // Send configuration to device and force restatrt app after that
            let newConfig = {
              'Neural.Project.Id': this.projectId,
              'Neural.Model.FrameWork': engineData.ModelFrameWork ? engineData.ModelFrameWork : 'NotYetDefined',
              'Neural.Project.Name': this.projectName,
              'Neural.Defects': this.defects,
              'Neural.DefectsWithDetection': this.defectsWithDetection || [],
              'Neural.Engine.Id': newEngineId, 
              'Neural.Engine.Version': newVer, 
              'Neural.Model.Name': engineData.ModelName ? engineData.ModelName : 'NotYetDefined',
              'Neural.Model.GuiName': engineData.ModelGuiName ? engineData.ModelGuiName : 'Not Yet Defined',
              'Neural.NIS.Width': engineData.NIS ? engineData.NIS.Width : 0,
              'Neural.NIS.Height': engineData.NIS ? engineData.NIS.Height : 0,
            };       
            // To restart app after config settings
            newConfig['System.Execute.Action'] = 'RestartApp';
            // let newConfig = {'Neural.Project.Id' : this.projectId, 'Neural.Project.Name': this.newProjectName,
            //   'Neural.Engine.Id': newEngineId, 'Neural.Model.Name': this.modelName, 'Neural.Defects': this.defects,
            //   'System.Execute.Action': 'RestartApp'};
            sendMessageUnified(devId, 'setUserConfig', newConfig, 'WebSocket');
            
        } else {
            console.log(`Changing Engine Version was cancelled`)
            event.target.value = store.state.devices.deviceInfo[devId].EngineId;
        }
      }
    },
    openEngineLog(data) {
      console.log(`openEngineLog: ${JSON.stringify(data)}`);
      let logUrl = data.presignedUrl;
      window.open(logUrl, '_blank');
      this.loadingOverlay = {show: false};
    },
    getEngineLog(taskId) {
      if (!taskId) return
      console.log(`Open Log of task: ${taskId}`);
      this.loadingOverlay = {show: true, text: 'Downloading Engine Log'};
      getTaskLogURL(this.userId, taskId, this.openEngineLog);
      
    },
    openHtmlNotebook(data) {
      console.log(`openHtmlNotebook: ${JSON.stringify(data)}`);
      let logUrl = data.presignedUrl;
      window.open(logUrl, '_blank');
      this.loadingOverlay = {show: false};
    },
    getHtmlNotebook(taskId, noteBookType='Complete') {
      if (!taskId) return
      console.log(`Get HTML notebook of engine task: ${taskId} of type: ${noteBookType}`);
      this.loadingOverlay = {show: true, text: 'Downloading Report'};
      getHtmlNotebookURL(this.userId, taskId, noteBookType, this.openHtmlNotebook);
      
    },
    openDatasetComment(dataset) {
      console.log(`openDatasetComment for dataset id: ${dataset.DatasetId}`);
      this.lastDatasetClicked = dataset;
      this.datasetCommentModalShow = true;
    },
    openEngineComment(engine) {
      console.log(`openEngineComment for engine id: ${engine.EngineId}`);
      this.lastEngineClicked = engine;
      this.engineCommentModalShow = true;
    },
    openMachineComment(machine) {
      console.log(`openMachineComment for machine: ${JSON.stringify(machine)}`);
      console.log(`openMachineComment for machine id: ${machine.MachineId}`);
      this.lastMachineClicked = machine;
      this.machineCommentModalShow = true;
    },
    getVersionFromEngineId(engineId) {
      if (engineId) return engineId.split('$ENG$')[1];
      else return '<None>';
    },
    getEngineIdfromProjectIdNVersion(projectId, version) {
      return projectId + '$ENG$' + version;
    },
    getProjectNameFromProjectId(projectId) {
      if (projectId) {
        let projectName = projectId.split('+')[0];
        return projectName
      } else return null;
    },
    getProjectNameFromEngineId(engineId) {
      if (engineId) {
        let projectId = engineId.split('$ENG$')[0]; 
        let projectName = projectId.split('+')[0];
        return projectName
      } else return null;
    },
    getProjectEngVer(engineId) {
      if (engineId) {
        // EngineId: <PrjId>$ENG$<version>
        let projectName = this.getProjectNameFromEngineId(engineId);
        let evgVersion = this.getVersionFromEngineId(engineId);
        //console.log(`projectEngV: ${projectName} ${evgVersion}`)
        console.log(`getProjectEngVer: ${engineId} ${projectName} ${evgVersion}`)
        return `${projectName} ${evgVersion}`;

      } else return '<None>';
    },
    devInfo(dev) {return store.state.devices.deviceInfo[dev]},
    engineData(engineId) {
      if (this.enginesReceived) {
        return store.state.projects.enginesOfProject[this.projectId]['Dict'][engineId];
      } else {
        return null;
      }
    },
    relativeDateTime(TimeStampMs, seconds) {
        return relativeDateTime(TimeStampMs, seconds);
    },
    datasetInTable(engine) {
      let out = ''
      if (engine.BaseDatasetIds && engine.BaseDatasetIds.length > 0) {
        let baseDatasetNames = engine.BaseDatasetIds.map(datasetId => getDatasetNameFromDatasetId(datasetId));
        out += 'All: ' + baseDatasetNames.join(', ');
      }
      if (engine.BaseTrainDatasetIds && engine.BaseTrainDatasetIds.length > 0) {
        let baseTrainDatasetNames = engine.BaseTrainDatasetIds.map(datasetId => getDatasetNameFromDatasetId(datasetId));
        out += 'Train: ' + baseTrainDatasetNames.join(', ').replace('[', '').replace(']', '');
      }
      if (engine.BaseValDatasetIds && engine.BaseValDatasetIds.length > 0) {
        let baseValDatasetNames = engine.BaseValDatasetIds.map(datasetId => getDatasetNameFromDatasetId(datasetId));
        out += ' - Val: ' + baseValDatasetNames.join(', ').replace('[', '').replace(']', '');
      }
      return out;
    },
    engineDatasetsSummary(engine) {
      let out = '';
      if (engine.NumImgs) {
        let totalTrain = engine.NumImgs.TrainNImgs;
        let totalVal = engine.NumImgs.ValNImgs;
        out += `\nTraining: ${totalTrain} (${(totalTrain/(totalTrain+totalVal)*100).toFixed(1)}%)`;
        for (let defect of this.defects) {
          if (engine.NumImgs.TrainNLabels && engine.NumImgs.TrainNLabels[defect]) out += `\n  ${defect}: ${engine.NumImgs.TrainNLabels[defect]} (${(engine.NumImgs.TrainNLabels[defect]/totalTrain*100).toFixed(1)}%)`;
        }
        out += `\nValidation: ${totalVal} (${(totalVal/(totalTrain+totalVal)*100).toFixed(1)}%)`;
        for (let defect of this.defects) {
          if (engine.NumImgs.ValNLabels && engine.NumImgs.ValNLabels[defect]) out += `\n  ${defect}: ${engine.NumImgs.ValNLabels[defect]} (${(engine.NumImgs.ValNLabels[defect]/totalVal*100).toFixed(1)}%)`;
        }   
      }     
      return out
    },
    getEngineVersionsPerModel() {
      let out = {}
      let readyEngines = this.engineReadyList
      for (let engine of readyEngines) {
        let modelName = engine.ModelName;
        let version = engine.EngineVer;
        if (modelName in out) out[modelName].push(version);
        else out[modelName] = [version];
      }
      return out;
    },
    getLatestReadyModelName() {
      let readyEngines = this.engineReadyList
      if (readyEngines && readyEngines.length > 0) {
        return readyEngines.slice(-1)[0].ModelName;
      } else return null;
    },
    getLatestReadyengineVersion() {
      let readyEngines = this.engineReadyList
      if (readyEngines && readyEngines.length > 0) {
        return readyEngines.slice(-1)[0].EngineVer;
      } else return null;
    },
    getLatestReadyengineNis() {
      let readyEngines = this.engineReadyList
      if (readyEngines && readyEngines.length > 0) {
        return readyEngines.slice(-1)[0].NIS;
      } else return null;
    },
  },
  computed: {
    ...mapState({
      servConnected: state => state.connection.servConnected, // Whether WebSocket to signalling server is conneced or not
    }),

    userId: function () { return store.state.login.email },
    customer: function () { return store.state.login.authorizedCustomer },
    projectData: function () { if (store.state.projects.projectData) return store.state.projects.projectData[this.projectId]},
    projectName: function() { if (this.projectData) {return this.projectData.ProjectName}},
    projectCreator: function() { if (this.projectData) {return this.projectData.Creator}},
    projectCreationDate: function() { if (this.projectData) {return this.projectData.CreaDate}},
    projectSharedUsers: function() { if (this.projectData) {return this.projectData.SharedUsers}},
    defects: function() { if (this.projectData) {return this.projectData.Defects}},
    defectsWithDetection: function() { if (this.projectData) {return this.projectData.DefectsWithDetection || []}},
    //modelName: function() { if (this.projectData) {return this.projectData.ModelName}},

    projectsReceived: function () { return store.state.projects != null && store.state.projects.projectList != null},
    datasetsReceived: function () { return store.state.projects != null && store.state.projects.datasetsOfProject != null},
    enginesReceived: function () { return store.state.projects != null && store.state.projects.enginesOfProject != null},
    devicesReceived: function () { return store.state.devices != null && store.state.devices.devicesForUserList != null}, 
    machinesReceived: function () { return store.state.projects != null && store.state.projects.machinesOfProject != null},
    datasetList: function() { if (this.datasetsReceived) {return store.state.projects.datasetsOfProject[this.projectId]} else {return []}},
    trainableDatasetList: function() { if (this.datasetList.length > 0) { return this.datasetList.filter(ds => ds.DatasetName[0]!='$')} else return []},
    engineList: function() { if (this.enginesReceived) {return store.state.projects.enginesOfProject[this.projectId]['List']} else {return []}},
    engineReadyList: function() { if (this.enginesReceived && store.state.projects.enginesOfProject[this.projectId]['List']) {return store.state.projects.enginesOfProject[this.projectId]['List'].filter(e => e.State === 'Ready')} else {return []}},
    machineList: function() { if (this.machinesReceived) {return store.state.projects.machinesOfProject[this.projectId]} else {return []}},
    allDeviceForUserList: function() {
      if (this.devicesReceived) return store.state.devices.devicesForUserList;
      else return [];
    },
    pipDeviceForUserList: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForUserList) {
          if (this.devInfo(dev).AppName === 'PAI' || this.devInfo(dev).AppName === 'PIP') list.push(dev); // BORRAR PAI part when all devices are updated
        }
        return list
      }
      else return [];
    },
    pipDeviceForUserListInfo: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForUserList) {
          if (this.devInfo(dev).AppName === 'PAI' || this.devInfo(dev).AppName === 'PIP') list.push(this.devInfo(dev)); // BORRAR PAI part when all devices are updated
        }
        return list
      }
      else return [];
    },
    
  },
  // Lifecycle hooks
  mounted() {
    console.log(`Project View Mounted for user Id: ${this.userId}, Project Id: ${this.projectId} and customer: ${this.customer}`);
    
    document.title = `AInspect Project`; // Set Page title
    // Open spinner overlay if not devices received yet
    // if (! this.projectsReceived) {
    //   this.loadingOverlay = {show: true, text: 'Loading Project'};
    //   // Refresh Projets Data
    //   this.refreshProjectsData();
    // }   
    // if (! this.datasetsReceived) {
    //   this.refreshDatasetsData();
    // }
    // if (! this.enginesReceived) {
    //   this.refreshEnginesData();
    // }

    // this.loadingOverlay = {show: true, text: 'Loading Project'};
    // this.refreshProjectsData();
    // this.refreshDatasetsData();
    // this.refreshEnginesData();
    // this.refreshMachinesData();

    // Page visibility control
    document.onvisibilitychange = () => {
        if (document.visibilityState === "visible") {
            this.onPageVisible();
        } else { //Hidden
            this.onPageHidden();
        }
    };
  
    // Connect to AWS signalling server through Web Socket
    if ( ! this.devicesReceived) {
      this.devicesMessage = 'Loading devices...';
      console.log(`devicesReceived: ${this.devicesReceived}`);
    }
    doKeepWsConnected();
    connectWs(this.onWsConnected, this.onWsDataReceived, this.onWsClosed);
  },
  unmounted() {
    console.log('Project View Unmounted');
    this.loadingOverlay.show = false;
    this.onPageHidden();
    removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
  },

})
export default class ProjectEditor extends Vue {}
</script>
<style scoped>
button {
    background-color: #22c713;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    color: white;
}
tr, th, td {
  @apply border border-gray-800 px-2 py-2
}


</style>




// Created by SPe on 07/04/2023
// component for Split Dataset Form
<template>
  <div class="root">
    <h2>Split Dataset</h2>
    <div class="bg-yellow-200 p-2"><small style="color:blue">Split the dataset into 2 subsets. Typically for Training and Validation</small></div>
    <!-- Training Dataset Name -->
    <h4>Training Dataset name:</h4>
    <!-- <label for="datasetName">Dataset name:</label><br> -->
    <input type="text" id="trainDatasetName" placeholder="Dataset Name" v-model="trainDatasetName" :class="{error: !validateInput(trainDatasetName)}"/>
    <div v-if="!validateInput(trainDatasetName)" class="bg-yellow-200 p-2"><small style="color:blue">Dataset name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed.</small></div>
    <!-- Validation Dataset Name -->
    <h4>Validation Dataset name:</h4>
    <!-- <label for="datasetName">Dataset name:</label><br> -->
    <input type="text" id="validateDatasetName" placeholder="Dataset Name" v-model="validateDatasetName" :class="{error: !validateInput(validateDatasetName)}"/>
    <div v-if="!validateInput(validateDatasetName)" class="bg-yellow-200 p-2"><small style="color:blue">Dataset name can only contains letters, numbers and ('-', '_') with maximun length of 24 characters. Spaces are not allowed.</small></div>
    <!-- Validation Dataset Fraction -->
    <h4>Validation Dataset fraction (%):</h4>
    <input type="number" min="1" max="99" onkeydown="return false" class="w-12" v-model="validateDatasetFraction"/>
    <h4>Group images before split</h4>
    <input type="checkbox" id="groupbydevice" name="groupbydevice"  v-model="groupByDevice" class="mx-2"><label for="groupbydevice">Group image by Device</label><br>
    <input type="checkbox" id="groupbyday" name="groupbyday"  v-model="groupByDay" class="mx-2"><label for="groupbyday">Group image by day</label><br>
    <small style="color:blue">Grouped images will be assign to only one set: Training or Validation.</small>
    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            trainDatasetName: this.baseDatasetName.replace('[', '').replace([']', '']) + '-Train', // Dataset Name          
            validateDatasetName: this.baseDatasetName + '-Val', // Dataset Name    
            validateDatasetFraction: 15,     
            groupByDevice: false,
            groupByDay: false, 
        }
    },
    props: {
        baseDatasetName: {type: String, default: 'Dataset'},
    },
    methods: {
        submitForm() {
            console.log('Submit Form');
            // Check validation
            // Valid dataset names
            if (this.validateInput(this.trainDatasetName) && this.validateInput(this.validateDatasetName)) {
                // Send event
                this.$emit("splitDatasetSubmitted", 
                    {   TrainDatasetName: this.trainDatasetName,
                        ValidateDatasetName: this.validateDatasetName,
                        ValidateDatasetFraction: this.validateDatasetFraction / 100,
                        GroupByDevice: this.groupByDevice,
                        GroupByDay: this.groupByDay,
                    }
                );
                console.log('Form successfully submitted.');
        
            } else {
                console.log('Form failed validation'); 
                this.errorMsg='Form Data not valid. Review it and re-submit'           
            }
        },
        cancel() {
            this.$emit("splitDatasetSubmitted", null);
        },       
        validateInput(inputValue) {
            if (inputValue) {
                // Regulatr expressión to check that string has length >= 1. Only letters and numbers and start with a letter
                let re = new RegExp("^[a-zA-Z][a-zA-Z0-9-_]*$");
                if (re.test(inputValue) && inputValue.length < 24) return true
                else return false;
            } else return false
        }
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Split Dataset Form Mounting'); 
        
    },
    unmounted() {
        console.log('Split Dataset Form Unmounted');
    },
        
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: red;
        background: #FDD;
    }
</style>



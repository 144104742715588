
// Store module for Devices related info

const state = {
    devicesForUserList: null, // List of devices in order
    devicesForPublicIpList: [], // List of devices in order
    allDevicesList: [], // All devices. devicesForUserList + devicesForPublicIpList
    deviceInfo: {}, // Dictionary with key=devId and value the device imformation
    inspectData: {}, // Dictionary with key=devId and value the latest collected inspection data
    imgView: {}, // Dictionary with key=devId and value an dict with key a camear pos and value the image view object
    logFilesInfo: [], // Log files info received from device
    deviceOperatingState: {}, // Dictionary with key=devId and value the operation state of the device. {hostMode: , alarm: , problemDetected: , State: }
    devicePanelInfo: {}, // Dictionary with key=devId and value the panel info of the device. {PanelInfo: }
    deviceIOStatus: {}, // Dictionary with key=devId and value the I/O status
    devInference: {}, // Dictionary with key=devId and value the dev_inference for this device
    imgClass: {}, // Dictionary with key=devId and value an dict with key a camear pos and value the image Classification object
    lastWsPong: {}, // Dictionary with key=devId and value the last time a pong was received from this device
}

const getters = {
    // Return the slaves devIds if any of a device
    getSlaveDevIds: (state) => (devId) => {
        let out = [];
        let deviceInfo = state.deviceInfo[devId];
        if (deviceInfo) {
            let GUIInfo = deviceInfo.GUIInfo;
            if (GUIInfo) {
                let clusterRole = GUIInfo.ClusterRole;
                if (clusterRole) {
                    if (clusterRole === 'Master') {
                        let clusterSlaveIDs = GUIInfo.ClusterSlaveIDs;
                        if (clusterSlaveIDs !== undefined) out = GUIInfo.ClusterSlaveIDs;
                        else console.error(`getSlaveDevIds error. No ClusterSlaveIDs found for device: ${devId}`);
                    } 
                } else console.error(`getSlaveDevIds error. No ClusterRole found for device: ${devId}`);
            } else console.error(`getSlaveDevIds error. No GUIInfo found for device: ${devId}`);
        } else console.error(`getSlaveDevIds error. No deviceInfo found for device: ${devId}`);
        return out;
    },
    // Return the device Name of a device
    getDevName: (state) => (devId) => {
        let out = [];
        let deviceInfo = state.deviceInfo[devId];
        if (deviceInfo) {
            let GUIInfo = deviceInfo.GUIInfo;
            if (GUIInfo) {
                out = GUIInfo.DeviceName;
            } else console.error(`getDevName error. No GUIInfo found for device: ${devId}`);
        } else console.error(`getDevName error. No deviceInfo found for device: ${devId}`);
        return out;
    },
    // Return the device Host ÌP of a device
    getDevIp: (state) => (devId) => {
        let out = [];
        let deviceInfo = state.deviceInfo[devId];
        if (deviceInfo) {
            let GUIInfo = deviceInfo.GUIInfo;
            if (GUIInfo) {
                out = GUIInfo.DeviceIp;
            } else console.error(`getDevIp error. No GUIInfo found for device: ${devId}`);
        } else console.error(`getDevIp error. No deviceInfo found for device: ${devId}`);
        return out;
    },
    // Return the last time a pong was received from this device
    getLastWsPong: (state) => (devId) => {
        let out = null;
        if (devId in state.lastWsPong) out = state.lastWsPong[devId];
        if (out) console.log(`getLastWsPong. Device: ${devId} Last Pong: ${out}`);
        else console.log(`getLastWsPong. Device: ${devId} No Pong received yet. state.lastWsPong: ${JSON.stringify(state.lastWsPong)}`);
        return out;
    },
    isDeviceLocal: (state) => (devId) => {
        let out = false;
        if (state.devicesForPublicIpList.includes(devId)) out = true;
        return out;
    }
}

const actions = {    
}

const mutations = {
    setDevicesInfo(state, data) {
        let devicesInfoList = data['DevicesInfo'];
        // Sort by Dev Name
        devicesInfoList.sort(function(a, b) {
            var keyA = a.Name;
            var keyB = b.Name;
            return keyA.localeCompare(keyB, undefined, {numeric: true, sensitivity: 'base'});
        });
        let devicesInfo = {};
        let devicesForUserList = [];
        let devicesForPublicIpList = []
        for (let devInfo of devicesInfoList) { 
            let devId = devInfo['DevId'];
            devicesInfo[devId] = devInfo;
            //console.log(`Dev Id: ${devId}`);
            if (data['DevicesForUser'].includes(devId)) devicesForUserList.push(devId);
            if (data['DevicesForPublicIp'].includes(devId)) devicesForPublicIpList.push(devId);
        }    
        state.deviceInfo = devicesInfo;
        state.devicesForUserList = devicesForUserList;
        state.devicesForPublicIpList = devicesForPublicIpList;
        state.allDevicesList = devicesForUserList.concat(devicesForPublicIpList);
        //console.log(`devicesForUserList: ${devicesForUserList}`);
    },
    // Add a device info to the store
    addDeviceInfo(state, data) {
        console.log(`Store.Device Adding Info: ${JSON.stringify(data)}`);
        // Update deviceInfo in devicesInfo. Add new device if not exist
        let deviceInfo = data['DevicesInfo'][0];
        let devId = deviceInfo['DevId'];
        console.log(`DevId: ${devId}`);
        if (! state.devicesForUserList) state.devicesForUserList = []; // Create empty list if not exist
        if (! state.devicesForPublicIpList) state.devicesForPublicIpList = []; // Create empty list if not exist
        // Add to state.deviceInfo if not exist
        if (! (devId in state.deviceInfo)) {
            state.deviceInfo[devId] = deviceInfo;
            state.devicesForUserList = state.devicesForUserList.concat(data['DevicesForUser']);
            state.devicesForPublicIpList = state.devicesForPublicIpList.concat(data['DevicesForPublicIp']);
        } else {
            // Update deviceInfo in state.deviceInfo
            state.deviceInfo[devId] = deviceInfo;
        }
        // Sort state.deviceInfo by Dev Name
        let devicesInfoList = Object.values(state.deviceInfo);
        devicesInfoList.sort(function(a, b) {
            var keyA = a.Name;
            var keyB = b.Name;
            return keyA.localeCompare(keyB, undefined, {numeric: true, sensitivity: 'base'});
        });
        let devicesInfo = {};
        let devicesForUserList = [];
        let devicesForPublicIpList = []
        for (let devInfo of devicesInfoList) { 
            let devId = devInfo['DevId'];
            devicesInfo[devId] = devInfo;
            //console.log(`Dev Id: ${devId}`);
            if (state.devicesForUserList.includes(devId)) devicesForUserList.push(devId);
            if (state.devicesForPublicIpList.includes(devId)) devicesForPublicIpList.push(devId);
        }
        state.deviceInfo = devicesInfo;
        state.devicesForUserList = devicesForUserList;
        state.devicesForPublicIpList = devicesForPublicIpList;
        state.allDevicesList = devicesForUserList.concat(devicesForPublicIpList);

        console.log(`devicesForUserList: ${state.devicesForUserList}`);
        console.log(`devicesForPublicIpList: ${state.devicesForPublicIpList}`);
        console.log(`allDevicesList: ${state.allDevicesList}`);


    },
    // Remove a device info from the store
    removeDeviceInfo(state, data) {
        console.log(`Store.Device Removing Info: ${JSON.stringify(data)}`);
        let devId = data['DevIdToRemove'];
        // Remove deviceInfo from devicesInfo
        delete state.deviceInfo[devId];
        // Remove from devicesForUserList if exist
        if (state.devicesForUserList && state.devicesForUserList.includes(devId)) {
            console.log(`Store.Device Removing from devicesForUserList: ${devId} in position: ${state.devicesForUserList.indexOf(devId)}`);
            state.devicesForUserList.splice(state.devicesForUserList.indexOf(devId), 1);
        } else console.log(`Store.Device Removing from devicesForUserList: ${devId} not found`);
        // Remove from devicesForPublicIpList if exist
        if (state.devicesForPublicIpList && state.devicesForPublicIpList.includes(devId)) {
            console.log(`Store.Device Removing from devicesForPublicIpList: ${devId} in position: ${state.devicesForPublicIpList.indexOf(devId)}`);
            state.devicesForPublicIpList.splice(state.devicesForPublicIpList.indexOf(devId), 1);
        } else console.log(`Store.Device Removing from devicesForPublicIpList: ${devId} not found`);
        // set allDevicesList = devicesForUserList + devicesForPublicIpList
        if (state.devicesForUserList && state.devicesForPublicIpList) {
            state.allDevicesList = state.devicesForUserList.concat(state.devicesForPublicIpList);
        }
        
    },
    setDeviceGUIInfo(state, guiInfo) {
        let devId = guiInfo.DeviceId;
        console.log(`Store.Device Adding to device: ${devId} GUI Info: ${JSON.stringify(guiInfo)}`)
        if (! state.deviceInfo[devId]) state.deviceInfo[devId] = {} // Create empty object if not exist
        state.deviceInfo[devId].GUIInfo = guiInfo // Fill GUIInfo

    },
    setInspectData(state, inspectData) {
        //console.log(`setInspectData: ${JSON.stringify(inspectData)}`);
        let devId = inspectData.DevId;
        state.inspectData[devId] = inspectData;

    },
    setImageView(state, imgView) {
        if (imgView) {
            let devId = imgView.dev_id;
            let pos = imgView.camera_pos;
            //console.log(`setImageView: ${JSON.stringify(imgView)}`);
            if (devId && pos !== undefined) {
                if ( ! (devId in state.imgView)) state.imgView[devId] = {};
                state.imgView[devId][pos] = imgView;
            }
        }
    },
    setLogFilesInfo(state, newData) {
        console.log(`setLogFilesInfo: ${JSON.stringify(newData)}`);
        state.logFilesInfo = newData;
        //state.logFilesInfo = [];
        //for (let element of newData) state.logFilesInfo.push(element);

    },
    setOperatingState(state, newData) {
        console.log(`setOperatingState: ${JSON.stringify(newData)}`);
        let devId = newData.devId;
        if (! (devId in state.deviceOperatingState)) state.deviceOperatingState[devId] = {} // Create empty object
        if ('hostMode' in newData) state.deviceOperatingState[devId]['hostMode'] = newData.hostMode;
        if ('alarm' in newData) state.deviceOperatingState[devId]['alarm'] = newData.alarm;
        if ('problemDetected' in newData) state.deviceOperatingState[devId]['problemDetected'] = newData.problemDetected;  
        if ('State' in newData) state.deviceOperatingState[devId]['State'] = newData.State;      
    },
    setPanelInfo(state, newData) {
        console.log(`setPanelInfo: ${JSON.stringify(newData)}`);
        let devId = newData.devId;
        if (! (devId in state.devicePanelInfo)) state.devicePanelInfo[devId] = {} // Create empty object
        if ('PanelInfo' in newData) state.devicePanelInfo[devId]['PanelInfo'] = newData.PanelInfo;    
    },
    setIOStatus(state, newData) {
        console.log(`setIOStatus: ${JSON.stringify(newData)}`);
        let devId = newData.devId;
        delete  newData.devId;
        state.deviceIOStatus[devId] = newData;
    },
    setDevInference(state, newData) {
        console.log(`setDevInference: ${JSON.stringify(newData)}`);
        let devId = newData.devId;
        if (! (devId in state.devInference)) state.devInference[devId] = {} // Create empty object
        state.devInference[devId] = newData;
    },
    setImageClass(state, imgClass) {
        if (imgClass) {
            let devId = imgClass.dev_id;
            let pos = imgClass.camera_pos;
            if (devId && pos !== undefined) {
                if ( ! (devId in state.imgClass)) state.imgClass[devId] = {};
                state.imgClass[devId][pos] = imgClass;
            }
        }
    },
    setLastWsPong(state, devId) {
        console.log(`setLastWsPong: ${devId}`);
        state.lastWsPong[devId] = new Date().getTime();
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
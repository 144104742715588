// Created by SPe on 27/11/22
// Page to watch device status

<template>
  <div class="inspection" ref="divContainer">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    
    <NavBar 
        :devName="devName" 
        :showBackButton="showBackButton" 
        :showSpinner="showSpinner"
        :servConnected="servConnected" 
        :devConnected="devConnected"
    />

    <div id="inspection" v-if="inspectionDataReady">
            <div  v-for="devId of getAllDevIdsWithInspectData" :key="devId">
                <table style="width: 100%"><tr>
                    <td><table>
                        <tr><td class="dataName">DevId</td><td class="dataValue">{{getDevInspectData(devId).DevId}}</td></tr>
                        <tr><td class="dataName">DevName</td><td class="dataValue">{{getDevInspectData(devId).DevName}}</td></tr>
                        <tr><td class="dataName">PublicIp</td><td class="dataValue">{{getDevInspectData(devId).PublicIp}}</td></tr>
                        <tr><td class="dataName">Ws Connected</td><td class="dataValue">{{getDevInspectData(devId).WsConnected}}</td></tr>
                        <tr><td class="dataName"># Web Clients</td><td class="dataValue">{{getDevInspectData(devId).NumWrtcClients}}</td></tr>
                    </table></td>
                    <td><table>
                        <tr><td class="dataName">HwType</td><td class="dataValue">{{getDevInspectData(devId).HwType}}</td></tr>
                        <tr><td class="dataName">HwVersion</td><td class="dataValue">{{getDevInspectData(devId).HwVersion}}</td></tr>
                        <tr><td class="dataName">SwVersion</td><td class="dataValue">{{getDevInspectData(devId).SwVersion}}</td></tr>
                        <tr><td class="dataName">SwPackage</td><td class="dataValue">{{getDevInspectData(devId).SwPackage}}</td></tr>
                        <tr><td class="dataName">RunningSince</td><td class="dataValue">{{getDevInspectData(devId).RunningSince}}</td></tr>
                    </table></td>
                    <td><table>
                        <tr><td class="dataName">HostIp</td><td class="dataValue">{{getDevInspectData(devId).HostIp}}</td></tr>
                        <tr><td class="dataName">Serial</td><td class="dataValue">{{getDevInspectData(devId).Serial}}</td></tr>
                        <tr><td class="dataName">Time</td><td class="dataValue">{{getDevInspectData(devId).Time}}</td></tr>
                        <tr><td class="dataName">ClickRate(1/Hour)</td><td class="dataValue">{{(getDevInspectData(devId).KeyClickRate*3600).toFixed(1)}}</td></tr>
                        <tr><td class="dataName">MacroRate(1/Hour)</td><td class="dataValue">{{(getDevInspectData(devId).KeyMacroRate*3600).toFixed(1)}}</td></tr>
                    </table></td>
                    <td><table>                 
                        <tr><td class="dataName">CPU Usage (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).CPUUsage, thrersholds=[70, 85], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).CPUUsage}}</td></tr>
                        <tr><td class="dataName">Memory Usage (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).MemoryUsage, thrersholds=[70, 85], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).MemoryUsage}}</td></tr>
                        <tr><td class="dataName">Disk Usage (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).DiskUsage, thrersholds=[80, 90], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).DiskUsage}}</td></tr>
                    </table></td>
                </tr></table>
            </div> 
        </div> 
        <!-- Footernbar -->
        <Footer /> 
  </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import { mapGetters } from 'vuex'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { Options, Vue } from 'vue-class-component'; 
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import { openUnifiedChannel, closeChannel,  joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'


@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters  
            //devName: store.getters['devices/getDevName'](this.$route.params.DevId), 
            allDevIds: [this.$route.params.DevId],
            inspectionDataReady: false,
            loaderTask: null,
        }
    },
    props: [],
    methods: {
        onDevConnected() {
            console.log(`Device: ${this.devId} is connected`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('new_inspection_data', this.onNewInspectionData);
            // Join InspectionData room
            joinRoomUnified(this.devId, 'InspectionData');
        },
        onNewInspectionData(devId, payLoad) {
            console.log(`new_inspection_data Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 5 seconds`);
                this.showSpinner = true;
            }, 5000);
            this.loadingOverlay.show = false;
            this.showSpinner = false;
            this.inspectionDataReady = true;
        },
        getDevInspectData(devId) {
            if (devId in store.state.devices.inspectData) {
                return store.state.devices.inspectData[devId];
            } else return {};
        },
        getImgView(devId, pos) {
            if (devId in store.state.devices.imgView) {
                if (pos in store.state.devices.imgView[devId]) return store.state.devices.imgView[devId][pos];
                else return {};
            } else return {};
        },
        joinAllRooms() {
            if (this.devId) {
                console.log(`Joining room: ${'InspectionData'}`)
                joinRoomUnified(this.devId, 'InspectionData');
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                console.log(`Leaving room: ${'InspectionData'}`)
                leaveRoomUnified(this.devId, 'InspectionData');
            }
        },
        changeColorRange(value, thrersholds, colors) {
            for (let index=0; index < thrersholds.length; index++) {
                let threshold = thrersholds[index];
                if (value < threshold) {
                    return {'background-color' : colors[index]};
                }
            } 
        // No below last threshold
        return {'background-color' : colors.at(-1)};                
        },
        changeColorMap(value, colorMap) {
            return {'background-color' : colorMap[value]};
        },
    },
    computed: {
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else null;
        },
        // FIlter allDevIds to return only devIds with data
        getAllDevIdsWithInspectData: function () {
            //return this.allDevIds.filter((devId) => {store.state.devices.inspectData[devId] !== undefined});
            let out = [];
            for (let devId of this.allDevIds) {
                if (store.state.devices.inspectData[devId] !== undefined) out.push(devId)
            }
            return out;
        },
        ...mapGetters('devices', [
            'getDevName',
        ]),
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('Inspection RKP View Created');
        document.title = `Inspection-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};                
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Inspection page being visible');
                // Join All rooms
                this.joinAllRooms();
            } else { //Hiden
                console.log('Inspection page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
            }
        };
    },
    unmounted() {
        console.log('Inspection View Unmounted')
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close all WebRTC conections
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);
            // Cancel loaderTask if any
            if (this.loaderTask) clearTimeout(this.loaderTask); 
        }
    },
})
export default class InspectionRKP extends Vue {}
</script>

<style scoped>
    * {
    box-sizing: border-box;
    }
    .column {
        float: left;
        padding: 10px;
    }
    .left {
        width: 70%;
    }
    .right {
        width: 30%;
    }
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    table, th, td {
        border: 1px solid black;
        vertical-align: top;
    }

    .image {
        width: 100%;
    }

    .container_cam_img {
        position: relative;
        text-align: center;
        color: white;
        margin: 0 10 0 10;
    }

    /* Bottom left text */
    .bottom-left {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }
    
    /* Top left text */
    .top-left {
        position: absolute;
        top: 8px;
        left: 16px;
    }
    
    /* Top right text */
    .top-right {
        position: absolute;
        top: 8px;
        right: 16px;
    }
    
    /* Bottom right text */
    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
    
    /* Centered text */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    table {
        text-align: left;
        position: relative;
        border-collapse: collapse; 
        text-indent: initial;
        white-space: normal;
        line-height: normal;
        font-weight: normal;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        font-variant: normal;
    }

    th, td {
        padding: 0.25rem;
    }

    .panelAllCam {
        background: rgb(182, 203, 235);
        z-index: 1;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        padding: 0.25rem;
        /*width: 100%;*/
    }

</style>



// Created by SPe on 27/11/22
// Page to view panel
<template>
    <div class="panel">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />
      
        <!-- Header  -->
        <div id="panel">            

            <div style="display: flex; justify-content: center;">
                <h1 class="py-2" style="text-align: center;">{{devName}} - {{keypadConfig.Status}}</h1>
                <i class="bi bi-file-lock cursor-pointer" style="font-size: 1.5em" @click="keypadLocked = !keypadLocked" :class="{'redColor': keypadLocked, 'greenColor': !keypadLocked}"></i>
            </div>

            <button class="bg-green-400" v-if="keypadConfig && keypadConfig.ChangeStatusEnabled" @click="changeKPConfig('ChangeStatusEnabled', false)">Control Externo Habilitado</button>
            <button class="bg-red-400" v-if="keypadConfig && !keypadConfig.ChangeStatusEnabled" @click="changeKPConfig('ChangeStatusEnabled', true)">Control Externo Desabilitado</button>

            <div class="display">
                <!-- <img  v-if="keypadConfig && !keypadConfig.statusForcedByExternal" v-bind:src="'data:image/jpeg;base64, ' + latest_display_img"  v-bind:alt="'Display'" class="image">
                <img  v-if="keypadConfig && keypadConfig.statusForcedByExternal" style="border: 5px solid red;" v-bind:src="'data:image/jpeg;base64, ' + latest_display_img"  v-bind:alt="'Display'" class="image"> -->
                <img  :style="displayStyle()" v-bind:src="'data:image/jpeg;base64, ' + latest_display_img"  v-bind:alt="'Display'" class="image">
            </div>

            <table style="width:20em; text-align: center">
                <tr align: center style="width:100%;">
                    <td style="width:3em; text-align: center"><button style="width:2.5em" class="keyButton" :class="{'sand': !justClicked['Sel'], 'blue': justClicked['Sel']}" @click="onKeyButton('Sel')">SEL</button></td>
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['Func1'], 'blue': justClicked['Func1']}" @click="onKeyButton('Func1')">FUNC1</button></td>
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['Func2'], 'blue': justClicked['Func2']}" @click="onKeyButton('Func2')">FUNC2</button></td>
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['Func3'], 'blue': justClicked['Func3']}" @click="onKeyButton('Func3')">FUNC3</button></td>                    
                </tr>
            </table>  
            <br>
            <table style="width:20em">
                <tr style="width:100%">
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['UpArrow'], 'blue': justClicked['UpArrow']}" @mousedown="onPressButton('UpArrow')" @mouseup="onReleaseButton('UpArrow')"><img class="mx-auto" :src="require('@/assets/pics/up-arrow.png')" style="height: 1em" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:20em">
                <tr style="width:100%">
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['LeftArrow'], 'blue': justClicked['LeftArrow']}" @mousedown="onPressButton('LeftArrow')"  @mouseup="onReleaseButton('LeftArrow')"><img class="mx-auto" :src="require('@/assets/pics/left-arrow.png')" style="height: 1em" /></button></td>
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['RightArrow'], 'blue': justClicked['RightArrow']}" @mousedown="onPressButton('RightArrow')"  @mouseup="onReleaseButton('RightArrow')"><img class="mx-auto" :src="require('@/assets/pics/right-arrow.png')" style="height: 1em" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:20em">
                <tr style="width:100%">
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['DownArrow'], 'blue': justClicked['DownArrow']}" @mousedown="onPressButton('DownArrow')"  @mouseup="onReleaseButton('DownArrow')"><img class="mx-auto" :src="require('@/assets/pics/down-arrow.png')" style="height: 1em" /></button></td>
                </tr>
            </table>  
            <br>
            <table style="width:20em">
                <tr style="width:100%">
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['End'], 'blue': justClicked['End']}" @click="onKeyButton('End')">END</button></td>
                    <td style="width:6em; text-align: center"><button style="width:5em" class="keyButton" :class="{'green': !justClicked['Enter'], 'blue': justClicked['Enter']}" @click="onKeyButton('Enter')">ENTER</button></td>
                </tr>
            </table> 
            <div v-if="macroList.length > 0" class="macros" id="macros" style="text-align: center">
                <p style="margin:0.1em">--------------- Macros ---------------</p>
                <table style="width:20em">
                    <tr v-for="row in numMacroRows" :key="row" style="width:100%">
                        <td v-for="col in numMacroColumns" :key="col" style="width:6em; text-align: center">
                            <!-- :disabled="!macroBeingExecuted && macroList[((row-1)*numMacroColumns + (col-1))]=='*CANCEL*'" -->
                            <button v-if="macroList.length > ((row-1)*numMacroColumns + (col-1))"
                                :title="JSON.stringify(macros[macroList[((row-1)*numMacroColumns + (col-1))]].Keys)"
                                style="width:5em" class="keyButton" 
                                :class="{
                                    'sand': macroList[((row-1)*numMacroColumns + (col-1))]!='*CANCEL*' && !justClicked[macroList[((row-1)*numMacroColumns + (col-1))]],
                                    'blue': macroList[((row-1)*numMacroColumns + (col-1))]!='*CANCEL*' && justClicked[macroList[((row-1)*numMacroColumns + (col-1))]],
                                    'red': macroList[((row-1)*numMacroColumns + (col-1))]=='*CANCEL*' 
                                }" 
                                @click="onMacroButton(macroList[((row-1)*numMacroColumns + (col-1))])">
                                    {{macroList[((row-1)*numMacroColumns + (col-1))]}}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import { mapGetters } from 'vuex'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { Options, Vue } from 'vue-class-component'; 
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel,  joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'

@Options({
    components: {
        LoadingOverlay,
        NavBar,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters
            devIp: this.$route.params.DevIp, // DevIp collected from Path Parameters   
            loaderTask: null,
            appMode: process.env.VUE_APP_MODE,

            keypadConfig: {}, // Keypad comfig 

            latest_display_img: null,   // Lates Display image receved from backend
            justClicked: {}, // Dict with key=keyName and value = True if was just pressed
            nowCliked: {}, // Dict with key=keyName and value = True if it is pressed
            bussy: false, // Whether the keyboard is bussy pressing buttons
            macroBeingExecuted: null, // Macro name being executed
            macroColorTask: null, // Task for keeping the macro button color during macro executio time
            numMacroColumns: 3, // Num of Macro columns
            keypadLocked: true, // Whether keypad is lock protected
        }
    },
    props: {
    },
    methods: {
        onDevConnected() {
            console.log(`Device: ${this.devId} is connected`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('new_display_img', this.onNewDisplayImg);
            registerUnifiedMessageCallBack('new_keypad_config', this.onNewKeyPadConfig);
            // Join KeypadData room
            joinRoomUnified(this.devId, 'KeypadData');
        },
        onNewDisplayImg(devId, payLoad) {
            console.log(`new_display_img Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad).substring(0, 100)}...`);
            this.loadingOverlay.show = false;
            this.showSpinner = false;
            this.latest_display_img = payLoad;
        },
        onNewKeyPadConfig(devId, payLoad) {
            console.log(`new_keypad_config Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            try {
                this.keypadConfig = payLoad;                
            } catch (error) {
                console.error(error);
            }
        },
        changeKPConfig(config, value) {
            if (confirm('Estas seguro que quieres cambiar el estado de control remoto?')) {
                console.log(`changeKPConfig. Config: ${config} To Value: ${value}`);
                if (config === 'ChangeStatusEnabled') {
                    sendMessageUnified(this.devId, 'setUserConfig', {'Integration.ChangeStatus.Enabled': value});
                } else {
                    console.error(`changeKPConfig error. Config not recognized: ${config}`)
                }
            } else console.log(`changeKPConfig change not permitted`);
        },

        // connectToDevice() {
        //     // Connect to device
        //     console.log(`DevId: ${this.devId}`);
        //     if (this.devId) {
        //         console.log(`Connecting via WebRTC to  device: ${this.devId}`)
        //         if (! isDeviceWrtconnected(this.devId)) connectWrtc(this.devId, this.onChannelConnected, this.onChannelDataReceived, this.onChannelClosed); //Master/single
        //     }    
        // },
        // onChannelConnected(devId) {
        //     try {
        //         console.log(`onChannelConnected for device: ${devId}`);
        //         this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};  
        //         // Join KeypadData room
        //         joinRoomUnified(devId, 'KeypadData');
        //     } catch(error) {
        //         console.error(`onChannelConnected exception: ${error}`);
        //     }
        // },
        // onChannelDataReceived(devId, msgType, payLoad) {
        //     try {
        //         console.log(`onChannelDataReceived from device: ${devId} with Type: ${msgType} and PayLoad: ${payLoad}`);
        //         if (msgType === 'new_display_img')  {
        //             this.loadingOverlay.show = false;
        //             this.showSpinner = false;
        //             this.latest_display_img;
        //         } else if (msgType === 'new_gui_info') {
        //             console.log(`onChannelDataReceived New GUI Info from device: ${devId}`);
        //             // Re-connect to devices. Just in case slaves exists
        //             this.connectToDevice();
        //         }
        //     } catch(error) {
        //         console.error(`onChannelDataReceived exception: ${error}`);
        //     }       
        // },
        // onChannelClosed(devId) {
        //     console.log(`Settings page. onWrtcClossed for device: ${devId}`);
        //     this.showSpinner = true;
        // },

        renderDisplayDelayed() {
            // If there is an ongoing timer --> Cancel it and create a new one
            if (this.renderDisplayTask !== null) clearTimeout(this.renderDisplayTask); // Cancel task
            // Create a delayed task to render the display
            this.renderDisplayTask = setTimeout(() => {
                this.renderDisplayTask = null;
                console.log(`Rendering Display`);
                this.lcd.render(this.ctx, 0, 0, 300, 100);
            }, 200);
        },        
        onKeyButton(keyName) {
            console.log(`onKeyButton: ${keyName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            if (!this.bussy) { // Prevent press button while keypad is bussy
                this.justClicked[keyName] = true;
                setTimeout(() => {this.justClicked[keyName]=false}, 350);
                console.log(`Sending Key Press: ${keyName}`);
                sendMessageUnified(this.devId, 'keyClick', keyName, 'WebSocket');
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },
        onPressButton(keyName) {
            console.log(`onPressButton: ${keyName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            if (!this.bussy) { // Prevent press button while keypad is bussy
                this.justClicked[keyName] = true;
                this.nowCliked[keyName] = true;
                this.bussy = true;
                // Start burst of keypress
                sendMessageUnified(this.devId, 'keyPress', keyName, 'WebSocket');
                setTimeout(() => {this.pressKeyAndCheckLater(keyName)}, 1000);
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },
        onReleaseButton(keyName) {
            console.log(`onReleaseButton: ${keyName}`);
            setTimeout(() => {this.justClicked[keyName]=false}, 150);
            this.nowCliked[keyName] = false;
            this.bussy = false;
            console.log(`Resending Key keyRelease: ${keyName}`);
            sendMessageUnified(this.devId, 'keyRelease', keyName, 'WebSocket');
        },
        // Check periodically if key is still pressed --> send keyPress
        pressKeyAndCheckLater(keyName) {
            console.log(`pressKeyAndCheck: ${keyName}, nowCliked: ${this.nowCliked[keyName]}`);
            if (this.nowCliked[keyName]) {
                console.log(`Resending Key Press: ${keyName}`);
                sendMessageUnified(this.devId, 'keyPress', keyName, 'WebSocket');
                setTimeout(() => {this.pressKeyAndCheckLater(keyName)}, 1000);
            }
        },
        onMacroButton(macroName) {
            console.log(`onMacroButton: ${macroName}`);
            if (this.keypadLocked) { // Check if keypad is locked
                alert('Keypad is locked. You can unlock in lock icon close to device name');
                return;
            }
            // Cancel ongoing macro execution
            if (macroName == '*CANCEL*' && this.macroBeingExecuted) {
                this.justClicked[macroName] = true;
                console.log(`Sending Macro CANCEL: ${macroName}`);
                sendMessageUnified(this.devId, 'keyClick', macroName, 'WebSocket');
                this.justClicked[this.macroBeingExecuted]=false; 
                this.bussy=false;
                this.macroBeingExecuted=null;
                if (this.macroColorTask) {
                    clearTimeout(this.macroColorTask);
                    this.macroColorTask = null;
                } 
            // Any normal macro
            } else if (!this.bussy || macroName == '*CANCEL*') { // Prevent press button while keypad is bussy
                this.bussy = true;
                this.justClicked[macroName] = true;
                console.log(`Sending Macro Press: ${macroName}`);
                sendMessageUnified(this.devId, 'keyClick', macroName, 'WebSocket');
                let colorTimeSeconds = 0;
                let macroKeys = this.macros[macroName].Keys;
                for (var keyNTime of macroKeys) {
                    console.log(`keyNTime: ${JSON.stringify(keyNTime)}`);
                    let time = Math.max(keyNTime[1], 0.5); // Get the key time (in seconds)
                    colorTimeSeconds += time;
                }
                console.log(`onMacroButton: ${macroName}. Macro time: ${colorTimeSeconds} seconds`);
                this.macroBeingExecuted = macroName;
                this.macroColorTask = setTimeout(() => {this.justClicked[macroName]=false; this.bussy=false; this.macroBeingExecuted=null;}, colorTimeSeconds * 1000);
            } else alert('Keypad is bussy. Wait a second before pressing a new button');
        },
        joinAllRooms() {
            if (this.devId) {
                joinRoomUnified(this.devId, 'KeypadData');
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                leaveRoomUnified(this.devId, 'KeypadData');
            }
        },
        displayStyle() {
            if (this.keypadConfig && this.keypadConfig.statusForcedByExternal) return {border: '5px solid red'}
            else return null;
        }
        
    },
    computed: {
        guiInfo: function () { if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo; else return null},
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                if (store.state.devices.deviceInfo[this.devId]) return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
                else return 'Unknown';
            } else null;
        },
        ...mapGetters('devices', [
            'getDevName',
        ]),
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not        
        macroList: function () { if (this.guiInfo) { return this.guiInfo.MacroList; } else return {}; },
        macros: function () { if (this.guiInfo) { return this.guiInfo.Macros; } else return {}; },
        numMacroRows: function () { if (this.macroList.length > 0) { return  Math.ceil(this.macroList.length / this.numMacroColumns);} else return 0;}
    },
    // Lifecycle hooks
    async mounted() {
        console.log(`PanelRKP-RGB View Created. Device: ${this.devId}, Mode: ${this.appMode}, IP: ${this.devIp}`);
        document.title = `Panel-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};    
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('PanelRKP-RGB page being visible');
                // Join All rooms
                this.joinAllRooms();
            } else { //Hiden
                console.log('PanelRKP-RGB page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
            }
        };        
    },
    unmounted() {
        console.log('PanelRKP-RGB View Unmounted')
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close WebRTC/SocketIO conections
            console.log(`Closing WebRTC connection to device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);      
        }
    },
})
export default class Panel_RKP_RGB extends Vue {}
</script>

<style scoped>
    body {background-color: rgb(220, 221, 218);}
        .keyButton {
            background-color: #80b32e;
            border-color: black;
            border-radius: 50%;
            color: white;
            padding: 0.1em;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 1em;
            margin: 0px 0px;
        }
        .display {            
            width: 320px;            
        }
        .image {
            margin: 0.0em 0.5em 0.2em 0.5em;
            border-width: 0.2rem;
            border-color: rgb(16, 14, 141);;
            border-radius: 0.5em;
        }
        .panel {
            width: 335px;
            border-color: rgb(161, 25, 100);
            border-radius: 1em;
            background-color: rgb(145, 230, 241);
        }
        .white {
            background-color: white;     
        }
        .blue {
            background-color: rgb(101, 134, 241);     
        }
        .purple {
            background-color: #d16de6;
        }
        .green {
            background-color: #79ad4f;
        }
        .sand {
            background-color: #91752a;
        }
        .red {
            background-color: #f86611;
        }
        .redColor {
            color: #f86611;
        }
        .greenColor {
            color: #79ad4f;
        }
</style>



// Created by SPe on 08/11/21
// Page to remote view Device logs

<template>
  <div class="logs" ref="divContainer">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <NavBar 
        :devName="devName" 
        :showBackButton="showBackButton" 
        :showSpinner="showSpinner"
        :showRefreshButton="showRefreshButton"
        @refreshButtonPress="refreshLogFiles"
        :servConnected="servConnected" 
        :devConnected="devConnected"
    />
        
    <div class="container-sm py-3 my-3">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <DataTable :value="logFilesInfo" v-model:selection="selectedRow" style="font-size: 0.9em"
                    selectionMode="single" dataKey="Name"
                    stripedRows autoLayout=true :scrollable="true" scrollHeight="flex">
                    <Column field="Name" header="Name" :sortable="true" style="width: 35%">
                        <template #body="slotProps">
                            <span @click="openFile(slotProps)">{{slotProps.data.Name}}</span>
                        </template>                    
                    </Column>
                    <Column field="Size" header="Size" :sortable="true" style="width: 20%"></Column>
                    <Column field="ModificationTime" header="ModificationTime" :sortable="true" style="width: 25%"></Column>
                    <!-- Include a column with download icon ans link to download file -->
                    <Column field="Download" header="Download" :sortable="false" style="width: 20%">
                        <template #body="slotProps">
                            <div style="margin: 0 auto; text-align:center;"><i class="pi pi-download" @click="downloadFile(slotProps)"></i></div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
    <!-- Footernbar -->
    <Footer />    
  </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import store from '@/store/index.js';
import appConfig from '@/config.js';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { sendWsMessage, doKeepWsConnected, dontKeepWsConnected } from '@/library/websocket'
import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel } from '@/library/client-unified-send'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        DataTable,
        Column,
        Button,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showRefreshButton: true,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters        
            selectedRow: null,
            windowRef: null,
            logFilesInfo: [],
            firstTimeDevConnected: true,
        }
    },
    props: [],
    methods: {
        onDevConnected() {
            if (!this.firstTimeDevConnected) {
                console.log(`Logs. Device: ${this.devId} is already connected`);
                return; // Do nothing if already connected
            }
            this.firstTimeDevConnected = false;
            console.log(`Logs. Device: ${this.devId} is connected`);
            // send SendSetClientPermissions message to backend
            sendWsMessage('SendSetClientPermissions', {ClientId: this.clientId, DevId: this.devId, UserId: store.state.login.email});
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('setLogFilesInfo', this.onNewLogFilesInfo);
            registerUnifiedMessageCallBack('setLogFileRawContent', this.onNewLogFileRawContent);
            // TODO: Improve this. Small delay to ensure SendSetClientPermissions takes effect before sending getLogFilesInfo
            // await new Promise(r => setTimeout(r, 1000));
            // Get Logs info. Repeat if not get in few seconds
            sendMessageUnified(this.devId, 'getLogFilesInfo', {});
            this.getLogFilesInfoTask = setInterval(() => {
                if (Object.keys(this.logFilesInfo).length === 0) {
                    // Just in case... SendSetClientPermissions message to backend
                    let clientPermisssions = {ClientId: this.clientId, DevId: this.devId}
                    sendWsMessage('SendSetClientPermissions', clientPermisssions);
                    // Send message to get Log Files
                    sendMessageUnified(this.devId, 'getLogFilesInfo', {});
                } else clearInterval(this.getLogFilesInfoTask);
            }, 2000);
            
        },
        onNewLogFilesInfo(devId, payLoad) {
            console.log(`onNewLogFilesInfo from device: ${devId}`);
            this.logFilesInfo = payLoad;
            this.loadingOverlay.show = false;
            this.showSpinner = false;
        },
        getLogFilesInfo() {
            return store.state.devices.logFilesInfo;
            //return store.state.devices.deviceInfo;
        },
        refreshLogFiles() {
            this.showSpinner = true;
            sendMessageUnified(this.devId, 'getLogFilesInfo', {});
        },
        openFile(slotProps) {
            console.log(`OpenFile: ${JSON.stringify(slotProps)}`);
            let fileName = slotProps.data.Name;
            let route = this.$router.resolve({ path: `/logfile/${fileName}/${this.devId}`});
            this.windowRef = window.open(route.href, '_blank');
        },
        downloadFile(slotProps) {
            console.log(`downloadFile: ${JSON.stringify(slotProps)}`);
            let fileName = slotProps.data.Name;
            this.loadingOverlay = {show: true, text: `Downloading ${fileName}`};
            sendMessageUnified(this.devId, 'getLogFileRawContent', {FileName: fileName});
        },
        onNewLogFileRawContent(devId, payLoad) {
            console.log(`onNewLogFileRawContent from device: ${devId} and file: ${payLoad.FileName}`);
            this.loadingOverlay.show = false;
            let fileName = payLoad.FileName;
            let fileContent = payLoad.Content;
            let blob = new Blob([fileContent], {type: 'text/plain'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
    },
    computed: {   
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else null;
        },
        clientId: function () { return store.state.login.clientId },
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('Logs View Mounting');
        document.title = `Logs-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};   
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);   

        // Page visibility control
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Logs page being visible');
                doKeepWsConnected();
            } else { //Hidden
                console.log('Logs page being hidden');
                dontKeepWsConnected();
            }
        };
    },
    unmounted() {
        console.log('Logs View Unmounted')
        if (this.devId) {
            // Close WebRTC connection
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);
        }
    },    
})
export default class Logs extends Vue {}
</script>

<style>
    
</style>



// Created by SPe on 
// Page to 
<template>
    <div class="logFile">
        <div style="display: flex;  flex-flow: column;  height: 100%;">
            <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
            <div style="flex: 0 1 auto;">
                <NavBar 
                    :devName="fileName + '@' + devName"
                    :showBackButton="showBackButton" 
                    :showSpinner="showSpinner"
                    :showRefreshButton="showRefreshButton"
                    @refreshButtonPress="refresh"
                    :servConnected="servConnected" 
                    :devConnected="devConnected"
                />
            </div>
            <div class="flex-fill" style="flex: 1 1 auto;">
                <iframe id="iframe" :srcdoc="fileContent" ref="iframe"></iframe>
            </div>
            <!-- Footernbar -->
            <Footer />
        </div>      
    </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import { sendWsMessage, doKeepWsConnected, dontKeepWsConnected } from '@/library/websocket'
import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel } from '@/library/client-unified-send'
import Button from 'primevue/button';

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Button,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showRefreshButton: true,
            showSpinner: false,
            fileName: this.$route.params.FileName, // fileName collected from Path Parameters
            devId: this.$route.params.DevId,
            fileContent: null,
            fileDownloaded: false,
            scrollY: 0, // scroll position when  refresh
            firstTimeDevConnected: true,
        }
    },
    props: [],
    methods: {
        async onDevConnected(devId) {
            if (!this.firstTimeDevConnected) {
                console.log(`LogFile. Device: ${devId} is already connected`);
                return; // Do nothing if already connected
            }
            this.firstTimeDevConnected = false;
            console.log(`LogFile. Device: ${devId} is connected`);
            // send SendSetClientPermissions message to backend
            sendWsMessage('SendSetClientPermissions', {ClientId: this.clientId, DevId: this.devId, UserId: store.state.login.email});
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('setLogFileContent', this.onNewFileContent);         
            registerUnifiedMessageCallBack('setLogFileRawContent', this.onNewFileRawContent);         
            this.loadingOverlay = {show: true, text: `Loading ${this.fileName} from: ${this.devName}`};
            await new Promise(r => setTimeout(r, 1000)); //Small delay to ensure SendSetClientPermissions takes effect before sending getLogFilesInfo
            sendMessageUnified(this.devId, 'getLogFileContent', {FileName: this.fileName});            
        },
        async onWRtcConnected(devId) {
            console.log(`LogFile. WebRTC is connected with Device: ${devId}`);
            // Just in case still file is not already loaded through WebSocket
            if (!this.fileContent) sendMessageUnified(this.devId, 'getLogFileContent', {FileName: this.fileName});              
        },
        refresh() {
            console.log(`refresh with scroll: ${window.scrollY}`);
            this.showSpinner = true;
            this.loadingOverlay = {show: true, text: `Reloading ${this.fileName}`};
            console.log(`Scroll: ${this.$refs.iframe.contentWindow.scrollY}`);
            this.scrollY = this.$refs.iframe.contentWindow.scrollY;
            // Ask device to get file content
            this.fileDownloaded = false;
            sendMessageUnified(this.devId, 'getLogFileContent', {FileName: this.fileName});
        },
        onNewFileContent(devId, payLoad) {
            console.log(`onNewFileContent from device: ${devId} and file: ${payLoad.FileName}`);
            console.log(` Setting scroll: ${this.scrollY}`);
            if (payLoad.FileName === this.fileName) {
                this.fileContent = payLoad.Content;
                this.fileDownloaded = true;
                setTimeout(() => {this.$refs.iframe.contentWindow.scrollTo(0, this.scrollY);}, 100);                
            } else console.error(`onNewFileContent error. File (${payLoad.FileName}) content not as expected: ${this.fileContent}`);
            setTimeout(() => {
                    this.loadingOverlay.show = false;
                    this.showSpinner = false;
                }, 500
            );             
        },
    },
    computed: {       
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else null;
        },
        clientId: function () { return store.state.login.clientId },
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('LogFile View Mounting');
        document.title = `Log-${this.fileName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};  
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected, null, this.onWRtcConnected);

        // Page visibility control
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('LogFile page being visible');
                doKeepWsConnected();
            } else { //Hidden
                console.log('LogFile page being hidden');
                dontKeepWsConnected();
            }
        };
    },
    unmounted() {
        console.log('LogFile View Unmounted');
        // Close WebRTC connection
        console.log(`Closing WebRTC connection to master device: ${this.devId}`);
        sendMessageUnified(this.devId, 'closeWrtcConnection', {});
        closeChannel('WebRTC', this.devId, 0);
    },    
})
export default class LogFile extends Vue {}
</script>
// Style only for this component/view
<style>
    html { height:100%; }
    body { height:100%; width:100%; margin:0;}
    iframe { height:100%; width:100%; display:block; }

    #app { height:100% }
    #nav { height:100% }

    .logFile { height:100% }
        
</style>



// Created by SPe on 08/11/21
// Page to remoete view and control ProInspector devices

<template>
  <div class="inspection" ref="divContainer">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    
    <NavBar 
        :devName="devName" 
        :showBackButton="showBackButton" 
        :showSpinner="showSpinner"
        :servConnected="servConnected" 
        :devConnected="devConnected"
    />

    <div id="inspection" v-if="inspectionDataReady">
            <div  v-for="devId of getAllDevIdsWithInspectData" :key="devId">
                <table style="width: 100%"><tr>
                    <td><table>
                        <tr><td class="dataName">DevId</td><td class="dataValue">{{getDevInspectData(devId).DevId}}</td></tr>
                        <tr><td class="dataName">DevName</td><td class="dataValue">{{getDevInspectData(devId).DevName}}</td></tr>
                        <tr><td class="dataName">Customer</td><td class="dataValue">{{getDevInspectData(devId).Customer}}</td></tr>
                        <tr><td class="dataName">PublicIp</td><td class="dataValue">{{getDevInspectData(devId).PublicIp}}</td></tr>
                        <tr><td class="dataName">Ws Connected</td><td class="dataValue">{{getDevInspectData(devId).WsConnected}}</td></tr>
                        <tr><td class="dataName"># Web Clients</td><td class="dataValue">{{getDevInspectData(devId).NumWrtcClients}}</td></tr>
                    </table></td>
                    <td><table>
                        <tr><td class="dataName">HwVersion</td><td class="dataValue">{{getDevInspectData(devId).HwVersion}}</td></tr>
                        <tr><td class="dataName">SwVersion</td><td class="dataValue">{{getDevInspectData(devId).SwVersion}}</td></tr>
                        <tr><td class="dataName">SwPackage</td><td class="dataValue">{{getDevInspectData(devId).SwPackage}}</td></tr>
                        <tr><td class="dataName">License Exp.</td><td class="dataValue">{{getDevInspectData(devId).LicenseExpDate}}</td></tr>
                        <tr><td class="dataName">Project</td><td class="dataValue">{{getDevInspectData(devId).ProjectName}}</td></tr>
                        <tr><td class="dataName">RunningSince</td><td class="dataValue">{{getDevInspectData(devId).RunningSince}}</td></tr>
                    </table></td>
                    <td><table>
                        <tr><td class="dataName">ClusterRole</td><td class="dataValue">{{getDevInspectData(devId).ClusterRole}}</td></tr>
                        <tr><td class="dataName">HostIp</td><td class="dataValue">{{getDevInspectData(devId).HostIp}}</td></tr>
                        <tr><td class="dataName">Serial</td><td class="dataValue">{{getDevInspectData(devId).Serial}}</td></tr>
                        <tr><td class="dataName">Time</td><td class="dataValue">{{getDevInspectData(devId).Time}}</td></tr>
                        <tr><td class="dataName">Engine</td><td class="dataValue">{{getDevInspectData(devId).EngineId}}</td></tr>
                    </table></td>
                    <td><table>                 
                        <tr><td class="dataName">CPU (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).CPUUsage, thrersholds=[70, 85], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).CPUUsage}}</td></tr>
                        <tr><td class="dataName">GPU (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).GPUUsage, thrersholds=[70, 85], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).GPUUsage}}</td></tr>
                        <tr><td class="dataName">Memory (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).MemoryUsage, thrersholds=[75, 85], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).MemoryUsage}}</td></tr>
                        <tr><td class="dataName">Disk (%)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).DiskUsage, thrersholds=[90, 95], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).DiskUsage}}</td></tr>
                        <tr><td class="dataName">Board (ºC)</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).BoardTemp, thrersholds=[70, 80], colors=['white', 'orange', 'red'])]">{{getDevInspectData(devId).BoardTemp}}</td></tr>
                    </table></td>
                    <td><table>             
                        <tr><td class="dataName">Num Config Cams</td><td class="dataValue">{{getDevInspectData(devId).NumConfigCameras}}</td></tr>
                        <tr><td class="dataName">Num Found Cams</td><td class="dataValue" :style="[changeColorRange(getDevInspectData(devId).NumFoundCameras, thrersholds=[getDevInspectData(devId).NumConfigCameras], colors=['red', 'white'])]">{{getDevInspectData(devId).NumFoundCameras}}</td></tr>
                        <tr><td class="dataName">TriggerMode</td><td class="dataValue" data-key="TriggerMode">{{getDevInspectData(devId).TriggerMode}}</td></tr>
                        <tr><td class="dataName">HostMode</td><td class="dataValue" data-key="HostMode">{{getDevInspectData(devId).HostMode}}</td></tr>
                        <tr><td class="dataName">State  <button class="toggleButton" @click="onStateToggle()">Toggle</button></td><td class="dataValue">{{getDevInspectData(devId).State}}</td></tr>
                    </table></td>
                    <td><table>
                        <tr><td class="dataName">Lights <button class="toggleButton" @click="onLightToggle('White')">Toggle</button></td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).Lights, colorMap={ON : 'yellow', OFF : 'grey'})]">{{getDevInspectData(devId).Lights}}</td></tr>
                        <tr><td class="dataName">Alarm <button class="toggleButton" @click="onLightToggle('Alarm')">Toggle</button></td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).Alarm, colorMap={ON : 'orange', OFF : 'grey'})]">{{getDevInspectData(devId).Alarm}}</td></tr>
                        <tr><td class="dataName">Relay <button class="toggleButton" @click="onLightToggle('Relay')">Toggle</button></td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).Relay, colorMap={ON : 'red', OFF : 'grey'})]">{{getDevInspectData(devId).Relay}}</td></tr>
                        <tr><td class="dataName">Button </td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).Button, colorMap={ON : 'blue', OFF : 'grey'})]">{{getDevInspectData(devId).Button}}</td></tr>
                        <tr><td class="dataName">Testing <button class="toggleButton" @click="onTestingToggle()">Testing</button></td><td class="dataValue">{{getDevInspectData(devId).Testing}}</td></tr>
                    </table></td>
                </tr></table>
                <table style="width: 100%"><tr>
                    <!-- Camera -->
                    <td v-for="(camData, pos) in getDevInspectData(devId).CameraData" :key="pos"><table>
                        <tr>
                            <td colspan="2">
                                <div class="container_cam_img" v-if="getImgView(devId, pos)">                            
                                    <img  v-bind:src="'data:image/jpeg;base64, ' + getImgView(devId, pos).image_jpg_base64"  v-bind:alt="'Camera '+ pos" class="image">                
                                    <div class="bottom-left">{{relativeDateTime(getImgView(devId, pos).time_stamp * 1000, true)}}</div>
                                    <div class="top-left"></div>
                                    <div class="top-right"></div>
                                    <div class="bottom-right">{{"Quality: " + round(getImgView(devId, pos).jpeg_quality, 2) + ' %'}}</div>
                                    <div class="centered"></div>                                             
                                </div>
                            </td>
                        </tr>          
                        <tr><td class="dataName">Name</td><td class="dataValue">{{camData.Name}}</td></tr>
                        <tr><td class="dataName">Id</td><td class="dataValue">{{camData.Id}}</td></tr>
                        <tr><td class="dataName">Position</td><td class="dataValue">{{camData.Position}}</td></tr>
                        <tr><td class="dataName">Height</td><td class="dataValue" :style="[changeColorRange(camData.Height, thrersholds=[2448], colors=['red', 'white'])]">{{camData.Height}}</td></tr>
                        <tr><td class="dataName">Width</td><td class="dataValue" :style="[changeColorRange(camData.Width, thrersholds=[3264], colors=['red', 'white'])]">{{camData.Width}}</td></tr>
                        <tr><td class="dataName">FPS</td><td class="dataValue" :style="[changeColorRange(camData.FPS, thrersholds=[3, 4], colors=['red', 'orange', 'white'])]">{{camData.FPS}}</td></tr>
                        <tr><td class="dataName">Frame Number</td><td class="dataValue">{{camData.NumFrame}}</td></tr>
                        <tr><td class="dataName">Frame Delay (s)</td><td class="dataValue" :style="[changeColorRange(camData.FrameDelay, thrersholds=[5, 6], colors=['white', 'orange', 'red'])]">{{camData.FrameDelay}}</td></tr>
                        <tr><td class="dataName">Error Freq (1/s)</td><td class="dataValue" :style="[changeColorRange(camData.ErrorFreq, thrersholds=[0.1, 0.5], colors=['white', 'orange', 'red'])]">{{camData.ErrorFreq}}</td></tr>
                        <tr><td class="dataName">Infer Freq (1/s)</td><td class="dataValue">{{camData.InferFreq}}</td></tr>
                        <tr><td class="dataName">Running Mode</td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).RunningMode, colorMap={listening : 'grey', watching : 'green'})]">{{camData.RunningMode}}</td></tr>
                        <tr><td class="dataName">Mov State</td><td class="dataValue" :style="[changeColorMap(getDevInspectData(devId).MovilState, colorMap={lowMobility : 'grey', highMobility : 'green'})]">{{camData.MovilState}}</td></tr>
                        <tr><td class="dataName">Mic Level</td><td class="dataValue" :style="[changeColorRange(camData.MicLevel, thrersholds=[5000, 8000], colors=['white', 'orange', 'red'])]">{{camData.MicLevel}}</td></tr>
                        <tr><td class="dataName">Mic Filt Level</td><td class="dataValue" :style="[changeColorRange(camData.MicFiltLevel, thrersholds=[3000, 5000], colors=['white', 'orange', 'red'])]">{{camData.MicFiltLevel}}</td></tr>
                        <tr><td class="dataName">FocusDistance</td><td class="dataValue">{{camData.FocusDistance}}</td></tr>
                    </table></td>
                </tr></table>
            </div> 
        </div> 
        <!-- Footernbar -->
        <Footer />      
  </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import { mapGetters } from 'vuex'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { Options, Vue } from 'vue-class-component'; 
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { relativeDateTime } from '@/library/utils'
import { doKeepWsConnected, dontKeepWsConnected } from '@/library/websocket'
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel,  joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters  
            //devName: store.getters['devices/getDevName'](this.$route.params.DevId), 
            inspectionDataReady: false,
            loaderTask: null,
            firstTimeDevConnected: true,
        }
    },
    props: [],
    methods: {
        onDevConnected(devId) {
            if (!this.firstTimeDevConnected) {
                console.log(`Logs. Device: ${this.devId} is already connected`);
                return; // Do nothing if already connected
            }
            this.firstTimeDevConnected = false;
            console.log(`Inspection. Device: ${devId} is connected through WebSocket`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('new_inspection_data', this.onNewInspectionData);
            registerUnifiedMessageCallBack('new_image_view', this.onNewImageView);
            // Join InspectionData & CameraViews room
            joinRoomUnified(this.devId, 'InspectionData');  // Master
            joinRoomUnified(this.devId, 'CameraViews');  // Master
            for (let devId of this.slaveDevIds) {
                joinRoomUnified(devId, 'InspectionData');  // Slave
                joinRoomUnified(devId, 'CameraViews');  // Slave
            }
        },
        onNewInspectionData(devId) {
            console.log(`new_inspection_data Received fron device: ${devId}. Data: ${JSON.stringify(store.state.devices.inspectData[devId])}`);
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 5 seconds`);
                this.showSpinner = true;
            }, 5000);
            this.loadingOverlay.show = false;
            this.inspectionDataReady = true;
        },
        onNewImageView(devId) {
            console.log(`new_image_view Received fron device: ${devId}`);
        },
        getDevInspectData(devId) {
            if (devId in store.state.devices.inspectData) {
                return store.state.devices.inspectData[devId];
            } else return {};
        },
        getImgView(devId, pos) {
            if (devId in store.state.devices.imgView) {
                if (pos in store.state.devices.imgView[devId]) return store.state.devices.imgView[devId][pos];
                else return {};
            } else return {};
        },
        onLightToggle(lightName) {
            try {
                console.log(`onLightToggle: ${lightName}`);
                sendMessageUnified(this.devId, 'toggleLight', lightName);
                for (let devId of this.slaveDevIds) {
                    console.log(`Sending toggleLight to slave device: ${devId}`);
                    sendMessageUnified(devId, 'toggleLight', lightName);
                }
            } catch(error) {
                console.error(`onLightToggle exception: ${error}`);
            }
        },
        onTestingToggle() {
            try {
                console.log(`onTestingToggle`);
                sendMessageUnified(this.devId, 'toggleTesting', {});
                for (let devId of this.slaveDevIds) {
                    console.log(`Sending toggleTesting to slave device: ${devId}`);
                    sendMessageUnified(devId, 'toggleTesting');
                }
            } catch(error) {
                console.error(`onTestingToggle exception: ${error}`);
            }
        },
        onStateToggle() {
            console.log(`onStateToggle`);
            sendMessageUnified(this.devId, 'toggleState', {});
            for (let devId of this.slaveDevIds) {
                console.log(`Sending toggleState to slave device: ${devId}`);
                sendMessageUnified(devId, 'toggleState', {});
            }
        },
        changeColorRange(value, thrersholds, colors) {
            for (let index=0; index < thrersholds.length; index++) {
                let threshold = thrersholds[index];
                //console.log(`changeColorRange: Value: ${value}, Threshold: ${threshold}`);
                if (value < threshold) {
                    return {'background-color' : colors[index]};
                }
            } 
        // No below last threshold
        return {'background-color' : colors.at(-1)};                
        },
        changeColorMap(value, colorMap) {
            return {'background-color' : colorMap[value]};
        },
        relativeDateTime(TimeStampMs, seconds) {
            return relativeDateTime(TimeStampMs, seconds);
        },
        round(number, digits) {
            if (number) return number.toFixed(digits);
            else return 0;
        },
        joinAllRooms() {
            if (this.devId) {
                // Master/Single
                joinRoomUnified(this.devId, 'InspectionData');
                joinRoomUnified(this.devId, 'CameraViews');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    joinRoomUnified(devId, 'InspectionData');
                    joinRoomUnified(devId, 'CameraViews');
                }
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                // Master/Single
                console.log(`Leaving 'InspectionData' and 'CameraViews' rooms for device: ${this.devId}`);
                leaveRoomUnified(this.devId, 'InspectionData');
                leaveRoomUnified(this.devId, 'CameraViews');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    console.log(`Leaving 'InspectionData' and 'CameraViews' rooms for slave device: ${devId}`);
                    leaveRoomUnified(devId, 'InspectionData');
                    leaveRoomUnified(devId, 'CameraViews');
                }
            }
        }
    },
    computed: {
        slaveDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return store.getters['devices/getSlaveDevIds'](this.$route.params.DevId); else return [];},
        allDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return [this.$route.params.DevId].concat(store.getters['devices/getSlaveDevIds'](this.$route.params.DevId)); else return []; },
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else null;
        },
        // FIlter allDevIds to return only devIds with data
        getAllDevIdsWithInspectData: function () {
            //return this.allDevIds.filter((devId) => {store.state.devices.inspectData[devId] !== undefined});
            let out = [];
            for (let devId of this.allDevIds) {
                if (store.state.devices.inspectData[devId] !== undefined) out.push(devId)
            }
            return out;
        },
        ...mapGetters('devices', [
            'getDevName',
        ]),
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('Inspection View Created');
        document.title = `Inspection-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};         
        // Connect to Master/Single and Slaves devices if any
        if (this.devId) {
            console.log(`Connecting via WebRTC to Master/Single device: ${this.devId}`)
            openUnifiedChannel(this.devId, this.onDevConnected);
            // Slaves
            for (let devId of this.slaveDevIds) {
                console.log(`Connecting via WebRTC to slave device: ${devId}`);
                openUnifiedChannel(devId, this.onDevConnected);
            }
        }    
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Inspection page being visible');
                // Join All rooms
                this.joinAllRooms();
                doKeepWsConnected();
            } else { //Hiden
                console.log('Inspection page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
                dontKeepWsConnected();
            }
        };
    },
    unmounted() {
        console.log(`Inspection View Unmounted for device: ${this.devId}`)
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close all WebRTC conections
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //sendMessageUnified(this.devId, 'closeWrtcConnection', {});
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds); // Master
            for (let devId of this.slaveDevIds) {
                console.log(`Closing WebRTC connection to slave device: ${devId}`);
                //sendMessageUnified(devId, 'closeWrtcConnection', {});
                closeChannel('WebRTC', devId, appConfig.WebRTCCloseDelaySeconds); // Slave
            }
            // Cancel loaderTask if any
            if (this.loaderTask) clearTimeout(this.loaderTask); 
        }
    },
})
export default class Inspection extends Vue {}
</script>

<style scoped>
    * {
    box-sizing: border-box;
    }
    .column {
        float: left;
        padding: 10px;
    }
    .left {
        width: 70%;
    }
    .right {
        width: 30%;
    }
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    table, th, td {
        border: 1px solid black;
        vertical-align: top;
    }

    .image {
        width: 100%;
    }

    .container_cam_img {
        position: relative;
        text-align: center;
        color: white;
        margin: 0 10 0 10;
    }

    /* Bottom left text */
    .bottom-left {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }
    
    /* Top left text */
    .top-left {
        position: absolute;
        top: 8px;
        left: 16px;
    }
    
    /* Top right text */
    .top-right {
        position: absolute;
        top: 8px;
        right: 16px;
    }
    
    /* Bottom right text */
    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
    
    /* Centered text */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    table {
        text-align: left;
        position: relative;
        border-collapse: collapse; 
        text-indent: initial;
        white-space: normal;
        line-height: normal;
        font-weight: normal;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        font-variant: normal;
    }

    th, td {
        padding: 0.25rem;
    }

    .panelAllCam {
        background: rgb(182, 203, 235);
        z-index: 1;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        padding: 0.25rem;
        /*width: 100%;*/
    }

    .toggleButton {
        @apply rounded-md bg-gray-300 border border-gray-800 py-1 px-1
    }

</style>



// Created by SPe on 08/01/23
// Footer
<template>
<footer class="bg-darkBlue text-gray-50">
    <div>
        <span class="pr-5">© 2022 AInspect Solutions</span>
        <span class="pr-5">www.ainspect.io</span>
        <i class="bi bi-envelope px-1"></i>
        <span>info@ainspect.io</span>
    </div>
</footer>
    

</template>

<script>


</script>

<style scoped>
    
</style>



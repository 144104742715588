import store from '@/store/index.js';
import auth from '@/library/auth'; 
import { unifiedProcessMessage } from '@/library/client-unified-receive';
import appConfig from '@/config.js';

// Glabal (module) variables
let webSocket = null; // Web Socket object
let connected = false; // Whether is connected
let messageCallBacks = {}; // dictionary with key=message type and value a list with callbacks
let keepConnected = false; // Whether to reconnect if any WebSocket disconnection
let onConnectedCBList = []; // List of onConnectedCB functions
let onDataCBList = []; // List of onDataCB functions
let onCloseCBList = []; // List of onCloseCB functions
let multiPartMsgs = {}; // Dictionary with key = MulPartMsgId and value a dict with multipart msg info


export function connect(onConnectedCB=null, onDataCB=null, onCloseCB=null) {    
  
  if (onDataCB && !onDataCBList.includes(onDataCB)) onDataCBList.push(onDataCB); // Add onDataCB to onDataCBList
  if (onCloseCB && !onCloseCBList.includes(onCloseCB)) onCloseCBList.push(onCloseCB); // Add onCloseCB to onCloseCBList

  let onConnectedCBCalled = false; // Flag to avoid repeating calling onConnectedCB

  if (! webSocket) { // If websocket is not connected
    console.log(`Starting connection to Web Socket`);
    if (onConnectedCB && !onConnectedCBList.includes(onConnectedCB)) onConnectedCBList.push(onConnectedCB); // Add onConnectedCB to onConnectedCBList
    const jwtToken = auth.getJWTToken();
    //console.log('Connecting to Web Socket with token: ' + jwtToken)
    webSocket = new WebSocket(`${appConfig.WebSocketURL}/?Source=Client&&Authorizer=${jwtToken}`);

    // Create a delayed task to retry open if not open
    webSocket.retryOpenTask = setTimeout(function() {
      console.error(`WebSocket connection Error. No backend message in ${appConfig.WebSocketOpenRetryPeriod} ms. Closing and retrying...`);
      close(); // Close connection
      // Reopen
      setTimeout(function() {connect(onConnectedCB, onDataCB, onCloseCB);}, 500);
    }, appConfig.WebSocketOpenRetryPeriod);
    
    webSocket.onopen = async () => {
      console.log(`WebSocket Open. email: ${store.state.login.email}, clientId: ${store.state.login.clientId}, regDevId: ${store.state.login.regDevId}`);

      //await new Promise(r => setTimeout(r, 10000));
      
      // Send SetClientInfo
      const setClientInfoData = {
        ClientId: store.state.login.clientId,
        UserId: store.state.login.email,
        DevId: store.state.login.regDevId,
      }
      sendWsMessage('SetClientInfo', setClientInfoData);      
    };
    webSocket.onclose = () => {
      console.error('Web Socket disconnected')
      store.commit('connection/setWsConnected', false);
      connected = false;
      webSocket = null;
      // Reopen if requied
      if (keepConnected) setTimeout(function() {connect(onConnectedCB, onDataCB, onCloseCB);}, 500);
      // Call onCloseCB call back if any
      for (let cb of onCloseCBList) cb();
    };
    webSocket.onerror = (error) => {
      console.error(`Web Socket Error: ${error.message}`);
      store.commit('connection/setWsConnected', false);
      connected = false;
    };
    webSocket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        console.log(`Web Socket Message received: ${JSON.stringify(data).substring(0, 150)}...`);
        // Check if is a multipart message
        if (data.MulPartMsgId) { // If is a multipart message
          if (!multiPartMsgs[data.MulPartMsgId]) { // This is first multipart message
            let pendingMsgIndxs = [...Array(data.MultPartNumParts).keys(data.MultPartNumParts - 1)]; // Generate all pending indexes
            multiPartMsgs[data.MulPartMsgId] = { // Create MultiPart message object
              MulPartMsgId: data.MulPartMsgId,
              MultPartNumParts: data.MultPartNumParts,
              PendingMsgIndxs: pendingMsgIndxs,
              Parts: {},
              LastMsgTimeStamp: null
            }; 
          }
          // Add part
          multiPartMsgs[data.MulPartMsgId].Parts[data.MultPartMsgIndx] = data.Part;
          multiPartMsgs[data.MulPartMsgId].LastMsgTimeStamp = new Date().getTime();
          // Remove data.MultPartMsgIndx from PendingMsgIndxs
          multiPartMsgs[data.MulPartMsgId].PendingMsgIndxs.splice(multiPartMsgs[data.MulPartMsgId].PendingMsgIndxs.indexOf(data.MultPartMsgIndx), 1);
          console.log(`Pending Msg Indxs (${data.MulPartMsgId}): ${multiPartMsgs[data.MulPartMsgId].PendingMsgIndxs}`);
          // Check if multipart message is completed
          if (multiPartMsgs[data.MulPartMsgId].PendingMsgIndxs.length === 0) { // This is last part --> ensamble message
            let dataStr = '';
            for (let indx of [...Array(data.MultPartNumParts).keys(data.MultPartNumParts - 1)]) {
              dataStr += multiPartMsgs[data.MulPartMsgId].Parts[indx];
            } 
            delete multiPartMsgs[data.MulPartMsgId]; // Remove multipart message
            console.log(`Last multipart message received of message: : ${data.MulPartMsgId}`);
            data = JSON.parse(dataStr); // Set data to complete multipart message
            // Check if some old multipart message is pending to be completed
            let now = new Date().getTime();
            for (let key in multiPartMsgs) {
              if (multiPartMsgs[key].LastMsgTimeStamp && (now - multiPartMsgs[key].LastMsgTimeStamp) > appConfig.WebSocketMultiPartMsgTimeout) {
                console.error(`Timeout for multipart message: ${key}. PendingMsgIndxs: ${multiPartMsgs[key].PendingMsgIndxs}`);
                delete multiPartMsgs[key]; // Remove multipart message
              }
            }
          } else return;
        }
        // Continue if it is not a multipart message or the multipart message is finished
        // Process message
        processMessage(data);
        const msgType = data.Type;
        console.log(`processMessage. DATA: ${JSON.stringify(data)}`);
        // Check if is a SetAuthorization message. First message from Back-End meaning that is connected
        if (msgType === 'SetAuthorization') {
          console.log(`SetAuthorization message received --> Client is connected)`);
          store.commit('connection/setWsConnected', true);
          connected = true;
          clearInterval(webSocket.retryOpenTask); // Cancel open retry peridic task
          // Call onConnected call back if any
          if (!onConnectedCBCalled && onConnectedCB) {
            console.log(`Callling onConnectedCB`);
            onConnectedCBCalled = true; // To avoid repetitions
            for (let cb of onConnectedCBList) cb();
          }     
        }
        // If any other valid message (comming from devices) Call all Call-Back if any
        if (msgType && onDataCBList.length > 0) { 
          console.log(`Callling onDataCB with data: ${JSON.stringify(data).substring(0, 100)}...`);
          for (let cb of onDataCBList) cb(data);
        }
    };

  } else { // Already connected or connecting
    console.log(`Web Socket Already connected or connecting`);
    if (connected) { // If already connected --> call onConnectedCB
      if (onConnectedCB) onConnectedCB();
    } else {
      if (onConnectedCB && !onConnectedCBList.includes(onConnectedCB)) onConnectedCBList.push(onConnectedCB); // Add onConnectedCB to onConnectedCBList
    }
  }
}

// Close websocket
export function close() {
  console.log('Closing Web Socket');
  if (webSocket) {
    webSocket.close();
    webSocket = null;
  } else console.error(`WebSocket close error. Websocket already closed`);

}

export function removeOnConnectedCB(onConnectedCB) {
  console.log(`Removing onConnectedCB from onConnectedCBList`);
  if (onConnectedCBList.includes(onConnectedCB)) onConnectedCBList.splice(onConnectedCBList.indexOf(onConnectedCB), 1);
  else console.error(`onConnectedCB not found in onConnectedCBList. Number of onConnectedCBs: ${onConnectedCBList.length}`);
}

// Return whether is connected or not
export function isWsConnected() {
  return connected;
}

export function doKeepWsConnected() {
  console.log('Setting Web Socket to keep connected');
  keepConnected = true;
}

export function dontKeepWsConnected() {
  console.log('Setting Web Socket to not keep connected');
  keepConnected = false;
}

// Register message callback function
export function registerWsMessageCallBack(msgType, callBackFunction) {
  console.log(`Registering Message CallBack on message type: ${msgType}`);
  let callBacks = messageCallBacks[msgType];
  //console.log(`callBacks: ${callBacks}`);
  if (callBacks && ! callBacks.includes(callBackFunction)) messageCallBacks[msgType].push(callBackFunction);
  else {
    messageCallBacks[msgType] = [callBackFunction];
    //console.log(`messageCallBacks[msgType]: ${messageCallBacks[msgType]}`);
  }
  //console.log(`messageCallBacks[msgType] (${typeof(messageCallBacks[msgType])}): ${messageCallBacks[msgType]}`);
}

// Unregister message callback function
export function unregisterWsMessageCallBack(msgType, callBackFunction) {
  console.log(`Unregistering Message CallBack on message type: ${msgType}`);
  let callBacks = messageCallBacks[msgType];
  //console.log(`callBacks: ${callBacks}`);
  if (callBacks) messageCallBacks[msgType].pop(callBackFunction);
}

// Send message to server
// Data should be an object/dictionary
// Return true if ok
export function sendWsMessage(path, data, connectIfNotConnected=false) {
  try {
    let dataStr = JSON.stringify(data);
    // Check if need to connect
    if (!webSocket || (connectIfNotConnected && !connected)) {
      console.log(`WebSocket sendWsMessage. Not connected and need to be connected`);
      connect(function() {
        console.log(`WebSocket sendWsMessage. Just connected to send message with path: ${path}`);
        sendWsMessage(path, data);
      }, null, null);
    } else {      
      console.log(`Sending WebSocket Message. Path: ${path}, Data: ${dataStr}.`)
      // Set 'action' as type so AWS API Gateway enrute it
      data.action = path
      // Send message to server
      webSocket.send(JSON.stringify(data));
      return true;
    }
  } catch(error) {
    console.error(`WebSocket.sendWsMessage exception: ${error}`);
    return false;
  }  
}

// Use Signalling server to send message to client (device)
// Return true if ok
export function sendWsMessageToClient(devId, msgType, payLoad, connectIfNotConnected=false) {
  console.log(`sendWsMessageToClient, DevId: ${devId}, Type: ${msgType}, PayLoad: ${JSON.stringify(payLoad)} connectIfNotConnected: ${connectIfNotConnected}`);
  let deviceLocation = 'Remote'
  if (store.getters['devices/isDeviceLocal'](devId)) deviceLocation = 'Local';
  let data = {
    Type: msgType,
    DevId: devId,
    DevLocation: deviceLocation,
    ClientId: store.state.login.clientId,
    UserId: store.state.login.email,
    Payload: payLoad
  }
  return sendWsMessage('ForwardMessageToDevice', data, connectIfNotConnected);
}


// Process Received Message
function processMessage(data) {

  const msgType = data.Type;
  if (msgType === 'SetDevicesInfo') { // This is the first message received from Back-End with useful info for client once is checked that user is registered
    // console.log(`SetDevicesInfo message received with Data: ${JSON.stringify(data).substring(0, 100)}...`);
    const ClientPublicIp = data.ClientPublicIp;
    console.log(`ClientPublicIp: ${ClientPublicIp}`);
    store.commit('login/setClientPublicIp', ClientPublicIp)
    store.commit('devices/setDevicesInfo', data)
    const twilioIceToken = data.TwilioICEToken
    store.commit('connection/setTwilioIceToken', twilioIceToken)
  } else if (msgType === 'AddDeviceToClient') {
    // console.log(`AddDeviceToClient message received with Data: ${JSON.stringify(data)}`);
    store.commit('devices/addDeviceInfo', data);
  } else if (msgType === 'RemoveDeviceFromClient') {
    // console.log(`RemoveDeviceFromClient message received with Data: ${JSON.stringify(data)}`);
    store.commit('devices/removeDeviceInfo', data);
  } else if (msgType === 'WRTCAnswer') {
    // console.log(`WRTCAnswer message receieved with Data: ${JSON.stringify(data)}`);
  } else if (msgType === 'WRTIceCandidate') {
    // console.log(`WRTIceCandidate message receieved with Data: ${JSON.stringify(data)}`);
  } else if (msgType === 'UnauthorizedUser') {
    console.log(`UnauthorizedUser message receieved with Data: ${JSON.stringify(data)}`);
  } else if (msgType === 'SetAuthorization') {
    console.log(`SetAuthorization message receieved with Data: ${JSON.stringify(data)}`);
    store.commit('login/setAuthorization', data);
  } else {
    // Process as unified message from device
    let devId = data.DevId;
    let payLoad = data.PayLoad;
    unifiedProcessMessage('WebSocket', devId, msgType, payLoad); 
  }

  // Call call-backs if any
  let callBacks = messageCallBacks[msgType];
  // console.log(`callBacks for msgType: ${msgType} : ${callBacks}`);
  if (callBacks) {
    for (var functionCB of callBacks) {
      //console.log(`Calling ${functionCB}`);
      functionCB(data);
      //console.log(`After Calling`);
    }
  }
}

// Created by SPe on 02/03/2024
// component for Labeling Selected images
<template>
  <div class="root">
    <h2>Label multiple images</h2>

    <table style="width:100%">
        <tr class="withBorder" style="width:100%;">
            <th class="withBorder">Defect</th>
            <th class="withBorder">Add Label</th>
            <th class="withBorder">Remove Label</th>
        </tr>
        <tr v-for="(defect, index) in defectList" :key="index" class="withBorder">
            <td class="withBorder">{{defect}}</td>
            <td class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="defectsSelectedAdd[defect]" @click="onDefectSelectedAdd(defect)"></td>
            <td class="withBorder"><input type="checkbox" id="cbox" value="checkbox" v-model="defectsSelectedRemove[defect]" @click="onDefectSelectedRemove(defect)"></td>
        </tr>
    </table>

    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-button-red rounded-lg">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import appConfig from '@/config.js';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            defectsSelectedAdd: {},
            defectsSelectedRemove: {},
            
        }
    },
    props: {
        defectList: {type: Array, default: []}, // List of defects including 'Ok'
    },
    methods: {
        submitForm() {
            console.log(`Submit Form. Selected Add: ${JSON.stringify(this.defectsSelectedAdd)} and Selected Remove: ${JSON.stringify(this.defectsSelectedRemove)}`);
            
            this.$emit("labelSelectedImages", 
                {   
                    defectsSelectedAdd: this.defectsSelectedAdd,
                    defectsSelectedRemove: this.defectsSelectedRemove,
                });
            console.log('Form successfully submitted.');
        },
        onDefectSelectedAdd(defect) {
            console.log(`onDefectSelectedAdd: ${defect} with previous value: ${this.defectsSelectedAdd[defect]}`);
            // When checking box
            if (!this.defectsSelectedAdd[defect]) {
                this.defectsSelectedAdd[defect] = true;
                // Ensure that the other checkbox is unchecked
                if (this.defectsSelectedRemove[defect]) this.defectsSelectedRemove[defect] = false;
                // If 'Ok' is selected, ensure that all other defects are added to defectsSelectedRemove
                if (defect === 'Ok') {
                    for (let defect of this.defectList) {
                        if (defect !== 'Ok') { 
                            this.defectsSelectedRemove[defect] = true;
                            this.defectsSelectedAdd[defect] = false;
                        }
                    }
                }
                // If any non 'Ok' defect is selected, ensure that 'Ok' is removed from defectsSelectedAdd
                if (defect !== 'Ok') {
                    this.defectsSelectedRemove['Ok'] = true;
                    this.defectsSelectedAdd['Ok'] = false;
                }
            } else { // When unchecking box
                this.defectsSelectedAdd[defect] = false;
            }
            console.log(`onDefectSelectedAdd. Selected Add: ${JSON.stringify(this.defectsSelectedAdd)} and Selected Remove: ${JSON.stringify(this.defectsSelectedRemove)}`);
        },
        onDefectSelectedRemove(defect) {
            console.log(`onDefectSelectedRemove: ${defect} with previous value: ${this.defectsSelectedRemove[defect]}`);
            // Only continue wnhen the checkbox is checked
            if (!this.defectsSelectedRemove[defect]) {
                this.defectsSelectedRemove[defect] = true;
                // Ensure that the other checkbox is unchecked
                if (this.defectsSelectedAdd[defect]) this.defectsSelectedAdd[defect] = false;
            } else { // When unchecking box
                this.defectsSelectedRemove[defect] = false;
                // If any non 'Ok' defect is unselected, ensure that 'Ok' is not added to defectsSelectedAdd
                if (defect !== 'Ok') {
                    this.defectsSelectedAdd['Ok'] = false;
                }
            }
            
        },
        cancel() {
            this.$emit("cancelLabelSelectedImages", null);
        },       
        
    },
    computed: {    
        appConfig() {return appConfig},
    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Label Selected Images Mounting');      

        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Enter") this.submitForm();
            else if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);

        // Initialize defectsSelected and defectsUnselected
        for (let defect of this.defectList) {
            this.defectsSelectedAdd[defect] = false;
            this.defectsSelectedRemove[defect] = false;
        }       
    },
    unmounted() {
        console.log('New Engine Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class LabelSelectedImageForm extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        min-width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: rgb(0, 5, 0);
        background: rgb(249, 250, 249);
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
    .withBorder {
        border:1px solid black;
    }
</style>



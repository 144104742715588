import { io } from "socket.io-client";
import store from '@/store/index.js';
import { unifiedProcessMessage } from '@/library/client-unified-receive';
//import appConfig from '@/config.js';

// Glabal (module) variables
let sioPerDevId = {}; // Dictionary with sio object per devId

export function connect(devId, devIp, onConnectedCB=null, onDataCB=null, onCloseCB=null) {  

  if (! (devId in sioPerDevId)) { // No socket io for device
    console.log(`Connecting to device: ${devId}, IP: ${devIp} SocketIO`);
    let socketIo = io(`ws://${devIp}`);
    sioPerDevId[devId] = socketIo;
      
    socketIo.on("connect", () => {
      console.log(`SocketIO Connected of device: ${devId} to IP: ${devIp}`);
      store.commit('connection/setSioConnected', {DevId: devId, Value: true});
      // Call onConnected call back if any
      if (onConnectedCB) onConnectedCB(devId);
    });

    socketIo.on("disconnect", (reason) => {
      console.error(`SocketIO disconnected of device: ${devId} from IP: ${devIp} with reason: ${reason}`);
      store.commit('connection/setSioConnected', {DevId: devId, Value: false});      
      socketIo = null;
      delete sioPerDevId[devId];
      if (onCloseCB) onCloseCB(devId);
      // try to reconnect if disconnected by server
      if (reason === "io server disconnect") {
        setTimeout(function() {connect(onConnectedCB, onDataCB, onCloseCB);}, 500);
      }
    });

    socketIo.on("connect_error", () => {
      console.log(`SocketIO connect error to IP: ${devIp}`);
    });

    socketIo.onAny((msgType, payLoad) => {
      console.log(`SocketIO Message received from device: ${devId}. Type: ${msgType}, Payload: ${JSON.stringify(payLoad)}`);
      unifiedProcessMessage('SocketIO', devId, msgType, payLoad); 
      if (onDataCB) onDataCB(devId, msgType, payLoad);
    });
    
  } else { // Already connected or connecting
    console.log(`SocketIO Already connected or connecting`);
  }
}

// Close SocketIO connection to device
export function close(devId) {
  console.log(`Closing SocketIO Connection for device: ${devId}`);
  if (devId in sioPerDevId) {
    sioPerDevId[devId].close();
    delete sioPerDevId[devId];
  } else console.error(`SocketIO closing error. No SocketIO connection stablished with device: ${devId}`);
}

// Send message to device
// Data should be an object/dictionary
// Return true if was sent
export function sendSioMessage(devId, msgType, payLoad) {
  try {
    console.log(`Sending SocketIO message to device: ${devId}, Type: ${msgType}, PayLoad: ${JSON.stringify(payLoad)}`);
    if (devId in sioPerDevId) { // SocketIO connected to device
      const socketIo = sioPerDevId[devId];
      socketIo.emit(msgType, payLoad);
      return true;
    } else { // No SocketIO connection stablished with device
      console.error(`sendSioMessage error. Device: ${devId} does not have a SocketIO conection`);
      return false;      
    }
  } catch(error) {
    console.error(`SocketIO.sendSioMessage exception: ${error}`);
    return false;
  }  
}

// Return true if device (devId) is connected
export function isDeviceSioConnected(devId) {
  return devId in sioPerDevId;
}


// Store module for Projects related info

const state = {
    rootData: null,
    customersList: null,
    usersList: null,
    projectsList: null,
    devicesList: null,
}

const getters = {
}

const actions = {    
}

const mutations = {

    setRootData(state, data) {
        state.rootData = data;
    },
    setCustomersData(state, data) {
        state.customersList = data;
    },
    setUsersData(state, data) {
        state.usersList = data;
    },
    setProjectsData(state, data) {
        state.projectsList = data;
    },
    setDevicesData(state, data) {
        state.devicesList = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};